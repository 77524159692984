<template>
  <div
    v-if="unitCost || suggestedSale"
    class="card-subprices mb-4"
  >
    <p
      v-if="unitCostFlag && hasCustomPrice({ price: unitCost })"
      class="text-subprices fs-10 mb-0"
    >
      {{ $t('unitCost') }}
      <Transition
        name="slide-fade"
        mode="out-in"
      >
        <span
          :key="unitCost"
          class="d-inline-flex"
        >
          {{ formatPrice(Number(unitCost)) }}
        </span>
      </Transition>
    </p>
    <p
      v-if="suggestedSaleFlag && hasCustomPrice({ price: suggestedSale })"
      class="text-subprices fs-10 mb-0"
    >
      {{ $t('suggestedSale') }}
      {{ formatPrice(Number(suggestedSale)) }}
    </p>
  </div>
</template>
<script>
import ProductMixin from '@/components/mixins/ProductMixin';
import { getStorageBottler } from '@/utils/config';
import { hasCustomPrice } from '@/utils/pages';

export default {
  mixins: [ProductMixin],
  props: {
    unitCost: {
      type: String,
      required: false,
      default: '',
    },
    suggestedSale: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      unitCostFlag: false,
      suggestedSaleFlag: false,
    };
  },
  created() {
    const bottlerStorage = getStorageBottler();
    this.unitCostFlag = bottlerStorage?.unitCost;
    this.suggestedSaleFlag = bottlerStorage?.suggestedSale;
  },
  methods: {
    hasCustomPrice,
  },
};
</script>
