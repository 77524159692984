<template>
  <div />
</template>
<script>
import router from '@/router';
import { isStandaloneActivated } from '@/utils/pages';

export default {
  data() {
    return {
      isStandaloneActivated: isStandaloneActivated(),
    };
  },
  async created() {
    await this.$store.dispatch('auth/logout').then(() => {
      if (this.isStandaloneActivated) router.navigateToPage('/login');
      else router.navigateToPage('/unauthenticated');
    });
  },
};
</script>
