import { get, put } from '@/utils/httpClient';

async function getProductsFavorites() {
  const result = await get({
    url: 'products/favorites',
  });

  if (result.status) {
    return result.status === 200 && result.data ? result.data : undefined;
  }

  return result.message.includes('timeout') ? undefined : [];
}

async function setProductsFavorites({ sku, isFavorite }) {
  const result = await put({
    url: 'products/favorites',
    data: { sku, isFavorite },
  });

  return result.status === 204;
}

export default { getProductsFavorites, setProductsFavorites };
