<script>
import { mapGetters } from 'vuex';
import { SET_ORDER_TYPE } from '@/constants/feature';
import { openModalNotification } from '@/utils/pages';

export default {
  computed: {
    ...mapGetters({
      orderType: 'getOrderType',
      selectedDate: 'getSelectDate',
    }),
  },
  methods: {
    // eslint-disable-next-line consistent-return
    getToastDescription() {
      if (this.orderType === SET_ORDER_TYPE.scheduledOrder) {
        return this.$t('order.deliveryOption.toastDescriptionScheduledOrder');
      }
      if (this.orderType === SET_ORDER_TYPE.recurrentOrder) {
        return this.$t('order.deliveryOption.toastDescriptionRecurrentOrder');
      }
    },
    // eslint-disable-next-line consistent-return
    getToastHeader() {
      if (this.orderType === SET_ORDER_TYPE.scheduledOrder) {
        return this.$t('order.deliveryOption.toastHeaderScheduled');
      }
      if (this.orderType === SET_ORDER_TYPE.recurrentOrder) {
        return this.$t('order.deliveryOption.toastHeaderRecurrent');
      }
    },
    showToastAlert() {
      openModalNotification(this.$bvModal, {
        title: this.getToastHeader(),
        description: this.getToastDescription(),
        okTitle: this.$t('ok'),
      });
    },
    isScheduledOrderCorrectlySelected() {
      if (this.orderType?.length && !this.selectedDate?.length) {
        this.showToastAlert(this.orderType);
        this.isDeliveryDateNotSelected = true;
      }
    },
  },
};
</script>
