<template>
  <div class="select-component d-flex justify-content-end mt-3">
    <b-form-select
      v-model="selected"
      :options="populateOptions()"
      class="mb-3"
      :aria-label="$t('ariaLabel.filterProducts')"
    >
      <!-- This slot appears above the options from 'options' prop -->
      <template v-slot:first>
        <b-form-select-option :value="null">
          {{ $t('packages') }}
        </b-form-select-option>
      </template>
    </b-form-select>
  </div>
</template>

<script>
export default {
  props: {
    packages: {
      type: [Object, Array],
      required: true,
    },
  },
  data() {
    return {
      selected: null,
    };
  },
  watch: {
    selected: {
      deep: true,
      handler() {
        this.$emit('selected', this.selected);
      },
    },
  },
  methods: {
    populateOptions() {
      const options = [];
      Object.keys(this.packages)
        .sort()
        .forEach((p) => {
          options.push({ value: p, text: p });
        });

      return options;
    },
  },
};
</script>

<style scoped>
:deep(.custom-select) {
  max-width: 50%;
}

@media only screen and (min-width: 850px) {
  :deep(.custom-select) {
    max-width: 250px;
  }
}
</style>
