<template>
  <div class="page__content">
    <div
      v-if="loading"
      class="loading"
    >
      <b-spinner label="Loading..." />
    </div>
  </div>
</template>

<script>
import redirectURL from '@/utils/redirect';
import router from '@/router';

export default {
  data() {
    return {
      loading: true,
    };
  },
  beforeCreate() {
    this.loading = true;
  },
  async created() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    router.navigateToPage(redirectURL.getRoutebyQueryString(urlParams));
    this.loading = false;
  },
};
</script>
