/* eslint-disable no-param-reassign */
import apiPromotion from '@/api/promotion';

const getProducts = async () => {
  const productsPayload = await apiPromotion.getPromotions();
  return productsPayload.map((pd) => {
    const prodDeepLevel = pd.product || pd;
    return { product: prodDeepLevel, quantity: 1 };
  });
};

export default {
  state: {
    products: [],
  },
  getters: {
    getProducts(state) {
      return state.products;
    },
  },
  mutations: {
    setProducts(state, payload) {
      state.products = payload;
    },
    setPromotionFavorite(state, payload) {
      const product = state.products.find((p) => p.product.sku === payload.sku);
      if (product) {
        product.product.isFavorite = payload.isFavorite;
      }
    },
  },
  actions: {
    async getPromotions({ commit }) {
      const products = await getProducts();
      commit('setProducts', products);
    },
  },
};
