import { put } from '@/utils/httpClient';

async function updateUserSession(key, value) {
  const result = await put({
    url: 'user/session',
    data: {
      key,
      value,
    },
  });

  return result && result.status === 200 ? result.data : undefined;
}

export default { updateUserSession };
