import apiClient from '@/api/client';

export default {
  state: {
    clientProfileInfo: {},
  },
  getters: {
    getClient(state) {
      return state.clientProfileInfo;
    },
  },
  mutations: {
    setClient(state, payload) {
      // eslint-disable-next-line no-param-reassign
      state.clientProfileInfo = payload;
    },
  },
  actions: {
    setClient({ commit }, value) {
      commit('setClient', value);
    },
    async getClient({ commit }) {
      try {
        const client = await apiClient.getClient();
        commit('setClient', client);
      } catch (error) {
        commit('setClient', []);
      }
    },
  },
};
