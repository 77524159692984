import { get } from '@/utils/httpClient';

async function getPaymentLimit() {
  const paymentMethod = 'paymentMethod';

  const result = await get({
    url: `payment/limit/${paymentMethod}`,
  });

  if (result.status) {
    return result.status === 200 && result.data ? parseFloat(result.data) : 0;
  }

  return result.message.includes('timeout') ? undefined : 0;
}

export default { getPaymentLimit };
