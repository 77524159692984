<template>
  <div class="page__content">
    <Breadcrumb
      :title="$t('pageTitles.promotions')"
      :sub-title="name"
    >
      <b-button
        variant="primary"
        class="promotion-button mb-3 mx-3"
        @click="showPromotionDetails"
      >
        {{ $t('viewPromotionDetails') }}
      </b-button>
    </Breadcrumb>

    <div class="container max-section-width">
      <div
        v-if="loading"
        class="spinner-center"
      >
        <div
          class="spinner-border"
          role="status"
        >
          <div>
            <span class="sr-only center">Loading...</span>
          </div>
        </div>
      </div>

      <div
        v-else
        class="row"
      >
        <ul
          id="products-page"
          class="col list-unstyled d-flex flex-wrap justify-content-center pl-1 pr-1"
        >
          <li
            v-for="product in products"
            :key="product.product.sku"
            class="product__box"
          >
            <Product
              :product="product"
              :promotion-nav="false"
              @add-to-cart="addItemToCart"
            />
          </li>
        </ul>
      </div>
      <NotFound
        v-if="error"
        msg="notFound.product"
      />
    </div>
    <Monitor />
  </div>
</template>
<script>
/* eslint-disable func-names */
import { mapActions, mapGetters } from 'vuex';
import Product from '@/components/product/Product.vue';
import Breadcrumb from '@/components/shared/Breadcrumb.vue';
import Monitor from '@/components/Monitor.vue';
import NotFound from '@/components/NotFound.vue';
import { openModalNotification } from '@/utils/pages';

export default {
  components: {
    Product,
    Breadcrumb,
    Monitor,
    NotFound,
  },
  data() {
    return {
      products: [],
      comboId: '',
      name: '',
      description: '',
      error: false,
      loading: true,
    };
  },
  computed: {
    ...mapGetters({
      combos: 'getCombos',
    }),
  },
  watch: {
    combos: {
      handler() {
        this.comboId = this.$route.params.comboId;
        this.setProducts();
        this.loading = false;
      },
      deep: true,
    },
  },
  async created() {
    if (this.combos.length > 0) {
      this.comboId = this.$route.params.comboId;
      this.setProducts();
      this.loading = false;
    }
  },
  mounted() {
    this.showPromotionDetails();
  },
  methods: {
    ...mapActions(['addItemToCart']),
    setProducts() {
      const combo = this.combos.find((c) => c.id === this.comboId);
      // eslint-disable-next-line no-restricted-globals
      if (combo && isNaN(this.comboId) === false) {
        this.name = combo.name;
        this.description = combo.description;
        this.products = combo.productItems.map(({ comboDetails, ...p }) => ({
          quantity: 1,
          product: p,
        }));
      } else {
        this.error = true;
      }
    },
    showPromotionDetails() {
      const h = this.$createElement;
      openModalNotification(this.$bvModal, {
        title: [
          h(
            'span',
            {
              style: { color: 'green', fontWeight: 500 },
            },
            ` ${this.$t('combo.quantityComboTitle')}`
          ),
        ],
        description: this.description
          .split('\n')
          .map((row) => h('p', [row]))
          .concat([
            h('p', this.$t('combo.quantityComboTitle')),
            h('p', this.$t('combo.quantityComboDescription')),
          ]),
        okTitle: this.$t('ok'),
      });
    },
  },
};
</script>
<style scoped>
.generic-combo-name {
  white-space: pre-wrap;
}
.promotion-button {
  width: 100%;
}

@media (min-width: 992px) {
  .promotion-button {
    width: 992px;
  }
}
</style>
