var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-3 d-flex",attrs:{"id":"product-item"}},[_c('div',{staticClass:"card-body d-flex flex-column justify-content-between mb-3 p-0 position-relative"},[_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"product__img-container w-100 text-center"},[_c('div',{staticClass:"position-relative d-block"},[_c('ImgGeneral',{staticClass:"responsive img-product pt-2",attrs:{"alt":_vm.printAlt,"url":_vm.product.product.imageUrl,"show-fallback-img":true,"show-loader-before-init":true}}),(_vm.product.product.iconBannerUrl)?_c('ImgGeneral',{staticClass:"position-absolute promotion-overlay-image",attrs:{"alt":_vm.$t('ariaLabel.promotionIcon'),"url":_vm.product.product.iconBannerUrl,"is-one-px-placeholder":true}}):_vm._e(),_c('hr',{staticClass:"m-0"}),(_vm.showTag)?_c('ProductTag'):_vm._e()],1)]),_c('div',{staticClass:"px-2 pt-2"},[_c('div',{staticClass:"d-flex mb-2"},[_c('h4',{staticClass:"product__title my-0 fs-12 text-break",attrs:{"translate":"no"}},[_vm._v(" "+_vm._s(_vm.product.product.description)+" ")]),_c('div',{staticClass:"fs-20 ml-2 heart-icon text-center"},[(_vm.isCallingFavorite === false)?_c('b-icon',{staticClass:"h4 m-0",attrs:{"icon":_vm.isFavorite ? 'heart-fill' : 'heart'},on:{"click":function($event){return _vm.favoriteClick()}}}):_c('b-spinner',{staticClass:"mb-1",attrs:{"label":"Loading..."}})],1)]),_c('b-container',{attrs:{"fluid":""}},[_c('b-row',{attrs:{"align-v":"center"}},[_c('b-col',{staticClass:"pl-0"},[_c('ProductPriceDetails',{attrs:{"unit-cost":_vm.product.product.unitCost,"suggested-sale":_vm.product.product.suggestedSale}}),(
                  !_vm.hasProductPricePromotion() &&
                  _vm.priceWithoutDiscountFlag &&
                  _vm.product.product.priceWithoutDiscount &&
                  Number(_vm.product.product.priceWithoutDiscount) >
                    _vm.product.product.price
                )?_c('p',{staticClass:"mb-0 fs-10 text-decoration-line-through text-mediumgrey"},[_vm._v(" "+_vm._s(_vm.formatPrice(Number(_vm.product.product.priceWithoutDiscount)))+" ")]):_vm._e(),(!_vm.hasProductPricePromotion())?_c('p',{staticClass:"product__price",style:({
                  marginBottom:
                    _vm.product.product.unitCost || _vm.product.product.suggestedSale
                      ? 'revert'
                      : '0',
                })},[_vm._v(" "+_vm._s(_vm.formatPrice(Number(_vm.product.product.price)))+" ")]):_vm._e(),(_vm.product.product.pricePromotions)?_c('table',[_c('tbody',_vm._l((_vm.product.product.pricePromotions),function(promotion){return _c('div',{key:promotion.minimumQuantity},[_c('div',[(
                          _vm.qty >= promotion.minimumQuantity &&
                          !_vm.disableDiscountPercentage
                        )?_c('p',{staticClass:"product__discount-value",attrs:{"aria-label":_vm.$t('ariaLabel.discountLabel', {
                            discount: promotion.discountPercentage,
                          })}},[_vm._v(" -"+_vm._s(promotion.discountPercentage)+"% ")]):_vm._e(),_c('tr',{staticClass:"product__minimum-value text-primary",class:_vm.checkClassOpacity(_vm.price, promotion, _vm.product)},[_c('td',[_c('span',{staticClass:"product__minimum-value-unit fs-10 fw-200"},[_vm._v(" "+_vm._s(promotion.minimumQuantity)+" "+_vm._s(_vm.$t('box'))+" ")])]),_c('td',[_c('span',{staticClass:"pl-1 pr-1 fs-12 fw-200"},[_vm._v(" "+_vm._s(_vm.formatPrice(Number(promotion.price)))+" ")])]),_c('td',[_c('span',{staticClass:"fs-10"},[_vm._v(" "+_vm._s(_vm.$t('each'))+" ")])])])])])}),0)]):_vm._e()],1),_c('div',{attrs:{"sm":"auto px-0"}},[(_vm.product.product.isRecommended)?_c('b-icon',{staticClass:"h4 m-0",attrs:{"icon":"star-fill","variant":"warning"}}):_vm._e()],1)],1)],1)],1)]),_c('div',{staticClass:"w-100 px-2 pb-2"},[_c('ProductQtyController',{attrs:{"define-price":_vm.definePrice,"enable-order":_vm.enableOrder,"quantity":_vm.quantity,"disabled":_vm.enableUserInteraction || _vm.product.product.quantityLimit === 0,"qty-limit":_vm.$store.state.client.clientProfileInfo.type === 'Stockist'
            ? _vm.product.product.quantityLimit
            : undefined,"qty-limit-alert":_vm.$store.state.client.clientProfileInfo.type === 'Stockist'
            ? () => _vm.showProductQtyLimitToast(_vm.product.product)
            : undefined},on:{"qty-updated":_vm.qtyUpdated}}),(
          typeof _vm.product.product.quantityLimit === 'number' &&
          _vm.$store.state.client.clientProfileInfo.type === 'Stockist'
        )?_c('p',{staticClass:"text-muted fs-10 text-center"},[_vm._v(" "+_vm._s(_vm.getWarningProductLimitText(_vm.product.product))+" ")]):_vm._e(),_c('button-add-cart',{attrs:{"disabled":_vm.product.product.quantityLimit === 0,"enable-user-interaction":_vm.enableUserInteraction},on:{"click-add-to-cart":function($event){return _vm.initialState()}}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }