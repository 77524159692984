/* eslint-disable import/prefer-default-export */
export const STORAGE_KEY = {
  authSettings: 'authSettings',
  bottler: 'bottler',
  appTitle: 'appTitle',
  ecommerceConfig: 'ecommerceConfig',
  appTenantPath: 'appTenantPath',
  authorization: 'authorization',
  language: 'language',
  country: 'country',
  sourceType: 'sourceType',
};
