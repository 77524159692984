<template>
  <div
    id="product-item"
    class="mt-3 d-flex"
  >
    <div class="card-body d-flex mb-3 flex-wrap">
      <div class="align-self-start w-100">
        <ProductTag
          v-if="isProductInCart"
          :label-title="$t('combo.tag')"
          :bg-color="'warning'"
        />
      </div>
      <div class="w-50 text-center d-flex align-items-center">
        <ImgGeneral
          :alt-text="product.product.description"
          class="mw-100"
          :url="product.product.imageUrl"
          :show-fallback-img="true"
        />
      </div>
      <div class="d-flex flex-wrap w-50">
        <h4
          class="fs-12 fw-300 w-100"
          translate="no"
        >
          {{ product.product.brand }}
        </h4>
        <div class="card-subprices mt-3 mb-4 w-100">
          <ul
            v-if="hasProductDiscountedPrices()"
            class="list-unstyled fs-14 position-relative"
          >
            <li
              v-for="(priceRange, priceIndex) in priceRangeCollection"
              :key="priceRange.sku"
              class="text-primary price"
              :class="{
                'price-active': isPriceLabelActive(
                  priceRange,
                  priceRangeCollection,
                  priceIndex
                ),
              }"
            >
              <span class="position-relative zi-1">
                {{ formatPrice(Number(priceRange.price)) }}
              </span>
              <span
                v-if="getRangeFriendlyValue(priceRange.tableCode) > 0"
                class="promotion-range-label fw-200 fs-10"
              >
                | {{ $t('combo.range') }}
                {{ getRangeFriendlyValue(priceRange.tableCode) }}
              </span>
              <span class="mark-indicator ml-2">
                <img :src="assetsPath + 'img/icon/hand-side-point.svg'" />
              </span>
            </li>
          </ul>
          <p
            v-else
            class="text-primary fs-14"
          >
            {{ formatPrice(product.product.price) }}
          </p>
        </div>
        <div class="d-flex w-100">
          <HelpButton
            v-if="showTag && isProductInCart"
            :help-description="$t('combo.helpCombo')"
            :sku="String(product.product.sku)"
            class="mr-2"
          />
          <p class="fs-12 fw-200 w-100">
            {{ product.product.description }}
          </p>
        </div>
        <ProductQtyController
          v-if="!isProductInCart"
          :quantity="product.quantity"
          :enable-order="true"
          :min-quantity-allowed="0"
          @qty-updated="productQuantityUpdated"
        />
        <div v-if="isProductInCart">
          <router-link
            v-if="isProductInCart.product.combo !== 0"
            class="position-relative btn btn-outline d-block"
            :to="`/combo-quantity/${isProductInCart.product.combo}`"
          >
            {{ $t('accessButton') }}
          </router-link>
          <router-link
            v-else
            class="position-relative btn btn-outline d-block"
            :to="// eslint-disable-next-line max-len
            `/category/brands/products/${isProductInCart.product.category}/${isProductInCart.product.brand}`"
          >
            {{ $t('accessButton') }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ProductTag from '@/components/product/ProductTag';
import ProductMixin from '@/components/mixins/ProductMixin';
import ImgGeneral from '@/components/shared/ImgGeneral';
import HelpButton from '@/components/shared/HelpButton';
import ProductQtyController from '@/components/product/ProductQtyController.vue';
import Analytics from '@/utils/analytics';
import { getAssetsPath } from '@/utils/config';
import { isProductBlockedToAddCart } from '@/utils/pages';

export default {
  components: {
    ImgGeneral,
    ProductTag,
    ProductQtyController,
    HelpButton,
  },
  mixins: [ProductMixin],
  props: {
    product: {
      type: Object,
      required: true,
    },
    comboCart: {
      type: Array,
      default() {
        return [];
      },
    },
    totalItemsAddedToCombo: {
      type: Number,
      default: 0,
    },
    pricePromotionsList: {
      type: Array,
      default() {
        return [];
      },
      required: true,
    },
  },
  data() {
    return {
      priceRangeCollection: null,
      comboNotInMinimumRange: false,
      sku: this.product.product.sku,
      comboId: null,
      isProductInCart: null,
      assetsPath: getAssetsPath(),
    };
  },
  computed: {
    ...mapGetters({
      cart: 'getCartItems',
    }),
  },
  mounted() {
    this.priceRangeCollection = this.priceRangeCollectionAfterSort();
    this.comboId = this.product.product.combo;
    this.isProductInCart = isProductBlockedToAddCart({
      productSku: this.product.product.sku,
      cart: this.cart,
      comboId: this.product.product.combo,
    });
  },
  methods: {
    hasProductDiscountedPrices() {
      const initialPrice = 0;
      const discountedPrices = this.product.product.pricePromotions.reduce(
        (previousValue, currentValue) =>
          previousValue + Number(currentValue.price),
        initialPrice
      );
      return this.product.product.price !== discountedPrices.toFixed(2);
    },
    tablePriceSorted(promoList) {
      const c = promoList.sort((a, b) => a.minimumQuantity - b.minimumQuantity);
      return c;
    },
    getRangeFriendlyValue(tableCode) {
      const friendlyRangeIndex = this.pricePromotionsList.findIndex(
        (element) => element.tableCode === tableCode
      );
      return friendlyRangeIndex + 1;
    },
    priceRangeCollectionAfterSort() {
      const { pricePromotions } = this.product.product;
      pricePromotions.sort((a, b) => b.price - a.price);
      return pricePromotions;
    },
    isPriceLabelActive(priceRange, priceRangeCollection, indexPrice) {
      let isActive = this.totalItemsAddedToCombo >= priceRange.minimumQuantity;
      const nextPriceRangeItem = priceRangeCollection[indexPrice + 1];
      if (
        nextPriceRangeItem &&
        this.totalItemsAddedToCombo >= nextPriceRangeItem.minimumQuantity
      ) {
        isActive = false;
      }
      return isActive;
    },
    productQuantityUpdated(quantity) {
      this.product.quantity = Math.round(quantity);
      const locationInCart = this.productPositionInCart(this.product);

      if (
        this.comboCart.some(
          (comboInCart) => comboInCart.product.sku === this.product.product.sku
        )
      ) {
        this.comboCart[locationInCart].quantity = Math.round(quantity);
      } else {
        this.$store.dispatch('addComboItemToCart', this.product);
        Analytics.addToCart(this.product.product, this.product.quantity);
      }

      if (quantity <= 0) {
        Analytics.removeFromCart(this.product.product, this.$t('currency'), 1);
        this.comboCart.splice(locationInCart, 1);
      }
      this.$emit('cbUpdatePrice');
    },
    productPositionInCart(product) {
      const productIndexInCart = this.comboCart.findIndex(
        (productInCart) => productInCart.product.sku === product.product.sku
      );
      return productIndexInCart;
    },
  },
};
</script>

<style scoped>
.price {
  opacity: 0.5;
  text-decoration: line-through;
  font-size: var(--fs-12);
  transition: opacity 1s;
}
.price::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  background-color: var(--primary);
}
.price-active {
  opacity: inherit;
  text-decoration: none;
  font-size: inherit;
  position: relative;
}
.price-active::before {
  animation-name: newPriceFlash;
  animation-iteration-count: 1;
  animation-duration: 0.8s;
  animation-fill-mode: backwards;
  animation-timing-function: ease-in-out;
}
.mark-indicator {
  display: none;
  color: var(--text-mediumgrey);
}
.price-active .mark-indicator {
  display: inline-block;
}
.price-active .promotion-range-label {
  display: none;
}

@keyframes newPriceFlash {
  0% {
    width: 0;
    left: 0;
  }
  50% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 0;
    left: 100%;
  }
}
</style>
