<template>
  <div class="page__content">
    <Breadcrumb />
    <div class="container max-section-width">
      <div class="row">
        <div class="col fw-200 text-center max-w-700 mx-auto mt-3 mb-4">
          <p class="w-100 fs-12 fs-md-14">
            {{ $t('marketing.featureDescription') }}
          </p>
        </div>
      </div>
      <ServiceCard
        :title="$t('marketing.kombo.title')"
        :description="$t('marketing.kombo.description')"
        :btn-message="$t('marketing.kombo.button')"
        :url="komboUrl + bottler"
        :image-url="assetsPath + 'img/services/kombo.jpg'"
        :cb-click="onClickCard"
      />
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/components/shared/Breadcrumb.vue';
import { MODULES, SERVICES } from '@/constants/feature';
import { KOMBO_URL } from '@/constants/external';
import ServiceCard from '@/components/ServiceCard.vue';
import { redirectUsingWindowOpen, registerService } from '@/utils/pages';
import { getStorageBottler, getAssetsPath } from '@/utils/config';

export default {
  components: {
    Breadcrumb,
    ServiceCard,
  },
  data() {
    return {
      appModules: MODULES,
      komboUrl: KOMBO_URL,
      assetsPath: getAssetsPath(),
    };
  },
  created() {
    const bottlerStorage = getStorageBottler();
    this.bottler = bottlerStorage?.bottler.toLowerCase();
  },
  methods: {
    onClickCard(url) {
      registerService({ service: SERVICES.kombo });
      redirectUsingWindowOpen(url, '_blank');
    },
  },
};
</script>
