import apiBanner from '@/api/banners';

export default {
  state: {
    banners: [],
  },
  getters: {
    getBanners(state) {
      return state.banners;
    },
  },
  mutations: {
    setBanners(state, payload) {
      const banners = Array.isArray(payload) ? payload : [];
      // eslint-disable-next-line no-param-reassign
      state.banners = banners;
    },
  },
  actions: {
    async getBanners({ commit }) {
      const banners = await apiBanner.getBanners();
      commit('setBanners', banners);
    },
  },
};
