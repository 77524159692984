import apiUser from '@/api/user';
import { setSessionData } from '@/utils/config';
import i18n from '@/utils/i18n';
import { STORAGE_KEY } from '@/constants/storage';
import router from '@/router';

export default {
  state: {
    profile: {},
    loading: false,
  },
  getters: {
    getProfile(state) {
      return state.profile;
    },
    getProfileLoading(state) {
      return state.loading;
    },
  },
  mutations: {
    setProfile(state, payload) {
      // eslint-disable-next-line no-param-reassign
      state.profile = payload;
    },
    setLoading(state, payload) {
      // eslint-disable-next-line no-param-reassign
      state.loading = payload;
    },
  },
  actions: {
    setProfile({ commit }, value) {
      commit('setProfile', value);
    },
    async getProfile({ commit }) {
      commit('setLoading', true);
      try {
        const profile = await apiUser.getUserProfile();
        const language = profile?.language ?? 'th';

        setSessionData({
          key: STORAGE_KEY.language,
          value: language,
        });
        i18n.locale = language;

        if (!profile.language) {
          throw new Error('Language property does not exist in the profile.');
        }

        commit('setProfile', profile);
      } catch (error) {
        commit('setProfile', { language: 'th' });
      }
      commit('setLoading', false);
    },
    async setLanguage({ commit, dispatch, rootState }, locale) {
      commit('setLoading', true);
      setSessionData({
        key: STORAGE_KEY.language,
        value: locale,
      });
      i18n.locale = locale;

      await dispatch('getProducts');
      await dispatch('getCombos');
      if (rootState.client?.clientProfileInfo?.type === 'Stockist') {
        await dispatch('getCategoriesStockist');
      } else {
        await dispatch('getCategories');
      }

      if (router.getPath().includes('/category/')) {
        router.setUrl(`/category`);
      }

      try {
        const profile = await apiUser.updateUserProfile(locale);
        if (!profile.language) {
          throw new Error('Language property does not exist in the profile.');
        }

        commit('setProfile', profile);
      } catch (e) {
        commit('setProfile', { language: locale });
      }
      commit('setLoading', false);
    },
  },
};
