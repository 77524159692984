import { get } from '@/utils/httpClient';
import { setSessionData, getStorageBottler } from '@/utils/config';
import { STORAGE_KEY } from '@/constants/storage';

async function fetchEcommerceConfig() {
  const bottlerStorage = getStorageBottler();
  const result = await get({
    url: 'ecommerce/config',
    useAzureAPIManagement: bottlerStorage?.useAzureAPIManagement,
  });

  let value = null;

  if (result?.status === 200) value = result.data;

  setSessionData({ key: STORAGE_KEY.ecommerceConfig, value });

  return value;
}

export default { fetchEcommerceConfig };
