<template>
  <div class="page__content">
    <Breadcrumb />
    <div class="max-section-width">
      <div
        v-if="cart.length"
        class="row"
      >
        <div
          v-if="
            !bottlerStorage.comboTypeQuantityRange ||
            bottlerStorage.comboTypeQuantityRange === false
          "
          id="comboCart"
          class="col col-md-10 mx-auto"
        >
          <ComboFamilyCart
            :items="cart"
            @remove="removeComboFromCart($event)"
            @edit="editComboFromCart($event)"
          />
        </div>
        <div
          v-if="bottlerStorage.comboTypeQuantityRange === true"
          id="comboCart"
          class="mt-4 col col-md-10 mx-auto"
        >
          <ComboQuantityCart
            :full-cart-items="cart"
            @remove="removeQuantityComboFromCart($event)"
            @edit="editQuantityComboFromCart($event)"
          />
        </div>
      </div>
      <div
        v-if="cart.length"
        id="cart"
        class="d-flex mt-4"
      >
        <Cart
          :cart="cart"
          :enable-order="enableOrder"
          :is-cart-item-loading="!isPriceFromApiReady"
          @cbUpdatePrice="definePrice()"
        />
      </div>

      <div v-if="cart.length">
        <p
          v-if="cart.length"
          class="fs-10 remove-container"
          @click="clearCart"
        >
          <ImgGeneral
            :is-one-px-placeholder="true"
            :data-src="`${assetsPath}img/trash-red.svg`"
            class="remove-icon"
          />
          <span class="remove-text"> {{ $t('combo.removeAllItems') }}</span>
        </p>
      </div>

      <div
        v-if="!cart.length && loading === false"
        class="emptyCart pt-5"
      >
        <div class="emptyCart__item">
          <ImgGeneral
            :alt="$t('cartLabel')"
            :url="assetsPath + 'img/icon/red-cart.svg'"
          />
        </div>
        <div class="emptyCart__item fs-18">
          {{ $t('cartPage.emptyCart') }}
        </div>
        <div>
          <router-link
            class="btn product-button mx-auto btn-primary"
            :to="'/category'"
            :href="'/category'"
          >
            {{ $t('redirectToProduct') }}
          </router-link>
          <div />
        </div>
      </div>
      <div class="col-md-10 mx-auto p-0">
        <div
          v-if="
            isDeliveryAddressConfirmationActive &&
            getClientFormattedAddress() &&
            cart.length
          "
          id="address"
        >
          <Alert
            :header="$t('cartPage.deliveryAddressValidation')"
            :sub-header="
              $t('cartPage.clientAddress', {
                address: getClientFormattedAddress(),
                contactPhone: bottlerStorage.contactPhone,
              })
            "
            :confirm-message="$t('cartPage.deliveryAddressConfirm')"
            :show-pending-confirm="showPendingConfirm"
            @update="isDeliveryAddressConfirmed($event)"
          />
        </div>
        <div
          v-if="
            isFinishOrderOverLimitAuthorized &&
            isPaymentLimitActive &&
            isOrderValueOverlimit() &&
            cart.length
          "
          class="creditLimit"
        >
          <Alert
            :header="
              creditFormatHeaderMessage($t('cartPage.creditLimitHeader'))
            "
            :sub-header="$t('cartPage.creditOverLimitAuthorizedMessage')"
            :confirm-message="$t('cartPage.confirmCreditLimit')"
            :show-pending-confirm="showPendingConfirm"
            @update="isCreditLimitAccepted($event)"
          />
        </div>
        <div v-if="isNotAllowedOverLimit">
          <Alert
            :header="
              creditFormatHeaderMessage($t('cartPage.creditLimitHeader'))
            "
            :sub-header="$t('cartPage.creditLimitMessage')"
          />
        </div>
        <div
          v-if="
            isGlassBottlesRequired() &&
            !isReturnablePackagingsEnough() &&
            !isReturnablePackagingsQuantityOverLimit()
          "
          class="GlassBottles"
        >
          <Alert
            :header="$t('cartPage.glassBottlesRequired')"
            :sub-header-items="getGlassBottlesMessage()"
            :confirm-message="$t('cartPage.glassBottlesConfirm')"
            :show-pending-confirm="showPendingConfirm"
            :button-type="buttonType"
            :button-header="$t(buttonHeader)"
            :button-text="$t(buttonText)"
            :button-action="getGlassBottleButtonAction"
            :button-style="buttonStyle"
            @update="isGlassBottlesAccepted($event)"
          />
        </div>
        <div
          v-if="isReturnablePackagingsQuantityOverLimit()"
          class="GlassBottles"
        >
          <Alert
            :header="$t('cartPage.returnablePackagingsOverLimitHeader')"
            :sub-header-items="getReturnablePackagingsOverLimitDescription()"
            :show-pending-confirm="showPendingConfirm"
            :button-type="'addGlassBottles'"
            :button-header="
              $t('cartPage.returnablePackagingsOverLimitButtonHeader')
            "
            :button-text="$t('cartPage.returnablePackagingsOverLimitButton')"
            :button-action="adjustReturnablePackagingsOnCart"
            :button-style="'primary'"
            @update="isGlassBottlesAccepted($event)"
          />
        </div>
        <div
          v-show="showPendingConfirm && cart.length"
          class="pending--confirm fs-14"
        >
          {{ $t('cartPage.pendingConfirm') }}
        </div>
      </div>
      <div v-if="showDeliveryOptionFlag && cart.length">
        <div
          v-if="isNotificationEnabledLoading"
          class="loading"
        >
          <Skeleton
            :height="'120px'"
            :type="'title-box'"
            class="mb-3"
          />
        </div>
        <div
          v-if="showNotificationOption && !isNotificationEnabledLoading"
          class="col-md-10 mx-auto p-0"
        >
          <CheckNotification
            :title="$t('notifications.scheduledOrderTitle')"
            :description="
              $t('notifications.scheduledOrderCheckCart', {
                bottler: bottlerName,
              })
            "
            :on-toggle="onAcceptReceiveNotification"
            @update-optin="startNotificationLoading"
          />
        </div>
        <div
          v-if="!showNotificationOption && !isNotificationEnabledLoading"
          class="scheduled col-md-10 mx-auto p-0"
        >
          <DeliveryOption
            :has-product-with-qty-limit="hasProductWithQtyLimit"
            @startLoadScheduleOrderDates="isCheckoutButtonEnabled = false"
            @finishLoadScheduleOrderDates="isCheckoutButtonEnabled = true"
            @finishGetAvailableDays="isAvailableDatesReady = true"
          />
        </div>
      </div>
      <div
        v-if="showNotificationOption && !showDeliveryOptionFlag && cart.length"
        class="scheduled col-md-10 mx-auto p-0"
      >
        <CheckNotification
          :title="$t('notifications.title')"
          :description="
            $t('notifications.checkCart', {
              bottler: bottlerName,
              value: botSourceAppName,
            })
          "
        />
      </div>
    </div>
    <Monitor
      v-if="isMonitorActive()"
      :active="isCheckoutButtonActive"
      :total-api-price="totalApiPrice"
      :price-before-discount="priceBeforeDiscount"
      :discount="discount"
      :taxes="taxes"
      @checkout="checkoutOrder()"
    />
    <DeliveryDateChildModal
      modal-id="cartmodal"
      :is-visible="showDeliveryDateChildModal"
      @selectedDataObj="handleModalSelectedDate"
    />
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */
import { mapGetters } from 'vuex';
import router from '@/router';
import {
  scrollToElement,
  openModalNotification,
  openModalNotificationAction,
} from '@/utils/pages';
import {
  getStorageBottler,
  getAssetsPath,
  getBotSourceAppName,
} from '@/utils/config';
import cart from '../stores/modules/cart';
import Analytics from '@/utils/analytics';
import analyticsEvents from '@/utils/events/analytics';
import Cart from '@/components/cart/Cart.vue';
import ComboFamilyCart from '@/components/combo/ComboFamilyCart.vue';
import ComboQuantityCart from '@/components/combo/ComboQuantityCart.vue';
import Breadcrumb from '@/components/shared/Breadcrumb.vue';
import Monitor from '@/components/Monitor.vue';
import ImgGeneral from '@/components/shared/ImgGeneral.vue';
import Alert from '@/components/order/Alert.vue';
import Skeleton from '@/components/shared/Skeleton.vue';
import PaymentLimitMixin from '@/components/mixins/PaymentLimitMixin.vue';
import GlassBottlesMixin from '@/components/mixins/GlassBottlesMixin.vue';
import DeliveryOption from '@/components/order/deliveryOption/DeliveryOption.vue';
import CheckNotification from '@/components/notification/CheckNotification.vue';
import apiOptInGet from '@/api/optin/optInGet';
import RecurrentOrderMixin from '@/components/mixins/RecurrentOrderMixin.vue';
import apiCreateSimulate from '@/api/order/orderSimulate';
import Common from '@/utils/common';
import DeliveryDateChildModal from '@/components/shared/DeliveryDateChildModal';

export default {
  components: {
    Cart,
    Breadcrumb,
    Monitor,
    ComboFamilyCart,
    ComboQuantityCart,
    ImgGeneral,
    Alert,
    Skeleton,
    DeliveryOption,
    CheckNotification,
    DeliveryDateChildModal,
  },
  mixins: [PaymentLimitMixin, GlassBottlesMixin, RecurrentOrderMixin],
  data() {
    return {
      tmoPrice: null,
      enableOrder: true,
      isPriceFromApiReady: true,
      totalPrice: 0,
      comboCart: [],
      glassBottlesAccepted: false,
      creditLimitAccepted: false,
      showPendingConfirm: false,
      showNotificationOption: false,
      bottlerName: '',
      bottlerStorage: {},
      showDeliveryOptionFlag: false,
      deliveryAddressConfirmed: false,
      isDeliveryAddressConfirmationActive: false,
      isNotificationEnabledLoading: true,
      buttonType: '',
      buttonHeader: '',
      buttonText: '',
      buttonStyle: '',
      isCheckoutButtonEnabled: true,
      isAvailableDatesReady: false,
      assetsPath: getAssetsPath(),
      botSourceAppName: getBotSourceAppName(),
      isDeliveryDateNotSelected: false,
      hasProductWithQtyLimit: false,
      totalApiPrice: 0,
      priceBeforeDiscount: 0,
      discount: 0,
      taxes: 0,
      simulateProductResponse: undefined,
      isCutoffModalActive: false,
      selectedDateObj: null,
      modalDeliveryDateSelected: '',
      showDeliveryDateChildModal: false,
      loadingCart: false,
      loading: true,
      isTrackOrderLinkClick: false,
      menuCLick: {},
    };
  },
  computed: {
    ...mapGetters({
      cart: 'getCartItems',
      isCartCutoffAcknowledged: 'getCartCutoffAcknowledged',
      referenceOrderNumber: 'getReferenceOrderNumber',
      referenceOrderType: 'getReferenceOrderType',
      isRepeatOrderAcknowledged: 'isRepeatOrderAcknowledged',
      client: 'getClient',
      getOrdersPlacedToday: 'getOrdersPlacedToday',
      getOrdersOfExpectedDeliveryDate: 'getOrdersOfExpectedDeliveryDate',
    }),
    isNotAllowedOverLimit() {
      const isNotAllowed =
        this.isFinishOrderOverLimitAuthorized === false &&
        this.isPaymentLimitActive &&
        this.isOrderValueOverlimit() &&
        this.cart.length;
      return isNotAllowed;
    },
    isCheckoutButtonActive() {
      let enabled = this.isCheckoutButtonEnabled && !this.isNotAllowedOverLimit;
      if (!this.isPriceFromApiReady) {
        enabled = false;
      } else if (this.showDeliveryOptionFlag) {
        enabled =
          this.isCheckoutButtonEnabled &&
          !this.isNotAllowedOverLimit &&
          this.isAvailableDatesReady;
      }
      return enabled;
    },
  },
  watch: {
    cart() {
      this.loading = false;
    },
  },
  beforeCreate() {
    this.loading = true;
  },
  async created() {
    this.bottlerStorage = getStorageBottler();
    this.$store.commit('setSelectDate', []);
    this.definePrice();
    this.showDeliveryOptionFlag = this.bottlerStorage?.showDeliveryOption;
    const showNotificationOptionFlag =
      this.bottlerStorage?.showNotificationOption;
    this.bottlerName = this.bottlerStorage?.name;
    this.showNotificationOption = await this.checkIfNotificationIsActive(
      showNotificationOptionFlag
    );
    this.isNotificationEnabledLoading = false;
    // eslint-disable-next-line max-len
    this.isDeliveryAddressConfirmationActive =
      this.bottlerStorage.isDeliveryAddressConfirmationActive;
    // check if should notify about adding suggested products
    if (this.$route?.params?.action === 'addSuggested') {
      openModalNotification(this.$bvModal, {
        title: this.$t('toast.titleSuggested'),
        description: this.$t('toast.bodySuggested'),
        okTitle: this.$t('ok'),
      });
    }
    this.hasProductWithQtyLimit = this.cart.some(
      (p) => p.product.quantityLimit > 0
    );
    Analytics.viewCart(this.cart, this.$t('currency'));

    if (this.client.type === 'Stockist') {
      const productOverQunatity = this.cart.find(
        (p) => p.quantity > p.product.quantityLimit
      );
      if (productOverQunatity) {
        // adjusting the cart items if the quantity is greater than max quantity
        this.cart.forEach((p) => {
          if (p.quantity > p.product.quantityLimit) {
            this.$store.commit('removeItemFromCart', p.product);
            this.$store.commit('addItemToCart', {
              ...p,
              quantity: p.product.quantityLimit,
            });
          }
        });
        // run alert to show the cart adjustment done
        this.showProductQtyLimitToast(productOverQunatity.product);
      }
    }
  },
  mounted() {
    if (typeof this.cart === 'object') {
      this.loading = false;
    }
    if (
      this.referenceOrderNumber &&
      this.referenceOrderType === 'Reorder' &&
      !this.isRepeatOrderAcknowledged
    ) {
      cart.mutations.setRepeatOrderAcknowledged(cart.state, true);
      // toast notify user cart has been updated with repeat order
      const h = this.$createElement;
      openModalNotification(this.$bvModal, {
        title: [
          h('b-icon', {
            props: {
              icon: 'arrow-counterclockwise',
              scale: '1',
              variant: 'secondary',
            },
          }),
          h(
            'span',
            {
              style: { color: 'green', fontWeight: 500 },
            },
            ` ${this.$t('orderTracking.repeat')}`
          ),
        ],
        description: [
          h('p', this.$t('orderTracking.repeatAddedToCart1')),
          h('p', this.$t('orderTracking.repeatAddedToCart2')),
        ],
        okTitle: this.$t('ok'),
      });
    } else if (
      this.referenceOrderNumber &&
      this.referenceOrderType === 'Edit'
    ) {
      // toast notify user cart has been updated with edit order
      const h = this.$createElement;
      openModalNotification(this.$bvModal, {
        title: [
          h(
            'span',
            {
              style: { color: 'red' },
            },
            ` ${this.$t('orderTracking.edit')}`
          ),
        ],
        headerClass: 'bg-white',
        headerCloseVariant: 'black',
        description: this.$t('orderTracking.editAddedToCart'),
        okTitle: this.$t('ok'),
      });
    }
  },
  methods: {
    showCutOffModalPopup() {
      const h = this.$createElement;
      const modalData = {
        headerBgVariant: 'primary',
        title: [
          h(
            'span',
            {
              style: { color: 'red' },
            },
            ` ${this.$t('cartPage.cartCutOffExceededTitle')}`
          ),
        ],
        description: [h('p', this.$t('cartPage.cartCutOffExceededAlert'))],
        okTitle: this.$t('ok'),
      };
      if (this.isCutoffModalActive) {
        openModalNotification(this.$bvModal, {
          ...modalData,
        }).then((value) => {
          if (value === false) {
            router.navigateToPage('/promotions');
          }
        });
      } else {
        openModalNotification(this.$bvModal, {
          ...modalData,
        });
      }
    },
    isMonitorActive() {
      let enabled = this.cart.length;
      if (this.showDeliveryOptionFlag) {
        enabled = this.cart.length && !this.isNotificationEnabledLoading;
      }
      return enabled;
    },
    removeComboFromCart(comboId) {
      const cartItemsNotInCombo = this.cart.filter(
        (item) => item.product.combo !== JSON.parse(comboId)
      );
      this.$store.dispatch('removeComboFromCart', comboId);
      this.$store.dispatch('setCartItems', cartItemsNotInCombo);
    },
    removeQuantityComboFromCart(combo) {
      const { comboId } = combo;
      const cartItemsNotInCombo = this.cart.filter(
        (item) => item.product.combo !== comboId
      );
      Analytics.logEvent(analyticsEvents.EVENTS.REMOVE_COMBO_FROM_CART, {
        description: JSON.stringify(comboId),
      });
      this.$store.dispatch('removeComboFromCart', comboId);
      this.$store.dispatch('setCartItems', cartItemsNotInCombo);
    },
    editQuantityComboFromCart(combo) {
      const { comboId } = combo;
      Analytics.logEvent(analyticsEvents.EVENTS.EDIT_COMBO_FROM_CART, {
        description: JSON.stringify(comboId),
      });
      router.navigateToPage(`combo-quantity/${comboId}/${combo.name}`);
    },
    editComboFromCart(comboId) {
      router.navigateToPage(`/combo/${comboId}`);
    },
    async definePrice() {
      if (this.bottlerStorage?.hasRecalculatePrice === true) {
        clearTimeout(this.tmoPrice);
        this.isPriceFromApiReady = false;
        this.tmoPrice = setTimeout(
          async () => this.recalculatePriceFromAPI(),
          1000
        );
      } else {
        this.$store.commit('setCartItems', this.cart);
        this.cart.forEach((productInCart) => {
          if (
            productInCart.product.pricePromotions &&
            productInCart.product.pricePromotions.length > 0
          ) {
            productInCart.product.pricePromotions.forEach((p) => {
              if (productInCart.quantity >= p.minimumQuantity) {
                productInCart.product.price = p.price;
              }
            });
          }
        });
      }
    },
    async recalculatePriceFromAPI() {
      await this.simulateCreateOrder();
      this.$store.commit('setCartItems', this.cart);
      if (
        this.simulateProductResponse &&
        this.simulateProductResponse.orderDetails
      ) {
        this.cart.forEach((productInCart) => {
          const simulatePriceProduct =
            this.simulateProductResponse.orderDetails.find(
              (p) => productInCart.product.sku === p.sku
            );
          if (simulatePriceProduct) {
            productInCart.product.price =
              simulatePriceProduct.unitPriceWithDiscount;
            productInCart.product.totalPrice = simulatePriceProduct.price;
          }
        });
      }
      this.$store.dispatch('setCartItems', this.cart);
      this.isPriceFromApiReady = true;
    },
    async simulateCreateOrder() {
      const products = this.buildProductBody();
      const orderInformation = {
        simulationPrice: 'X',
        products,
        DeliveryDate: this.client.deliveryDate,
        orderType: this.orderType,
        userFlow: {
          glassBottlesAccepted: this.glassBottlesAccepted,
          creditLimit: this.creditLimit,
        },
      };
      try {
        this.simulateProductResponse = await apiCreateSimulate.getOrderSimulate(
          orderInformation
        );
        this.isCheckoutButtonEnabled = true;
        this.totalApiPrice = this.simulateProductResponse.totalValue;
        this.priceBeforeDiscount =
          this.simulateProductResponse.priceBeforeDiscount;
        this.discount = this.simulateProductResponse.discount;
        this.taxes = this.simulateProductResponse.vat;
        this.$store.commit('setSimulatedPrice', {
          totalPrice: this.totalApiPrice,
          priceBeforeDiscount: this.priceBeforeDiscount,
          discount: this.discount,
          taxes: this.taxes,
        });
      } catch (error) {
        this.errorMsg = this.showBottlerErrorsMsg
          ? error?.response?.data?.message
          : '';
        this.isPriceFromApiReady = true;
        this.$store.commit('setSimulatedPrice', this.errorMsg);
        return false;
      }
      if (!this.simulateProductResponse) {
        return false;
      }
      this.loading = false;
      return true;
    },
    buildProductBody() {
      const products = [];
      this.cart.forEach((c) => {
        if (c.quantity > 0) {
          products.push({
            sku: c.product.sku,
            quantity: c.quantity,
            combo: c.product.combo,
            familyId: c.product.familyId,
            price: c.product.price,
            description: c.product.description,
            alcoholic: c.product.alcoholic,
            brand: c.product.brand,
            type: c.product.category,
            pricePromotions: c.product.pricePromotions,
          });
        }
      });
      return products;
    },
    checkoutOrder() {
      this.isDeliveryDateNotSelected = false;
      this.showPendingConfirm = false;
      this.creditLimitOverLimitValidate();
      this.creditLimitValidate();
      this.glassBottlesValidate();
      this.deliveryAddressValidate();
      this.isScheduledOrderCorrectlySelected();

      const currentTime = new Date();
      // get the endTime
      const endTime = new Date(new Date(currentTime).setHours(17, 30));

      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      let tomorrowString = tomorrow.toISOString().slice(0, 10);

      const deliveryDates = this.client?.deliveryDates;
      let firstElem;

      // while tomorrow is a sunday or public holiday
      while (
        tomorrow.getDay() === 0 ||
        Common.isAPublicHoliday(tomorrowString)
      ) {
        tomorrow.setDate(tomorrow.getDate() + 1);
        tomorrowString = tomorrow.toISOString().slice(0, 10);
      }

      if (this.isGlassBottlesRequired() && !this.glassBottlesAccepted) {
        scrollToElement('.GlassBottles');
      }

      if (
        (this.isGlassBottlesRequired() && this.glassBottlesAccepted) ||
        !this.isGlassBottlesRequired()
      ) {
        // if weekend or public holiday ; then increment tomorrow until it is a non weekend/public holiday
        // skip the first date from the delivery dates
        if (
          deliveryDates &&
          currentTime >= endTime &&
          new Date(tomorrowString) >= new Date(deliveryDates[0].Date) &&
          this.isCartCutoffAcknowledged
        ) {
          // past 5:30 - show different dates
          firstElem = deliveryDates.shift();
        }

        if (this.client?.distance === 'D') {
          const filteredDates = this.client.deliveryDates.filter(
            (d) => d.IsInbook === true && d.MinimumValue !== null
          );
          if (
            currentTime >= endTime &&
            this.client.deliveryDateSelected === '1' && // if is is tmrw date we are reseting the cart, client.deliveryDate in cartmodal
            filteredDates &&
            filteredDates.length > 0 &&
            tomorrowString === filteredDates[0].Date // filteredDates[0].Date checks for the first delivery date
          ) {
            this.showCutoffModal();
          } else if (
            !this.showPendingConfirm &&
            !this.isDeliveryDateNotSelected
          ) {
            if (this.isCartCutoffAcknowledged) {
              deliveryDates?.unshift(firstElem);
            }

            if (this.client.deliveryDate) {
              router.navigateToPage('check', { finishOrderCorrectly: true });
            } else {
              this.showDeliveryDateNotSelected();
            }
          }
        } else if (
          this.client?.distance !== 'D' &&
          deliveryDates &&
          deliveryDates.length > 0 &&
          currentTime >= endTime &&
          this.client.deliveryDateSelected === '1' && // if is is tmrw date we are reseting the cart, client.deliveryDate in cartmodal
          tomorrowString === deliveryDates[0].Date // deliveryDates[0].Date checks for the first delivery date
        ) {
          this.showCutoffModal();
        } else if (
          !this.showPendingConfirm &&
          !this.isDeliveryDateNotSelected
        ) {
          if (this.isCartCutoffAcknowledged) {
            deliveryDates?.unshift(firstElem);
          }

          if (this.client.deliveryDate) {
            router.navigateToPage('check', { finishOrderCorrectly: true });
          } else {
            // Show delivery date popup instead of date selector warning
            this.showDeliveryDates();
          }
          if (this.menuCLick.cartmodal === true) {
            this.$bvModal.show('cartmodal');
          }
          this.menuCLick.cartmodal = true;
        }
      }
    },
    creditLimitOverLimitValidate() {
      if (
        this.isFinishOrderOverLimitAuthorized &&
        this.isPaymentLimitActive &&
        this.isOrderValueOverlimit() &&
        !this.creditLimitAccepted
      ) {
        this.focusOnAlert();
      }
    },
    creditLimitValidate() {
      if (
        !this.isFinishOrderOverLimitAuthorized &&
        this.isPaymentLimitActive &&
        this.isOrderValueOverlimit()
      ) {
        this.focusOnAlert();
      }
    },
    focusOnAlert() {
      this.showPendingConfirm = true;
      scrollToElement('.footer');
    },
    isCreditLimitAccepted(value) {
      this.creditLimitAccepted = value;
      this.updateTracking(value);
    },
    async checkIfNotificationIsActive(showNotificationOptionFlag) {
      if (showNotificationOptionFlag) {
        const optinResponse = await apiOptInGet.get();
        if (!optinResponse.isNotificationEnable) return true;
      }

      return false;
    },
    startNotificationLoading() {
      this.isNotificationEnabledLoading = true;
    },
    onAcceptReceiveNotification(value) {
      if (value === 'true') {
        this.showNotificationOption = false;
        openModalNotification(this.$bvModal, {
          title: this.$t('notifications.title'),
          description: this.$t('notifications.optinSuccess', {
            value: this.botSourceAppName,
          }),
          okTitle: this.$t('ok'),
        });
      }
      this.isNotificationEnabledLoading = false;
    },
    deliveryAddressValidate() {
      if (
        !this.deliveryAddressConfirmed &&
        this.getClientFormattedAddress() &&
        this.isDeliveryAddressConfirmationActive
      ) {
        this.focusOnAlert();
      }
    },
    isDeliveryAddressConfirmed(value) {
      this.deliveryAddressConfirmed = value;
    },
    getClientFormattedAddress() {
      const { address } = this.client;
      if (!address) return undefined;

      const formattedAddress = address
        .split(' ')
        .reduce((result, addr) => {
          if (addr) {
            result.push(
              addr[0].toUpperCase() + addr.substring(1).toLowerCase()
            );
          }
          return result;
        }, [])
        .join(' ');

      return formattedAddress;
    },
    showCutoffModal() {
      this.isCutoffModalActive = true;
      this.showCutOffModalPopup();
      this.$store.dispatch('resetCart');
      // localStorage.setItem('cart', []);
      this.$store.commit('setCartCutoffAcknowlegement', true);
    },
    navigateHome() {
      router.navigateToPage('home');
    },

    confirmAdditionalOrder() {
      const h = this.$createElement;
      openModalNotification(this.$bvModal, {
        id: 'duplicateOrder',
        headerBgVariant: 'white',
        headerTextVariant: 'danger',
        hideHeaderClose: true,
        okVariant: 'secondary',
        okOnly: true,
        noCloseOnBackdrop: true,
        noCloseOnEsc: true,
        title: [
          h('b-icon', {
            props: {
              icon: 'exclamation-triangle-fill',
              scale: '1',
              variant: 'danger',
            },
          }),
          h(
            'span',
            {
              style: { color: 'red' },
            },
            ` ${this.$t('orderTracking.possibleDuplicateOrderTitle')}`
          ),
        ],
        description: [
          h('p', ` ${this.$t('orderTracking.possibleDuplicateOrderbody1')}`),
          h('p', ` ${this.$t('orderTracking.possibleDuplicateOrderbody2')}`),
          h('p', [
            h('span', [
              ` ${this.$t('orderTracking.possibleDuplicateOrderbody3')}`,
              h(
                'a',
                {
                  on: {
                    click: () => {
                      this.isTrackOrderLinkClick = true;
                      this.$bvModal.hide('duplicateOrder');
                      router.navigateToPage('/order/tracking');
                    },
                  },
                  style: {
                    cursor: 'pointer',
                    textDecoration: 'underline',
                    color: 'rgb(228, 30, 43)',
                  },
                },
                ` ${this.$t('orderTracking.possibleDuplicateOrderHistory')}`
              ),
              ` ${this.$t('orderTracking.possibleDuplicateOrderbody4')}`,
            ]),
          ]),
        ],
        okTitle: this.$t('ok'),
      }).then((value) => {
        if (value === true) {
          if (this.modalDeliveryDateSelected !== null) {
            // selected new date will be added to the client
            this.client.minimumOrder = Number(
              this.modalDeliveryDateSelected.MinimumValue
            );
            this.client.deliveryDateSelected =
              this.modalDeliveryDateSelected.header;
            this.client.deliveryDate =
              this.modalDeliveryDateSelected.Date.split('-').join('');
            this.$store.commit('setClient', this.client);
          }
          if (
            this.client.minimumOrder !== null &&
            this.isTrackOrderLinkClick === false
          ) {
            // if user clicks the next delivery date then continue the route to create order delivery
            router.navigateToPage('check', { finishOrderCorrectly: true });
          } else {
            // Show delivery date popup instead of date selector warning
            this.showDeliveryDates();
          }
        }
      });
    },

    async showDeliveryDates() {
      this.showDeliveryDateChildModal = true;
    },

    handleModalSelectedDate(data) {
      if (data === null) return;
      this.modalDeliveryDateSelected = data;
      if (
        this.modalDeliveryDateSelected !== null &&
        typeof this.modalDeliveryDateSelected === 'object'
      ) {
        //  if user clicks the next delivery date then continue the route to create order
        if (this.link === 'toggle-search') {
          this.$emit('toggle-search');
        } else {
          const ordersCreatedToday = this.getOrdersPlacedToday(
            Common.getTodayDate()
          );
          const ordersWithSameExpectedDeliveryDate =
            this.getOrdersOfExpectedDeliveryDate(
              Common.formatDateYYYYMMDDtoDDMMYYYY(
                this.modalDeliveryDateSelected.Date
              )
            );
          if (
            (ordersCreatedToday.length > 0 ||
              ordersWithSameExpectedDeliveryDate.length > 0) &&
            this.$route.path !== '/order/tracking'
          ) {
            this.confirmAdditionalOrder(this.link);
          } else {
            // selected new date will be added to the client
            this.client.minimumOrder = Number(
              this.modalDeliveryDateSelected.MinimumValue
            );
            this.client.deliveryDateSelected =
              this.modalDeliveryDateSelected.header;
            this.client.deliveryDate =
              this.modalDeliveryDateSelected.Date.split('-').join('');
            this.$store.commit('setClient', this.client);

            // if user clicks the next delivery date then continue the route to create order
            if (this.link === 'toggle-search') {
              this.$emit('toggle-search');
            }
          }
        }
      }
      // for handling when the user dismisses (cancel) the modal
      if (this.modalDeliveryDateSelected === null) {
        this.showDeliveryDateChildModal = false;
      }
    },

    clearCart() {
      const h = this.$createElement;
      openModalNotificationAction(this.$bvModal, {
        id: 'clearCartConfirm',
        headerBgVariant: 'white',
        headerTextVariant: 'danger',
        hideHeaderClose: true,
        okVariant: 'secondary',
        okOnly: false,
        noCloseOnBackdrop: true,
        noCloseOnEsc: true,
        title: [
          h('b-icon', {
            props: {
              icon: 'exclamation-triangle-fill',
              scale: '1',
              variant: 'danger',
            },
          }),
          h(
            'span',
            {
              style: { color: 'red' },
            },
            ` ${this.$t('combo.removeAllItems')}`
          ),
        ],
        description: [
          h(
            'p',
            {
              style: { fontWeight: 200 },
            },
            ` ${this.$t('combo.clearCartConfirm')}`
          ),
        ],
        cancelTitle: this.$t('orderTracking.cancel'),
        okTitle: this.$t('ok'),
      }).then((value) => {
        if (value === true) {
          this.$store.dispatch('resetCart');
        }
      });
    },
  },
};
</script>
<style scoped>
#comboCart {
  position: relative;
  display: flex;
  justify-content: center;
}
.emptyCart {
  display: grid;
  grid-template-rows: auto auto auto;
  text-align: center;
  margin-bottom: 25px;
}
.emptyCart__item {
  margin-bottom: 30px;
}
.pending--confirm {
  border: 1px solid var(--primary);
  box-sizing: border-box;
  border-radius: 7px;
  color: var(--primary);
  margin-bottom: 20px;
  padding: 5px;
  text-align: center;
}
.scheduled {
  margin-bottom: 20px;
}
.remove-container {
  display: flex;
  margin-top: -15px;
  margin-right: 20px;
  align-items: margin-right;
  justify-content: right;
  cursor: pointer;
}

.remove-icon {
  margin-right: 5px;
}

.remove-text {
  text-decoration: underline;
  cursor: pointer;
  color: #d03635;
}
#address {
  white-space: pre-line;
}

@media only screen and (min-width: 850px) {
  .remove-container {
    margin-right: 100px;
  }
}
</style>
<style src="@/assets/styles/deliverydates.css" scoped></style>
