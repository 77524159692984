<template>
  <div class="container">
    <div class="scheduled-title d-block">
      <p>{{ title }}</p>
    </div>
    <div class="check fs-14 fw-400 mb-3 ml-3">
      <input
        id="checkbox-notification-optin"
        class="pr-3"
        type="checkbox"
        @change="registerOptInStatus"
      />
      <label
        for="checkbox-notification-optin"
        class="fw-200"
      >
        {{ description }}
      </label>
    </div>
  </div>
</template>

<script>
import apiOptInRegister from '@/api/optin/optInRegister';

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    onToggle: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isChecked: false,
    };
  },
  methods: {
    async registerOptInStatus() {
      this.$emit('update-optin');
      this.isChecked = !this.isChecked;
      const registerStatus = await apiOptInRegister.register(
        this.isChecked.toString()
      );
      this.onToggle(
        registerStatus.userOptIn?.toString() || registerStatus.toString()
      );
      return registerStatus;
    },
  },
};
</script>
