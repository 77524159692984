/* eslint-disable no-console */
import axios from 'axios';
import { getInstallationFunctionBaseUrl } from '@/utils/installation';
import { eventBus, events } from '@/utils/events/eventBus';

let httpClientInstance;
let APIManagementHttpClientInstance;
let isAuthorizationSet = false;

const defaultConfig = { withCredentials: true };

const initializeHttpClient = (useAzureAPIManagement) => {
  if (
    (httpClientInstance && isAuthorizationSet && !useAzureAPIManagement) ||
    (APIManagementHttpClientInstance &&
      isAuthorizationSet &&
      useAzureAPIManagement)
  )
    return;

  const urlParams = new URLSearchParams(window.location.search);
  const urlToken = urlParams.get('userToken');
  const storedToken = sessionStorage.getItem('authorization');

  const token = urlToken || storedToken;

  const axiosHeaders = {
    Authorization: `Bearer ${token}`,
  };

  const azLatamFunctionKey = process.env.VUE_APP_AZ_LATAM_FUNCTION_KEY;
  if (azLatamFunctionKey) {
    axiosHeaders['x-functions-key'] = azLatamFunctionKey;
  }

  const clientInstance = axios.create({
    baseURL: getInstallationFunctionBaseUrl(useAzureAPIManagement),
    timeout: 60000,
    headers: axiosHeaders,
  });

  if (token) isAuthorizationSet = true;

  clientInstance.interceptors.response.use(undefined, (error) => {
    if (
      error.response &&
      [401, 403].includes(error.response.status) &&
      error.config &&
      // eslint-disable-next-line no-underscore-dangle
      !error.config.__isRetryRequest
    ) {
      if (window.location.pathname.includes('login')) {
        return Promise.resolve(error);
      }
      eventBus.$emit(events.CRITICAL_ERROR);
      return Promise.resolve(error);
    }

    if (
      error.response &&
      ([400].includes(error.response.status) ||
        [500].includes(error.response.status))
    ) {
      return Promise.reject(error);
    }

    return error;
  });

  if (useAzureAPIManagement) {
    APIManagementHttpClientInstance = clientInstance;
  } else {
    httpClientInstance = clientInstance;
  }
};

export const request = async (params) => {
  initializeHttpClient();

  return httpClientInstance(params);
};

export const get = async ({
  url,
  config = defaultConfig,
  useAzureAPIManagement,
}) => {
  initializeHttpClient(useAzureAPIManagement);
  if (useAzureAPIManagement)
    return APIManagementHttpClientInstance.get(url, config);
  return httpClientInstance.get(url, config).catch((err) => {
    console.error(err.response);
    return err;
  });
};

export const post = async ({
  url,
  data,
  config = defaultConfig,
  useAzureAPIManagement,
}) => {
  initializeHttpClient(useAzureAPIManagement);
  if (useAzureAPIManagement) {
    return APIManagementHttpClientInstance.post(url, data, config);
  }
  return httpClientInstance.post(url, data, config).catch((err) => {
    console.error(err.response);
    return err;
  });
};

export const put = async ({ url, data, config = defaultConfig }) => {
  initializeHttpClient();

  return httpClientInstance.put(url, data, config).catch((err) => {
    console.error(err.response);
    return err;
  });
};

export const patch = async ({ url, data, config = defaultConfig }) => {
  initializeHttpClient();

  return httpClientInstance.patch(url, data, config).catch((err) => {
    console.error(err.response);
    return err;
  });
};
