<template>
  <div class="container">
    <div class="row">
      <div
        class="scheduled-check"
        :aria-label="checkBoxAriaLabel"
      >
        <div class="check fs-14 fw-400 mb-0 confirm">
          <input
            :id="description"
            v-model="checked"
            type="checkbox"
            @click="disabledClick(!checked, description)"
          />
          <label
            class="mb-0"
            :for="description"
          >
            {{ description }}
          </label>
          <HelpButton
            :help-description="helpMessage"
            :sku="description"
            class="d-inline-block ml-2"
          />
        </div>
      </div>
    </div>
    <div
      v-if="checked"
      class="row"
    >
      <SelectRecurrentDeliveryOption
        v-if="description === descriptionRecurrent"
        @startLoadScheduleOrderDates="$emit('startLoadScheduleOrderDates')"
        @finishLoadScheduleOrderDates="$emit('finishLoadScheduleOrderDates')"
      />
      <SelectScheduledDeliveryOption
        v-if="description === descriptionScheduled"
      />
    </div>
  </div>
</template>

<script>
import SelectRecurrentDeliveryOption from '@/components/order/deliveryOption/SelectRecurrentDeliveryOption.vue';
import SelectScheduledDeliveryOption from '@/components/order/deliveryOption/SelectScheduledDeliveryOption.vue';
import HelpButton from '@/components/shared/HelpButton';

export default {
  components: {
    SelectRecurrentDeliveryOption,
    SelectScheduledDeliveryOption,
    HelpButton,
  },
  props: {
    description: {
      type: String,
      default: '',
    },
    checkBoxAriaLabel: {
      type: String,
      default: '',
    },
    helpMessage: {
      type: String,
      default: '',
    },
    disabledClick: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      checked: false,
      descriptionRecurrent: this.$t(
        'order.deliveryOption.descriptionRecurrent'
      ),
      descriptionScheduled: this.$t(
        'order.deliveryOption.descriptionScheduled'
      ),
    };
  },
  watch: {
    checked(val) {
      this.$emit('checked', val);
    },
  },
};
</script>

<style scoped>
.scheduled-check {
  margin-left: 15px;
}
</style>
