<template>
  <router-link
    class="menu-item"
    to=""
    :aria-label="$t('ariaLabel.pageDescription', { page: name })"
    @click.native="disableMenu(link.replaceAll('/', ''))"
  >
    <div class="menu-icon-wrapper">
      <ImgGeneral
        class="menu-item-icon"
        :data-src="imgSource"
        :alt="alt"
      />
      <span
        v-if="link === '/cart'"
        class="menu-item-badge fs-10"
        >{{ quantity }}</span
      >
    </div>
    <div
      :class="{
        'fs-8 ': $i18n.locale === 'en',
        'fs-12 ': $i18n.locale === 'th',
      }"
      class="menu-item-name fs-md-12"
    >
      <p>{{ name.toUpperCase() }}</p>
    </div>
    <div
      v-if="isOverdue && link === '/credit'"
      class="p-3 flex-auto align-right justify-content-end"
    >
      <span
        ><b-badge
          pill
          variant="danger"
          ><span class="fs-10 fw-200">{{
            $t('creditPage.overdue')
          }}</span></b-badge
        ></span
      >
    </div>
    <DeliveryDateChildModal
      :is-visible="showDeliveryDateChildModal"
      :modal-id="link.replaceAll('/', '')"
      @selectedDataObj="handleModalSelectedDate"
    />
  </router-link>
</template>

<script>
import { mapGetters } from 'vuex';
import ImgGeneral from '@/components/shared/ImgGeneral';
import { openModalNotification } from '@/utils/pages';
import router from '@/router';
import Common from '@/utils/common';
import DeliveryDateChildModal from '@/components/shared/DeliveryDateChildModal';

export default {
  components: {
    ImgGeneral,
    DeliveryDateChildModal,
  },
  props: {
    imgSource: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedDateObj: null,
      modalDeliveryDateSelected: '',
      showDeliveryDateChildModal: false,
      isTrackOrderLinkClick: false,
      menuCLick: {},
    };
  },
  computed: {
    ...mapGetters({
      quantity: 'getItemsQuantity',
      client: 'getClient',
      isOverdue: 'getIsOverdue',
      isCartCutoffAcknowledged: 'getCartCutoffAcknowledged',
      getOrdersPlacedToday: 'getOrdersPlacedToday',
      getOrdersOfExpectedDeliveryDate: 'getOrdersOfExpectedDeliveryDate',
    }),
  },
  mounted() {
    // Accessing the Vue I18n instance
  },
  methods: {
    disableMenu(curModal) {
      // Order tracking allowed without delivery date and users may not want to order and just view
      if (
        this.client.deliveryDateSelected ||
        this.link === '/order/tracking' ||
        this.link === '/credit'
      ) {
        router.navigateToPage(this.link);
        return true;
      }

      if (router.getPath() === '/home') {
        this.showToastAlert();
      } else {
        this.showDeliveryDates();
      }

      if (this.menuCLick[curModal] === true && router.getPath() !== '/home') {
        this.$bvModal.show(curModal);
      }
      this.menuCLick[curModal] = true;
      return false;
      // Improvment for the future -> change the router link
    },

    confirmAdditionalOrder(link) {
      const h = this.$createElement;
      openModalNotification(this.$bvModal, {
        id: 'duplicateOrder',
        headerBgVariant: 'white',
        headerTextVariant: 'danger',
        hideHeaderClose: true,
        okVariant: 'secondary',
        okOnly: true,
        noCloseOnBackdrop: true,
        noCloseOnEsc: true,
        title: [
          h('b-icon', {
            props: {
              icon: 'exclamation-triangle-fill',
              scale: '1',
              variant: 'danger',
            },
          }),
          h(
            'span',
            {
              style: { color: 'red' },
            },
            ` ${this.$t('orderTracking.possibleDuplicateOrderTitle')}`
          ),
        ],
        description: [
          h('p', ` ${this.$t('orderTracking.possibleDuplicateOrderbody1')}`),
          h('p', ` ${this.$t('orderTracking.possibleDuplicateOrderbody2')}`),
          h('p', [
            h('span', [
              ` ${this.$t('orderTracking.possibleDuplicateOrderbody3')}`,
              h(
                'a',
                {
                  on: {
                    click: () => {
                      this.$bvModal.hide('duplicateOrder');
                      this.isTrackOrderLinkClick = true;
                      router.navigateToPage('/order/tracking');
                    },
                  },
                  style: {
                    cursor: 'pointer',
                    textDecoration: 'underline',
                    color: 'rgb(228, 30, 43)',
                  },
                },
                ` ${this.$t('orderTracking.possibleDuplicateOrderHistory')}`
              ),
              ` ${this.$t('orderTracking.possibleDuplicateOrderbody4')}`,
            ]),
          ]),
        ],
        okTitle: this.$t('ok'),
      }).then((value) => {
        if (value === true) {
          // selected new date will be added to the client
          this.client.minimumOrder = Number(
            this.modalDeliveryDateSelected.MinimumValue
          );
          this.client.deliveryDateSelected =
            this.modalDeliveryDateSelected.header;
          this.client.deliveryDate =
            this.modalDeliveryDateSelected.Date.split('-').join('');
          this.$store.commit('setClient', this.client);

          // if user clicks the next delivery date then continue the route to create order
          if (this.isTrackOrderLinkClick === false) {
            router.navigateToPage(link);
          }
        }
      });
    },

    handleModalSelectedDate(data) {
      if (data === null) {
        return;
      }
      this.modalDeliveryDateSelected = data;
      if (
        this.modalDeliveryDateSelected !== null &&
        typeof this.modalDeliveryDateSelected === 'object'
      ) {
        //  if user clicks the next delivery date then continue the route to create order
        if (this.link === 'toggle-search') {
          this.$emit('toggle-search');
        } else {
          const ordersCreatedToday = this.getOrdersPlacedToday(
            Common.getTodayDate()
          );
          const ordersWithSameExpectedDeliveryDate =
            this.getOrdersOfExpectedDeliveryDate(
              Common.formatDateYYYYMMDDtoDDMMYYYY(
                this.modalDeliveryDateSelected.Date
              )
            );
          if (
            (ordersCreatedToday.length > 0 ||
              ordersWithSameExpectedDeliveryDate.length > 0) &&
            this.$route.path !== '/order/tracking'
          ) {
            this.confirmAdditionalOrder(this.link);
          } else {
            // selected new date will be added to the client
            this.client.minimumOrder = Number(
              this.modalDeliveryDateSelected.MinimumValue
            );
            this.client.deliveryDateSelected =
              this.modalDeliveryDateSelected.header;
            this.client.deliveryDate =
              this.modalDeliveryDateSelected.Date.split('-').join('');
            this.$store.commit('setClient', this.client);

            // if user clicks the next delivery date then continue the route to create order
            if (this.link === 'toggle-search') {
              this.$emit('toggle-search');
            } else if (this.isTrackOrderLinkClick === false) {
              router.navigateToPage(this.link);
            }
          }
        }
      }

      // for handling when the user dismisses (cancel) the modal
      if (this.modalDeliveryDateSelected === null) {
        this.showDeliveryDateChildModal = false;
      }
    },

    async showDeliveryDates() {
      this.showDeliveryDateChildModal = true;
    },
    showToastAlert() {
      const h = this.$createElement;
      openModalNotification(this.$bvModal, {
        title: [
          h(
            'span',
            {
              style: { color: 'red' },
            },
            ` ${this.$t('deliveryDateSelector.toastHeaderScheduled')}`
          ),
        ],
        description: this.$t(
          'deliveryDateSelector.toastDescriptionScheduledOrder'
        ),
        okTitle: this.$t('ok'),
      });
    },
  },
};
</script>

<style scoped>
.menu-item {
  position: relative;
  display: flex;
  margin-right: 12px;
  margin-left: 12px;
  color: var(--dark);
  flex-direction: column;
  align-items: center;
}

.menu-item:hover,
.menu-item:focus {
  filter: brightness(0.9);
}

.secondary .menu-item p {
  margin-bottom: 0;
}

.menu-item-name {
  margin-top: 8px;
  font-weight: 200;
  text-align: center;
}

.router-link-active .menu-item-name {
  pointer-events: none;
  cursor: pointer;
}

.menu.primary .menu-icon-wrapper {
  width: 64px;
  height: 64px;
  display: flex;
  background-color: var(--primary);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}

.router-link-active {
  color: var(--dark);
}

.menu-item-icon {
  width: 42px;
  height: 42px;
  align-items: center;
}

.menu-item-badge {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  width: 24px;
  height: 24px;
  font-weight: 200;
  line-height: 0;
  color: var(--light);
  background-color: var(--dark-grey);
  border: 0;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  transform: translate(40%, -20%);
}

@media only screen and (min-width: 850px) {
  .menu {
    margin-bottom: 30px;
  }

  .menu-item {
    margin: 0 24px;
  }

  .menu-icon-wrapper {
    width: 40px;
    height: 40px;
  }
}

.justify-content-end {
  justify-content: flex-end !important;
}
</style>
<style src="@/assets/styles/deliverydates.css" scoped></style>
