<template>
  <div
    class="product__quantity-section d-flex flex-wrap justify-content-center mt-1 mb-2"
  >
    <p class="text-center fs-10 w-100 mb-2">
      {{ $t('combo.quantity') }}
    </p>
    <span
      class="d-flex justify-content-center product__quantity-controls-holder"
    >
      <b-button
        class="minus-button"
        :class="
          enableExcludeButton && quantity <= minQuantityAllowed + 1
            ? 'product__btn-trash'
            : ''
        "
        :aria-label="$t('ariaLabel.decreaseAmount')"
        variant="light"
        :disabled="qty <= minQuantityAllowed || disabled"
        @click="subNumber()"
      >
        <span
          v-if="
            !enableExcludeButton ||
            (enableExcludeButton && quantity > minQuantityAllowed + 1)
          "
        >
          -
        </span>
        <span v-if="enableExcludeButton && quantity <= minQuantityAllowed + 1">
          <ImgGeneral
            :is-one-px-placeholder="true"
            :data-src="`${assetsPath}img/icon/trash-icon.svg`"
            :alt-text="$t('cartPage.trashIconLabel')"
          />
        </span>
      </b-button>
      <input
        v-model.number.lazy="qty"
        :title="$t('combo.quantity')"
        type="number"
        class="product__input-quantity fs-16 text-center"
        min="1"
        step="1"
        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
        :disabled="!enableOrder || disabled"
        @change="changeQty(qty)"
      />
      <b-button
        class="plus-button"
        :aria-label="$t('ariaLabel.increaseAmount')"
        variant="light"
        :disabled="disabled || plusDisabled"
        @click="addNumber()"
      >
        +
      </b-button>
    </span>
  </div>
</template>

<script>
import { getAssetsPath } from '@/utils/config';
import ImgGeneral from '@/components/shared/ImgGeneral';

export default {
  components: {
    ImgGeneral,
  },
  props: {
    definePrice: {
      type: Function,
      default() {
        return [];
      },
    },
    enableOrder: {
      type: Boolean,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    quantity: {
      type: Number,
      required: false,
      default() {
        return 1;
      },
    },
    minQuantityAllowed: {
      type: Number,
      default: 1,
    },
    enableExcludeButton: {
      type: Boolean,
      default: false,
    },
    qtyLimit: {
      type: Number,
      default: 9999,
    },
    qtyLimitAlert: {
      type: Function,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      qty: 1,
      assetsPath: getAssetsPath(),
      plusDisabled: this.disabled,
    };
  },
  watch: {
    quantity() {
      this.qty = this.quantity;
      this.definePrice(this.qty);
      this.plusDisabled = this.qty === this.qtyLimit;
    },
  },
  created() {
    this.qty = this.quantity;
    this.plusDisabled = this.qty === this.qtyLimit;
  },
  methods: {
    addNumber() {
      this.qty = this.qty + 1;
      this.$emit('qty-updated', this.qty);
      this.definePrice(this.qty);
    },
    subNumber() {
      this.qty =
        this.qty > this.minQuantityAllowed
          ? this.qty - 1
          : this.minQuantityAllowed;
      this.$emit('qty-updated', this.qty);
      this.definePrice(this.qty);
    },
    changeQty(qty) {
      let integerQuantity = Math.round(qty);

      if (integerQuantity > this.qtyLimit) {
        this.qtyLimitAlert();
        integerQuantity = this.qtyLimit;
        this.qty = this.qtyLimit;
      }

      this.definePrice(integerQuantity);
      this.$emit('qty-updated', Number(integerQuantity));
    },
  },
};
</script>
