<template>
  <div class="page__content">
    <Breadcrumb />
    <Carousel />
    <div class="container max-section-width pt-4">
      <ul
        v-if="!loading"
        id="categories"
        class="row list-unstyled justify-content-center"
      >
        <li
          v-for="category in categories"
          :key="category.sku"
          class="category-box product__box justify-content-center d-flex"
        >
          <Category
            :category="category"
            @set-category="setCategory($event)"
          />
        </li>
      </ul>
      <NoResponse v-if="!loading && !categories" />
      <NotFound
        v-if="!loading && categories && !categories.length > 0"
        msg="notFound.category"
      />
      <div
        v-if="loading"
        class="loading"
      >
        <b-spinner label="Loading..." />
      </div>
    </div>
    <Monitor />
  </div>
</template>

<script>
/* eslint-disable func-names */
import { mapActions, mapGetters } from 'vuex';
import Category from '@/components/product/Category.vue';
import Breadcrumb from '@/components/shared/Breadcrumb.vue';
import Monitor from '@/components/Monitor.vue';
import Carousel from '@/components/Carousel.vue';
import NoResponse from '@/components/NoResponse.vue';
import NotFound from '@/components/NotFound.vue';
import router from '@/router';

export default {
  components: {
    Category,
    Breadcrumb,
    Monitor,
    Carousel,
    NoResponse,
    NotFound,
  },
  data() {
    return {
      categories: [],
      loading: true,
    };
  },
  computed: {
    ...mapGetters({
      client: 'getClient',
    }),
  },
  watch: {
    '$store.state.category.categories': function () {
      this.categoriesLoaded();
    },
    '$store.state.category.error': function () {
      this.redirectToErrorPage();
    },
    client() {
      this.loadCategory();
    },
  },
  beforeCreate() {
    this.loading = true;
  },
  created() {
    if (typeof this.client.type === 'string') {
      this.loadCategory();
    }
  },
  methods: {
    ...mapActions(['setCategory']),
    async loadCategory() {
      if (this.$store.state.category?.categories?.length) {
        this.categoriesLoaded();
        return;
      }
      if (this.client.type === 'Stockist') {
        this.$store.dispatch('getCategoriesStockist');
      } else {
        this.$store.dispatch('getCategories');
      }
    },
    categoriesLoaded() {
      if (this.client.type === 'Stockist' || this.client.type === 'Retail') {
        const categories = this.$store.state.category.categories
          .map((c) => ({
            ...c,
            name: decodeURI(c.name),
            sort: Number(c.sort),
          }))
          .sort((a, b) => a.sort - b.sort);
        this.categories = categories;
      } else {
        this.categories = this.$store.state.category.categories;
      }
      this.loading = false;
    },
    setCategory(category) {
      if (this.client?.type === 'Stockist') {
        router.navigateToPage(
          `group/products/${category.name.replaceAll(/\./g, 'dot')}`
        );
      } else {
        this.$store.commit('setCategory', category.name);
        router.navigateToPage(
          `group/products/${category.name.replaceAll(/\./g, 'dot')}`
        );
      }
    },
    redirectToErrorPage() {
      if (this.$store.state.category?.error) router.navigateToPage('/error');
    },
  },
};
</script>

<style scoped>
.category-box {
  padding-bottom: 12px;
}
</style>
