<template>
  <div
    :style="tagColor[bgColor]"
    class="text-center text-light d-flex flex-nowrap justify-content-center align-items-center p-2 mb-2 w-100"
  >
    <ImgGeneral
      :is-one-px-placeholder="true"
      class="icon-tag mr-2"
      :url="assetsPath + 'img/icon/white-cart.svg'"
    />
    <p class="text-left m-0 fs-10 fw-100">
      {{ labelTitle.toUpperCase() || $t('itemIsAlreadyInCart') }}
    </p>
  </div>
</template>

<script>
import { getAssetsPath } from '@/utils/config';
import ImgGeneral from '@/components/shared/ImgGeneral';

export default {
  components: {
    ImgGeneral,
  },
  props: {
    labelTitle: {
      type: String,
      required: false,
      default: '',
    },
    bgColor: {
      type: String,
      required: false,
      default: 'default',
    },
  },
  data() {
    return {
      tagColor: {
        default: 'background: var(--secondary)',
        warning: 'background: var(--orange)',
      },
      assetsPath: getAssetsPath(),
    };
  },
};
</script>

<style scoped>
.icon-tag {
  max-width: 24px;
}
</style>
