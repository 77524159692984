<template>
  <nav
    v-if="loadMenu"
    :class="level"
    class="menu w-100"
  >
    <div
      :class="level"
      class="main-menu"
    >
      <MenuItem
        v-for="menuItem in filteredMenuOptions"
        :key="menuItem.id"
        :img-source="assetsPath + formatImg(moduleInfo(menuItem).imgSource)"
        :link="'/' + moduleInfo(menuItem).route"
        :name="$t(moduleInfo(menuItem).name)"
        :alt="$t(moduleInfo(menuItem).alt)"
      />
      <MenuItemExtra
        v-if="level == 'secondary' && showNotificationOptionFlag"
        :level="level"
      />
    </div>
    <slot name="footer" />
  </nav>
  <nav
    v-else
    :class="level"
    class="menu w-100"
  >
    <div
      class="spinner-border"
      role="status"
    >
      <div>
        <span class="sr-only center">Loading...</span>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  getStorageBottler,
  getSessionData,
  getAssetsPath,
} from '@/utils/config';
import MenuItem from '@/components/menu/MenuItem.vue';
import { STORAGE_KEY } from '@/constants/storage';
import { MODULES } from '@/constants/feature';
import apiEcommerceConfig from '@/api/ecommerceConfig';
import MenuItemExtra from '@/components/menu/MenuItemExtra.vue';

const isCompletePage = window.location.href.includes('complete');
const isErrorPage = window.location.href.includes('error');

export default {
  components: {
    MenuItem,
    MenuItemExtra,
  },
  props: {
    level: {
      type: String,
      default: 'primary',
    },
  },
  data() {
    return {
      menuOptions: [],
      assetsPath: getAssetsPath(),
      showNotificationOptionFlag: false,
      loadMenu: isCompletePage || isErrorPage,
    };
  },
  computed: {
    ...mapGetters({
      ecommerceConfig: 'getEcommerceConfig',
      client: 'getClient',
      isOverDue: 'getIsDue',
      products: 'getSearchProducts',
      profileLoading: 'getProfileLoading',
      cart: 'getCartItems',
    }),
    filteredMenuOptions() {
      if (!this.menuOptions) return [];

      const filtered = this.menuOptions.reduce((selectedMenus, currentMenu) => {
        if (
          this.isMenuAllowedForBottler(currentMenu) &&
          this.isMenuAllowedForClient(currentMenu) &&
          this.isMenuAllowedForStockist(currentMenu)
        ) {
          selectedMenus.push(currentMenu);
        }

        return selectedMenus;
      }, []);

      return filtered;
    },
  },
  watch: {
    profileLoading(newVal) {
      if (newVal === false) {
        this.loadMenu = true;
      }
    },
    cart(newVal, oldVal) {
      if (
        (window.location.href.includes('complete') ||
          window.location.href.includes('error')) &&
        newVal.length === 0 &&
        oldVal.length > 0
      ) {
        this.loadMenu = true;
      }
    },
  },
  async created() {
    const storageBottler = getStorageBottler();
    this.menuOptions = storageBottler?.menu[this.level];
    this.showNotificationOptionFlag = storageBottler?.showNotificationOption;
    this.$emit('menuItemsCount', this.filteredMenuOptions.length);
    if (this.cart.length > 0) {
      this.loadMenu = true;
    }
  },
  methods: {
    moduleInfo(id) {
      return MODULES[id];
    },
    formatImg(src) {
      if (this.level === 'secondary') {
        const newSrc = src.split('.')[0];
        return `${newSrc}-secondary.svg`;
      }
      return src;
    },
    async getEcommerceConfig() {
      const storageBottler = getStorageBottler();
      if (!storageBottler?.useAPIEcommerceConfig) return undefined;

      const ecommerceConfig = getSessionData(STORAGE_KEY.ecommerceConfig);

      return ecommerceConfig || apiEcommerceConfig.fetchEcommerceConfig();
    },
    isMenuAllowedForStockist(menu) {
      if (this.client.paymentType !== 'Credit') {
        if (this.isOverDue && this.client.paymentType === 'Cash') {
          return true;
        }
        if (menu === 'credits') return false;
      } else {
        return true;
      }
      return true;
    },
    isMenuAllowedForClient(menu) {
      const avoidedModules = [
        'products',
        'promotions',
        'combos',
        'favorites',
        'cart',
        'suggested',
        'bingo',
      ];
      return (
        this.client.isOrderOptionActive !== false ||
        !avoidedModules.includes(menu)
      );
    },
    isMenuAllowedForBottler(currentMenu) {
      return (
        typeof this.ecommerceConfig?.menu[currentMenu] === 'undefined' ||
        this.ecommerceConfig?.menu[currentMenu] === true
      );
    },
  },
};
</script>

<style scoped>
.menu {
  background-color: var(--light);
  display: flex;
  justify-content: center;
  padding: 24px 2px 10px;
  box-shadow: 0 3px 24px -10px rgba(0, 0, 0, 0.25);
  z-index: 1020;
}

.main-menu.primary {
  display: flex;
  flex-direction: row;
}
.menu.secondary {
  height: 100%;
  background-color: transparent;
  flex-direction: column;
  justify-content: space-between;
}

.menu.secondary :deep(.menu-item) {
  max-width: none;
}

.menu-item-name:hover .menu-item:focus {
  text-decoration: underline;
}

@media only screen and (min-width: 850px) {
  .menu.primary {
    max-width: 1278px;
    margin: 0 auto;
    padding-bottom: 20px;
    position: relative;
    border-radius: 0 0 10px 10px;
  }
}
.center {
  margin: 0 auto;
  width: 30px;
}
</style>
