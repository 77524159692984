export default {
  state: {
    glassBottlesAccepted: false,
    creditLimit: {
      creditLimitAccepted: false,
      isPaymentLimitActive: false,
      isFinishOrderOverLimitAuthorized: false,
      limit: 0,
    },
  },
  getters: {
    getGlassBottlesTracking(state) {
      return state.glassBottlesAccepted;
    },
    getCreditLimitTracking(state) {
      return state.creditLimit;
    },
  },
  mutations: {
    setGlassBottlesTracking(state, payload) {
      // eslint-disable-next-line no-param-reassign
      state.glassBottlesAccepted = payload;
    },
    setCreditLimitTracking(state, payload) {
      // eslint-disable-next-line no-param-reassign
      state.creditLimit = payload;
    },
  },
  actions: {
    setGlassBottlesTracking({ commit }, value) {
      commit('setGlassBottlesTracking', value);
    },
    setCreditLimitTracking({ commit }, value) {
      commit('setCreditLimitTracking', value);
    },
  },
};
