import { post } from '@/utils/httpClient';
import { isValidBottler, setSessionData } from '../../utils/config';

const processExpiredToken = async ({ token }) => {
  const result = await post({
    url: 'webToken/expiredToken',
    data: {
      token,
    },
  });

  try {
    if (result.status === 200) {
      const { data } = result;
      if (data) {
        const { bottler, isUserContextResetSuccess } = data;
        return (
          isValidBottler({ bottler }) &&
          setSessionData({
            key: 'authSettings',
            value: { bottler, isUserContextResetSuccess },
          })
        );
      }
    }
    return false;
  } catch (e) {
    return false;
  }
};

export default {
  processExpiredToken,
};
