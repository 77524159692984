<template>
  <div>
    <fieldset>
      <label
        v-for="(option, index) in options"
        :id="'radioLabel-' + index"
        :key="option.header + index"
        :for="'radio-' + index"
        class="row radio-label mr-0 mt-1 ml-0"
      >
        <b-container class="radio-selection m-0 pt-2 pb-2 pl-3">
          <b-row align-h="between">
            <b-col cols="8">
              <span
                class="text-grey headerInformation"
                :class="
                  option.header == ' ' ||
                  option.header == '  ' ||
                  option.header == '   ' ||
                  option.header == '    '
                    ? ''
                    : ''
                "
              >
                {{ option.header === '1' ? '' : option.header }}
              </span>
              <span class="fs-14 text-mediumgrey sideInformation">
                {{ option.sideInformation }}
              </span>
              <div
                class="text-mediumgrey footer"
                :class="
                  option.header == ' ' ||
                  option.header == '  ' ||
                  option.header == '   ' ||
                  option.header == '    '
                    ? ''
                    : ''
                "
              >
                <span class="footer-label">{{
                  $t('deliveryDateSelector.minimumOrder')
                }}</span>
                {{ option.footer }}
              </div>
            </b-col>
            <b-col
              cols="4"
              align-self="center"
            >
              <div class="radio-container">
                <input
                  :id="'radio-' + index"
                  :checked="isChecked(option)"
                  :value="option"
                  type="radio"
                  name="radio-input"
                  class="mr-2"
                  :aria-labelledby="'radioLabel-' + index"
                  @change="handleChange(option)"
                  @click="goToProductsPage(option)"
                />
              </div>
            </b-col>
          </b-row>
        </b-container>
      </label>
    </fieldset>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ProductMixin from '@/components/mixins/ProductMixin';
import { openModalNotification } from '@/utils/pages';
import Common from '@/utils/common';
import router from '@/router';

export default {
  mixins: [ProductMixin],
  props: {
    options: { type: Array, default: () => [] },
  },
  data() {
    return {
      selectedValue: '',
    };
  },
  computed: {
    ...mapGetters(['getOrdersPlacedToday', 'getOrdersOfExpectedDeliveryDate']),
  },
  methods: {
    handleChange(option) {
      this.$emit('selected', option);
    },
    confirmAdditionalOrder(option) {
      const h = this.$createElement;
      openModalNotification(this.$bvModal, {
        id: 'duplicateOrder',
        headerBgVariant: 'white',
        headerTextVariant: 'danger',
        hideHeaderClose: true,
        okVariant: 'secondary',
        okOnly: true,
        noCloseOnBackdrop: true,
        noCloseOnEsc: true,
        title: [
          h('b-icon', {
            props: {
              icon: 'exclamation-triangle-fill',
              scale: '1',
              variant: 'danger',
            },
          }),
          h(
            'span',
            {
              style: { color: 'red' },
            },
            ` ${this.$t('orderTracking.possibleDuplicateOrderTitle')}`
          ),
        ],
        description: [
          h('p', ` ${this.$t('orderTracking.possibleDuplicateOrderbody1')}`),
          h('p', ` ${this.$t('orderTracking.possibleDuplicateOrderbody2')}`),
          h('p', [
            h('span', [
              ` ${this.$t('orderTracking.possibleDuplicateOrderbody3')}`,
              h(
                'a',
                {
                  on: {
                    click: () => {
                      this.$bvModal.hide('duplicateOrder');
                      router.navigateToPage('/order/tracking');
                    },
                  },
                  style: {
                    cursor: 'pointer',
                    textDecoration: 'underline',
                    color: 'rgb(228, 30, 43)',
                  },
                },
                ` ${this.$t('orderTracking.possibleDuplicateOrderHistory')}`
              ),
              ` ${this.$t('orderTracking.possibleDuplicateOrderbody4')}`,
            ]),
          ]),
        ],
        okTitle: this.$t('ok'),
      }).then((value) => {
        if (value === true) {
          if (this.$route.path === '/home') {
            this.handleChange(option);
            router.navigateToPage('/category');
          }
        }
      });
    },
    goToProductsPage(option) {
      const ordersCreatedToday = this.getOrdersPlacedToday(
        Common.getTodayDate()
      );
      const ordersWithSameExpectedDeliveryDate =
        this.getOrdersOfExpectedDeliveryDate(
          Common.formatDateYYYYMMDDtoDDMMYYYY(option.Date)
        );

      if (
        ordersCreatedToday.length > 0 ||
        ordersWithSameExpectedDeliveryDate.length > 0
      ) {
        this.confirmAdditionalOrder();
      } else {
        // eslint-disable-next-line
        if (this.$route.path === '/home') {
          router.navigateToPage('/category');
          this.handleChange(option);
        } else {
          router.navigateToPage('/home');
        }
      }
    },
    isChecked(option) {
      if (option.selected) this.handleChange(option);
      return option.selected;
    },
  },
};
</script>

<style scoped>
.footer-label {
  font-weight: lighter;
}

.headerInformation {
  font-weight: bold;
}

.radio-selection {
  box-sizing: border-box;
  width: 100%;
}
.radio-label {
  background-color: #d6d8db;
  margin-bottom: 0;
}

.radio-container {
  display: flex;
  justify-content: center;
}

input[type='radio'] {
  accent-color: var(--primary);
  width: 2em;
  height: 2em;
}
</style>
