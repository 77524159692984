import { get } from '@/utils/httpClient';
import { getStorageBottler } from '@/utils/config';

async function getClient() {
  const bottlerStorage = getStorageBottler();
  const result = await get({
    url: 'client',
    useAzureAPIManagement: bottlerStorage?.useAzureAPIManagement,
  });

  return result.status === 200 && result.data ? result.data : undefined;
}

export default { getClient };
