var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('fieldset',_vm._l((_vm.options),function(option,index){return _c('label',{key:option.header + index,staticClass:"row radio-label mr-0 mt-1 ml-0",attrs:{"id":'radioLabel-' + index,"for":'radio-' + index}},[_c('b-container',{staticClass:"radio-selection m-0 pt-2 pb-2 pl-3"},[_c('b-row',{attrs:{"align-h":"between"}},[_c('b-col',{attrs:{"cols":"8"}},[_c('span',{staticClass:"text-grey headerInformation",class:option.header == ' ' ||
                option.header == '  ' ||
                option.header == '   ' ||
                option.header == '    '
                  ? ''
                  : ''},[_vm._v(" "+_vm._s(option.header === '1' ? '' : option.header)+" ")]),_c('span',{staticClass:"fs-14 text-mediumgrey sideInformation"},[_vm._v(" "+_vm._s(option.sideInformation)+" ")]),_c('div',{staticClass:"text-mediumgrey footer",class:option.header == ' ' ||
                option.header == '  ' ||
                option.header == '   ' ||
                option.header == '    '
                  ? ''
                  : ''},[_c('span',{staticClass:"footer-label"},[_vm._v(_vm._s(_vm.$t('deliveryDateSelector.minimumOrder')))]),_vm._v(" "+_vm._s(option.footer)+" ")])]),_c('b-col',{attrs:{"cols":"4","align-self":"center"}},[_c('div',{staticClass:"radio-container"},[_c('input',{staticClass:"mr-2",attrs:{"id":'radio-' + index,"type":"radio","name":"radio-input","aria-labelledby":'radioLabel-' + index},domProps:{"checked":_vm.isChecked(option),"value":option},on:{"change":function($event){return _vm.handleChange(option)},"click":function($event){return _vm.goToProductsPage(option)}}})])])],1)],1)],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }