<template>
  <div class="container">
    <div class="row">
      <div class="scheduled-select p-2">
        <b-form-select
          v-model="selected"
          :options="recurringPeriod"
          :selected="loadAvailableDates()"
        />
        <b-form-datepicker
          v-if="bottlerStorage.showRecurrentOrderCalendar"
          v-model="dateValue"
          :locale="getLocale()"
          :placeholder="datepickerPlaceholder"
          label-no-date-selected=""
          :date-format-options="{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }"
          :hide-header="true"
          :min="getMinimumDate()"
          :max="getLimitDate()"
          label-help=""
          :value-as-date="true"
          @input="updateLimitDate"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import apiScheduledOrderAvailableDate from '@/api/order/scheduledOrderAvailableDate';
import { setSelectedDate } from '@/utils/pages';
import { getStorageBottler } from '@/utils/config';

export default {
  data() {
    return {
      selected: null,
      recurringPeriod: [],
      dateValue: null,
      datepickerPlaceholder: this.$t(
        'order.deliveryOption.datepickerPlaceholder'
      ),
      MS_PER_DAY: 1000 * 3600 * 24,
    };
  },
  computed: {
    ...mapGetters({
      availableDaysWithMaxPeriod: 'getAvailableDaysWithMaxPeriod',
    }),
  },
  created() {
    this.bottlerStorage = getStorageBottler();
    this.recurringPeriod = [
      { value: null, text: this.$t('order.deliveryOption.selectPeriod') },
      ...(this.getRecurrentOrderList() || []),
    ];
  },
  methods: {
    async setRecurrentOrderPeriod(period) {
      this.$emit('startLoadScheduleOrderDates');
      const availableDates =
        await apiScheduledOrderAvailableDate.getScheduledOrderAvailableDate({
          period,
        });
      this.$emit('finishLoadScheduleOrderDates');
      this.$store.commit('setSelectDate', setSelectedDate({ availableDates }));
    },
    async loadAvailableDates() {
      if (this.selected) {
        await this.setRecurrentOrderPeriod(this.selected);
        this.dateValue = null;
      }
      if (!this.selected) {
        this.$store.commit('setSelectDate', undefined);
      }
    },
    getRecurrentOrderList() {
      const formattedList =
        this.bottlerStorage?.recurrentOrderOptionalPeriods?.reduce(
          (optionalRecurrentPeriod, day) => {
            optionalRecurrentPeriod.push({
              value: day,
              text: this.$t('order.deliveryOption.recurrentOrderPeriod', {
                value: day,
              }),
            });
            return optionalRecurrentPeriod;
          },
          []
        );
      return formattedList;
    },
    async updateLimitDate() {
      const today = new Date();
      const daysToLimitDate = Math.ceil(
        (this.dateValue.getTime() - today.getTime()) / this.MS_PER_DAY
      );
      this.selected = null;

      await this.setRecurrentOrderPeriod(daysToLimitDate);
    },
    getLocale() {
      const { language, country } = this.bottlerStorage;
      const localeString = `${language}-${country}`;
      return localeString;
    },
    getMinimumDate() {
      // We receive an error when we try to create a
      // recurrent order with limit date equal to date on index 0
      const minimumAvailableDate = new Date(
        this.availableDaysWithMaxPeriod?.at(1)?.date
      );
      minimumAvailableDate.setDate(minimumAvailableDate.getDate() + 1);

      return minimumAvailableDate;
    },
    getLimitDate() {
      const today = new Date();
      const timeToMaxDate =
        this.MS_PER_DAY *
        this.bottlerStorage?.recurrentOrderOptionalPeriods?.slice(-1);
      const limitDate = new Date(today.getTime() + timeToMaxDate);
      return limitDate;
    },
  },
};
</script>

<style scoped>
.scheduled-select {
  margin-left: 30px;
  margin-bottom: 10px;
}
:deep(.custom-select) {
  min-width: 240px;
}
</style>
