<template>
  <div>
    <div class="section card-body">
      <div>
        <ImgGeneral
          :alt="imageAlt"
          class="mw-100"
          :data-src="imageUrl"
        />
      </div>
      <div class="title-card fw-500 fs-18 mt-3 mb-2">
        {{ title }}
      </div>
      <div
        v-if="description"
        class="description-card fw-200 fs-14 mt-2 mb-4"
      >
        <span>
          {{ description }}
        </span>
      </div>
      <div>
        <button
          class="btn btn-primary"
          type="button"
          @click="cbClick(url)"
        >
          <span class="p-3">
            {{ btnMessage }}
          </span>
        </button>
        <div />
      </div>
    </div>
  </div>
</template>

<script>
import ImgGeneral from '@/components/shared/ImgGeneral';

export default {
  components: {
    ImgGeneral,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    btnMessage: {
      type: String,
      default: '',
    },
    imageUrl: {
      type: String,
      default: '',
      required: true,
    },
    imageAlt: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: '',
    },
    cbClick: {
      type: Function,
      default: () => {},
    },
    displayImage: {
      type: String,
      default: 'banner',
    },
  },
  computed: {
    imageClass() {
      return this.displayImage === 'sideways'
        ? 'img-card align-middle float-right p-3'
        : 'img-card w-100 br-12';
    },
  },
};
</script>

<style scoped>
@media all and (min-width: 1024px) {
  .card-body {
    max-width: 40%;
    margin: 0 auto;
  }
}
</style>
