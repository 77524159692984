import Auth from '@/pages/auth/Auth.vue';
import Logout from '@/pages/auth/Logout.vue';
import Login from '@/pages/auth/Login.vue';
import Unauthenticated from '@/pages/auth/Unauthenticated.vue';
import HomePage from '@/pages/Home.vue';
import Search from '@/pages/Search.vue';
import OrderTracking from '@/pages/order/OrderTracking.vue';
import Products from '@/pages/products/Products.vue';
import Categories from '@/pages/products/Categories.vue';
import Promotions from '@/pages/products/Promotions.vue';
import Brands from '@/pages/products/Brands.vue';
import Suggested from '@/pages/products/Suggested.vue';
import Favorites from '@/pages/products/Favorites.vue';
import CartPage from '@/pages/CartPage.vue';
import Check from '@/pages/order/Check.vue';
import Order from '@/pages/order/Order.vue';
import Marketing from '@/pages/Marketing.vue';
import Bingo from '@/pages/Bingo.vue';
import Combos from '@/pages/combos/Combos.vue';
import ComboFamilyDetail from '@/pages/combos/ComboFamilyDetail.vue';
import ComboGeneric from '@/pages/combos/ComboGeneric.vue';
import PromotionsList from '@/pages/combos/PromotionsList.vue';
import ComboQuantityRangeDetail from '@/pages/combos/ComboQuantityRangeDetail.vue';
import Notifications from '@/pages/Notifications.vue';
import Credit from '@/pages/Credit.vue';
import Finance from '@/pages/finance/Finance.vue';
import Error from '@/pages/Error.vue';
import OpenInvoices from '@/pages/finance/OpenInvoices.vue';
import { getSessionData } from '@/utils/config';
import redirectURL from '@/utils/redirect';
import stores from '@/stores';
import { isStandaloneActivated as isStandalone } from '@/utils/pages';
import Layout from '../components/Layout.vue';

let router = [];

const isNotAuthenticated = async (to, from, next) => {
  const isAuthenticated = getSessionData('isAuthenticated') === true;
  if (!isAuthenticated && to.path !== '/unauthenticated') {
    next({ path: '/unauthenticated' });
  }
  if (!isAuthenticated) {
    const historyPage = router.history._startLocation;
    let launchPage = '';

    const url = new URL(window.location);
    const token = url.searchParams.get('userToken');
    if (token) {
      localStorage.setItem('userToken', token);
    }

    if (historyPage.startsWith('?') || historyPage.includes('page')) {
      // URLSearchParams object from the query string
      const urlParams = new URLSearchParams(historyPage);

      // if the 'page' parameter exists
      if (urlParams.has('page')) {
        launchPage = urlParams.get('page');
      }
    } else {
      // If historyPage doesn't start with '?', it should be the path itself (for local env)
      launchPage = historyPage;
    }
    // const pagePath = router.history._startLocation;
    launchPage = launchPage.startsWith('/') ? launchPage.slice(1) : launchPage;
    if (launchPage !== null && launchPage !== 'unauthenticated') {
      sessionStorage.setItem('startLocation', launchPage);
    }

    next();
  }
  if (isAuthenticated) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    next({ path: redirectURL.getRoutebyQueryString(urlParams) });
  }
  return true;
};

const isAuthenticated = async (to, from, next) => {
  if (
    stores.modules.client.state.clientProfileInfo.isOrderOptionActive ===
      false &&
    to.meta.showOnlyWhenOrderOptionActive
  ) {
    next({ path: '/home' });
    return;
  }
  const isAuthenticated = getSessionData('isAuthenticated') === true;

  if (isAuthenticated) {
    next();
    return;
  }
  // use router to not throw navigation guard exception
  router.push({ path: '/unauthenticated' });
};

const isStandaloneActivated = (to, from, next) => {
  if (isStandalone()) {
    next();
    return;
  }

  router.push({ path: '/unauthenticated' });
};

const setRouterInstance = (routerInstance) => {
  router = routerInstance;
};

/**
 * if params are sent, vue named route is used
 */
const navigateToPage = (targetPage, paramsUrl) => {
  if (targetPage !== router?.history?.current.path) {
    const routerTarget = {
      path: targetPage,
    };
    if (paramsUrl) {
      routerTarget.name = targetPage;
      routerTarget.params = paramsUrl;
    }
    router.push(routerTarget);
  }
};

/**
 * hard change url
 */
const setUrl = (newPath) => {
  router.replace(newPath);
};

/**
 * get the current path
 */
const getPath = () => {
  return router.currentRoute.path;
};

/**
 * For each route, if it has a name, the SC page title will be pull from
 * locale pageTitles object where key == name. If a meta titleInAppPage is present
 * this will override the title. Otherwise, the page title is blank
 */
const routes = [
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
          title: 'login',
          hideHeader: true,
          hideMenu: true,
          hideFooter: true,
        },
        beforeEnter: isStandaloneActivated,
      },
      {
        path: '/logout',
        name: 'logout',
        component: Logout,
        meta: {
          title: 'logout',
          hideHeader: true,
          hideMenu: true,
          hideFooter: true,
        },
      },
      {
        path: '/category',
        name: 'category',
        component: Categories,
        meta: {
          title: 'category',
          titleInAppPage: 'products',
          showOnlyWhenOrderOptionActive: true,
        },
        beforeEnter: isAuthenticated,
      },
      {
        path: '/',
        component: Auth,
        meta: {
          title: 'auth',
          hideHeader: true,
          hideMenu: true,
          hideFooter: true,
        },
        beforeEnter: isNotAuthenticated,
      },
      {
        path: '/unauthenticated',
        name: 'unauthenticated',
        component: Unauthenticated,
        meta: {
          title: 'unauth',
          hideHeader: true,
          hideMenu: true,
          hideFooter: true,
        },
        beforeEnter: isNotAuthenticated,
      },
      {
        path: '/category/brands/products/:categoryId/:brandId',
        component: Products,
        meta: {
          title: 'products',
          showOnlyWhenOrderOptionActive: true,
        },
        beforeEnter: isAuthenticated,
      },
      {
        path: '/group/products/:selectedId',
        component: Products,
        meta: {
          title: 'products',
          showOnlyWhenOrderOptionActive: true,
        },
        beforeEnter: isAuthenticated,
      },
      {
        path: '/cart',
        name: 'cart',
        component: CartPage,
        meta: {
          title: 'cart',
          showOnlyWhenOrderOptionActive: true,
        },
        beforeEnter: isAuthenticated,
      },
      {
        path: '/home',
        name: 'home',
        component: HomePage,
        meta: { title: 'home' },
        beforeEnter: isAuthenticated,
      },
      {
        path: '/promotions',
        name: 'promotions',
        component: Promotions,
        meta: {
          title: 'promotions',
          showOnlyWhenOrderOptionActive: true,
        },
        beforeEnter: isAuthenticated,
      },
      {
        path: '/category/brands/:categoryId',
        component: Brands,
        meta: {
          title: 'brands',
          showOnlyWhenOrderOptionActive: true,
        },
        beforeEnter: isAuthenticated,
      },
      {
        path: '/search',
        name: 'search',
        component: Search,
        meta: {
          title: 'search',
          showOnlyWhenOrderOptionActive: true,
        },
        beforeEnter: isAuthenticated,
      },
      {
        path: '/check',
        name: 'check',
        component: Check,
        meta: {
          title: 'check',
          hideMenu: true,
          showOnlyWhenOrderOptionActive: true,
        },
        beforeEnter: isAuthenticated,
        props: (route) => ({
          ...route.params,
        }),
      },
      {
        path: '/suggested',
        name: 'suggested',
        component: Suggested,
        meta: {
          title: 'suggested',
          showOnlyWhenOrderOptionActive: true,
        },
        beforeEnter: isAuthenticated,
      },
      {
        path: '/favorites',
        name: 'favorites',
        component: Favorites,
        meta: {
          title: 'favorites',
          showOnlyWhenOrderOptionActive: true,
        },
        beforeEnter: isAuthenticated,
      },
      {
        path: '/bingo',
        name: 'bingo',
        component: Bingo,
        meta: { title: 'bingo' },
        beforeEnter: isAuthenticated,
      },
      {
        path: '/order/complete',
        name: 'orderComplete',
        component: Order,
        meta: {
          title: 'order-complete',
          showOnlyWhenOrderOptionActive: true,
        },
        beforeEnter: isAuthenticated,
        props: (route) => ({
          ...route.params,
        }),
      },
      {
        path: '/order/tracking',
        name: 'orderTracking',
        component: OrderTracking,
        meta: { title: 'tracking' },
        beforeEnter: isAuthenticated,
      },
      {
        path: '/marketing',
        name: 'marketing',
        component: Marketing,
        meta: { title: 'marketing' },
        beforeEnter: isAuthenticated,
      },
      {
        path: '/combos',
        name: 'combos',
        component: Combos,
        meta: {
          title: 'combos',
          titleInAppPage: 'promotions',
          showOnlyWhenOrderOptionActive: true,
        },
        beforeEnter: isAuthenticated,
      },
      {
        path: '/combo/:comboId',
        name: 'combosFamily',
        component: ComboFamilyDetail,
        meta: {
          title: 'combos',
          showOnlyWhenOrderOptionActive: true,
        },
        beforeEnter: isAuthenticated,
      },
      {
        path: '/combo-quantity/:comboId/:comboName?',
        component: ComboQuantityRangeDetail,
        meta: {
          title: 'combos',
          showOnlyWhenOrderOptionActive: true,
        },
        beforeEnter: isAuthenticated,
      },
      {
        path: '/combo-generic/:comboId',
        component: ComboGeneric,
        meta: {
          title: 'combos',
          showOnlyWhenOrderOptionActive: true,
        },
        beforeEnter: isAuthenticated,
      },
      {
        path: '/promotionslist',
        component: PromotionsList,
        meta: {
          title: 'promotionslist',
          showOnlyWhenOrderOptionActive: true,
        },
        beforeEnter: isAuthenticated,
      },
      {
        path: '/notifications',
        name: 'notifications',
        component: Notifications,
        meta: { title: 'notifications' },
        beforeEnter: isAuthenticated,
      },
      {
        path: '/credit',
        name: 'credit',
        component: Credit,
        meta: { title: 'credit' },
        beforeEnter: isAuthenticated,
      },
      {
        path: '/error',
        name: 'error',
        component: Error,
        meta: { title: 'error' },
        props: (route) => ({
          ...route.params,
        }),
      },
      {
        path: '/finance',
        name: 'finance',
        component: Finance,
        meta: { title: 'finance' },
        beforeEnter: isAuthenticated,
      },
      {
        path: '/finance/openInvoices',
        component: OpenInvoices,
        meta: { title: 'finance' },
        beforeEnter: isAuthenticated,
      },
      { path: '/*', component: Auth, beforeEnter: isNotAuthenticated },
    ],
  },
];

export default {
  routes,
  navigateToPage,
  setRouterInstance,
  setUrl,
  getPath,
};
