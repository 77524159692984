<template>
  <div class="page__content">
    <Breadcrumb />
    <div class="container max-section-width">
      <div class="row">
        <div class="col fw-200 text-center max-w-700 mx-auto mt-3">
          <p class="w-100 fs-12 fs-md-14">
            {{ $t('orderTracking.featureDescription') }}
          </p>
        </div>
      </div>
      <div v-if="!loading">
        <div
          v-for="(order, index) in orders"
          :key="index"
        >
          <div v-if="order && order.order">
            <Tracking
              :index="index"
              :steps="getSteps(order)"
              :status="order.status"
              :order-number="order.order.toString()"
              :order-day="order.createdDate"
              :order-discount="order.discount"
              :order-system="order.orderSystem"
              :current-step="getCurrentStep(order)"
              :expected-delivery-date="order.expectedDeliveryDate"
              :full-price="Number(order.fullPrice)"
              :order-products="order.orderProducts"
              :full-products="fullProducts"
              :edited-by="order.editedBy"
              @refreshOrders="reloadAfterCancel($event)"
            />
          </div>
        </div>
      </div>
      <div
        v-if="!loading && !orders.length"
        class="emptyOrders"
      >
        <div>
          <NotFound
            class="fs-16"
            msg="notFound.orders"
          />
        </div>
        <router-link
          v-if="fullProducts.length"
          id="btn-back-category"
          class="btn mx-auto pl-md-5 pr-md-5 btn-primary"
          :aria-label="$t('ariaLabel.category')"
          :to="'/category'"
        >
          {{ $t('redirectToProduct') }}
        </router-link>
      </div>
      <div
        v-if="loading"
        class="loading mt-lg-5"
      >
        <b-spinner label="Loading..." />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import apiOrderTracking from '@/api/order/orderTracking';
import Breadcrumb from '@/components/shared/Breadcrumb.vue';
import Tracking from '@/components/order/Tracking.vue';
import NotFound from '@/components/NotFound.vue';
import { MODULES, SERVICES } from '@/constants/feature';
import { registerService, openModalNotification } from '@/utils/pages';
import { getStorageBottler } from '@/utils/config';

export default {
  components: {
    Breadcrumb,
    Tracking,
    NotFound,
  },
  data() {
    return {
      indexHistory: 10,
      orders: [],
      loading: true,
      appModules: MODULES,
      orderStatus: [
        'Submitted',
        'Confirmed',
        'Cancelled by customer',
        'Cancelled',
        'Delivering',
        'Completed',
        'Completed with partial delivery',
        'Unsuccessful delivery',
      ],
    };
  },
  computed: {
    ...mapGetters({
      fullProducts: 'getSearchProducts',
      userOrders: 'getOrders',
    }),
    ...mapActions({
      getOrders: 'getOrders',
    }),
    isOrderEnabled() {
      return this.client.isOrderOptionActive !== false;
    },
    languageLable() {
      return this.profile?.language?.toLocaleUpperCase?.() ?? 'TH';
    },
  },
  mounted() {
    this.orders = this.userOrders.orders;
    this.init();
  },
  created() {
    const storageBottler = getStorageBottler();
    if (storageBottler?.orderStatus) {
      this.orderStatus = storageBottler.orderStatus;
    }

    this.$store.dispatch('getOrders');
  },
  methods: {
    init() {
      this.loadOrderTracking();
      registerService({ service: SERVICES.orderTracking });
    },
    async loadOrderTracking() {
      /*
       ---- Required: the below logic will check for the cancelled order with the order number
       and adds that to the existing list adjusting the view with a refresh ----
       If we need to ajust using the index we are calling the api and adjusting the records
       */
      let order = [];
      const calls = [];
      const bottlerStorage = getStorageBottler();
      if (bottlerStorage?.isAllOrderTrackingEnabled) {
        this.orders = this.$store.dispatch('getOrders');
      } else {
        for (let index = 0; index < this.indexHistory; index++) {
          order = apiOrderTracking.getOrderTracking({
            indexOrderHistory: index,
          });
          if (order) {
            calls.push(order);
          }
        }
        if (calls.length) {
          const results = await Promise.allSettled(calls);
          this.orders = [];
          results.forEach((result) => {
            if (
              result.status === 'fulfilled' &&
              (Array.isArray(result.value) ? result.value.length : result.value)
            ) {
              this.orders.push(result.value);
            }
          });
        }
      }

      this.orders = this.linkEditedOrders(this.orders);
      this.loading = false;
    },
    linkEditedOrders(orders) {
      // Create a map for quick reference by order ID
      const orderMap = new Map(orders.map((order) => [order.order, order]));

      // Use array iteration instead of a traditional loop
      orders.forEach((order) => {
        // Check if the order was created via 'Edit'
        if (order.orderInformation === 'Edit') {
          // Find the parent edited order
          const editedOrder = orderMap.get(order.referenceId);

          // If the edited order is found, update it with a reference to the new order
          if (editedOrder) {
            editedOrder.editedBy = order.order;
          }
        }
      });

      return orders;
    },
    getCurrentStep(order) {
      if (
        order.status === 'Submitted' ||
        order.status === 'Cancelled by customer' ||
        order.status === 'Cancelled'
      ) {
        return 1;
      }

      if (order.status === 'Confirmed') {
        return 2;
      }

      if (
        order.status === 'Unsuccessful delivery' ||
        order.status === 'Delivering'
      ) {
        return 3;
      }

      if (
        order.status === 'Completed with partial delivery' ||
        order.status === 'Completed'
      ) {
        return 4;
      }
      return this.orderStatus.length;
    },
    getStatus(order) {
      if (order.statusI18n) {
        return (
          this.$t('orderTracking.statusOfOrder') +
          this.$t(`orderTracking.status.${order.statusI18n}`)
        );
      }
      if (order.status) {
        return this.$t('orderTracking.statusOfOrder') + order.status;
      }
      return order.generalInformation;
    },
    getSteps(order) {
      const statusMapping = {
        Submitted: ['Submitted', 'Confirmed', 'Delivering', 'Completed'],
        'Completed with partial delivery': [
          'Submitted',
          'Confirmed',
          'Delivering',
          'Completed with partial delivery',
        ],
        'Unsuccessful delivery': [
          'Submitted',
          'Confirmed',
          'Delivering',
          'Unsuccessful delivery',
        ],
        Delivering: ['Submitted', 'Confirmed', 'Delivering', 'Completed'],
        Confirmed: ['Submitted', 'Confirmed', 'Delivering', 'Completed'],
        'Cancelled by customer': ['Submitted', 'Cancelled by customer'],
        Cancelled: ['Submitted', 'Cancelled'],
        Completed: ['Submitted', 'Confirmed', 'Delivering', 'Completed'],
      };

      const steps = statusMapping[order.status];

      return steps || order.status;
    },
    async reloadAfterCancel(orderNumber) {
      const h = this.$createElement;
      openModalNotification(this.$bvModal, {
        title: [
          h('b-icon', {
            props: {
              icon: 'check-circle-fill',
              scale: '1',
              variant: 'secondary',
            },
          }),
          h(
            'span',
            {
              style: { color: 'green' },
            },
            ` ${this.$t('orderTracking.orderCancelled')}`
          ),
        ],
        description: this.$t('orderTracking.orderCancelledMessage', {
          orderNumber,
          value: 5,
        }),
        headerClass: 'bg-white',
        headerBgVariant: null,
        headerTextVariant: 'dark',
        okTitle: this.$t('ok'),
      });
      this.loading = true;
      this.fullProducts = this.$store.state.search.products;
      this.loadOrderTracking();
      registerService({ service: SERVICES.orderTracking });
    },
  },
};
</script>

<style scoped>
.emptyOrders {
  display: grid;
  grid-template-rows: auto auto auto;
  text-align: center;
  margin-bottom: 25px;
}
</style>
