<template>
  <main class="vh-100">
    <div class="page__wrapper">
      <div class="page__content">
        <Header
          v-if="!staticLayoutAssets.hideHeader"
          :client-name="client.name"
        />
        <Menu v-if="!staticLayoutAssets.hideMenu" />
        <FeatureModal />
        <router-view :key="$route.fullPath" />
      </div>
      <footer class="footer-container">© Thainamthip Winnie</footer>
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import Header from '@/components/header/Header.vue';
import FeatureModal from '@/components/FeatureModal.vue';
import Menu from '@/components/menu/Menu.vue';
import {
  getAssetsPath,
  setSessionData,
  getStorageBottler,
} from '@/utils/config';
import Analytics from '@/utils/analytics';
import analyticsEvents from '@/utils/events/analytics';
import { openModalNotification, staticLayoutAssets } from '@/utils/pages';
import { loadAndInjectFonts } from '@/utils/fonts';
import { eventBus, events } from '@/utils/events/eventBus';
import router from './router';

export default {
  name: 'App',
  components: {
    Header,
    Menu,
    FeatureModal,
  },
  data() {
    return {
      removeStartup: null,
      assetsPath: getAssetsPath(),
      staticLayoutAssets,
      storageBottler: getStorageBottler(),
    };
  },
  computed: {
    ...mapGetters({
      client: 'getClient',
    }),
  },
  async created() {
    eventBus.$on(events.CRITICAL_ERROR, this.onCriticalApiError);
    this.verifyThirdPartyCookies();
    await this.loadCssDependencies();
    const fontSizes = this.storageBottler?.fontSizeOverride;
    if (fontSizes) {
      this.overrideFontSizes(fontSizes);
    }

    if (
      window.location.href.includes('login') ||
      window.location.href.includes('unauthenticated')
    ) {
      this.hideStartupScreen();
      return;
    }
    this.initAnalytics();
    this.removeStartup = setTimeout(this.hideStartupScreen, 1000);
  },
  methods: {
    initAnalytics() {
      const clientId = this.client.external_id || this.client.document_id;
      const { bottler } = this.client;
      setSessionData({ key: 'authSettings', value: { clientId, bottler } });
      if (clientId) {
        Analytics.logEvent(analyticsEvents.EVENTS.USER_AUTHENTICATED, {
          userId: clientId,
          bottler,
        });
      }
    },
    onCriticalApiError() {
      router.navigateToPage('logout');
    },
    async loadCssDependencies() {
      const root = document.querySelector(':root');
      root.style.setProperty('--assets-path', this.assetsPath);

      await loadAndInjectFonts();
    },
    overrideFontSizes(sizes) {
      const root = document.querySelector(':root');
      Object.keys(sizes).forEach((key) => {
        root.style.setProperty(`--${key}`, sizes[key]);
      });
    },
    hideStartupScreen() {
      eventBus.$emit(events.APP_IS_SHOWN);
      clearTimeout(this.removeStartup);
      const startupPreloader = document.querySelector('.startup-preloader');
      if (startupPreloader) startupPreloader.remove();
      // check if google translate is enabled
      if (document.querySelector('html').classList.contains('translated-ltr')) {
        openModalNotification(this.$bvModal, {
          title: this.$t('toast.warning'),
          description: this.$t('toast.translator'),
          okTitle: this.$t('ok'),
        });
      }
    },
    verifyThirdPartyCookies() {
      try {
        if (window.localStorage) {
          return;
        }
      } catch (err) {
        router.navigateToPage('error', {
          errorMessage: 'thirdPartyCookiesDisabled',
        });
      }
    },
  },
};
</script>

<style>
.footer-container {
  position: relative;
  bottom: 0;
  width: 100%;
  font-size: 12px;
  padding: 16px;
  border-top: 1px solid var(--light-grey);
}
</style>

<style src="@/assets/styles/bootstrap.css"></style>

<style src="@/assets/styles/button.css"></style>

<style src="@/assets/styles/symbol.css"></style>

<style src="@/assets/styles/color.css"></style>

<style src="@/assets/styles/typography.css"></style>

<style src="@/assets/styles/common.css"></style>

<style src="@/assets/styles/product.css"></style>

<style src="@/assets/styles/check.css"></style>

<style src="@/assets/styles/select.css"></style>

<style src="@/assets/styles/multiselect.css"></style>

<style src="@/assets/styles/toast.css"></style>

<style src="@/assets/styles/step-progress.css"></style>

<style src="@/assets/styles/animations.css"></style>

<style src="@/assets/styles/calendar.css"></style>
