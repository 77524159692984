<template>
  <ul
    v-show="combosInCart.length"
    class="list-unstyled mt-4"
  >
    <li
      v-for="combo in combosInCart"
      :key="combo"
      class="list-item list-combo-item"
    >
      <div class="combo-image">
        <ImgGeneral
          class="item-img"
          :url="getCombo(combo).image"
        />
      </div>
      <div class="pl-1">
        <h4
          translate="no"
          class="product__title mb-2"
        >
          {{ getCombo(combo).name }}
        </h4>
        <h4 class="product__price">
          {{ formatPrice(Number(showComboValue(combo))) }}
        </h4>
        <ul class="list-unstyled">
          <li
            v-for="item in items"
            :key="item.product.sku + item.product.combo"
          >
            <div v-if="item.product.combo === combo">
              <h4 class="product__title mb-1">
                <span class="text-primary">{{ item.quantity }}x</span>
                {{ item.product.brand }}
              </h4>
              <p class="fs-10 fw-200">
                {{ item.product.description }}
              </p>
            </div>
          </li>
          <div class="edit-button">
            <b-button
              class=""
              variant="tertiary"
              @click="$emit('edit', combo)"
            >
              EDITAR COMBO
            </b-button>
          </div>
        </ul>
      </div>
      <div class="remove-button">
        <b-button
          class=""
          variant="primary"
          @click="$emit('remove', combo)"
        >
          REMOVER
        </b-button>
      </div>
    </li>
  </ul>
</template>
<script>
import { getStorageBottler } from '@/utils/config';
import apiCombo from '@/api/combo';
import ProductMixin from '@/components/mixins/ProductMixin';
import ImgGeneral from '@/components/shared/ImgGeneral';

export default {
  components: {
    ImgGeneral,
  },
  mixins: [ProductMixin],
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      combos: [],
      comboItems: [],
      combosInCart: [],
    };
  },
  watch: {
    items: {
      deep: true,
      handler() {
        this.checkCart();
      },
    },
  },
  created() {
    const storageBottler = getStorageBottler();
    if (!storageBottler?.isComboServiceAvailable) {
      return;
    }
    this.checkCart();
    this.loadCombos();
  },
  methods: {
    showComboValue(comboId) {
      const totalPrice = this.items
        .filter((p) => p.product.combo && p.product.combo === parseInt(comboId))
        .reduce((t, item) => t + Number(item.product.price * item.quantity), 0);
      return totalPrice;
    },
    getCombo(comboId) {
      if (this.combos && this.combos.length > 0) {
        const combo = this.combos.find((c) => c.id === parseInt(comboId));
        return combo;
      }
      return '';
    },
    async loadCombos() {
      this.combos = await apiCombo.getCombos();
    },
    checkCart() {
      this.combosInCart = [
        ...new Set(
          this.items
            .filter((p) => p.product.combo && p.product.combo !== 0)
            .map((p) => p.product.combo)
        ),
      ];
    },
  },
};
</script>

<style scoped>
.list-item {
  background-color: var(--light);
  border-radius: 12px;
  filter: drop-shadow(0 7px 18px rgba(0, 0, 0, 0.25));
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
}

.item-img {
  height: 5rem;
  margin: auto;
}
</style>
