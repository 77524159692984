<template>
  <div>
    <router-link
      v-if="isStockist"
      class="aux-icon-header favorites"
      :aria-label="$t('ariaLabel.favorite')"
      to="/credit"
    >
      <ImgGeneral
        :is-one-px-placeholder="true"
        :data-src="assetsPath + 'img/header/small-credit.svg'"
        :alt="$t('menu.creditIconAlt')"
      />
    </router-link>
    <button
      v-if="!profileLoading"
      @click="toggleLanguage"
      >{{ languageLable }}
    </button>
    <b-spinner
      v-if="profileLoading"
      variant="light"
      label="Spinning"
    />
    <router-link
      v-if="isOrderEnabled"
      class="aux-icon-header favorites"
      :aria-label="$t('ariaLabel.favorite')"
      to=""
      @click.native="checkForDeliveryDate(`/favorites`)"
    >
      <ImgGeneral
        :data-src="assetsPath + 'img/header/small-heart.svg'"
        :alt="$t('header.favoritesIconAlt')"
      />
    </router-link>
    <button
      v-if="isOrderEnabled && products.length"
      class="aux-icon-header search"
      :aria-label="$t('ariaLabel.search')"
      @click="toggleSearchField"
    >
      <ImgGeneral
        id="search-icon"
        :data-src="assetsPath + 'img/header/small-search.svg'"
        :alt="$t('header.searchIconAlt')"
      />
    </button>
    <router-link
      v-if="urlCart && isOrderEnabled"
      class="aux-icon-header cart"
      :aria-label="$t('ariaLabel.cart')"
      to=""
      @click.native="checkForDeliveryDate(`/${urlCart}`)"
    >
      <ImgGeneral
        :data-src="assetsPath + 'img/icon/white-cart.svg'"
        :alt="$t('header.cartIconAlt')"
      />
      <span
        class="quantity-badge position-relative d-inline-block align-bottom fw-100 fs-10"
      >
        {{ Math.round(quantity) }}
      </span>
    </router-link>
    <DeliveryDateChildModal
      modal-id="icon"
      :is-visible="showDeliveryDateChildModal"
      @selectedDataObj="handleModalSelectedDate"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getAssetsPath } from '@/utils/config';
import { MODULES } from '@/constants/feature';
import ImgGeneral from '@/components/shared/ImgGeneral';
import { openModalNotification } from '@/utils/pages';
import router from '@/router';
import Common from '@/utils/common';
import DeliveryDateChildModal from '@/components/shared/DeliveryDateChildModal';

export default {
  components: {
    ImgGeneral,
    DeliveryDateChildModal,
  },
  props: {
    menuOptions: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      urlFavorites: false,
      urlCart: false,
      urlTracking: false,
      assetsPath: getAssetsPath(),
      selectedDateObj: null,
      modalDeliveryDateSelected: '',
      showDeliveryDateChildModal: false,
      link: '',
      isTrackOrderLinkClick: false,
      menuCLick: {},
    };
  },
  computed: {
    ...mapGetters({
      quantity: 'getItemsQuantity',
      client: 'getClient',
      products: 'getSearchProducts',
      profile: 'getProfile',
      profileLoading: 'getProfileLoading',
      isOverDue: 'getIsDue',
      isCartCutoffAcknowledged: 'getCartCutoffAcknowledged',
      getOrdersPlacedToday: 'getOrdersPlacedToday',
      getOrdersOfExpectedDeliveryDate: 'getOrdersOfExpectedDeliveryDate',
    }),
    isStockist() {
      // the first if condition is specifically introduced to
      // the customers who transisioned from Credit to cash
      // if the customer got any overdue we will show the credit icon
      if (this.isOverDue && this.client.paymentType === 'Cash') {
        return true;
      }
      return this.client.paymentType === 'Credit';
    },
    isOrderEnabled() {
      return this.client.isOrderOptionActive !== false;
    },
    languageLable() {
      return this.profile?.language === 'en' ? 'ไทย' : 'EN';
    },
  },
  watch: {
    menuOptions: {
      handler() {
        this.renderIcons();
      },
    },
  },
  mounted() {
    if (this.menuOptions) this.renderIcons();
  },
  methods: {
    renderIcons() {
      if (this.menuOptions?.fixed?.includes('cart'))
        this.urlCart = MODULES.cart.route;
      if (this.menuOptions?.fixed?.includes('favorites'))
        this.urlFavorites = MODULES.favorites.route;
      if (!this.urlFavorites && this.menuOptions?.fixed?.includes('tracking'))
        this.urlTracking = MODULES.tracking.route;
    },
    async toggleLanguage() {
      this.$store.dispatch(
        'setLanguage',
        this.profile?.language === 'en' ? 'th' : 'en'
      );
    },
    toggleSearchField() {
      if (this.client.deliveryDateSelected) {
        this.$emit('toggle-search');
      } else if (router.getPath() === '/home') {
        this.showToastAlert();
      } else {
        const link = 'toggle-search';
        this.showDeliveryDates(link);
      }

      if (
        this.menuCLick.icon === true &&
        router.getPath() !== '/home' &&
        !this.client.deliveryDateSelected
      ) {
        this.$bvModal.show('icon');
      }
      this.menuCLick.icon = true;
    },
    handleModalSelectedDate(data) {
      if (data === null) {
        return;
      }
      this.modalDeliveryDateSelected = data;
      if (
        this.modalDeliveryDateSelected !== null &&
        typeof this.modalDeliveryDateSelected === 'object'
      ) {
        //  if user clicks the next delivery date then continue the route to create order
        const ordersCreatedToday = this.getOrdersPlacedToday(
          Common.getTodayDate()
        );
        const ordersWithSameExpectedDeliveryDate =
          this.getOrdersOfExpectedDeliveryDate(
            Common.formatDateYYYYMMDDtoDDMMYYYY(
              this.modalDeliveryDateSelected.Date
            )
          );
        if (
          (ordersCreatedToday.length > 0 ||
            ordersWithSameExpectedDeliveryDate.length > 0) &&
          this.isTrackOrderLinkClick === false
        ) {
          this.confirmAdditionalOrder(this.link);
        } else {
          // selected new date will be added to the client
          this.client.minimumOrder = Number(
            this.modalDeliveryDateSelected.MinimumValue
          );
          this.client.deliveryDateSelected =
            this.modalDeliveryDateSelected.header;
          this.client.deliveryDate =
            this.modalDeliveryDateSelected.Date.split('-').join('');
          this.$store.commit('setClient', this.client);

          // if user clicks the next delivery date then continue the route to create order
          if (this.link === 'toggle-search') {
            this.$emit('toggle-search');
          } else {
            router.navigateToPage(this.link);
          }
        }
      }

      // for handling when the user dismisses (cancel) the modal
      if (this.modalDeliveryDateSelected === null) {
        this.showDeliveryDateChildModal = false;
      }
    },
    checkForDeliveryDate(link) {
      if (this.client.deliveryDateSelected) {
        router.navigateToPage(link);
        return true;
      }
      if (router.getPath() === '/home') {
        this.showToastAlert();
      } else {
        this.showDeliveryDates(link);
      }
      if (this.menuCLick.icon === true && router.getPath() !== '/home') {
        this.$bvModal.show('icon');
      }
      this.menuCLick.icon = true;
      return false;
      // Improvment for the future -> change the router link
    },

    confirmAdditionalOrder(link) {
      const h = this.$createElement;
      openModalNotification(this.$bvModal, {
        id: 'duplicateOrder',
        headerBgVariant: 'white',
        headerTextVariant: 'danger',
        hideHeaderClose: true,
        okVariant: 'secondary',
        okOnly: true,
        noCloseOnBackdrop: true,
        noCloseOnEsc: true,
        title: [
          h('b-icon', {
            props: {
              icon: 'exclamation-triangle-fill',
              scale: '1',
              variant: 'danger',
            },
          }),
          h(
            'span',
            {
              style: { color: 'red' },
            },
            ` ${this.$t('orderTracking.possibleDuplicateOrderTitle')}`
          ),
        ],
        description: [
          h('p', ` ${this.$t('orderTracking.possibleDuplicateOrderbody1')}`),
          h('p', ` ${this.$t('orderTracking.possibleDuplicateOrderbody2')}`),
          h('p', [
            h('span', [
              ` ${this.$t('orderTracking.possibleDuplicateOrderbody3')}`,
              h(
                'a',
                {
                  on: {
                    click: () => {
                      this.isTrackOrderLinkClick = true;
                      this.$bvModal.hide('duplicateOrder');
                      router.navigateToPage('/order/tracking');
                    },
                  },
                  style: {
                    cursor: 'pointer',
                    textDecoration: 'underline',
                    color: 'rgb(228, 30, 43)',
                  },
                },
                ` ${this.$t('orderTracking.possibleDuplicateOrderHistory')}`
              ),
              ` ${this.$t('orderTracking.possibleDuplicateOrderbody4')}`,
            ]),
          ]),
        ],
        okTitle: this.$t('ok'),
      }).then((value) => {
        if (value === true) {
          // selected new date will be added to the client
          this.client.minimumOrder = Number(
            this.modalDeliveryDateSelected.MinimumValue
          );
          this.client.deliveryDateSelected =
            this.modalDeliveryDateSelected.header;
          this.client.deliveryDate =
            this.modalDeliveryDateSelected.Date.split('-').join('');
          this.$store.commit('setClient', this.client);

          // if user clicks the next delivery date then continue the route to create order
          if (link === 'toggle-search') {
            this.$emit('toggle-search');
          } else if (this.isTrackOrderLinkClick === false) {
            router.navigateToPage(link);
          }
        }
      });
    },

    async showDeliveryDates(link) {
      this.showDeliveryDateChildModal = true;
      this.link = link;
    },

    showToastAlert() {
      const h = this.$createElement;
      openModalNotification(this.$bvModal, {
        title: [
          h(
            'span',
            {
              style: { color: 'red' },
            },
            ` ${this.$t('deliveryDateSelector.toastHeaderScheduled')}`
          ),
        ],
        description: this.$t(
          'deliveryDateSelector.toastDescriptionScheduledOrder'
        ),
        okTitle: this.$t('ok'),
      });
    },
  },
};
</script>

<style scoped>
.aux-icon-header {
  background-color: transparent;
  border: 0;
  padding: 0;
  margin: 0 6px;
  position: relative;
  display: flex;
  align-items: center;
}

.aux-icon-header:last-child {
  margin-right: 0;
}

.quantity-badge {
  background-color: var(--primary);
  border-radius: 20px;
  color: var(--light);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 3px 6px;
  margin-left: 2px;
  max-width: 38px;
}
</style>
<style src="@/assets/styles/deliverydates.css" scoped></style>
