<template>
  <div>
    <div class="container">
      <div
        v-b-toggle="'collapse-' + invoiceId"
        variant="primary"
        class="row"
        aria-label="$t('finance.openInvoices.collapseButton')"
      >
        <div
          :style="statusColor[status]"
          class="invoice-date col d-flex flex-column align-items-center flex-sm-row justify-content-center"
        >
          <ImgGeneral
            :alt="$t('finance.openInvoices.calendarImgAlt')"
            class="calendar-img mb-1 mb-sm-0"
            :url="assetsPath + 'img/icon/calendar.png'"
          />
          <span class="ml-1 invoice-information">
            {{ $d(dueDateWithoutTime, 'short') }}
          </span>
        </div>
        <div
          :style="statusColor[status]"
          class="invoice-status col d-flex flex-column align-items-center flex-sm-row justify-content-center"
        >
          <ImgGeneral
            v-if="status == 'overdue'"
            :alt="$t('finance.openInvoices.warningImgAlt')"
            :url="assetsPath + 'img/icon/red-warning.svg'"
            class="warning-icon overdue-img mb-1 mb-sm-0"
          />
          <ImgGeneral
            v-if="status == 'expired'"
            :alt="$t('finance.openInvoices.warningExpiredImgAlt')"
            :url="assetsPath + 'img/icon/expired.png'"
            class="warning-icon expiredImg mb-1 mb-sm-0"
          />
          <ImgGeneral
            v-if="status == 'dueNextDay'"
            :alt="$t('finance.openInvoices.warningYellowImgAlt')"
            :url="assetsPath + 'img/icon/yellow-warning.png'"
            class="warning-icon next-day-img mb-1 mb-sm-0"
          />
          <span class="ml-1 invoice-information">
            {{ $t('finance.openInvoices.status')[status] }}
          </span>
        </div>
        <div
          class="invoice-debt col d-flex flex-column align-items-center flex-sm-row justify-content-center"
        >
          <ImgGeneral
            class="dollar-img mb-1 mb-sm-0"
            :url="assetsPath + 'img/icon/dollar-bill.png'"
            :alt="$t('finance.openInvoices.dollarBillImgAlt')"
          />
          <span class="ml-1 invoice-information">
            {{ $n(invoiceDebt, 'currency') }}
          </span>
        </div>
      </div>
      <b-collapse
        :id="'collapse-' + invoiceId"
        visible
        accordion="my-accordion"
        role="tabpanel"
        class="invoice-info mt-2 ml-md-4"
      >
        <div class="collapsed-information">
          <div v-if="status == 'expired'">
            <p class="information expiredInfo">
              {{ $t('finance.openInvoices.expired') }}
            </p>
            <p class="information expiredInfo">
              {{
                $t('finance.openInvoices.contact', {
                  contactPhone: bottlerStorage.contactPhone,
                  value: botSourceAppName,
                })
              }}
            </p>
          </div>
          <div v-if="status == 'overdue'">
            <p class="information overdue-info">
              {{ $t('finance.openInvoices.payment') }}
            </p>
          </div>
          <div
            v-if="status !== 'expired'"
            class="valid-info"
          >
            <div class="information">
              <div v-if="orderNumber">
                <p>
                  <span class="fw-300">
                    {{ $t('finance.openInvoices.orderNumber') }}
                  </span>
                  {{ orderNumber }}
                </p>
              </div>
              <div v-if="invoiceDocumentNumber">
                <p>
                  <span class="fw-300">
                    {{ $t('finance.openInvoices.invoiceDocumentNumber') }}
                  </span>
                  {{ invoiceDocumentNumber }}
                </p>
              </div>
              <div>
                <p>
                  <span class="fw-300">
                    {{ $t('finance.openInvoices.dueDate') }}
                  </span>
                  {{ $d(dueDateWithoutTime, 'short') }}
                </p>
              </div>
              <div>
                <p>
                  <span class="fw-300">
                    {{ $t('finance.openInvoices.barCode') }}
                  </span>
                  {{ invoiceBarCode }}
                </p>
              </div>
            </div>
            <button
              :id="'copyBtn-' + invoiceId"
              aria-label="$t('finance.openInvoices.copy-button')"
              class="btn btn-primary mx-auto mt-0 mb-1"
              @click="copy"
            >
              <span>
                {{ $t('finance.openInvoices.copy') }}
              </span>
            </button>
          </div>
        </div>
      </b-collapse>
    </div>
    <hr class="horizontal-line" />
  </div>
</template>

<script>
import ImgGeneral from '@/components/shared/ImgGeneral';
import {
  getStorageBottler,
  getAssetsPath,
  getBotSourceAppName,
} from '@/utils/config';

export default {
  components: {
    ImgGeneral,
  },
  props: {
    formattedInvoiceDueDate: {
      type: String,
      default: '',
    },
    invoiceDebt: {
      type: Number,
      default: 0,
    },
    invoiceBarCode: {
      type: String,
      default: '',
    },
    invoicePaymentExpired: {
      type: Boolean,
      default: false,
    },
    orderNumber: {
      type: String,
      default: '',
    },
    invoiceDocumentNumber: {
      type: String,
      default: '',
    },
    invoiceDueDate: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      status: '',
      invoiceId: '',
      statusColor: {
        overdue: 'color: var(--primary)',
        dueNextDay: 'color: var(--orange)',
        expired: 'color: var(--medium-grey)',
      },
      bottlerStorage: getStorageBottler(),
      assetsPath: getAssetsPath(),
      botSourceAppName: getBotSourceAppName(),
      dueDateWithoutTime: '',
    };
  },
  created() {
    this.invoiceId = this.getInvoiceId();
    this.getStatus();
  },
  methods: {
    getStatus() {
      this.dueDateWithoutTime = new Date(
        this.invoiceDueDate.substring(0, 4),
        this.invoiceDueDate.substring(5, 7) - 1,
        this.invoiceDueDate.substring(8, 10)
      );
      const todayDate = new Date();
      todayDate.setHours(0, 0, 0, 0);
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      tomorrow.setHours(0, 0, 0, 0);
      if (this.invoicePaymentExpired) {
        this.status = 'expired';
        return;
      }
      if (todayDate > this.dueDateWithoutTime && !this.invoicePaymentExpired) {
        this.status = 'overdue';
      } else if (tomorrow.getDate() === this.dueDateWithoutTime.getDate()) {
        this.status = 'dueNextDay';
      } else {
        this.status = '';
      }
    },
    async copy() {
      await navigator.clipboard.writeText(this.invoiceBarCode);
      const buttonId = `copyBtn-${this.invoiceId}`;
      document.getElementById(buttonId).innerHTML = this.$t(
        'finance.openInvoices.copied'
      );
      setTimeout(() => {
        document.getElementById(buttonId).innerHTML = this.$t(
          'finance.openInvoices.copy'
        );
      }, 3000);
    },
    getInvoiceId() {
      return this.invoiceBarCode.replace(/\s/g, '');
    },
  },
};
</script>

<style scoped>
.horizontal-line {
  border: 0;
  clear: both;
  display: block;
  width: 100%;
  background-color: var(--tertiary);
  height: 1px;
}

.calendar-img {
  width: 12px;
  height: 12px;
}

.information {
  text-align: left;
}

.collapsed-information {
  margin: 20px;
}

.warning-icon {
  width: 12px;
}

.copy-button {
  background-color: var(--primary);
}

.dollar-img {
  width: 12px;
}
.invoice-information {
  font-weight: 300;
  font-size: var(--fs-11);
}
</style>
