import { get } from '@/utils/httpClient';

async function getOrderSuggested() {
  const result = await get({
    url: 'order/suggested',
  });

  if (result.status) {
    return result.status === 200 && result.data ? result.data.items : undefined;
  }

  return result.message.includes('timeout') ? undefined : [];
}

export default { getOrderSuggested };
