<template>
  <div>
    <b-modal
      v-show="feature"
      ref="feature-modal"
      title="BootstrapVue"
      :hide-header="true"
      :hide-footer="true"
      centered
      @hidden="onCloseModal"
    >
      <h1
        class="bg-primary text-light fs-18 fw-200 m-0 ml-3 position-absolute p-2 highlight-title"
      >
        {{ feature.calloutText || $t('newFeatureTitle') }}
      </h1>
      <ImgGeneral
        v-if="!isMediaTypeVideo"
        class="mw-100 w-100 feature-modal__highlight-img"
        :url="feature.mediaUrl"
        :alt="feature.altTextImage"
        :title="feature.altTextImage"
      />
      <video
        v-if="isMediaTypeVideo"
        ref="video"
        width="100%"
        height="280"
        preload
        muted
        loop
        :src="feature.mediaUrl"
      />
      <div
        :class="feature.mediaUrl ? 'mt-3' : ''"
        class="p-3 text-center"
      >
        <h2
          v-if="feature.msgTitle"
          class="fs-18"
        >
          {{ feature.msgTitle }}
        </h2>
        <div
          v-if="feature.msgText"
          class="fs-14 fw-200 mb-5"
        >
          {{ feature.msgText }}
        </div>
        <button
          v-if="!feature.routeUrl"
          class="mx-auto fs-12 fw-200 mt-2 text-primary btn btn-primary text-light"
          @click="closeModal()"
        >
          {{ $t('proceed') }}
        </button>
        <a
          v-if="feature.routeUrl"
          class="btn btn-primary"
          :href="feature.routeUrl"
          @click.prevent="closeModal()"
        >
          {{ $t('access') }}
        </a>
        <button
          v-if="feature.routeUrl"
          class="d-block mx-auto fs-12 fw-200 mt-2 button-no-style text-primary text-decoration-underline"
          @click="closeModal()"
        >
          {{ $t('keepBrowsing') }}
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ImgGeneral from '@/components/shared/ImgGeneral';
import apiUser from '@/api/user';
import router from '@/router';
import { setSessionData } from '@/utils/config';
import { STORAGE_KEY } from '@/constants/storage';
import { eventBus, events } from '@/utils/events/eventBus';
import Analytics from '@/utils/analytics';
import analyticsEvents from '@/utils/events/analytics';

export default {
  components: {
    ImgGeneral,
  },
  data() {
    return {
      feature: {
        calloutText: '',
        mediaType: '',
        mediaUrl: '',
      },
      modal: null,
    };
  },
  computed: {
    ...mapGetters({
      ecommerceConfig: 'getEcommerceConfig',
    }),
    isMediaTypeVideo() {
      return this.feature.mediaUrl && this.feature.mediaType?.includes('mp4');
    },
  },
  mounted() {
    if (this.ecommerceConfig?.features?.length > 0) {
      [this.feature] = this.ecommerceConfig.features;
      this.modal = this.$refs['feature-modal'];
      if (this.modal && this.modal.show) this.modal.show();
      eventBus.$on(events.APP_IS_SHOWN, this.isAppShown);
    }
  },
  methods: {
    ...mapActions(['setEcommerceConfig']),
    async onCloseModal() {
      const feature = {
        feature: this.feature.id,
      };
      this.removeFeatureFromEcommerceConfig();
      this.featureSeen = await apiUser.updateUser(feature);
      Analytics.logEvent(analyticsEvents.EVENTS.MODAL_NEW_FEATURE, {
        feature: this.feature.category,
        modalId: this.feature.id,
      });
    },
    isAppShown() {
      const { video } = this.$refs;
      if (video) {
        video.play();
      }
    },
    removeFeatureFromEcommerceConfig() {
      const updatedEcommConfigFeatures = this.ecommerceConfig.features.filter(
        (item) => item.id !== this.feature.id
      );
      this.ecommerceConfig.features = updatedEcommConfigFeatures;
      this.$store.commit('setEcommerceConfig', this.ecommerceConfig);
      setSessionData({
        key: STORAGE_KEY.ecommerceConfig,
        value: this.ecommerceConfig,
      });
    },
    closeModal() {
      const routerUrl = this.feature.routeUrl;
      if (routerUrl) {
        router.navigateToPage(`/${routerUrl}`);
      }
      if (this.modal?.hide) this.modal.hide();
    },
  },
};
</script>

<style scoped>
:deep(.modal-body) {
  padding: 0;
}
:deep(.modal-content) {
  border-radius: 10px;
}
:deep(.modal-backdrop) {
  opacity: 0.8;
}
.highlight-title {
  transform: translateY(-50%);
}
.feature-modal__highlight-img {
  border-radius: 10px 10px 0 0;
}
</style>
