import { DateTime } from 'luxon';
import { WHATSAPP_OPENER_URL } from '@/constants/external';
import { getLocalStorage } from '@/utils/config';
import { SOURCES } from '@/constants/config';
import apiServiceTracking from '@/api/serviceTracking';

export const isValidURL = (url) => {
  const regex =
    // eslint-disable-next-line no-useless-escape
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
  return regex.test(url);
};

export const redirectToURL = (url) => {
  if (!isValidURL(url)) return;
  window.location.replace(url);
};

export const redirectBackToWhatsApp = ({
  whatsAppNumber,
  defaultMsg,
  timeout = 2000,
}) => {
  const whatsAppFinalUrl = !defaultMsg
    ? `${WHATSAPP_OPENER_URL}/${whatsAppNumber}`
    : `${WHATSAPP_OPENER_URL}/${whatsAppNumber}?text=${defaultMsg}`;
  setTimeout(() => {
    if (whatsAppNumber) redirectToURL(whatsAppFinalUrl);
  }, timeout);
};

export const hasCustomPrice = ({ price }) => {
  if (price && price > 0) {
    return true;
  }
  return false;
};

export const isProductOnCart = ({ productSku, cart }) =>
  cart.find((item) => item.product.sku === productSku);

export const isProductBlockedToAddCart = ({ productSku, cart, comboId }) =>
  cart.find((item) => {
    let combo = 0;
    if (comboId) combo = comboId;
    const handledComboId = item.product.combo || 0;
    return item.product.sku === productSku && handledComboId !== combo;
  });

export const getBingoPrizeProductsText = ({ prizeProducts }) => {
  if (!prizeProducts || !prizeProducts.length) return '';

  const msgs = prizeProducts.map(
    (product) => `${product.qty}x ${product.description}`
  );

  return msgs.join('\n');
};

export const getDateLastDayOfCurrentMonth = () => {
  const current = new Date();
  const date = DateTime.fromJSDate(
    new Date(current.getFullYear(), current.getMonth() + 1, 0)
  );
  return date;
};

export const getDateDiffInDays = ({ start, end }) => {
  if (!start || !end || !start.isValid || !end.isValid) return 0;

  const diff = end
    .diff(start, 'days', { conversionAccuracy: 'longterm' })
    .toObject();

  return Math.round(diff.days);
};

export const scrollToElement = (selector) => {
  const element = document.querySelector(selector);
  element.scrollIntoView({ behavior: 'smooth' });
};

export const isStandaloneActivated = () =>
  process.env.VUE_APP_STANDALONE_ACTIVATED === 'true';

export const getOrigin = () => {
  const utm = getLocalStorage({ key: 'utm' });

  if (isStandaloneActivated() && utm?.source === SOURCES.standalone) {
    return SOURCES.standalone;
  }

  if (utm?.source) {
    return utm?.source;
  }

  return SOURCES.whatsApp;
};

export const redirectUsingWindowOpen = (url, target) => {
  window.open(url, target, 'noopener');
};

export const setSelectedDate = ({ availableDates }) => {
  if (availableDates && availableDates.length) {
    const availableDatesArray = availableDates.map((date) =>
      parseInt(date.date.replace(/[^0-9]/g, ''))
    );
    return availableDatesArray;
  }
  return [];
};

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const openToastNotification = (toastReference, options) =>
  toastReference.toast(options.description || '', {
    title: options.title || '',
    toaster: options.position || 'b-toaster-bottom-right',
    solid: true,
    noAutoHide: !options.autoHideDelay,
    autoHideDelay: options.autoHideDelay || 2000,
    headerClass: options.headerClass,
    appendToast: true,
  });

export const openModalNotification = (modalReference, options) =>
  modalReference.msgBoxOk(options.description || '', {
    centered: options.centered || true,
    size: options.size || 'sm',
    headerClass: options.headerClass || 'bg-white',
    headerTextVariant: options.headerTextVariant || 'dark',
    okOnly: options.okOnly || true,
    okVariant: options.okVariant || 'secondary',
    title: options.title || '',
    hideHeaderClose: options.hideHeaderClose || false,
    okTitle: options.okTitle,
    noCloseOnBackdrop: options.noCloseOnBackdrop || false,
    noCloseOnEsc: options.noCloseOnEsc || false,
    id: options.id,
  });

export const openModalNotificationAction = (modalReference, options) =>
  modalReference.msgBoxConfirm(options.description || '', {
    centered: options.centered || true,
    id: options.id,
    size: options.size || 'sm',
    headerClass: options.headerClass || 'bg-white',
    headerTextVariant: options.headerTextVariant || 'dark',
    okOnly: options.okOnly || true,
    okVariant: options.okVariant || 'secondary',
    title: options.title || '',
    hideHeaderClose: options.hideHeaderClose || false,
    okTitle: options.okTitle,
    cancelDisabled: options.cancelDisabled || false,
    cancelTitle: options.cancelTitle,
    cancelVariant: options.cancelVariant || 'primary',
  });

export const staticLayoutAssets = {
  hideMenu: false,
  hideHeader: false,
  hideFooter: false,
};

export const setMenuVisibility = (layoutState) => {
  staticLayoutAssets.hideMenu = layoutState?.hideMenu;
  staticLayoutAssets.hideHeader = layoutState?.hideHeader;
  staticLayoutAssets.hideFooter = layoutState?.hideFooter;
};

export const registerService = async ({ service, data }) => {
  await apiServiceTracking.registerServiceTracking({
    origin: getOrigin(),
    service,
    data,
  });
};
