<template>
  <div v-if="isValid">
    <div v-if="!isBingoCardAlreadyComplete">
      <div class="bingo-card-header">
        <h4>{{ $t('bingo.completeAndWin') }}</h4>
      </div>
      <ul class="bingo-card-body list-unstyled p-0">
        <li
          v-for="product in currentCard.products"
          :key="product.SKU"
          class="bingo-card-product"
        >
          <ImgGeneral
            :alt="product.description"
            :url="product.imgURL"
            class="bingo-card-product-img"
          />
          <ImgGeneral
            v-if="isProductAcquired(product.SKU)"
            :alt="product.description"
            :url="currentCard.imgCheckURL"
            class="bingo-card-product-check"
          />
        </li>
        <p>
          {{ $t('bingo.cardPrizeInfo') }}
        </p>
      </ul>
    </div>
    <BingoPrize
      :is-bingo-card-already-complete="isBingoCardAlreadyComplete"
      :img-prize-url="currentCard.imgPrizeURL"
      :prize-products-text="prizeProductsText"
    />
  </div>
</template>

<script>
import BingoPrize from '@/components/bingo/BingoPrize.vue';
import { getBingoPrizeProductsText } from '@/utils/pages';
import Analytics from '@/utils/analytics';
import analyticsEvents from '@/utils/events/analytics';
import ImgGeneral from '@/components/shared/ImgGeneral';

export default {
  components: {
    BingoPrize,
    ImgGeneral,
  },
  props: {
    currentCard: {
      type: Object,
      required: true,
    },
    isBingoCardAlreadyComplete: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    // TODO: Move this tracking to GTM triggers and remove from code
    Analytics.logEvent(analyticsEvents.EVENTS.BINGO_CARD, {
      description: JSON.stringify(this.currentCard),
    });
    return {
      isValid: this.currentCard ? this.currentCard.products.length > 0 : false,
      prizeProductsText: '',
    };
  },
  created() {
    if (this.isValid) {
      const { prizeProducts } = this.currentCard;
      this.prizeProductsText = getBingoPrizeProductsText({ prizeProducts });
    }
  },
  methods: {
    isProductAcquired(sku) {
      return this.currentCard.acquiredProducts.some((p) => p.SKU === sku);
    },
  },
};
</script>

<style scoped>
.bingo-card-header {
  display: flex;
  justify-content: center;
}

.bingo-card-header h4 {
  font-size: var(--fs-18);
  font-weight: 300;
  color: var(--dark);
}

.bingo-card-header h4::before,
.bingo-card-header h4::after {
  vertical-align: middle;
  content: var(--star-icon);
}

.bingo-card-header h4::before {
  margin-right: 15px;
}

.bingo-card-header h4::after {
  margin-left: 15px;
}

.bingo-card-body {
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  padding-bottom: 15px;
}

.bingo-card-body p {
  font-size: var(--fs-10);
  font-weight: 100;
  color: var(--dark-grey);
}

.bingo-card-product {
  display: flex;
  width: calc(100% / 3);
  margin: 0;
}

.bingo-card-product-img {
  z-index: 0;
  width: 100%;
}

.bingo-card-product-check {
  z-index: 1;
  width: 100%;
  margin-left: -100%;
}
</style>
