<template>
  <div class="py-4">
    <OrderDetails
      :order-number="'-'"
      :order-price="orderPrice"
      :delivery-date="deliveryDate"
    />
    <div class="py-4 d-flex max-w-500 mx-auto justify-content-center">
      <b-icon
        class="mt-2"
        font-scale="1"
        icon="truck"
        variant="danger"
        :aria-label="$t('order.complete.imgRedTruck')"
      />
      <div class="ml-3 text-left d-inline-block">
        <p class="mb-2 fw-300 fs-18">
          {{ $t('order.complete.deliveryDay') }}
        </p>
        <p
          v-for="(order, index) in orderNumber"
          :key="index"
          class="fw-200 d-block fs-16 mb-1 dataDeliveryDate"
        >
          {{ $t('order.complete.orderDetail.orderNumber') }}
          {{ orderNumber[index] }},
          {{ deliveryDate[index] }}
        </p>
        <p class="fw-200 fs-12 mt-3">
          {{ $t('order.complete.scheduledOrder.notification') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import OrderDetails from '@/components/order/OrderDetails.vue';
import router from '@/router';

export default {
  components: {
    OrderDetails,
  },
  props: {
    orderNumber: {
      type: Array,
      required: true,
    },
    orderPrice: {
      type: [Number, String],
      required: true,
    },
    deliveryDate: {
      type: Array,
      required: true,
    },
  },
  created() {
    if (this.orderNumber.length < 1) router.navigateToPage('/home');
    this.$store.dispatch('resetCart');
  },
};
</script>
