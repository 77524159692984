function loadImage(imgElement) {
  if (!imgElement.dataset.src) {
    setTimeout(() => {
      loadImage(imgElement);
    }, 100);
    return;
  }
  imgElement.addEventListener('load', () => {
    setTimeout(() => imgElement.classList.add('loaded'), 100);
  });
  imgElement.setAttribute('src', imgElement.dataset.src);
  imgElement.removeAttribute('data-src');
}

export default {
  bind: (imgElement) => {
    function handleIntersect(entries, observer) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          loadImage(imgElement);
          observer.unobserve(imgElement);
        }
      });
    }

    function createObserver() {
      const options = {
        root: null,
        threshold: '0',
      };
      const observer = new IntersectionObserver(handleIntersect, options);
      observer.observe(imgElement);
    }
    if (window.IntersectionObserver) {
      createObserver();
    } else {
      loadImage(imgElement);
    }
  },
};
