<template>
  <a
    href=""
    class="text-center"
    :class="client.type === 'Stockist' || 'Retail' ? 'pack-card' : 'card-body'"
    :aria-label="$t('ariaLabel.category')"
    @click="onClickCategory($event, category)"
  >
    <ImgGeneral
      v-if="category.imageUrl"
      :alt="printAlt"
      class="pack-image"
      :data-src="category.imageUrl"
      :show-loader-before-init="true"
    />
    <p
      v-if="storageBottler.useCategoryIconsRevamped"
      class="fs-12 fw-200 text-dark mt-2 mb-5"
    >
      {{ category.name }}
    </p>
  </a>
</template>

<script>
import { mapGetters } from 'vuex';
import { getStorageBottler } from '@/utils/config';
import ImgGeneral from '@/components/shared/ImgGeneral';

export default {
  components: {
    ImgGeneral,
  },
  props: {
    category: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      storageBottler: getStorageBottler(),
    };
  },
  computed: {
    // Components should not connect to Store to be generic but this is a temporary for Stockists
    ...mapGetters({
      client: 'getClient',
    }),
    printAlt() {
      return this.category.name.toLowerCase();
    },
  },
  methods: {
    onClickCategory(evt, category) {
      evt.preventDefault();
      this.$emit('set-category', category);
    },
  },
};
</script>

<style scoped>
.text-box {
  width: 110px;
  height: 110px;
  color: black;
  margin: auto;
  align-content: center;
}
.text-category {
  margin: 0;
}
.pack-card {
  border-radius: 12px;
  border: 1px solid var(--light-grey);
  overflow: hidden; /* Ensure the image does not overflow the border-radius */
  position: relative;
  width: 100%; /* Make the card body responsive */
}
.pack-image {
  height: auto;
  width: 100%;
  border-radius: 12px;
  object-fit: cover; /* Ensure the image covers the container */
}
</style>
