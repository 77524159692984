import apiCredits from '@/api/credit';
import Common from '@/utils/common';

export default {
  state: {
    credits: {},
  },
  getters: {
    getCredits(state) {
      return state.credits;
    },
    getIsOverdue(state) {
      let isOverDue = false;

      if (typeof state.credits.due === 'object')
        isOverDue = state.credits.due.some((dueDate) =>
          Common.checkDateOverdue(dueDate.date)
        );
      return isOverDue;
    },
    getIsDue(state) {
      return Array.isArray(state.credits.due) && state.credits.due.length > 0;
    },
  },
  mutations: {
    setCredits(state, payload) {
      // eslint-disable-next-line no-param-reassign
      state.credits = payload;
    },
  },
  actions: {
    setCredits({ commit }, value) {
      commit('setCredits', value);
    },
    async getCredits({ commit }) {
      try {
        const credits = await apiCredits.getCredits();
        commit('setCredits', credits);
      } catch (error) {
        commit('setCredits', []);
      }
    },
  },
};
