<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    class="warning-msg text-center text-light d-flex flex-nowrap justify-content-center align-items-center p-2 m-2 mb-2"
  >
    <p
      class="text-center m-0 fs-11 fw-400"
      v-html="text"
    />
  </div>
</template>

<script>
import { getAssetsPath } from '@/utils/config';

export default {
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      assetsPath: getAssetsPath(),
    };
  },
};
</script>

<style scoped>
.warning-msg > p {
  color: var(--dark);
}
</style>
