import { post } from '@/utils/httpClient';

async function cancelOrder(orderNumber) {
  try {
    const result = await post({
      url: 'order/cancel',
      data: { orderNumber },
    });

    if (result.status === 204) {
      return true;
    }

    throw result;
  } catch (error) {
    // Catching the error and returning the error message
    if (error.response && error.response.data && error.response.data.message) {
      throw new Error(error.response.data.message);
    } else {
      // If the error structure is different
      throw new Error('An unexpected error occurred.');
    }
  }
}

export default { cancelOrder };
