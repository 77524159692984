<template>
  <div class="bingo-card-info">
    <div v-if="!isBingoCardAlreadyComplete">
      <p class="bingo-text">
        {{ $t('bingo.incompleteCardInfo') }}
      </p>
    </div>
    <div v-else>
      <h4>{{ $t('congratulations') }}!</h4>
      <p class="bingo-text">
        {{ $t('bingo.completeCardInfo') }}
      </p>
    </div>
    <div class="bingo-card-prize">
      <ImgGeneral
        :alt="prizeProductsText"
        :url="imgPrizeUrl"
      />
      <pre>{{ prizeProductsText }}</pre>
    </div>
    <router-link
      v-if="!isBingoCardAlreadyComplete"
      to="/category"
      class="bingo-cta-products"
    >
      {{ $t('bingo.completeProducts') }}
    </router-link>
    <div
      v-else
      class="bingo-prize-delivery"
    >
      <div>
        <ImgGeneral :url="assetsPath + 'img/icon/truck.svg'" />
      </div>
      <div>
        <p>
          {{ $t('bingo.prizeDelivery') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import ImgGeneral from '@/components/shared/ImgGeneral';
import { getAssetsPath } from '@/utils/config';

export default {
  components: {
    ImgGeneral,
  },
  props: {
    isBingoCardAlreadyComplete: {
      type: Boolean,
      required: true,
    },
    imgPrizeUrl: {
      type: String,
      required: true,
    },
    prizeProductsText: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      assetsPath: getAssetsPath(),
    };
  },
};
</script>

<style scoped>
.bingo-text {
  font-size: var(--fs-12);
  font-weight: 100;
  color: var(--dark-grey);
}

.bingo-card-info {
  display: flex;
  padding: 15px 0;
  flex-direction: column;
}

.bingo-card-info h4 {
  font-size: var(--fs-18);
  font-weight: 300;
  color: var(--dark);
}

.bingo-card-prize {
  display: flex;
  padding: 15px 0;
  flex-direction: row;
  align-items: center;
}

.bingo-card-prize img {
  max-width: 40%;
}

.bingo-card-prize pre {
  margin-left: 5px;
  font-family: var(--primary-font);
  font-size: var(--fs-12);
  font-weight: 300;
  color: var(--dark-grey);
}

.bingo-cta-products {
  padding: 5px 20px;
  font-size: var(--fs-12);
  font-weight: 300;
  color: var(--light);
  background-color: var(--primary);
  border: 0;
  align-self: center;
  border-radius: 20px;
}

.bingo-prize-delivery {
  display: flex;
  padding: 15px 30px;
  margin-top: 15px;
  flex-direction: row;
  background-color: var(--dark-grey);
  border-radius: 5px;
  align-items: center;
  justify-content: center;
}

.bingo-prize-delivery img {
  margin-right: 10px;
}

.bingo-prize-delivery p {
  font-size: var(--fs-12);
  font-weight: 100;
  color: var(--light);
  text-align: center;
}
</style>
