<template>
  <div class="page__content">
    <Breadcrumb />
    <div class="container max-section-width">
      <div class="row">
        <div class="col">
          <div
            v-if="comboProducts"
            class="max-w-700 mx-auto"
          >
            <div class="combo-name text-center fs-24">
              {{ combo[0].name.toUpperCase() }}
            </div>
            <div
              v-for="(family, index) in comboProducts"
              :key="index"
            >
              <ComboFamily
                :family="family"
                :index="index"
                @plus="plus($event)"
                @minus="minus($event)"
                @change="change($event)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <ComboMonitor
      :combo-products="comboProducts"
      :combo-cart="comboCart"
      :combo-cart-num-items="comboCartNumItems"
      :enable-combo="enableCombo"
      :minimum-products-required="minimumProductsRequired"
      @totalPrice="total($event)"
      @addComboToCart="addComboToCart($event)"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import apiComboProducts from '@/api/comboProducts';
import apiCombo from '@/api/combo';
import Analytics from '@/utils/analytics';
import router from '@/router';
import Breadcrumb from '@/components/shared/Breadcrumb.vue';
import ComboFamily from '@/components/combo/ComboFamily.vue';
import ComboMonitor from '@/components/combo/ComboMonitor.vue';

export default {
  components: {
    Breadcrumb,
    ComboFamily,
    ComboMonitor,
  },
  data() {
    return {
      comboId: null,
      comboProducts: undefined,
      comboName: '',
      combos: {},
      combo: {},
      totalPrice: 0,
      minimumProductsRequired: 100,
      comboCartNumItems: 0,
      enable: false,
      enableCombo: false,
    };
  },
  computed: {
    ...mapGetters({
      cart: 'getCartItems',
      comboCart: 'getComboCartItems',
    }),
  },
  watch: {
    comboCart: {
      deep: true,
      handler() {
        this.comboCartNumItems = this.getNumProducts();
        this.getCombos();
      },
    },
    comboCartNumItems: {
      deep: true,
      handler() {
        this.enableAddCombo();
      },
    },
  },
  created() {
    this.comboId = this.$route.params.comboId;
    this.loadComboTitle();
    this.$store.dispatch('resetComboCart');
    this.getCombos();
  },
  mounted() {
    const comboItemsInCart = this.cart.filter(
      (item) => item.product.combo === parseInt(this.comboId)
    );
    this.$store.dispatch('setComboCartItems', comboItemsInCart);
    this.enableAddCombo();
  },
  methods: {
    async getCombos() {
      await apiComboProducts.getComboProducts(this.comboId).then((data) => {
        this.comboProducts = data.map((f) => {
          const family = {};
          family.familyId = f.familyId;
          family.name = f.name;
          family.minimum = f.minimum;
          family.products = f.products.map((pd) => ({
            product: pd,
            quantity: this.updateCombo(pd),
          }));
          return family;
        });
      });
      this.callMaxItems();
      this.comboCartNumItems = this.getNumProducts();
      this.enableAddCombo();
    },
    updateCombo(pd) {
      const product = this.comboCart.find((p) => p.product.sku === pd.sku);
      if (product && product.quantity > 0) {
        return product.quantity;
      }
      return 0;
    },
    addComboToCart() {
      const cartWithCombos = this.cart.filter(
        (item) => item.product.combo !== JSON.parse(this.comboId)
      );
      this.$store.dispatch('setCartItems', cartWithCombos);
      this.comboCart.forEach((product) => {
        this.$store.dispatch('addItemToCart', product);
      });
      router.navigateToPage('/cart');
    },
    async loadComboTitle() {
      this.combos = await apiCombo.getCombos();
      this.combo = this.combos.filter((c) => c.id === parseInt(this.comboId));
    },
    minus(product) {
      const locationInCart = this.comboCart.findIndex(
        (p) => p.product.sku === product.product.sku
      );
      if (this.comboCart[locationInCart].quantity <= 1) {
        this.comboCart[locationInCart].quantity--;
        this.comboCart.splice(locationInCart, 1);
        Analytics.removeFromCart(product.product, this.$t('currency'), 1);
      } else {
        this.comboCart[locationInCart].quantity--;
      }
      this.$store.dispatch('setComboCartItems', this.comboCart);
    },
    plus(product) {
      if (this.comboCart.some((c) => c.product.sku === product.product.sku)) {
        const locationInCart = this.comboCart.findIndex(
          (p) => p.product.sku === product.product.sku
        );
        this.comboCart[locationInCart].quantity++;
      } else {
        // eslint-disable-next-line no-param-reassign
        product.quantity = 1;
        this.$store.dispatch('addComboItemToCart', product);
        Analytics.addToCart(product.product, product.quantity);
        this.analyticsEvent(product.product, 'addItem');
      }
      this.$store.dispatch('setComboCartItems', this.comboCart);
    },
    analyticsEvent(product, eventName) {
      Analytics.logEvent(eventName, {
        eventCategory: product.category,
        eventAction: eventName,
        eventLabel: product.description,
        eventValue: product.price,
      });
    },
    total(total) {
      this.totalPrice = total;
    },
    callMaxItems() {
      if (this.comboProducts) {
        this.minimumProductsRequired = this.comboProducts.reduce(
          (total, family) => total + Number(family.minimum),
          0
        );
      }
      if (this.minimumProductsRequired === 0) this.minimumProductsRequired = 1;
    },
    getMinimumByFamily(id) {
      if (this.comboProducts) {
        const family = this.comboProducts.find((f) => f.familyId === id);
        return family.minimum;
      }
      return [];
    },
    enableAddCombo() {
      this.enableCombo = this.comboCartNumItems >= this.minimumProductsRequired;
    },
    getNumProducts() {
      let numItems = 0;
      const familyIdCompleted = [];
      this.comboCart.forEach((c) => {
        const { familyId } = c.product;
        if (familyIdCompleted[familyId] === undefined) {
          familyIdCompleted[familyId] = 0;
        }
        if (this.getMinimumByFamily(familyId) >= 0) {
          const minimum = Number(this.getMinimumByFamily(familyId));
          if (familyIdCompleted[familyId] < minimum) {
            numItems++;
            familyIdCompleted[familyId]++;
          }
        }
      });
      return numItems;
    },
  },
};
</script>
