import Vue from 'vue';
import StepProgress from 'vue-step-progress';
import VueRouter from 'vue-router';
import Vuex from 'vuex';
import VueI18n from 'vue-i18n';
import Vuelidate from 'vuelidate';
import {
  BootstrapVue,
  CarouselPlugin,
  SpinnerPlugin,
  ProgressPlugin,
  ToastPlugin,
  ButtonPlugin,
  IconsPlugin,
  ModalPlugin,
  FormSelectPlugin,
} from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'core-js/actual/array';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'regenerator-runtime/runtime';
import Multiselect from 'vue-multiselect';
import apiClient from '@/api/orchestration';
import apiUser from '@/api/user';
import router from './router';
import stores from './stores';
import App from './App.vue';
import {
  getStorageBottler,
  getBottlerConfig,
  getSessionValue,
  setLocalStorage,
  isEnabledOrchestration,
} from './utils/config';
import {
  getInstallationBottler,
  getInstallationAppTitle,
  getInstallationLanguage,
} from './utils/installation';
import { setMenuVisibility } from '@/utils/pages';
import LazyLoadDirective from './directives/LazyLoadDirective';
import { STORAGE_KEY } from '@/constants/storage';
import i18nInstance, { initializeI18N } from '@/utils/i18n';

let routerInstance;
let storeInstance;
let storageBottlerConfig;
let app;

const initializePlugins = () => {
  [
    VueI18n,
    VueRouter,
    Vuex,
    Vuelidate,
    StepProgress,
    Multiselect,
    BootstrapVue,
    CarouselPlugin,
    SpinnerPlugin,
    ProgressPlugin,
    ButtonPlugin,
    IconsPlugin,
    ModalPlugin,
    FormSelectPlugin,
    ToastPlugin,
  ].forEach((plugin) => Vue.use(plugin));
};

const initializeExtraConfigs = () => {
  Vue.directive('lazyload', LazyLoadDirective);
  Vue.config.productionTip = false;
};

const initializeRouter = () => {
  routerInstance = new VueRouter({
    routes: router.routes,
    mode: 'history',
    base: process.env.VUE_APP_HOST_RELATIVE_PATH,
  });
  router.setRouterInstance(routerInstance);
  routerInstance.afterEach((to) => {
    const appTitle =
      storageBottlerConfig?.appTitle || getInstallationAppTitle();
    const pageTitle =
      i18nInstance.messages[
        storageBottlerConfig?.language || getInstallationLanguage()
      ]?.pageTitles[to.meta?.title] || '';
    document.title = `${appTitle} - ${pageTitle}`;
    setMenuVisibility({
      hideHeader: to.meta.hideHeader,
      hideMenu: to.meta.hideMenu,
      hideFooter: to.meta.hideFooter,
    });
    window.scrollTo(0, 0);
  });
};

const initializeStore = () => {
  storeInstance = new Vuex.Store({
    modules: stores.modules,
  });
};

const dispatchMainStoreModules = async () => {
  if (
    !isEnabledOrchestration() &&
    storeInstance?.state?.auth?.isAuthenticated
  ) {
    await Promise.all([
      storeInstance.dispatch('getClient'),
      storeInstance.dispatch('getEcommerceConfig'),
    ]);
    if (storeInstance.getters?.getClient?.isOrderOptionActive === false) {
      return;
    }
    storeInstance.dispatch('getProfile');
    Promise.all([
      storeInstance.dispatch('initCart'),
      storeInstance.dispatch('getProducts'),
      storeInstance.dispatch('getCombos'),
      storeInstance.dispatch('getCredits'),
      storeInstance.dispatch('getOrders'),
    ]);
  }
};

const initializeStorageBottler = () => {
  let storageBottler = getStorageBottler();

  const bottler = getInstallationBottler();

  if (!storageBottler && bottler) {
    storageBottler = getBottlerConfig({ bottler });
  }

  storageBottlerConfig = storageBottler;
};

const initializeDataLayer = () => {
  window.dataLayer = window.dataLayer || [];
};

const initializeApp = () => {
  app = new Vue({
    i18n: i18nInstance,
    router: routerInstance,
    store: storeInstance,
    render: (h) => h(App),
  }).$mount('#app');
};

const authenticate = async () => {
  const urlParams = new URLSearchParams(window.location.search);
  const userToken =
    urlParams.get('userToken') || getSessionValue(STORAGE_KEY.authorization);
  const source = urlParams.get('utm_source');
  const medium = urlParams.get('utm_medium');

  if (source && medium)
    setLocalStorage({ key: 'utm', value: { source, medium } });
  if (userToken) {
    if (isEnabledOrchestration()) {
      // storage token for expired and regenerate link
      await sessionStorage.setItem('authorization', userToken);
      await storeInstance.dispatch('auth/authenticate', {
        token: userToken,
      });
      const authResult = getSessionValue('isAuthenticated');
      if (authResult) {
        storeInstance.dispatch('getProfile');
        const profile = await apiUser.getUserProfile();
        apiClient.orchestration({ token: userToken }).then(async (result) => {
          if (result) {
            storeInstance.commit('auth/setAuthenticated', {
              result: result.isValid,
            });
            await storeInstance.commit('setClient', result.client);
            storeInstance.commit('auth/setAuthorization', userToken);
            storeInstance.commit('setCredits', result.credits);
            storeInstance.commit('setOrders', result.orders);
            storeInstance.dispatch('initCart');
            storeInstance.dispatch('getCombos');
            storeInstance.dispatch(
              'setLanguage',
              profile?.language === 'en' ? 'en' : 'th'
            );
          }
        });
      }
    } else {
      await storeInstance.dispatch('auth/authenticate', { token: userToken });
    }
  }
};

const create = async () => {
  initializePlugins();
  initializeExtraConfigs();
  initializeStore();
  await authenticate();
  dispatchMainStoreModules();
  initializeStorageBottler();
  initializeI18N();
  initializeDataLayer();
  initializeRouter();
  initializeApp();

  return app;
};

export default create();
