<template>
  <section class="page__content">
    <div class="container max-section-width text-center w-100 pb-0">
      <div v-if="!Array.isArray(orderNumber)">
        <OrderDetails
          :order-number="orderNumber"
          :order-price="orderPrice"
          :delivery-date="deliveryDate"
        />
      </div>
      <div v-else>
        <ScheduledOrderDetails
          :order-number="orderNumber"
          :order-price="orderPrice"
          :delivery-date="deliveryDate"
        />
      </div>
      <NPS v-if="enableNPS" />
      <TextAndButton
        v-if="origin === sources.whatsApp"
        :button-cta="
          $t('order.complete.backToOrigin', { origin: origin.toUpperCase() })
        "
        target="external"
        class="max-w-500 mt-5 mx-auto"
        @onClickCTA="goBackOriginApp()"
      >
        <p class="fw-200">
          {{ $t('order.complete.backToBot') }}
        </p>
      </TextAndButton>
    </div>
  </section>
</template>

<script>
import OrderDetails from '@/components/order/OrderDetails.vue';
import ScheduledOrderDetails from '@/components/order/ScheduledOrderDetails.vue';
import TextAndButton from '@/components/shared/TextAndButton.vue';
import NPS from '@/components/order/NPS.vue';
import router from '@/router';
import { redirectBackToWhatsApp, getOrigin } from '@/utils/pages/index';
import {
  getStorageBottler,
  setLocalStorage,
  getLocalStorage,
} from '@/utils/config';
import { SOURCES } from '@/constants/config';

export default {
  components: {
    OrderDetails,
    ScheduledOrderDetails,
    NPS,
    TextAndButton,
  },
  props: {
    orderNumber: {
      type: [String, Array],
      required: true,
    },
    orderPrice: {
      type: String,
      required: true,
    },
    deliveryDate: {
      type: [String, Array],
      required: true,
    },
  },
  data() {
    return {
      bottlerStorage: getStorageBottler(),
      origin: getOrigin(),
      enableNPS: false,
      orderSequenceKeyName: 'ko_order_sequence',
      lastNpsDateKeyName: 'ko_last_nps_date',
      showNPSEveryNOrder: 2,
      sources: SOURCES,
      MS_PER_DAY: 1000 * 3600 * 24,
    };
  },
  created() {
    if (!this.orderNumber) router.navigateToPage('/home');
    this.$store.dispatch('resetCart');

    const bottlerConfigOrderNPS = this.bottlerStorage?.showNPSEveryNOrder;
    if (typeof bottlerConfigOrderNPS === 'number')
      this.showNPSEveryNOrder = bottlerConfigOrderNPS;

    let orderSequence =
      getLocalStorage({ key: this.orderSequenceKeyName }) || 0;
    const requireEvaluation = this.hasNPSBeenSent();

    this.enableNPS =
      orderSequence === 0 &&
      typeof bottlerConfigOrderNPS === 'number' &&
      requireEvaluation;
    orderSequence++;

    setLocalStorage({ key: this.orderSequenceKeyName, value: orderSequence });
    if (orderSequence >= this.showNPSEveryNOrder + 1) {
      setLocalStorage({ key: this.orderSequenceKeyName, value: 0 });
    }

    if (this.enableNPS) {
      setLocalStorage({ key: this.lastNpsDateKeyName, value: new Date() });
    }
  },
  methods: {
    async goBackOriginApp() {
      await this.$store.dispatch('auth/logout').finally(() => {
        redirectBackToWhatsApp({
          whatsAppNumber: this.bottlerStorage?.whatsAppNumber,
        });
        localStorage.setItem('bottler', undefined);
      });
    },
    hasNPSBeenSent() {
      const lastNpsRawDate =
        getLocalStorage({ key: this.lastNpsDateKeyName }) || null;
      const lastNpsDate = new Date(lastNpsRawDate);
      const currentDate = new Date();
      const daysSinceLastNps = (currentDate - lastNpsDate) / this.MS_PER_DAY;

      return daysSinceLastNps >= 15;
    },
  },
};
</script>
