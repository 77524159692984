<script>
import { mapGetters } from 'vuex';
import { getStorageBottler } from '@/utils/config';
import apiPaymentLimit from '@/api/paymentLimit';
import ProductMixin from '@/components/mixins/ProductMixin';

export default {
  mixins: [ProductMixin],
  data() {
    return {
      isPaymentLimitActive: false,
      isFinishOrderOverLimitAuthorized: false,
      paymentLimit: 0,
    };
  },
  computed: {
    ...mapGetters({
      total: 'getTotalPrice',
      client: 'getClient',
    }),
  },
  created() {
    this.initPaymentLimit();
    this.updateTracking(false);
  },
  methods: {
    async initPaymentLimit() {
      const storageBottler = getStorageBottler();
      this.isPaymentLimitActive =
        storageBottler?.isPaymentLimitActive &&
        this.client.paymentLimit !== null;
      this.paymentLimit = this.client.paymentLimit || 0;
      if (
        this.isPaymentLimitActive &&
        this.client.isFinishOrderOverLimitAuthorized
      ) {
        this.isFinishOrderOverLimitAuthorized =
          this.client.isFinishOrderOverLimitAuthorized;
      }
      if (storageBottler?.skipPaymentLimitVerifyCheckout) return;
      await this.getPaymentLimit();
    },
    async getPaymentLimit() {
      this.paymentLimit = this.isPaymentLimitActive
        ? await apiPaymentLimit.getPaymentLimit()
        : undefined;
    },
    creditFormatHeaderMessage(message) {
      return `${message + this.formatPrice(Number(this.paymentLimit))}.`;
    },
    isOrderValueOverlimit() {
      return this.total > this.paymentLimit;
    },
    updateTracking(value) {
      const limit = {
        creditLimitAccepted: value,
        isPaymentLimitActive: this.isPaymentLimitActive,
        isFinishOrderOverLimitAuthorized: this.isFinishOrderOverLimitAuthorized,
        limit: this.paymentLimit,
      };
      this.$store.commit('setCreditLimitTracking', limit);
    },
  },
};
</script>
