async function fetchRegenerateLinkApi() {
  const token =
    sessionStorage.getItem('authorization') ||
    localStorage.getItem('userToken');
  const pageToRedirect = sessionStorage.getItem('startLocation');
  let apiBaseUrl = process.env.VUE_APP_AZ_FUNCTION_BASEURL;
  const options = {
    method: 'GET',
    headers: {
      Accept: '*/*',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    let redirect = 'home';

    if (pageToRedirect !== null && pageToRedirect !== 'unauthenticated') {
      redirect = pageToRedirect;
    }
    if (apiBaseUrl.charAt(apiBaseUrl.length - 1) !== '/') {
      apiBaseUrl = `${apiBaseUrl}/`;
    }
    const response = await fetch(
      `${apiBaseUrl}regenerate/link?page=${redirect}`,
      options
    );
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error('Error fetching data:', error);
  }
}

export default fetchRegenerateLinkApi;
