<template>
  <div class="page__content">
    <Breadcrumb
      :title="$t('pageTitles.promotions')"
      :sub-title="comboTitle"
    />
    <div class="container max-section-width">
      <div class="row">
        <div class="col fw-200 text-center max-w-700 mx-auto mt-3 mb-4">
          <h1 class="fs-18 fw-500">
            {{ $t('combo.quantityComboTitle') }}
          </h1>
          <p class="mt-3 mb-4">
            {{ $t('combo.quantityComboDescription') }}
          </p>
          <div
            v-if="loading"
            class="loading"
          >
            <b-spinner label="Loading..." />
          </div>
          <div v-if="minimumProductsRequired > 1">
            <hr />
            <b-icon
              icon="exclamation-triangle"
              class="mb-2"
            />
            <p class="fs-12 mb-4">
              {{
                $t('combo.minimumQuantity', {
                  quantity: minimumProductsRequired,
                })
              }}
            </p>
          </div>
          <PriceRangeTable
            v-if="!loading"
            :price-promotions-list="orderedPricePromotions"
            @update-price-promotions="onUpdatePricePromotions"
          />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div v-if="comboProducts">
            <ul
              class="col list-unstyled d-flex flex-wrap justify-content-center pl-1 pr-1"
            >
              <li
                v-for="product in comboProducts"
                :key="product.sku"
                class="combo__box"
              >
                <ProductComboQuantityRange
                  :product="product"
                  :combo-cart="comboCart"
                  :price-promotions-list="orderedPricePromotions"
                  :total-items-added-to-combo="totalItemsAddedToCombo"
                  @cbUpdatePrice="checkUpdatePrices()"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <ComboMonitor
      :combo-products="comboProducts"
      :combo-cart="comboCart"
      :total-items-added-to-combo="totalItemsAddedToCombo"
      :enable-combo="enableCombo"
      :minimum-products-required="minimumProductsRequired"
      :type="'quantity'"
      :price-promotions="pricePromotions"
      :show="!loading"
      @addComboToCart="addComboToCart($event)"
    />
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */
import { mapGetters } from 'vuex';

import apiComboProducts from '@/api/comboProducts';
import Analytics from '@/utils/analytics';
import analyticsEvents from '@/utils/events/analytics';
import router from '@/router';
import Breadcrumb from '@/components/shared/Breadcrumb.vue';
import ProductComboQuantityRange from '@/components/combo/ProductComboQuantityRange.vue';
import PriceRangeTable from '@/components/combo/PriceRangeTable.vue';
import ComboMonitor from '@/components/combo/ComboMonitor.vue';

export default {
  components: {
    Breadcrumb,
    ProductComboQuantityRange,
    PriceRangeTable,
    ComboMonitor,
  },
  data() {
    return {
      comboId: null,
      comboProducts: undefined,
      comboTitle: '',
      comboDetailsRawData: null,
      loading: true,
      totalItemsAddedToCombo: 0,
      currentDiscountTableRange: null,
      pricePromotions: [],
      enableCombo: false,
      minimumProductsRequired: 1,
      nextRangeQuantity: null,
    };
  },
  computed: {
    ...mapGetters({
      cart: 'getCartItems',
      comboCart: 'getComboCartItems',
      comboItemsQuantity: 'getComboItemsQuantity',
    }),
    orderedPricePromotions() {
      return this.orderPricePromotions();
    },
  },
  watch: {
    totalItemsAddedToCombo: {
      handler() {
        this.enableAddCombo();
        this.updateDiscountTableRange();
      },
    },
    comboCart: {
      deep: true,
      handler() {
        this.getComboProductsTotalQuantity();
        this.updateDiscountTableRange();
        this.updateProductsQuantitiesBasedOnCart();
      },
    },
  },
  async mounted() {
    this.comboId = this.$route.params.comboId;
    this.$store.dispatch('resetComboCart');
    this.getCombosFromCart();
    await this.getCombos();
    this.updateProductsQuantitiesBasedOnCart();
    this.comboTitle = this.comboDetailsRawData.name;
  },
  methods: {
    getCombosFromCart() {
      const allCartItems = JSON.parse(JSON.stringify(this.cart));
      const comboItemsInCart = allCartItems.filter(
        (item) => item.product.combo === this.comboId
      );
      this.$store.dispatch('setComboCartItems', comboItemsInCart);
    },
    async getCombos() {
      await apiComboProducts.getComboProducts(this.comboId).then((data) => {
        this.comboDetailsRawData = data;
        this.comboProducts = this.comboDetailsRawData.products.map(
          (comboItem) => ({
            name: comboItem.category,
            product: comboItem,
            regularPrice: comboItem.price,
            quantity: this.getComboItemFromCart(comboItem),
            activeCodeTable: null,
            nextRangeQuantity: null,
          })
        );
      });
      this.loading = false;
    },
    updateProductsQuantitiesBasedOnCart() {
      if (!this.comboDetailsRawData) return;
      this.comboProducts.forEach((comboProduct) => {
        comboProduct.quantity = this.getComboItemFromCart(comboProduct.product);
      });
    },
    getComboItemFromCart(comboItem) {
      const product = this.comboCart.find(
        (product) => product.product.sku === comboItem.sku
      );
      if (product && product.quantity > 0) {
        return product.quantity;
      }
      return 0;
    },
    checkUpdatePrices() {
      this.updateDiscountTableRange();
      this.$store.dispatch('setComboCartItems', this.comboCart);
    },
    onUpdatePricePromotions(pricePromotions) {
      this.pricePromotions = pricePromotions;
    },
    updateDiscountTableRange() {
      if (!this.comboProducts) return;
      this.currentDiscountTableRange = null;
      this.pricePromotions.forEach((priceRange, index) => {
        if (this.totalItemsAddedToCombo >= priceRange.minimumQuantity) {
          this.currentDiscountTableRange = priceRange.tableCode;
          this.nextRangeQuantity =
            this.pricePromotions[index + 1]?.minimumQuantity;
        }
      });
      let rangeIsUpdated = false;
      this.comboProducts.forEach((comboItem) => {
        if (comboItem.activeCodeTable !== this.currentDiscountTableRange) {
          rangeIsUpdated = true;
        }
        comboItem.activeCodeTable = this.currentDiscountTableRange;
      });
      if (rangeIsUpdated) {
        this.trackEventOnRangeChange();
      }
      this.enableAddCombo();
    },
    trackEventOnRangeChange() {
      Analytics.logEvent(analyticsEvents.EVENTS.COMBO_QUANTITY_UPDATE_RANGE, {
        description: JSON.stringify(this.comboId),
      });
    },
    enableAddCombo() {
      this.enableCombo =
        this.totalItemsAddedToCombo >= this.minimumProductsRequired;
    },
    addComboToCart() {
      this.updateDiscountTableRange();
      const cartWithCombos = this.cart.filter(
        (item) => item.product.combo !== this.comboId
      );
      this.$store.dispatch('setCartItems', cartWithCombos);
      this.comboCart.forEach((product) => {
        product.activeCodeTable = this.currentDiscountTableRange;
        product.nextRangeQuantity =
          this.nextRangeQuantity - this.totalItemsAddedToCombo;
        this.$store.dispatch('addItemToCart', product);
      });
      router.navigateToPage('/cart');
    },
    orderPricePromotions() {
      const pricePromotionsSorted =
        this.comboDetailsRawData.pricePromotions.sort(
          (a, b) => a.minimumQuantity - b.minimumQuantity
        );
      this.minimumProductsRequired = pricePromotionsSorted[0]?.minimumQuantity;
      return pricePromotionsSorted;
    },
    getComboProductsTotalQuantity() {
      this.totalItemsAddedToCombo = this.comboCart.reduce(
        (prevValue, newValue) => prevValue + Number(newValue.quantity),
        0
      );
    },
  },
};
</script>

<style scoped>
.combo__box {
  padding: 0 6px;
  width: 100%;
}

@media only screen and (min-width: 850px) {
  .combo__box {
    width: 33%;
  }
}
</style>
