<template>
  <div
    v-if="showButton && showBackToTopButton"
    class="scroll-to-top"
    @click="scrollToTop"
  >
    <b-iconstack font-scale="2">
      <b-icon
        stacked
        icon="chevron-double-up"
        variant="danger"
      />
    </b-iconstack>
  </div>
</template>
<script>
import { getStorageBottler } from '@/utils/config';

export default {
  data() {
    return {
      showButton: false,
      scrollingTimeout: null,
      showBackToTopButton: false,
    };
  },
  created() {
    const bottlerStorage = getStorageBottler();
    this.showBackToTopButton = bottlerStorage?.showBackToTopButton;
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll();
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      clearTimeout(this.scrollingTimeout);
      this.showButton = false;
      this.scrollingTimeout = setTimeout(() => {
        this.showButton = window.pageYOffset > 0;
      }, 500);
    },
    scrollToTop() {
      this.smoothScrollToTop();
    },
    smoothScrollToTop() {
      const scrollToY = (scrollTargetY, speed, easing) => {
        let currentTime = 0;
        const time = Math.max(
          0.1,
          Math.min(Math.abs(window.pageYOffset - scrollTargetY) / speed, 0.8)
        );
        const easingEquations = {
          easeOutSine: (pos) => Math.sin(pos * (Math.PI / 2)),
        };
        const tick = () => {
          currentTime += 1 / 60;
          const p = currentTime / time;
          const t = easingEquations[easing](p);
          if (p < 1) {
            window.requestAnimationFrame(tick);
            window.scrollTo(
              0,
              window.pageYOffset + (scrollTargetY - window.pageYOffset) * t
            );
          } else {
            window.scrollTo(0, scrollTargetY);
          }
        };
        tick();
      };
      scrollToY(0, 500, 'easeOutSine');
    },
  },
};
</script>
<style>
.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid var(--light-grey);
  border-radius: 50%;
  cursor: pointer;
  z-index: 9999;
  color: var(--white);
}
.scroll-to-top b-icon {
  font-size: 2rem;
}
</style>
