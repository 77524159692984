import { get } from '@/utils/httpClient';

async function fetchBingoCardInPeriod(month, year) {
  const result = await get({
    url: `bingo/period/${month}/${year}`,
  });

  return result && result.status === 200 ? result.data : undefined;
}

async function fetchBingoHistory() {
  const result = await get({
    url: 'bingo/history',
  });

  return result && result.status === 200 ? result.data : undefined;
}

async function getCurrentCard() {
  const currentDate = new Date();
  const month = `${currentDate.getMonth() + 1}`.padStart(2, '0');
  const year = currentDate.getFullYear();

  try {
    const currentCard = await fetchBingoCardInPeriod(month, year);
    return currentCard;
  } catch (err) {
    return undefined;
  }
}

async function getClientBingo() {
  const currentCard = await getCurrentCard();
  let history;

  try {
    history = await fetchBingoHistory();
  } catch (err) {
    history = undefined;
  }

  return {
    currentCard,
    history,
  };
}

export default {
  fetchBingoCardInPeriod,
  fetchBingoHistory,
  getCurrentCard,
  getClientBingo,
};
