import { get } from '@/utils/httpClient';

async function getScheduledOrderAvailableDate({ period }) {
  const result = await get({
    url: `scheduled/order/available/date/${period}`,
  });

  if (result.status) {
    return result.status === 200 && result.data ? result.data : undefined;
  }

  return result.message.includes('timeout') ? undefined : [];
}

export default { getScheduledOrderAvailableDate };
