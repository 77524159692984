<template>
  <div class="d-flex align-center">
    <label class="switch">
      <input
        type="checkbox"
        :checked="isChecked"
        @change="switchState"
      />
      <span class="slider round" />
      <span class="sr-only">
        {{ $t('ariaLabel.roundSwitch') }}
        {{ $t(text) }}
      </span>
    </label>
    <span class="fs-14 ml-3 mb-0 slider-text">
      {{ $t(text) }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    state: Boolean,
    text: {
      type: String,
      required: true,
    },
  },
  computed: {
    isChecked() {
      return this.state === true || this.state === '1';
    },
  },
  methods: {
    switchState(event) {
      this.$emit('switched', event.target.checked);
    },
  },
};
</script>

<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--medium-grey);
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: var(--background);
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--primary);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--primary);
}

input:checked + .slider:before {
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.slider-text {
  position: relative;
  top: 7px;
}
</style>
