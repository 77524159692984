<template>
  <div class="py-4">
    <b-icon
      class="m-auto d-flex"
      font-scale="5"
      icon="check-circle-fill"
      aria-label="$t('order.complete.imgCheckMark')"
      variant="success"
    />
    <p class="mb-4 mt-3 fs-24 fw-200">
      {{ $t('order.complete.orderDetail.successMessage') }}
    </p>
    <p class="mb-4 mt-3 fs-10 fw-200 align-left">
      {{ $t('order.complete.orderDetail.orderReceivedMessage') }}
    </p>
    <div class="container mb-2">
      <!-- Show credit limit exceed only when formattedBalance/credit balance < 0 -->
      <!-- <div
        v-if="!isCashPayment && formattedBalance < 0"
        class="inner-box mb-2 mt-3 fs-24 fw-200 text-left"
      >
        <div class="d-flex">
          <b-icon
            icon="exclamation-triangle-fill"
            variant="danger"
            class="d-flex mr-1 mt-1 fs-12"
            font-scale="1"
          />
          <p class="mb-1 fs-10 text-danger mr-1 fs-12 fw-300">
            {{ $t('creditLimitExceeded') }}
          </p>
        </div>
        <p class="mb-1 fs-12">
          {{ $t('contactSalesTeam') }}
        </p>
        <p class="mb-1 fs-12">
          {{ $t('contactSale') }}
          <span
            class="phone-number font-weight-bold"
            @click="handlePhoneClick"
            >{{ $t('phoneNumber') }}</span
          >
        </p>
      </div> -->

      <p
        id="dataOrderNumber"
        class="fs-13 mb-1 ml-4 text-left text-danger max-w-500"
      >
        {{ $t('order.complete.orderDetail.orderNumber') }}
        <span
          class="phone-number text-danger"
          style="text-decoration: none"
        >
          #{{ orderNumber }}</span
        >
      </p>

      <div class="text-left ml-4">
        <div class="mt-1 fw-200 gap-10 max-w-500">
          <!-- First column -->
          <div class="align-left">
            <p
              id="dataDeliveryDate"
              class="fs-12"
            >
              <DeliveryDate :delivery-date="deliveryDate" />
            </p>

            <p class="fs-10 mb-2">
              <b-icon
                class="mx-auto mr-2"
                font-scale="1"
                icon="cash"
              />
              {{ $t('order.complete.orderDetail.totalPrice') }}
            </p>
            <p
              id="dataOrderPrice"
              class="fs-16"
            >
              {{
                orderPrice.includes('฿')
                  ? formatPrice(orderPrice.split('฿')[1])
                  : orderPrice
              }}
            </p>
          </div>

          <!-- <div
            v-if="!isCashPayment"
            class="w-50"
          >
            <p class="fs-12 mb-2">
              <span class="baht-icon mr-1">฿</span>
              {{ $t('CreditBalance') }}
            </p>
            <p
              id="creditBalance"
              class="fs-18 ml-1"
            >
              {{ formatPrice(Number(formattedBalance)) }}
            </p>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DeliveryDate from '@/components/order/DeliveryDate.vue';

export default {
  components: {
    DeliveryDate,
  },
  props: {
    orderNumber: {
      type: [String, Array],
      default: '',
    },
    orderPrice: {
      type: String,
      default: '',
    },
    deliveryDate: {
      type: [String, Array],
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      client: 'getClient',
    }),
    isMobile() {
      return (
        window.innerWidth <= 768 &&
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      );
    },
    // formattedBalance() {
    //   return this.$store.state.credit.credits.balance;
    // },
    // isCashPayment() {
    //   return this.client.paymentType === 'Cash';
    // },
  },

  methods: {
    handlePhoneClick() {
      if (this.isMobile) {
        window.location.href = `tel:${this.$t('phoneNumber')}`;
      }
    },
    formatPrice(price) {
      let priceCheck = Number.isNaN(parseInt(price)) ? 0 : price;
      if (typeof priceCheck === 'number') {
        priceCheck = priceCheck.toFixed(2);
      }
      priceCheck = Number(priceCheck).toLocaleString('th-TH', {
        style: 'currency',
        currency: 'THB',
      });
      return priceCheck;
    },
  },
};
</script>

<style src="@/assets/styles/order-details.css" scoped></style>
