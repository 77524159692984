<template>
  <div>
    <fieldset>
      <label
        v-for="(option, index) in options"
        :id="'radioLabel-' + index"
        :key="option.header + index"
        :for="'radio-' + index"
        class="row radio-label mr-0 mt-1 ml-0"
      >
        <b-container class="radio-selection m-0 pt-2 pb-2 pl-3">
          <b-row align-h="between">
            <b-col cols="8">
              <span
                class="text-grey headerInformation"
                :class="
                  option.header == ' ' ||
                  option.header == '  ' ||
                  option.header == '   ' ||
                  option.header == '    '
                    ? ''
                    : ''
                "
              >
                {{ option.header === '1' ? '' : option.header }}
              </span>
              <span class="fs-14 text-mediumgrey sideInformation">
                {{ option.sideInformation }}
              </span>
              <div
                class="text-mediumgrey footer"
                :class="
                  option.header == ' ' ||
                  option.header == '  ' ||
                  option.header == '   ' ||
                  option.header == '    '
                    ? ''
                    : ''
                "
              >
                <span class="footer-label">{{
                  $t('deliveryDateSelector.minimumOrder')
                }}</span>
                {{ option.footer }}
              </div>
            </b-col>
            <b-col
              cols="4"
              align-self="center"
            >
              <div class="radio-container">
                <input
                  :id="'radio-' + index"
                  :checked="isChecked(option)"
                  :value="option"
                  type="radio"
                  name="radio-input"
                  class="mr-2"
                  :aria-labelledby="'radioLabel-' + index"
                  @change="handleChange(option)"
                />
              </div>
            </b-col>
          </b-row>
        </b-container>
      </label>
    </fieldset>
  </div>
</template>
<script>
import ProductMixin from '@/components/mixins/ProductMixin';
import router from '@/router';

export default {
  mixins: [ProductMixin],
  props: {
    options: { type: Array, default: () => [] },
  },
  data() {
    return {
      selectedValue: '',
    };
  },
  methods: {
    handleChange(option) {
      this.$emit('selected', option);
    },
    goToCart(option) {
      router.navigateToPage('/cart');
      this.handleChange(option);
    },
    isChecked(option) {
      if (option.selected) this.handleChange(option);
      return option.selected;
    },
  },
};
</script>

<style scoped>
.footer-label {
  font-weight: lighter;
}

.headerInformation {
  font-weight: bold;
}
.radio-selection {
  box-sizing: border-box;
  width: 100%;
}
.radio-label {
  background-color: #d6d8db;
  margin-bottom: 0;
}

.radio-container {
  display: flex;
  justify-content: center;
}

input[type='radio'] {
  accent-color: var(--primary);
  width: 2em;
  height: 2em;
}
</style>
