import axios from 'axios';
import webToken from '@/api/webtokenValidate';
import webTokenExpired from '@/api/webTokenExpired';
import { JWT_EXPIRED_ERROR } from '@/constants/error';
import { getSessionData } from '@/utils/config';

// initial state
const state = () => ({
  isAuthenticated: getSessionData('isAuthenticated') === true,
  authorization: getSessionData('authorization'),
});

// actions
const actions = {
  async authenticate({ commit }, { token }) {
    const result = await webToken.authenticate({ token });

    if (result.authError) {
      if (result.authError === JWT_EXPIRED_ERROR) {
        // storage token for expired and regenerate link
        sessionStorage.setItem('authorization', token);
        await webTokenExpired.processExpiredToken({ token });
      }

      commit('setAuthenticated', { result: false });
    } else {
      commit('setAuthenticated', { result: !!result });

      if (result) {
        commit('setAuthorization', token);
      }
    }
  },
  async logout() {
    sessionStorage.clear();
    localStorage.setItem('cart', JSON.stringify([]));
  },
};

// mutations
const mutations = {
  setAuthenticated(state, { result }) {
    // eslint-disable-next-line no-param-reassign
    state.isAuthenticated = result;
    sessionStorage.setItem('isAuthenticated', result);
  },
  setAuthorization(state, token) {
    // eslint-disable-next-line no-param-reassign
    state.authorization = token;
    sessionStorage.setItem('authorization', token);
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
