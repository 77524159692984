<template>
  <div class="page__content">
    <div class="py-3 d-flex flex-column align-items-center page-login m-auto">
      <ImgGeneral
        :alt="$t('logoAssistantKO')"
        class="mt-5 mb-5"
        :data-src="assetsPath + 'img/assistant-logo.svg'"
      />
      <div>
        <h2 class="fs-20">
          {{ $t('login.welcome') }}
        </h2>
        <p class="fs-14 fw-200 mb-4 text-mediumgrey">
          {{ $t('login.info') }}
        </p>
        <LoginForm />
      </div>
    </div>
  </div>
</template>

<script>
import LoginForm from '@/components/login/LoginForm.vue';
import ImgGeneral from '@/components/shared/ImgGeneral';
import { getAssetsPath } from '@/utils/config';

export default {
  components: {
    LoginForm,
    ImgGeneral,
  },
  data() {
    return {
      assetsPath: getAssetsPath(),
    };
  },
};
</script>

<style scoped>
.max-section-width {
  background-color: var(--light);
}

@media only screen and (min-width: 850px) {
  .page-login {
    background-color: var(--light);
    border: 1px solid var(--light-grey);
    border-radius: 42px;
    padding: 0 100px;
    margin: 24px 0;
  }
  .page__content {
    background-color: var(--background);
  }
}
</style>
