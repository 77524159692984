import { get } from '@/utils/httpClient';

async function getOpenInvoices() {
  const result = await get({
    url: 'getOpenInvoices',
  });

  if (result.status) {
    return result.status === 200 && result.data ? result.data : undefined;
  }

  return result.message.includes('timeout') ? undefined : [];
}

export default { getOpenInvoices };
