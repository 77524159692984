<template>
  <div>
    <!-- SINGLE BOX -->
    <span
      v-if="type === ''"
      class="skeleton-element"
      :style="`height: ${height}`"
    />
    <!-- TITLE AND BOX -->
    <div v-if="type === 'title-box'">
      <div class="skeleton-element mb-2 w-50" />
      <div
        class="skeleton-element"
        :style="`height: ${height}`"
      />
    </div>
    <!-- PRODUCT -->
    <div
      v-if="type === 'product'"
      class="d-flex justify-content-center flex-wrap"
    >
      <div
        class="skeleton-element mb-2 w-100"
        :style="`height: ${height}`"
      />
      <div class="skeleton-element w-75 mb-5" />
      <div
        class="skeleton-element w-50 mb-3"
        style="height: 40px"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '20px',
    },
    type: {
      type: String,
      default: '',
      validator(value) {
        const allowedPropTypes = ['', 'title-box', 'product'];
        return allowedPropTypes.includes(value);
      },
    },
  },
};
</script>
<style scoped>
.skeleton-element {
  width: 100%;
  height: 20px;
  display: block;
  background: linear-gradient(
    110deg,
    var(--light-grey) 8%,
    var(--background) 18%,
    var(--light-grey) 33%
  );
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}
</style>
