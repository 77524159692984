<template>
  <div class="page__content">
    <div
      class="container max-section-width d-flex justify-content-center align-items-center text-center"
      style="min-height: 80vh"
    >
      <h5 v-if="origin === sources.standalone">
        {{ $t('unauthenticated.login') }}
      </h5>
      <h5 v-else-if="!bottlerWhatsAppNumber">
        {{
          isMobile
            ? $t('unauthenticated.default_new', { value: 'LineChat' })
            : $t('unauthenticated.default', { value: 'LineChat' })
        }}
        <br /><br />
        {{
          isMobile
            ? 'Link has expired. Please click the button below to go to Line Chat. Winnie will send you a link to order new products.'
            : 'Oops, link expired. Please go back to LineChat and start the conversation again to create a new link.'
        }}
        <br /><br />
        <a
          v-if="isMobile"
          id="btnRegenLink"
          class="m-auto px-5 d-block btn btn-primary"
          type="button"
          :href="chatbotUrl"
          style="width: 175px"
          @click="fetchData"
        >
          {{ $t('unauthenticated.regen_link') }}
        </a>
      </h5>

      <h5 v-else-if="bottlerWhatsAppNumber && isUserContextResetSuccess">
        {{
          $t('unauthenticated.redirectWithDefaultMsg', {
            value: botSourceAppName,
          })
        }}
      </h5>
      <h5 v-else>
        {{
          $t('unauthenticated.redirect', {
            value: botSourceAppName,
          })
        }}
      </h5>
    </div>
  </div>
</template>

<script>
import { redirectBackToWhatsApp, getOrigin } from '@/utils/pages';
import {
  getBottlerConfig,
  getSessionData,
  getBotSourceAppName,
} from '@/utils/config';
import fetchRegenerateLinkApi from '@/api/unauthenticated';

import { getInstallationLineChatbotUrl } from '@/utils/installation';
import { SOURCES } from '@/constants/config';
import router from '@/router';

export default {
  components: {},
  data() {
    return {
      timeoutForRedirect: 4000,
      isUserContextResetSuccess: false,
      bottlerWhatsAppNumber: '',
      origin: getOrigin(),
      sources: SOURCES,
      botSourceAppName: getBotSourceAppName(),
      chatbotUrl: getInstallationLineChatbotUrl(),
    };
  },
  computed: {
    isMobile() {
      return (
        window.innerWidth <= 768 &&
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      );
    },
  },
  created() {
    const authSettings = getSessionData('authSettings');
    const { bottler, isUserContextResetSuccess } = authSettings || {};
    const bottlerConfig = getBottlerConfig({ bottler });
    this.isUserContextResetSuccess = isUserContextResetSuccess;
    this.bottlerWhatsAppNumber = bottlerConfig?.whatsAppNumber;

    if (this.bottlerWhatsAppNumber && this.origin === this.sources.whatsApp) {
      // Set input to send user to order flow on bot only if user context clear
      const defaultMsg = isUserContextResetSuccess
        ? `${this.$t('WHATSAPP_REDIRECT_MSGS.unauthorized')}`
        : false;
      redirectBackToWhatsApp({
        whatsAppNumber: this.bottlerWhatsAppNumber,
        defaultMsg,
        timeout: this.timeoutForRedirect,
      });
    }

    if (this.origin === this.sources.standalone) {
      setTimeout(() => {
        router.navigateToPage('/login');
      }, this.timeoutForRedirect);
    }
  },

  methods: {
    async fetchData() {
      try {
        this.data = await fetchRegenerateLinkApi();
      } catch (error) {
        throw new Error('Error fetching data:', error);
      }
    },
  },
};
</script>

<style scoped>
h5 {
  white-space: pre-line;
}
</style>
