<template>
  <div class="page__wrapper">
    <form
      autocomplete="off"
      @submit.prevent="submit"
    >
      <div class="page__content mb-5">
        <LoginError v-if="submitStatus === 'ERROR_INCORRECT'" />
        <div
          v-if="showBottlerInput"
          class="form-group"
        >
          <label
            for="bottler"
            class="fs-10 fw-400 ml-2"
          >
            Bottler
          </label>
          <select
            id="bottler-select"
            v-model="bottler"
            class="form-select fs-10 fw-200 py-2 px-2 form__select form__input"
            aria-label="bottler"
          >
            <option
              v-for="item in bottlersList"
              :key="item.value"
              :value="item.value"
              class="fw-200"
            >
              {{ item.name }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label
            for="clientId"
            class="fs-10 fw-400 ml-2"
            :class="{ 'text-primary': $v.clientId.$error }"
          >
            {{ $t('login.clientId') }}
          </label>
          <input
            id="clientId"
            v-model="clientId"
            type="text"
            class="fs-10 fw-200 p-2 form__input"
            :class="{ 'form__input--error': $v.clientId.$error }"
            @click="$v.$reset"
          />
          <p
            v-if="$v.clientId.$error"
            id="clientIdInvalid"
            class="fs-10 fw-200 pl-2 pt-1 text-primary"
          >
            {{ $t('login.clientIdInvalid') }}
          </p>
        </div>
        <div class="form-group">
          <label
            for="password"
            class="fs-10 fw-400 ml-2"
            :class="{ 'text-primary': $v.password.$error }"
          >
            {{ $t('login.password') }}
          </label>
          <input
            id="password"
            v-model="password"
            type="password"
            class="fs-10 fw-200 p-2 form__input"
            :class="{ 'form__input--error': $v.password.$error }"
            @click="$v.$reset"
          />
          <p
            v-if="$v.password.$error"
            id="passwordInvalid"
            class="fs-10 fw-200 pl-2 pt-1 text-primary"
          >
            {{ $t('login.passwordInvalid') }}
          </p>
        </div>
        <div class="ml-2 mb-5">
          <b-link
            v-b-toggle.form-link
            class="fs-10 text-primary"
          >
            {{ $t('login.forgotPassword') }}
          </b-link>
          <b-collapse id="form-link">
            <ForgotPassword />
          </b-collapse>
        </div>
        <button
          v-if="!loading"
          id="btnLogin"
          class="m-auto px-5 d-block btn btn-primary"
          type="submit"
        >
          {{ $t('login.labelLoginBtn') }}
        </button>
        <div
          v-if="loading"
          class="d-flex justify-content-center"
        >
          <b-spinner label="Loading..." />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { required, helpers } from 'vuelidate/lib/validators';
import ForgotPassword from '@/components/login/ForgotPassword.vue';
import LoginError from '@/components/login/LoginError.vue';
import apiAuthenticateUser from '@/api/login';
import { BOTTLERS, BOTTLER_DETAILS_LIST } from '@/constants/config';
import { getRelativeAppPath, isLatamBottler } from '@/utils/config';
import { getInstallationBottler } from '@/utils/installation';

const passwordValidation = helpers.regex(
  'alpha',
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
);
export default {
  components: {
    ForgotPassword,
    LoginError,
  },
  data() {
    return {
      clientId: '',
      password: '',
      bottler: '',
      showBottlerInput:
        !BOTTLERS.KOBOSS.includes(getInstallationBottler()) &&
        !BOTTLERS.LATAM.includes(getInstallationBottler()),
      userToken: undefined,
      submitStatus: null,
      bottlersList: isLatamBottler()
        ? BOTTLER_DETAILS_LIST.LATAM
        : BOTTLER_DETAILS_LIST.KOBOSS,
      loading: false,
    };
  },
  validations: {
    clientId: {
      required,
    },
    password: {
      required,
      passwordValidation,
    },
  },
  methods: {
    async submit() {
      this.loading = true;
      this.submitStatus = await this.attemptToLogin();
    },
    async attemptToLogin() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.loading = false;
        return 'ERROR_INVALID';
      }
      try {
        this.userToken = await apiAuthenticateUser.userAuthentication(
          this.clientId,
          this.password,
          this.bottler
        );
        if (!this.userToken) {
          this.loading = false;
          return 'ERROR_INCORRECT';
        }
      } catch (error) {
        this.loading = false;
        return 'ERROR_API';
      }
      window.location.href = `${getRelativeAppPath()}?utm_source=standalone&utm_medium=standalone&userToken=${
        this.userToken
      }`;
      return 'OK';
    },
  },
};
</script>

<style scoped>
.form__input {
  border: 1px solid #363537;
  box-sizing: border-box;
  border-radius: 52px;
  width: 100%;
}
.form__input--error {
  border-color: var(--primary);
  background: var(--error-icon) calc(100% - 10px) 50% no-repeat;
  background-size: 16px;
}
.form__select {
  background: var(--arrow-down-icon) calc(100% - 10px) 50% no-repeat;
  appearance: none;
}
</style>
