import { post } from '@/utils/httpClient';
import {
  getBottlerConfigEnv,
  isValidBottler,
  setStorageBottler,
  setSessionData,
  getSessionData,
} from '@/utils/config';
import { STORAGE_KEY } from '@/constants/storage';

const orchestration = async ({ token }) => {
  const bottlerStorage = getBottlerConfigEnv();
  const language = getSessionData(STORAGE_KEY.language);

  const result = await post({
    url: 'orchestration',
    data: { token },
    config: { params: { language: encodeURIComponent(language) } },
    useAzureAPIManagement: bottlerStorage?.useAzureAPIManagement,
  });

  try {
    if (result.status === 200) {
      const { data } = result;
      const { authError } = data.validatePayload.result
        ? data.validatePayload.result
        : '';
      const { bottler } = data.validatePayload.payload
        ? data.validatePayload.payload
        : '';
      const { clientId } = data.validatePayload.payload
        ? data.validatePayload.payload
        : '';

      if (bottler && isValidBottler({ bottler })) {
        setSessionData({ key: 'authSettings', value: { bottler, clientId } });

        if (data) {
          const isValid =
            isValidBottler({ bottler }) && setStorageBottler({ bottler });
          const validate = data.validatePayload
            ? data.validatePayload
            : undefined;
          const client = data.client ? data.client : undefined;

          let products = [];
          let combos = [];
          let credits = {};
          let orders = [];
          if (data.products) {
            const validProducts = data.products ? data.products : undefined;
            products = validProducts?.reduce((productList, pd) => {
              if (Number(pd.price || pd.product?.price)) {
                const prodDeepLevel = pd.product || pd;
                productList.push({ product: prodDeepLevel, quantity: 1 });
              }
              return productList;
            }, []);
          }

          if (data.combos) {
            combos = data.combos;
          }

          if (data.credits) {
            credits = data.credits;
          }

          if (data.orders) {
            orders = data.orders;
          }

          const result = {
            isValid,
            validate,
            client,
            products,
            combos,
            credits,
            orders,
          };
          return result;
        }
        return false;
      }
      return authError ? { authError } : false;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export default { orchestration };
