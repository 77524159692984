import { get } from '@/utils/httpClient';

async function getCredits() {
  const result = await get({
    url: 'credits',
  });

  return result.status === 200 && result.data ? result.data : undefined;
}

export default { getCredits };
