import Vue from 'vue';
import VueI18n from 'vue-i18n';
import {
  getI18NNumberFormats,
  getI18NDateTimeFormats,
  getI18NMessages,
  getStorageBottler,
} from './config';

import {
  getInstallationLanguage,
  getInstallationCountry,
} from './installation';

Vue.use(VueI18n);

export const initializeI18N = () => {
  const storageBottler = getStorageBottler();

  const language = storageBottler?.language || getInstallationLanguage();
  const country = storageBottler?.country || getInstallationCountry();

  const { en } = getI18NMessages('en', 'EN');
  const { th } = getI18NMessages('th', 'TH');

  const messages = { en, th };

  const numberFormats = getI18NNumberFormats(language, country);
  const dateTimeFormats = getI18NDateTimeFormats(language, country);

  return new VueI18n({
    locale: `${language}-${country}`,
    fallbackLocale: getInstallationLanguage(),
    messages,
    silentFallbackWarn: true,
    numberFormats,
    dateTimeFormats,
  });
};

const i18n = initializeI18N();
export default i18n;
