<template>
  <div class="page__content">
    <div v-if="bottlerStorage.showDeliveryDateSelector === true">
      <DeliveryDateSelector />
    </div>
    <Carousel />
    <section class="parent-wrapper container max-section-width">
      <FeaturedBanner
        v-if="!loading"
        :description="featuredBanner.description"
        :button-url="featuredBanner.buttonURL"
        :button-title="featuredBanner.buttonTitle"
        :image="featuredBanner.image"
      />
      <div
        v-if="loading"
        class="text-center m-5"
      >
        <b-spinner label="Loading..." />
      </div>
    </section>
  </div>
</template>

<script>
import DeliveryDateSelector from '@/components/order/DeliveryDateSelector';
import Carousel from '@/components/Carousel.vue';
import FeaturedBanner from '@/components/shared/FeaturedBanner';
import {
  getSessionData,
  getStorageBottler,
  getAssetsPath,
} from '@/utils/config';
import { sleep } from '@/utils/pages';
import { STORAGE_KEY } from '@/constants/storage';

export default {
  components: {
    Carousel,
    FeaturedBanner,
    DeliveryDateSelector,
  },
  data() {
    return {
      featuredBanner: {},
      loading: true,
      showNotificationOptionFlag: undefined,
      assetsPath: getAssetsPath(),
      deliveryDateObj: {},
      bottlerStorage: {},
    };
  },
  created() {
    this.bottlerStorage = getStorageBottler();
  },
  async mounted() {
    // given the vuejs arch this is needed to give enough time to have the sessionstorage info
    await sleep(500);
    const ecommerceConfig = getSessionData(STORAGE_KEY.ecommerceConfig);
    this.featuredBanner = ecommerceConfig?.featuredBanner[0] || {};
    if (!this.featuredBanner.image)
      this.featuredBanner.image = `${this.assetsPath}img/banner.png`;
    this.loading = false;

    const bottlerStorage = getStorageBottler();
    this.showNotificationOptionFlag = bottlerStorage?.showNotificationOption;

    if (this.showNotificationOptionFlag) this.$store.dispatch('setOptIn');
  },
};
</script>
