const EVENTS = {
  BANNER_CLICK: 'banner_click',
  BINGO_CARD: 'bingo_card',
  BINGO_HISTORY: 'bingo_history',
  REMOVE_COMBO_FROM_CART: 'remove_combo_from_cart',
  EDIT_COMBO_FROM_CART: 'edit_combo_from_cart',
  COMBO_QUANTITY_UPDATE_RANGE: 'combo_quantity_update_range',
  HAMBURGUER_MENU_OPEN: 'open_secondary_menu',
  USER_AUTHENTICATED: 'user_authenticated',
  MODAL_NEW_FEATURE: 'modal_new_feature',
  NPS: 'nps',
  NPS_EVALUATION: 'nps_evaluation',
  NPS_FEEDBACK: 'nps_feedback',
  REPEAT_ORDER: 'repeat_order',
  EDIT_ORDER: 'edit_order',
};

export default {
  EVENTS,
};
