<template>
  <div class="page__content">
    <Breadcrumb />
    <div class="container max-section-width">
      <div class="row description-text fs-10 fw-200 mt-3 max-w-700 mx-auto">
        <div class="col fw-200 text-center">
          <p class="w-100 fs-12 fs-md-14">
            {{ $t('order.suggested') }}
          </p>
          <button
            v-if="!loading && products.length > 0 && productsT"
            id="btn-include-all-suggested"
            class="btn btn-primary mt-3"
            @click="addAll()"
          >
            {{ $t('includeAll') }}
          </button>
        </div>
      </div>
      <div class="row">
        <ul
          v-if="!loading && products.length > 0"
          class="col list-unstyled d-flex flex-wrap justify-content-center pl-1 pr-1 mt-5"
        >
          <li
            v-for="product in products"
            :key="product.product.sku"
            class="product__box"
          >
            <Product
              :product="product"
              @add-to-cart="addItemToCart"
            />
          </li>
        </ul>
      </div>
      <div class="row">
        <div class="col mt-5">
          <NoResponse v-if="!loading && !productsT" />
          <NotFound
            v-if="!loading && !products.length > 0"
            msg="notFound.suggested"
          />
          <div
            v-if="loading"
            class="loading"
          >
            <b-spinner label="Loading..." />
          </div>
        </div>
      </div>
    </div>
    <Monitor />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { MODULES } from '@/constants/feature';
import apiOrderSuggested from '@/api/order/orderSuggested';
import Product from '@/components/product/Product.vue';
import Breadcrumb from '@/components/shared/Breadcrumb.vue';
import Monitor from '@/components/Monitor.vue';
import NoResponse from '@/components/NoResponse.vue';
import NotFound from '@/components/NotFound.vue';
import router from '@/router';

export default {
  components: {
    Product,
    Breadcrumb,
    Monitor,
    NoResponse,
    NotFound,
  },
  data() {
    return {
      products: [],
      productsT: [],
      loading: true,
      appModules: MODULES,
    };
  },
  beforeCreate() {
    this.loading = true;
  },
  created() {
    this.loadOrderSuggested();
  },
  methods: {
    ...mapActions(['addItemToCart']),
    async loadOrderSuggested() {
      this.productsT = await apiOrderSuggested.getOrderSuggested();
      if (this.productsT) {
        this.productsT.forEach((pd) => {
          const product = { product: pd, quantity: pd.quantity };
          this.products.push(product);
        });
      }

      this.loading = false;
    },
    addAll() {
      const products = Object.assign([], this.productsT);
      products.forEach((pd) => {
        let { quantity } = pd;

        if (!quantity) {
          quantity = 1;
        }

        const product = { product: pd, quantity };
        this.$store.dispatch('addItemToCart', product);
      });
      router.navigateToPage('cart', {
        action: 'addSuggested',
      });
    },
  },
};
</script>
