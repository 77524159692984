<template>
  <div class="back-section-container align-items-center justify-content-center">
    <div class="back-section align-items-center max-section-width w-100 p-3">
      <a
        :aria-label="$t('ariaLabel.back')"
        :href="getPreviousPage()"
        @click="redirectToPreviousPage($event)"
      >
        <ImgGeneral
          id="back-arrow"
          :alt="$t('ariaLabel.back')"
          :url="assetsPath + 'img/icon/left-arrow.svg'"
        />
      </a>
      <h2
        v-if="currentPage()"
        class="fs-12 m-0 text-center"
      >
        {{ $t(currentPage()).toUpperCase() }}
      </h2>
      <h2
        v-else
        class="fs-12 m-0 text-center"
      >
        <span v-if="(title || title2) && !subTitle">
          <span class="d-block"
            >{{ title.toUpperCase() || title2.toUpperCase() }}
          </span>
        </span>
        <span v-if="title && subTitle">
          <span>{{ title.toUpperCase() }} / </span>
          <span>{{ subTitle.toUpperCase() }}</span>
        </span>
      </h2>
    </div>
    <div class="slot">
      <slot />
    </div>
  </div>
</template>

<script>
import ImgGeneral from '@/components/shared/ImgGeneral';
import { getAssetsPath } from '@/utils/config';
import router from '@/router';

export default {
  components: {
    ImgGeneral,
  },
  props: {
    title: {
      type: String,
      default: '',
      required: false,
    },
    subTitle: {
      type: String,
      default: '',
      required: false,
    },
  },
  data() {
    return {
      show: false,
      title2: '',
      assetsPath: getAssetsPath(),
    };
  },

  created() {
    this.title2 = this.$route.params.selectedId;
  },
  methods: {
    currentPage() {
      const { titleInAppPage } = this.$route.meta;
      const routeName = this.$route.name;
      if (titleInAppPage) {
        return `pageTitles.${titleInAppPage}`;
      }
      if (routeName) {
        return `pageTitles.${routeName}`;
      }
      return false;
    },
    getPreviousPage() {
      let previousPage = this.$route.path.split('/');
      if (previousPage.length > 2) {
        previousPage.pop();
        previousPage = previousPage.join('/');
      } else {
        previousPage = '/home';
      }
      return previousPage;
    },
    redirectToPreviousPage(event) {
      if (this.$route.path === '/order/complete')
        router.navigateToPage('/home');
      event.preventDefault();
      this.$router.back();
    },
  },
};
</script>

<style scoped>
.back-section-container {
  border-bottom: 1px solid var(--light-grey);
  background-color: var(--background);
  z-index: 1019;
  position: sticky;
  top: 40px;
}
.back-section {
  display: grid;
  grid-template-columns: 1fr 10fr 1fr;
}
.slot {
  display: flex;
  justify-content: center;
}
</style>
