<template>
  <div class="page__content">
    <Breadcrumb
      :title="$t('pageTitles.products')"
      :sub-title="sectionTitle"
    />
    <div class="container max-section-width pt-4">
      <ul
        v-if="!loading"
        id="brands"
        class="row list-unstyled justify-content-center"
      >
        <li
          v-for="brand in brands"
          :key="brand.name"
          class="brand-box product__box justify-content-center d-flex"
        >
          <brand
            :brand="brand"
            @set-brand="setBrand($event)"
          />
        </li>
      </ul>
      <NoResponse v-if="!loading && !brandsT" />
      <NotFound
        v-if="!loading && !brands.length > 0 && brandsT"
        msg="notFound.brand"
      />
      <div
        v-if="loading"
        class="loading"
      >
        <b-spinner label="Loading..." />
      </div>
    </div>
    <Monitor />
  </div>
</template>

<script>
/* eslint-disable func-names */
import { mapActions } from 'vuex';
import Breadcrumb from '@/components/shared/Breadcrumb.vue';
import Brand from '@/components/product/Brand.vue';
import Monitor from '@/components/Monitor.vue';
import NoResponse from '@/components/NoResponse.vue';
import NotFound from '@/components/NotFound.vue';
import router from '@/router';

export default {
  components: {
    Breadcrumb,
    Brand,
    Monitor,
    NoResponse,
    NotFound,
  },
  data() {
    return {
      brands: [],
      brandsT: [],
      loading: true,
    };
  },
  computed: {
    sectionTitle() {
      return this.$store.state.category?.selectedCategory?.toUpperCase();
    },
  },
  watch: {
    '$store.state.brand.brands': function () {
      this.brandsLoaded();
    },
    '$store.state.brand.error': function () {
      this.redirectToErrorPage();
    },
  },
  beforeCreate() {
    this.loading = true;
  },
  created() {
    const { categoryId } = this.$route.params;
    if (categoryId) {
      this.$store.commit('setCategory', categoryId);
    }
    if (!this.$store.state.category.selectedCategory) {
      router.navigateToPage('/home');
      return;
    }

    this.loadBrand();
  },
  methods: {
    ...mapActions(['setBrand']),
    async loadBrand() {
      this.$store.dispatch('getBrands');
    },
    setBrand(brands) {
      this.$store.commit('setBrand', brands);
      this.$router.push(
        `/category/brands/products/${this.$store.state.category.selectedCategory}/${brands.name}`
      );
    },
    brandsLoaded() {
      this.brands = this.$store.state.brand.brands;
      this.loading = false;
    },
    redirectToErrorPage() {
      if (this.$store.state.brand?.error) router.navigateToPage('/error');
    },
  },
};
</script>

<style scoped>
.brand-box {
  padding-bottom: 12px;
}
</style>
