import { get } from '@/utils/httpClient';
import { getStorageBottler } from '@/utils/config';

async function getOrderTracking({ indexOrderHistory }) {
  const bottlerStorage = getStorageBottler();

  const result = await get({
    url: `order/history/${indexOrderHistory}`,
    useAzureAPIManagement: bottlerStorage?.useAzureAPIManagement,
  });

  if (result.status) {
    return result.status === 200 && result.data ? result.data : undefined;
  }

  return result.message.includes('timeout') ? undefined : [];
}

async function getAllOrderTracking() {
  const result = await get({
    url: 'order/history',
  });

  if (result.status) {
    return result.status === 200 && result.data ? result.data : undefined;
  }

  return result.message.includes('timeout') ? undefined : [];
}

export default { getOrderTracking, getAllOrderTracking };
