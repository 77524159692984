<template>
  <div>
    <b-modal
      :id="modalId"
      :visible="isVisible"
      :title="$t('orderTracking.repeatTitle')"
      centered
      :ok-title="$t('ok')"
      :cancel-title="$t('cancel')"
      ok-variant="secondary"
      cancel-variant="primary"
      :ok-disabled="okDisabled"
      @change="$emit('update:isVisible', $event)"
      @ok="handleOk"
      @cancel="handleCancel(modalId)"
      @hide="handleClose(modalId)"
      @close="handleClose(modalId)"
    >
      <p v-if="selectedDate">
        {{ $t('orderTracking.currentDeliveryDate') }}: {{ selectedDate }}
      </p>

      <div class="header-delivery text-light fs-14">
        <span>{{ $t('deliveryDateSelector.headerTierB') }}</span>
      </div>

      <div
        v-for="(date, index) in deliveryDates"
        :key="index"
        class="delivery-date-item"
        @click="selectDate(date, index)"
      >
        <div class="delivery-date-item-content">
          <div class="sideInformation">{{ date.sideInformation }}</div>
          <div class="footer">
            <span class="footer"
              >{{ $t('deliveryDateSelector.minimumOrder') }}
            </span>
            <span class="footer-bold">{{ date.footer }}</span>
          </div>
        </div>
        <input
          :id="`date-${index}`"
          type="radio"
          name="deliveryDate"
          :value="date.value"
          class="delivery-date-radio"
          :style="{ accentColor: 'red' }"
          @change="selectDate(date, index)"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import getDeliveryDatesCommon from '@/utils/deliverydateshelper';

export default {
  name: 'DeliveryDateChildModal',
  components: {},
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    modalId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      okDisabled: true,
      selectedDate: '',
      deliveryDates: [],
    };
  },
  computed: {
    ...mapGetters({
      client: 'getClient',
      isCartAlertAcknowledged: 'getCartAlertAcknowledged',
    }),
  },

  watch: {
    isVisible: {
      async handler() {
        this.invokeGetDeliveryDatesCommon();
      },
    },
    client: {
      async handler() {
        this.invokeGetDeliveryDatesCommon();
      },
    },
  },

  async created() {
    // this.invokeGetDeliveryDatesCommon();
  },

  methods: {
    selectDate(date, index) {
      this.selectedDateObj = date;
      const radio = document.getElementById(`date-${index}`);
      if (radio) {
        radio.checked = true;
        this.okDisabled = false;
      }
    },
    async handleOk() {
      await this.$emit('selectedDataObj', this.selectedDateObj);
    },
    handleClose(curModal) {
      this.$bvModal.hide(curModal);
    },
    handleCancel(curModal) {
      this.okDisabled = true;
      this.$emit('selectedDataObj', null);
      this.$bvModal.hide(curModal);
    },
    async invokeGetDeliveryDatesCommon() {
      this.deliveryDates = await getDeliveryDatesCommon(
        this.client,
        this.isCartCutoffAcknowledged,
        this.$root.$i18n.locale,
        this.$store
      );
    },
  },
};
</script>
<style src="@/assets/styles/deliverydates.css" scoped></style>
