<template>
  <ul
    v-show="combosInCart.length"
    class="list-unstyled mt-4"
  >
    <li
      v-for="(combo, index) in combosInCart"
      :key="combo + index"
      class="list-item list-combo-item card-body mb-3"
    >
      <div class="combo-image">
        <ImgGeneral
          :alt-text="$t('combo.comboImageAltText', { name: combo.name })"
          class="item-img mw-100 br-12"
          :url="combo.image"
          :show-fallback-img="true"
          :title="combo.name"
        />
      </div>
      <div class="pl-3">
        <h4 class="product__title mb-1 mt-0 fs-12">
          {{ combo.name }}
        </h4>
        <h4 class="product__price mb-3">
          <del
            v-if="combo.priceInfo.totalDiscount > 0"
            class="fw-200 text-mediumgrey opacity-50"
          >
            {{ formatPrice(Number(combo.priceInfo.totalRegularPrice)) }}
          </del>
          <span class="fs-14">
            {{ formatPrice(Number(combo.priceInfo.totalPrice)) }}
          </span>
        </h4>
        <p class="fs-12 fw-200 mb-3">
          <span v-if="combo.priceInfo.totalDiscount > 0">
            {{
              $t('combo.youSave', {
                save: formatPrice(Number(combo.priceInfo.totalDiscount)),
              })
            }}
          </span>
          <span v-if="combo.nextRange">
            {{
              $t('combo.takeMore', {
                unity: combo.nextRange,
              })
            }}
          </span>
        </p>
        <ul class="list-unstyled">
          <li
            v-for="item in fullCartItems"
            :key="item.product.sku + item.product.combo"
          >
            <div
              v-if="item.product.combo === combo.comboId && item.quantity > 0"
            >
              <h4 class="product__title mb-1 fs-12">
                <span class="text-primary">{{ item.quantity }}x</span>
                {{ item.product.brand }}
              </h4>
              <p class="fs-10 fw-200">
                {{ item.product.description }}
              </p>
            </div>
          </li>
          <div class="edit-buttons d-flex gap-10 mt-4">
            <b-button
              variant="primary"
              @click="$emit('edit', combo)"
            >
              {{ $t('combo.updateLabel') }}
            </b-button>
            <b-button
              class="btn-outline"
              variant="primary"
              @click="$emit('remove', combo)"
            >
              {{ $t('combo.removeLabel') }}
            </b-button>
          </div>
        </ul>
      </div>
    </li>
  </ul>
</template>
<script>
/* eslint-disable func-names */
import { getStorageBottler } from '@/utils/config';
import ProductMixin from '@/components/mixins/ProductMixin';
import ImgGeneral from '@/components/shared/ImgGeneral';

export default {
  components: {
    ImgGeneral,
  },
  mixins: [ProductMixin],
  props: {
    fullCartItems: {
      type: Array,
      default() {
        return [];
      },
      required: true,
    },
  },
  data() {
    return {
      combos: [],
      uniqueCombosInCart: [],
      combosInCart: [],
    };
  },
  watch: {
    '$store.state.cart.items': function () {
      this.checkCartForCombos();
      this.populateExtraInfoForCombos();
    },
    '$store.state.combo.isComboCached': function () {
      this.isComboLoaded();
    },
  },
  async mounted() {
    const storageBottler = getStorageBottler();
    if (!storageBottler?.isComboServiceAvailable) {
      return;
    }
    this.checkCartForCombos();
    await this.loadCombos();
    this.populateExtraInfoForCombos();
  },
  methods: {
    getTotalComboPrice(comboId) {
      const comboProducts = this.getComboProducts(comboId);
      let totalRegularPrice = 0;
      const comboTotalQuantity = comboProducts.reduce(
        (t, item) => Math.round(t + Number(item.quantity)),
        0
      );
      /*
       * FIX We should avoid iterate & recalc all the prices again,
       * but since it seems vuex store is not prepared to calculate on brower refresh
       * this is the way it work with less impact
       */
      comboProducts.map((comboItem) => {
        const item = comboItem;
        if (
          item.product.pricePromotions &&
          item.product.pricePromotions.length > 0
        ) {
          const promotions = comboItem.product.pricePromotions;
          promotions.forEach((p) => {
            if (comboTotalQuantity >= p.minimumQuantity) {
              item.product.price = p.price;
            }
          });
        }
        return item;
      });
      const totalPrice = comboProducts.reduce((previousPrice, item) => {
        totalRegularPrice += Number(item.regularPrice * item.quantity);
        return previousPrice + Number(item.product.price * item.quantity);
      }, 0);
      return {
        totalPrice,
        totalRegularPrice,
        totalDiscount: totalRegularPrice - totalPrice,
      };
    },
    getComboProducts(comboId) {
      const comboProducts = this.fullCartItems.filter(
        (product) => product.product.combo && product.product.combo === comboId
      );
      return comboProducts;
    },
    getCombo(comboId) {
      if (this.combos && this.combos.length > 0) {
        const combo = this.combos.find((combo) => combo.id === comboId);
        return combo;
      }
      return '';
    },
    async loadCombos() {
      if (this.$store.state.combo.isComboCached) {
        this.isComboLoaded();
        return;
      }
      this.$store.dispatch('getCombos');
    },
    isComboLoaded() {
      this.combos = this.$store.state.combo.data;
      this.populateExtraInfoForCombos();
    },
    checkCartForCombos() {
      this.uniqueCombosInCart = [
        ...new Set(
          this.fullCartItems
            .filter(
              (comboProductInCart) =>
                comboProductInCart.product.combo &&
                comboProductInCart.product.combo !== 0
            )
            .map((product) => product.product.combo)
        ),
      ];
    },
    populateExtraInfoForCombos() {
      this.combosInCart = this.uniqueCombosInCart.map((combo) => ({
        comboId: combo,
        image: this.getCombo(combo).image,
        name: this.getCombo(combo).name,
        priceInfo: this.getTotalComboPrice(combo),
        nextRange: this.getComboProducts(combo)[0].nextRangeQuantity,
      }));
    },
  },
};
</script>

<style scoped>
.list-item {
  display: grid;
  grid-template-columns: 1fr 2fr;
}
</style>
