<template>
  <a
    v-if="isExternalLink"
    :href="to"
    rel="noopener noreferrer"
    target="_blank"
  >
    <slot />
  </a>
  <router-link
    v-else
    v-bind="$props"
  >
    <slot />
  </router-link>
</template>

<script>
export default {
  name: 'AppLink',

  props: {
    to: {
      type: String,
      default: '',
    },
  },

  computed: {
    isExternalLink() {
      return this.to.startsWith('http');
    },
  },
};
</script>
