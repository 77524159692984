<template>
  <div class="page__content">
    <BackToTopButton />
    <Breadcrumb
      :title="sectionTitle"
      :sub-title="sectionSubTitle"
    />
    <div class="container max-section-width">
      <div
        v-if="
          client.type !== 'Stockist' ||
          /\b(RGB|Can|PET)\b/.test($route.params.selectedId)
        "
        class="row"
      >
        <div class="col">
          <Select
            :packages="packages"
            @selected="selected($event)"
          />
        </div>
      </div>
      <div class="row">
        <ul
          v-if="!loading"
          id="products-page"
          class="col list-unstyled d-flex flex-wrap justify-content-center pl-1 pr-1"
        >
          <li
            v-for="product in products"
            :key="product.product.sku"
            class="product__box"
          >
            <Product
              :product="product"
              @add-to-cart="addItemToCart"
            />
          </li>
        </ul>
      </div>
      <NoResponse v-if="!loading && !productsT" />
      <NotFound
        v-if="!loading && products.length == 0 && productsT.length == 0"
        msg="notFound.product"
      />
      <div
        v-if="loading"
        class="loading pt-4"
      >
        <b-spinner label="Loading..." />
      </div>
    </div>
    <Monitor />
  </div>
</template>

<script>
/* eslint-disable func-names */
import { mapActions, mapGetters } from 'vuex';
import Product from '@/components/product/Product.vue';
import Breadcrumb from '@/components/shared/Breadcrumb.vue';
import Monitor from '@/components/Monitor.vue';
import NoResponse from '@/components/NoResponse.vue';
import NotFound from '@/components/NotFound.vue';
import Select from '@/components/Select.vue';
import BackToTopButton from '@/components//shared/BackToTopButton.vue';
import router from '@/router';

export default {
  components: {
    Product,
    Breadcrumb,
    Monitor,
    NoResponse,
    NotFound,
    BackToTopButton,
    Select,
  },
  data() {
    return {
      products: [],
      category: '',
      loading: true,
      packages: [],
    };
  },
  computed: {
    ...mapGetters({
      selectedCategory: 'getCategory',
      selectedBrand: 'getBrand',
      brands: 'getBrands',
      productsT: 'getSearchProducts',
      client: 'getClient',
    }),
    sectionTitle() {
      if (this.client.type === 'Stockist') {
        return this.$route.params?.selectedId
          ?.toUpperCase()
          ?.replaceAll('_', ' - ')
          ?.replaceAll('DOT', '.');
      }
      return this.$store.state.category?.selectedCategory?.toUpperCase();
    },
    sectionSubTitle() {
      return this.$store.state.brand.selectedBrand?.name?.toUpperCase();
    },
  },
  watch: {
    '$store.state.search.products': function onGetProducts() {
      if (this.client.type === 'Stockist') {
        this.filterProductsStockist();
      } else {
        this.filterProducts();
      }
      this.loading = false;
    },
    '$store.state.search.error': function () {
      this.redirectToErrorPage();
    },
  },
  beforeCreate() {
    this.loading = true;
  },
  async mounted() {
    if (this.$store.state.search.products.length > 0) {
      this.onCreate();
    }
    if (
      typeof this.$store.state.search.products !== 'undefined' &&
      this.$store.state.search.products.length > 0
    ) {
      this.loading = false;
    }
  },
  methods: {
    ...mapActions(['addItemToCart']),
    async loadBrands() {
      await this.$store.dispatch('getBrands');
    },
    async onCreate() {
      if (this.client.type === 'Stockist') {
        this.filterProductsStockist();
      } else {
        // set state in case of deep link access
        const urlCategoryId = this.$route.params.categoryId;
        if (urlCategoryId) {
          this.$store.commit('setCategory', urlCategoryId);
        }
        // load brands prior to filter when deep linking
        const urlBrandId = this.$route.params.brandId;
        if (urlBrandId) {
          await this.loadBrands();
          const activeBrand = this.brands.find(
            (item) => item.name === urlBrandId
          );
          this.$store.commit('setBrand', activeBrand);
        }
        if (this.selectedCategory) {
          this.filterProducts();
        } else {
          router.navigateToPage('/home');
        }
      }
    },
    filterProducts() {
      const getUniqueFilters = (key) => {
        return this.products.reduce(
          (accu, curr) =>
            curr.product[key] in accu
              ? accu
              : { ...accu, [curr.product[key]]: curr.product[key] },
          {}
        );
      };

      const { selectedId } = this.$route.params;
      const decodedId = selectedId.replaceAll('dot', '.');

      this.products = this.productsT.filter(
        (p) => p.product.winnieGroup === decodedId
      );

      if (/\b(RGB|Can|PET)\b/.test(selectedId)) {
        this.packages = getUniqueFilters('brand');
      } else {
        this.packages = getUniqueFilters('submenu');
      }

      this.loading = false;
    },
    filterProductsStockist() {
      const getUniqueFilters = (key) => {
        return this.products.reduce(
          (accu, curr) =>
            curr.product[key] in accu
              ? accu
              : { ...accu, [curr.product[key]]: curr.product[key] },
          {}
        );
      };

      const { selectedId } = this.$route.params;
      const decodedId = selectedId.replaceAll('dot', '.');

      this.products = this.productsT.filter(
        (p) => p.product.xlGroup === decodedId
      );

      if (/\b(RGB|Can|PET)\b/.test(selectedId)) {
        this.packages = getUniqueFilters('brand');
      } else {
        this.packages = getUniqueFilters('submenu');
      }
      this.loading = false;
    },
    selected(selected) {
      if (this.client.type === 'Stockist') {
        this.filterProductsStockist();
        if (selected !== null) {
          const { selectedId } = this.$route.params;
          if (/\b(RGB|Can|PET)\b/.test(selectedId)) {
            this.products = this.products.filter(
              (p) => p.product.brand === selected
            );
          } else {
            this.products = this.products.filter(
              (p) => p.product.submenu === selected
            );
          }
        }
      } else {
        this.filterProducts();
        // if (selected) this.products = this.packages[selected];
        if (selected !== null) {
          const { selectedId } = this.$route.params;
          if (/\b(RGB|Can|PET)\b/.test(selectedId)) {
            this.products = this.products.filter(
              (p) => p.product.brand === selected
            );
          } else {
            this.products = this.products.filter(
              (p) => p.product.submenu === selected
            );
          }
        }
      }
    },
    redirectToErrorPage() {
      if (this.$store.state.search?.error) router.navigateToPage('/error');
    },
  },
};
</script>
