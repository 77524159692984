<!-- eslint-disable max-len -->
<template>
  <div>
    <slot />
    <a
      v-if="target === 'external'"
      class="btn btn-primary mb-5"
      :href="url"
      @click="onClick($event)"
    >
      {{ buttonCta }}
    </a>
    <button
      v-if="target === 'internal'"
      class="btn btn-primary mb-5"
      @click="onClick($event)"
    >
      {{ buttonCta }}
    </button>
    <router-link
      v-if="target === 'router'"
      class="btn btn-primary mb-5"
      :to="url"
      :href="url"
    >
      {{ buttonCta }}
    </router-link>
  </div>
</template>

<script>
import { getStorageBottler } from '@/utils/config';

export default {
  props: {
    buttonCta: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: '',
    },
    target: {
      type: String,
      default: 'internal',
      validator(value) {
        return ['external', 'internal', 'router'].includes(value);
      },
    },
  },
  data() {
    return {
      bottlerStorage: getStorageBottler(),
    };
  },
  created() {},
  methods: {
    onClick(evt) {
      evt.preventDefault();
      this.$emit('onClickCTA');
    },
  },
};
</script>
