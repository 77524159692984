import { post } from '@/utils/httpClient';

async function postEvent(eventId, data) {
  const result = await post({
    url: `event/?event=${eventId}`,
    data,
  });

  return result.status === 204;
}

export default { postEvent };
