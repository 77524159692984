import apiScheduledOrderAvailableDate from '@/api/order/scheduledOrderAvailableDate';
import { getStorageBottler } from '@/utils/config';

export default {
  state: {
    availableDaysWithMaxPeriod: null,
    selectDate: [],
    orderType: '',
  },
  getters: {
    getSelectDate(state) {
      return state.selectDate;
    },
    getAvailableDaysWithMaxPeriod(state) {
      return state.availableDaysWithMaxPeriod;
    },
    getOrderType(state) {
      return state.orderType;
    },
  },
  mutations: {
    setSelectDate(state, payload) {
      // eslint-disable-next-line no-param-reassign
      state.selectDate = payload;
    },
    setAvailableDaysWithMaxPeriod(state, payload) {
      // eslint-disable-next-line no-param-reassign
      state.availableDaysWithMaxPeriod = payload;
    },
    resetState(state) {
      // eslint-disable-next-line no-param-reassign
      state.orderType = '';
      // eslint-disable-next-line no-param-reassign
      state.selectDate = [];
    },
    setOrderType(state, payload) {
      // eslint-disable-next-line no-param-reassign
      state.orderType = payload;
    },
  },
  actions: {
    setSelectDate({ commit }, value) {
      commit('setSelectDate', value);
    },
    async setAvailableDaysWithMaxPeriod({ commit }) {
      const showDeliveryOption = getStorageBottler()?.showDeliveryOption;
      const recurrentOrderOptions =
        getStorageBottler()?.recurrentOrderOptionalPeriods;
      const maxPeriod = recurrentOrderOptions?.at(
        recurrentOrderOptions?.length - 1
      );
      if (showDeliveryOption) {
        const availableDates =
          await apiScheduledOrderAvailableDate.getScheduledOrderAvailableDate({
            period: maxPeriod,
          });
        if (availableDates) {
          commit('setAvailableDaysWithMaxPeriod', availableDates);
        }
      }
    },
    resetState({ commit }) {
      commit('resetState', this.state);
    },
    setOrderType({ commit }, value) {
      commit('setOrderType', value);
    },
  },
};
