import { get } from '@/utils/httpClient';
import { getStorageBottler } from '@/utils/config';

async function getPromotions() {
  const bottlerStorage = getStorageBottler();
  const result = await get({
    url: 'promotions',
    useAzureAPIManagement: bottlerStorage?.useAzureAPIManagement,
  });

  if (result.status) {
    return result.status === 200 && result.data ? result.data : undefined;
  }

  return result.message.includes('timeout') ? undefined : [];
}

export default { getPromotions };
