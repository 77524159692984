<template>
  <div
    id="product-item"
    class="mt-3 d-flex"
  >
    <div
      class="card-body d-flex flex-column justify-content-between mb-3 p-0 position-relative"
    >
      <div class="w-100">
        <div class="product__img-container w-100 text-center">
          <div class="position-relative d-block">
            <ImgGeneral
              :alt="printAlt"
              class="responsive img-product pt-2"
              :url="product.product.imageUrl"
              :show-fallback-img="true"
              :show-loader-before-init="true"
            />

            <ImgGeneral
              v-if="product.product.iconBannerUrl"
              :alt="$t('ariaLabel.promotionIcon')"
              class="position-absolute promotion-overlay-image"
              :url="product.product.iconBannerUrl"
              :is-one-px-placeholder="true"
            />
            <hr class="m-0" />
            <ProductTag v-if="showTag" />
          </div>
        </div>
        <div class="px-2 pt-2">
          <div class="d-flex mb-2">
            <h4
              translate="no"
              class="product__title my-0 fs-12 text-break"
            >
              {{ product.product.description }}
            </h4>
            <div class="fs-20 ml-2 heart-icon text-center">
              <b-icon
                v-if="isCallingFavorite === false"
                class="h4 m-0"
                :icon="isFavorite ? 'heart-fill' : 'heart'"
                @click="favoriteClick()"
              />
              <b-spinner
                v-else
                label="Loading..."
                class="mb-1"
              />
            </div>
          </div>
          <b-container fluid>
            <b-row align-v="center">
              <b-col class="pl-0">
                <ProductPriceDetails
                  :unit-cost="product.product.unitCost"
                  :suggested-sale="product.product.suggestedSale"
                />
                <p
                  v-if="
                    !hasProductPricePromotion() &&
                    priceWithoutDiscountFlag &&
                    product.product.priceWithoutDiscount &&
                    Number(product.product.priceWithoutDiscount) >
                      product.product.price
                  "
                  class="mb-0 fs-10 text-decoration-line-through text-mediumgrey"
                >
                  {{
                    formatPrice(Number(product.product.priceWithoutDiscount))
                  }}
                </p>
                <p
                  v-if="!hasProductPricePromotion()"
                  :style="{
                    marginBottom:
                      product.product.unitCost || product.product.suggestedSale
                        ? 'revert'
                        : '0',
                  }"
                  class="product__price"
                >
                  {{ formatPrice(Number(product.product.price)) }}
                </p>
                <table v-if="product.product.pricePromotions">
                  <tbody>
                    <div
                      v-for="promotion in product.product.pricePromotions"
                      :key="promotion.minimumQuantity"
                    >
                      <div>
                        <p
                          v-if="
                            qty >= promotion.minimumQuantity &&
                            !disableDiscountPercentage
                          "
                          class="product__discount-value"
                          :aria-label="
                            $t('ariaLabel.discountLabel', {
                              discount: promotion.discountPercentage,
                            })
                          "
                        >
                          -{{ promotion.discountPercentage }}%
                        </p>
                        <tr
                          class="product__minimum-value text-primary"
                          :class="checkClassOpacity(price, promotion, product)"
                        >
                          <td>
                            <span
                              class="product__minimum-value-unit fs-10 fw-200"
                            >
                              {{ promotion.minimumQuantity }} {{ $t('box') }}
                            </span>
                          </td>
                          <td>
                            <span class="pl-1 pr-1 fs-12 fw-200">
                              {{ formatPrice(Number(promotion.price)) }}
                            </span>
                          </td>
                          <td>
                            <span class="fs-10">
                              {{ $t('each') }}
                            </span>
                          </td>
                        </tr>
                      </div>
                    </div>
                  </tbody>
                </table>
              </b-col>
              <div sm="auto px-0">
                <b-icon
                  v-if="product.product.isRecommended"
                  class="h4 m-0"
                  icon="star-fill"
                  variant="warning"
                />
              </div>
            </b-row>
          </b-container>
        </div>
      </div>
      <div class="w-100 px-2 pb-2">
        <ProductQtyController
          :define-price="definePrice"
          :enable-order="enableOrder"
          :quantity="quantity"
          :disabled="
            enableUserInteraction || product.product.quantityLimit === 0
          "
          :qty-limit="
            $store.state.client.clientProfileInfo.type === 'Stockist'
              ? product.product.quantityLimit
              : undefined
          "
          :qty-limit-alert="
            $store.state.client.clientProfileInfo.type === 'Stockist'
              ? () => showProductQtyLimitToast(product.product)
              : undefined
          "
          @qty-updated="qtyUpdated"
        />
        <p
          v-if="
            typeof product.product.quantityLimit === 'number' &&
            $store.state.client.clientProfileInfo.type === 'Stockist'
          "
          class="text-muted fs-10 text-center"
        >
          {{ getWarningProductLimitText(product.product) }}
        </p>
        <button-add-cart
          :disabled="product.product.quantityLimit === 0"
          :enable-user-interaction="enableUserInteraction"
          @click-add-to-cart="initialState()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ProductMixin from '@/components/mixins/ProductMixin';
import { getStorageBottler } from '@/utils/config';
import apiProductsFavorites from '@/api/productsFavorites';
import ProductPriceDetails from '@/components/product/ProductPriceDetails.vue';
import ProductTag from '@/components/product/ProductTag.vue';
import ButtonAddCart from '@/components/product/ButtonAddCart.vue';
import Analytics from '@/utils/analytics';
import apiSendEvent from '@/api/event';
import ImgGeneral from '@/components/shared/ImgGeneral';
import ProductQtyController from '@/components/product/ProductQtyController.vue';

export default {
  components: {
    ProductPriceDetails,
    ProductTag,
    ButtonAddCart,
    ImgGeneral,
    ProductQtyController,
  },
  mixins: [ProductMixin],
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      enableOrder: true,
      qty: this.product.quantity,
      price: this.product.product.price,
      disableDiscountPercentage: {},
      sku: this.product.product.sku,
      priceWithoutDiscountFlag: false,
      quantity: 1,
      isFavorite: false,
      isCallingFavorite: false,
      enableUserInteraction: false,
    };
  },
  computed: {
    printAlt() {
      const descriptionProductLowerCase = this.product
        ? `${this.$t(
            'product'
          )} ${this.product.product.description.toLowerCase()}`
        : '';
      return descriptionProductLowerCase;
    },
  },
  watch: {
    '$store.state.cart.simulatedPriceLoading':
      function onSimulatedPriceLoadingUpdate(newVal) {
        if (!newVal) {
          this.enableUserInteraction = false;
        }
      },
  },
  created() {
    const bottlerStorage = getStorageBottler();
    this.disableDiscountPercentage =
      bottlerStorage?.disableProductDiscountPercentage;
    this.priceWithoutDiscountFlag = bottlerStorage?.showPriceWithoutDiscount;
    this.quantity = this.product.product.quantity;
    this.isFavorite = this.product.product.isFavorite;
  },
  methods: {
    initialState() {
      this.enableUserInteraction = true;
      if (this.qty > 0 && !Number.isNaN(this.qty)) {
        this.enableOrder = true;
        const { product } = this.product;

        if (product.quantityLimit > 0) {
          this.qty = this.getQtyLimitAllowedAddToCart(product);
        }

        this.$emit('add-to-cart-p', { product, quantity: Number(this.qty) });
        Analytics.addToCart(product, Number(this.qty));
        apiSendEvent.postEvent('AddToCartv2', {
          addtocart: true,
          products: [{ sku: this.sku, quantity: Number(this.qty) }],
        });
        this.quantity = 1;
      } else {
        this.enableOrder = false;
      }
    },
    checkClassOpacity(price, promotion, product) {
      if (
        price === promotion.price ||
        (!promotion.priceRange && price === product.product.price)
      ) {
        return 'opacity-100';
      }
      return 'opacity-25';
    },
    qtyUpdated(quantity) {
      this.quantity = quantity;
    },
    favoriteClick() {
      if (!this.isCallingFavorite) {
        this.isCallingFavorite = true;

        const initialFavoriteValue = this.isFavorite;
        this.isFavorite = !initialFavoriteValue;
        // Update local Favorite store as product is only called once in the app
        this.$store.dispatch('setFavorite', {
          sku: this.sku,
          isFavorite: this.isFavorite,
        });

        apiProductsFavorites
          .setProductsFavorites({ sku: this.sku, isFavorite: this.isFavorite })
          .then((response) => {
            if (response === false) {
              this.isFavorite = initialFavoriteValue;
              this.$store.dispatch('setFavorite', {
                sku: this.sku,
                isFavorite: this.isFavorite,
              });
            }
            this.isCallingFavorite = false;
          });
      }
    },
  },
};
</script>
