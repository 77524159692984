<template>
  <div class="fw-200 fs-10 mt-1">
    <p class="decoration-bar pl-1">
      <span class="d-block ml-1">{{ $t('login.changePassword') }}</span>
      <span class="d-block ml-1">
        {{
          $t('login.howTochangePassword', {
            value: botSourceAppName,
          })
        }}
      </span>
    </p>
  </div>
</template>

<script>
import { getBotSourceAppName } from '@/utils/config';

export default {
  data() {
    return {
      botSourceAppName: getBotSourceAppName(),
    };
  },
};
</script>

<style scoped>
.decoration-bar {
  border-left: 2px solid var(--primary);
}
</style>
