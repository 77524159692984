/* eslint-disable no-param-reassign */
import apiProducts from '@/api/products';
import { uniqueElements } from '../../utils/config/index';

export default {
  state: {
    show: false,
    searchString: '',
    products: [],
    error: '',
  },
  getters: {
    getSearchShow(state) {
      return state.show;
    },
    getSearchString(state) {
      return state.searchString;
    },
    getSearchProducts(state, getters) {
      getters.getCombos.forEach((combo) => {
        combo.products.forEach((comboProductId) => {
          const product = state.products.find(
            (p) => p.product.sku === comboProductId
          );

          if (product) {
            const { products: _, ...comboDetails } = combo;
            product.product.comboDetails = comboDetails;

            if (combo.productItems) {
              combo.productItems = combo.productItems.concat(product.product);
            } else {
              combo.productItems = [product.product];
            }
          }
        });
      });

      return state.products;
    },
    getSearchPromotion(state) {
      return state.products.filter((p) => p.product.isPromotionType);
    },
    getSearchError(state) {
      return state.error;
    },
  },
  mutations: {
    setSearchShow(state, payload) {
      state.show = payload;
    },
    setSearchString(state, payload) {
      state.searchString = payload;
    },
    setSearchProducts(state, payload) {
      state.products = uniqueElements(
        payload,
        (a, b) => a.product.sku === b.product.sku
      );
    },
    setError(state, payload) {
      state.error = payload;
    },
    setFavorite(state, payload) {
      const product = state.products.find((p) => p.product.sku === payload.sku);
      if (product) {
        product.product.isFavorite = payload.isFavorite;
      }
    },
  },
  actions: {
    setSearchShow({ commit }, value) {
      commit('setSearchShow', value);
    },
    setSearchString({ commit }, value) {
      commit('setSearchString', value);
    },
    setFavorite({ commit }, value) {
      commit('setFavorite', value);
      commit('setPromotionFavorite', value);
    },
    async getProducts({ commit, getters }) {
      let productsPayload;

      try {
        productsPayload = await apiProducts.getProducts();
      } catch (error) {
        commit('setSearchProducts', []);
        commit('setError', error);
      }

      if (productsPayload) {
        const products = productsPayload.reduce((productList, pd) => {
          if (Number(pd.price || pd.product?.price)) {
            const prodDeepLevel = pd.product || pd;
            productList.push({ product: prodDeepLevel, quantity: 1 });
          }
          return productList;
        }, []);

        const cartItems = getters.getCartItems.map(({ product, quantity }) => {
          const updatedProduct = productsPayload.find(
            (p) => p.sku === product.sku
          );

          return { product: updatedProduct, quantity };
        });
        commit('setError', '');
        commit('setSearchProducts', products);
        commit('setCartItems', cartItems);
      }
    },
  },
};
