var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cart-item",class:{ 'swiped-left': _vm.swipedLeft },on:{"touchstart":_vm.handleTouchStart,"touchmove":_vm.handleTouchMove,"touchend":_vm.handleTouchEnd}},[_c('div',{staticClass:"product__img-container mr-2 d-flex justify-content-center align-items-center"},[_c('ImgGeneral',{staticClass:"item-img",attrs:{"alt-text":_vm.product.product.description,"url":_vm.product.product.imageUrl,"show-fallback-img":true,"show-loader-before-init":true}})],1),_c('div',{staticClass:"description mb-3"},[_c('h4',{staticClass:"product__title mb-1 fs-12",attrs:{"translate":"no"}},[_vm._v(" "+_vm._s(_vm.product.product.brand)+" ")]),_c('p',{staticClass:"fs-10 fw-200",attrs:{"translate":"no"}},[_vm._v(" "+_vm._s(_vm.product.product.description)+" ")]),(
        !_vm.hasProductPricePromotion() &&
        _vm.priceWithoutDiscountFlag &&
        _vm.product.product.priceWithoutDiscount &&
        Number(_vm.product.product.priceWithoutDiscount) > _vm.product.product.price
      )?_c('p',{staticClass:"mb-0 fs-10 text-decoration-line-through text-mediumgrey"},[_vm._v(" "+_vm._s(_vm.formatPrice(Number(_vm.product.product.priceWithoutDiscount)))+" ")]):_vm._e(),(!_vm.isLoading)?_c('p',{staticClass:"product__price"},[_vm._v(" "+_vm._s(_vm.formatPrice(Number(_vm.product.product.price)))+" ")]):_vm._e(),(!_vm.isLoading && _vm.product.product.totalPrice)?_c('p',{staticClass:"mb-0 fs-10 text-mediumgrey opacity-50 relativebottom"},[_vm._v(" "+_vm._s(_vm.$t('subtotal'))+" "+_vm._s(_vm.formatPrice(Number(_vm.product.product.totalPrice)))+" ")]):_vm._e(),(_vm.isLoading)?_c('Skeleton',{staticClass:"w-25",attrs:{"height":'30px'}}):_vm._e()],1),_c('div',{staticClass:"product__quantity-section d-flex align-items-center"},[_c('div',[_c('ProductQtyController',{attrs:{"quantity":_vm.product.quantity,"enable-order":true,"disabled":_vm.enableUserInteraction || _vm.product.product.quantityLimit === 0,"qty-limit":_vm.$store.state.client.clientProfileInfo.type === 'Stockist'
            ? _vm.quantityLimit
            : undefined,"qty-limit-alert":_vm.$store.state.client.clientProfileInfo.type === 'Stockist'
            ? () => _vm.showProductQtyLimitToast(_vm.product.product)
            : undefined},on:{"qty-updated":_vm.productQuantityUpdated}}),(
          typeof _vm.product.product.quantityLimit === 'number' &&
          _vm.$store.state.client.clientProfileInfo.type === 'Stockist'
        )?_c('p',{staticClass:"text-muted fs-10 text-center"},[_vm._v(" "+_vm._s(_vm.getWarningProductLimitText(_vm.product.product))+" ")]):_vm._e(),(!_vm.isLoading)?_c('p',{staticClass:"fs-10 text-center remove-container remove-section",on:{"click":_vm.removeItem}},[_c('ImgGeneral',{staticClass:"remove-icon",attrs:{"is-one-px-placeholder":true,"data-src":`${_vm.assetsPath}img/trash-grey.svg`}}),_c('span',{staticClass:"remove-text"},[_vm._v(" "+_vm._s(_vm.$t('combo.remove')))])],1):_vm._e()],1),_c('div',{staticClass:"delete-button",on:{"click":_vm.removeItem}},[_c('div',{staticClass:"remove-icon-flex"},[_c('ImgGeneral',{attrs:{"is-one-px-placeholder":true,"data-src":_vm.assetsPath + 'img/delete.svg'}}),_c('span',{staticClass:"remove-text-white"},[_vm._v(" "+_vm._s(_vm.$t('combo.remove')))])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }