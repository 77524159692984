import { get } from '@/utils/httpClient';
import { getSessionData } from '@/utils/config';
import { STORAGE_KEY } from '@/constants/storage';

async function getBrands(category) {
  const language = getSessionData(STORAGE_KEY.language);
  const result = await get({
    url: `brands/${category}`,
    config: { params: { language: encodeURIComponent(language) } },
  });

  if (result.status) {
    return result.status === 200 && result.data ? result.data : undefined;
  }

  return result.message.includes('timeout') ? undefined : [];
}

export default { getBrands };
