export const SERVICES = {
  orderTracking: 'orderTracking',
  kombo: 'kombo',
  promotion: 'promotion',
  openInvoices: 'duplicate',
  notification: 'notification',
};

export const MODULES = {
  products: {
    imgSource: 'img/nav/coke-bottle.svg',
    route: 'category',
    name: 'products',
    alt: 'menu.orderIconAlt',
    description: '',
  },
  promotions: {
    imgSource: 'img/nav/price-tag.svg',
    route: 'promotions',
    name: 'promotions',
    alt: 'menu.promotionsIconAlt',
    description: '',
  },
  promotionslist: {
    imgSource: 'img/nav/price-tag.svg',
    route: 'promotionslist',
    name: 'promotions',
    alt: 'menu.promotionsIconAlt',
    description: '',
  },
  combos: {
    imgSource: 'img/nav/price-tag.svg',
    route: 'combos',
    name: 'promotions',
    alt: 'menu.promotionsIconAlt',
    description: '',
  },
  favorites: {
    imgSource: 'img/nav/heart.svg',
    route: 'favorites',
    name: 'favorites',
    alt: 'menu.favoritesIconAlt',
    description: '',
  },
  credits: {
    imgSource: 'img/nav/credit-limit-white.svg',
    route: 'credit',
    name: 'credits',
    alt: 'menu.creditIconAlt',
    description: '',
  },
  cart: {
    imgSource: 'img/nav/cart-icon.svg',
    route: 'cart',
    name: 'cart',
    alt: 'menu.cartIconAlt',
    description: '',
  },
  suggested: {
    imgSource: 'img/nav/suggested-icon.svg',
    route: 'suggested',
    name: 'suggestedOrder',
    alt: 'menu.suggestedOrderIconAlt',
    description: '',
  },
  bingo: {
    imgSource: 'img/nav/bingo-icon.svg',
    route: 'bingo',
    name: 'bingo.title',
    alt: 'bingo.bingoIconAlt',
    description: '',
  },
  tracking: {
    imgSource: 'img/nav/tracking-icon.svg',
    route: 'order/tracking',
    name: 'orderTracking.title',
    alt: 'menu.orderTrackingIconAlt',
    description: '',
  },
  marketing: {
    imgSource: 'img/nav/marketing.svg',
    route: 'marketing',
    name: 'marketing.title',
    alt: 'menu.marketingIconAlt',
    description: '',
  },
  finance: {
    imgSource: 'img/nav/finance.svg',
    route: 'finance',
    name: 'finance.title',
    alt: 'menu.financeIconAlt',
    description: '',
  },
};

export const SET_ORDER_TYPE = {
  scheduledOrder: 'Pedido programado',
  recurrentOrder: 'Pedido recorrente',
};

export const GLASS_BOTTLE_BUTTON_ACTION = {
  ADD: 'addGlassBottles',
  REDIRECT: 'redirect',
};
