<template>
  <div class="page__content">
    <Breadcrumb />
    <div class="container max-section-width">
      <NotFound :msg="errorMessage" />
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/components/shared/Breadcrumb.vue';
import NotFound from '@/components/NotFound.vue';

export default {
  components: {
    Breadcrumb,
    NotFound,
  },

  data() {
    return {
      errorMessage:
        this.$route?.params?.errorMessage ===
        'ขออภัยค่ะ ยอดคำสั่งซื้อต่ำกว่าที่กำหนด กรุณาเพิ่มสินค้าในรถเข็นของคุณ'
          ? 'notFound.minimumOrderQtyError'
          : 'notFound.error',
    };
  },
};
</script>
