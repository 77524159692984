<template>
  <div class="page__content">
    <Breadcrumb v-if="(!loading && !result) || result === 'limitOrder'" />
    <div
      class="container max-section-width d-flex align-items-center text-center justify-content-center"
    >
      <div
        v-if="!loading"
        class="icon-check"
      >
        <b-icon
          v-if="!result || result === 'limitOrder'"
          class="mb-4"
          icon="x-circle-fill"
          variant="danger"
          font-scale="5"
        />
        <b-icon
          v-if="
            (result || result !== 'limitOrder') && origin !== sources.standalone
          "
          class="mb-4"
          font-scale="5"
          icon="check-circle-fill"
          variant="success"
        />
        <div class="msg">
          <p
            v-if="result === 'limitOrder'"
            class="msg"
          >
            {{ $t('limitOrder') }}
          </p>
          <p
            v-if="!result"
            class="msg"
          >
            {{ $t('failOrder') }}
          </p>
          <p
            v-if="origin !== sources.standalone"
            class="msg"
          >
            {{
              $t('successOrder', {
                value: formatPrice(totalValue),
              })
            }}
          </p>
        </div>
      </div>
      <div
        v-if="loading"
        class="loading"
      >
        <ImgGeneral
          :alt="$t('order.completing.image-alt')"
          class="mb-1"
          :data-src="assetsPath + 'img/coke-heart-loading.svg'"
        />
        <h2 class="fs-18 fw-600">
          {{ $t('order.completing.title') }}
        </h2>
        <p class="fs-14 fw-200 mx-auto text-msg">
          {{ $t('order.completing.text') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ProductMixin from '@/components/mixins/ProductMixin';
import Breadcrumb from '@/components/shared/Breadcrumb.vue';
import ImgGeneral from '@/components/shared/ImgGeneral';
import apiCreateOrder from '@/api/order/orderCreate';
import apiEditOrder from '@/api/order/orderEdit';
import Analytics from '@/utils/analytics';
import { getStorageBottler, getAssetsPath } from '@/utils/config';
import { redirectBackToWhatsApp, getOrigin } from '@/utils/pages/index';
import router from '@/router';
import { SOURCES } from '@/constants/config';

export default {
  components: {
    ImgGeneral,
    Breadcrumb,
  },
  mixins: [ProductMixin],
  data() {
    return {
      loading: true,
      products: [],
      result: undefined,
      origin: getOrigin(),
      sources: SOURCES,
      totalValue: undefined,
      showDeliveryOptionScheduledFlag: undefined,
      showDeliveryOptionRecurrentFlag: undefined,
      scheduledDeliveryDate: undefined,
      assetsPath: getAssetsPath(),
      errorMsg: '',
      showBottlerErrorsMsg: undefined,
      bottlerStorage: getStorageBottler(),
    };
  },
  computed: {
    ...mapGetters({
      cart: 'getCartItems',
      glassBottlesAccepted: 'getGlassBottlesTracking',
      creditLimit: 'getCreditLimitTracking',
      client: 'getClient',
      orderType: 'getOrderType',
      referenceOrderNumber: 'getReferenceOrderNumber',
      referenceOrderType: 'getReferenceOrderType',
    }),
    ...mapActions({
      getOrders: 'getOrders',
    }),
  },
  beforeCreate() {
    this.loading = true;
  },
  async created() {
    this.showDeliveryOptionScheduledFlag =
      this.bottlerStorage?.showDeliveryOptionScheduled;
    this.showDeliveryOptionRecurrentFlag =
      this.bottlerStorage?.showDeliveryOptionRecurrent;
    this.showBottlerErrorsMsg = this.bottlerStorage?.showBottlerErrorsMsg;

    if (
      this.showDeliveryOptionScheduledFlag &&
      this.showDeliveryOptionRecurrentFlag
    ) {
      this.scheduledDeliveryDate = this.$store.getters.getSelectDate;
    }
    if (!this.$route?.params.finishOrderCorrectly) {
      router.navigateToPage('/home');
    } else {
      await this.finishOrder();
      if (!this.result) {
        router.navigateToPage('error', { errorMessage: this.errorMsg });
        return;
      }
      this.loading = false;
    }
  },
  methods: {
    async finishOrder() {
      const products = this.buildProductBody();
      if (!products.length) {
        this.loading = false;
        router.navigateToPage('/cart');
        return false;
      }
      const requireNPSOnBot = !this.bottlerStorage?.keepLoggedAfterCheckout;

      let orderInformation = {
        products,
        scheduledDeliveryDate: this.scheduledDeliveryDate,
        DeliveryDate: this.client.deliveryDate,
        orderType: this.orderType,
        userFlow: {
          glassBottlesAccepted: this.glassBottlesAccepted,
          creditLimit: this.creditLimit,
        },
        requireNPSOnBot,
      };

      if (this.referenceOrderNumber && this.referenceOrderType === 'Reorder') {
        orderInformation = {
          ...orderInformation,
          // if order is repeat or edit, reference order number included here as YourReferenceID prop
          referenceId: this.referenceOrderNumber,
          // if order is repeat or edit, add order type here as "Edit" || "Reorder" in OrderInformation prop
          orderInformation: this.referenceOrderType,
        };
      }

      if (this.referenceOrderType === 'Edit') {
        // EDIT ORDER API CALL
        orderInformation = {
          deliveryDate: this.client.deliveryDate,
          referenceId: this.referenceOrderNumber,
          products,
        };
        try {
          this.result = await apiEditOrder.getOrderEdit(orderInformation);
        } catch (error) {
          this.errorMsg = this.showBottlerErrorsMsg
            ? error?.response?.data?.message
            : '';
          return false;
        }
      } else {
        // CREATE ORDER API CALL
        try {
          this.result = await apiCreateOrder.getOrderCreate(orderInformation);
        } catch (error) {
          this.errorMsg = this.showBottlerErrorsMsg
            ? error?.response?.data?.message
            : '';
          return false;
        }
      }

      if (!this.result) {
        return false;
      }

      this.ecommerceAddItem(products);

      this.totalValue = this.result.totalValue || this.result[0].totalValue;

      this.$store.dispatch('getOrders');

      this.$store.dispatch('getCredits');

      this.$store.commit('resetState');
      if (
        this.bottlerStorage?.keepLoggedAfterCheckout === true ||
        this.origin === this.sources.standalone
      ) {
        this.redirectToOrderComplete();
      } else {
        await this.redirectToWhatsapp();
      }

      return true;
    },
    async redirectToWhatsapp() {
      await this.$store.dispatch('auth/logout').finally(() => {
        redirectBackToWhatsApp({
          whatsAppNumber: this.bottlerStorage?.whatsAppNumber,
        });
        localStorage.setItem('bottler', undefined);
      });
    },
    redirectToOrderComplete() {
      const resultObject = this.result.orderFormatted || this.result;
      const orderDetails = this.getOrderDetails(resultObject);
      router.navigateToPage('orderComplete', {
        orderNumber: orderDetails.orderNumber,
        orderPrice: orderDetails.orderPrice,
        deliveryDate: orderDetails.deliveryDate,
      });
    },
    ecommerceAddItem() {
      let index = 0;
      const filteredCart = this.cart.map((item) => ({
        item_id: item.product.sku,
        item_name: item.product.description,
        affiliation: '',
        coupon: '',
        creative_name: '',
        creative_slot: '',
        discount: 0,
        index: index++,
        item_brand: item.product.brand,
        item_category: item.product.category,
        item_category2: '',
        item_category3: '',
        item_category4: '',
        item_category5: '',
        item_list_id: '',
        item_list_name: '',
        item_variant: '',
        location_id: '',
        price: parseFloat(item.product.price),
        promotion_id: item.product.combo,
        promotion_name: '',
        quantity: item.quantity,
      }));
      Analytics.purchase(filteredCart, {
        transaction_id: this.result.orderNumber || this.result[0].orderNumber,
        value: this.result.totalValue || this.result[0].totalValue,
        currency: this.$t('currency'),
        coupon: this.orderType,
        shipping: 0,
        tax: 0,
      });
    },
    buildProductBody() {
      const products = [];
      this.cart.forEach((c) => {
        if (c.quantity > 0) {
          products.push({
            sku: c.product.sku,
            quantity: c.quantity,
            combo: c.product.combo,
            familyId: c.product.familyId,
            price: c.product.price,
            description: c.product.description,
            alcoholic: c.product.alcoholic,
            brand: c.product.brand,
            type: c.product.category,
            pricePromotions: c.product.pricePromotions,
          });
        }
      });

      return products;
    },
    getScheduledOrderDetails(orderObject) {
      const recurrentOrderDetails = {
        orderNumber: [],
        orderPrice: this.formatPrice(orderObject[0].totalValue),
        deliveryDate: [],
      };

      orderObject.reduce((finalOrderDetails, currentOrder) => {
        const { orderNumber, formattedDeliveryDate, deliveryDate } =
          currentOrder;

        finalOrderDetails.orderNumber.push(orderNumber);
        finalOrderDetails.deliveryDate.push(
          formattedDeliveryDate || deliveryDate
        );

        return finalOrderDetails;
      }, recurrentOrderDetails);

      return recurrentOrderDetails;
    },
    getSimpleOrderDetails(orderObject) {
      const { orderNumber } = orderObject;
      const deliveryDate =
        orderObject.formattedDeliveryDate || orderObject.deliveryDate;
      const orderPrice = this.formatPrice(orderObject.totalValue);

      return { orderNumber, deliveryDate, orderPrice };
    },
    getOrderDetails(orderObject) {
      if (Array.isArray(orderObject)) {
        return this.getScheduledOrderDetails(orderObject);
      }

      return this.getSimpleOrderDetails(orderObject);
    },
  },
};
</script>

<style scoped>
.loading {
  margin: 0;
}
.text-msg {
  max-width: 260px;
}
</style>
