import { render, staticRenderFns } from "./Monitor.vue?vue&type=template&id=67f91464&scoped=true"
import script from "./Monitor.vue?vue&type=script&lang=js"
export * from "./Monitor.vue?vue&type=script&lang=js"
import style0 from "@/assets/styles/monitor.css?vue&type=style&index=0&id=67f91464&prod&%2C=true&scoped=true&lang=css&external"
import style1 from "@/assets/styles/deliverydates.css?vue&type=style&index=1&id=67f91464&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67f91464",
  null
  
)

export default component.exports