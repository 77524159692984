<template>
  <div class="page__content">
    <Breadcrumb />
    <div class="container max-section-width">
      <div
        id="products"
        class="row"
      >
        <div
          v-if="loadingProducts"
          class="spinner-center"
        >
          <div
            class="spinner-border"
            role="status"
          >
            <div>
              <span class="sr-only center">Loading...</span>
            </div>
          </div>
        </div>

        <ul
          v-else
          class="col list-unstyled d-flex flex-wrap justify-content-center pl-1 pr-1"
        >
          <li
            v-for="combo in combos"
            :key="combo.id"
            class="product__box"
          >
            <Combo :combo="combo" />
          </li>
          <li
            v-for="product in products"
            :key="product.product.sku"
            class="product__box"
          >
            <Promotion
              :product="product"
              @add-to-cart-p="addItemToCart"
            />
          </li>
        </ul>
      </div>
      <NoResponse v-if="!products && !combos" />
      <NotFound
        v-if="!products.length > 0 && !combos.length > 0 && !loadingProducts"
        msg="notFound.promotions"
      />
    </div>
    <Monitor />
  </div>
</template>

<script>
/* eslint-disable func-names */
import { mapActions, mapGetters } from 'vuex';
import Promotion from '@/components/product/Promotion.vue';
import Combo from '@/components/combo/Combo.vue';
import Breadcrumb from '@/components/shared/Breadcrumb.vue';
import Monitor from '@/components/Monitor.vue';
import NoResponse from '@/components/NoResponse.vue';
import NotFound from '@/components/NotFound.vue';
import { registerService } from '@/utils/pages';
import { SERVICES } from '@/constants/feature';

export default {
  components: {
    Promotion,
    Combo,
    Breadcrumb,
    Monitor,
    NoResponse,
    NotFound,
  },
  data() {
    return {
      loadingProducts: true,
      comboLoaded: false,
      productsLoaded: false,
    };
  },
  computed: {
    ...mapGetters({
      products: 'getSearchPromotion',
      combos: 'getCombos',
      profileLoading: 'getProfileLoading',
    }),
  },
  watch: {
    products() {
      this.$forceUpdate();
      this.productsLoaded = true;
      if (this.comboLoaded) {
        this.loadingProducts = false;
      }
    },
    combos() {
      this.$forceUpdate();
      this.comboLoaded = true;
      if (this.productsLoaded) {
        this.loadingProducts = false;
      }
    },
    profileLoading() {
      if (this.productsLoaded && this.comboLoaded) {
        this.loadingProducts = false;
      }
    },
  },
  created() {
    registerService({ service: SERVICES.promotion });
    if (this.products.length > 0 || this.profileLoading === false) {
      this.loadingProducts = false;
    }
  },
  methods: {
    ...mapActions(['addItemToCart']),
    enableOrderFlag(flag) {
      if (flag !== this.enableOrder) {
        this.enableOrder = flag;
      }
    },
  },
};
</script>
