<template>
  <div class="page__content">
    <Breadcrumb />
    <div class="container max-section-width">
      <div class="row">
        <div class="col fw-200 text-center max-w-700 mx-auto mt-3 mb-4">
          <p class="w-100 fs-12 fs-md-14">
            {{ $t('finance.featureDescription') }}
          </p>
        </div>
      </div>
      <ServiceCard
        :title="$t('finance.openInvoices.title')"
        :btn-message="$t('accessButton')"
        :url="'openInvoices'"
        :image-url="assetsPath + 'img/services/2a-via-boletos-icon.png'"
        :cb-click="onClickOpenInvoicesCard"
        :display-image="'sideways'"
      />
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/components/shared/Breadcrumb.vue';
import { MODULES } from '@/constants/feature';
import ServiceCard from '@/components/ServiceCard.vue';
import { getStorageBottler, getAssetsPath } from '@/utils/config';
import router from '@/router';

export default {
  components: {
    Breadcrumb,
    ServiceCard,
  },
  data() {
    return {
      appModules: MODULES,
      assetsPath: getAssetsPath(),
    };
  },
  created() {
    const bottlerStorage = getStorageBottler();
    this.bottler = bottlerStorage?.bottler.toLowerCase();
  },
  methods: {
    onClickOpenInvoicesCard() {
      router.navigateToPage('/finance/openInvoices');
    },
  },
};
</script>
