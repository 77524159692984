import { post } from '@/utils/httpClient';
import { getStorageBottler } from '@/utils/config';

async function getOrderCreate(data) {
  const bottlerStorage = getStorageBottler();
  const result = await post({
    url: 'order/create',
    data,
    useAzureAPIManagement: bottlerStorage?.useAzureAPIManagement,
  });

  if (result.status === 200 && result.data) {
    return result.data;
  }

  if (result === 'Error: Request failed with status code 406') {
    return 'limitOrder';
  }

  throw result;
}

export default { getOrderCreate };
