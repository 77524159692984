import apiCategory from '@/api/category';

export default {
  state: {
    selectedCategory: '',
    categories: [],
    error: '',
  },
  getters: {
    getCategory(state) {
      return state.selectedCategory;
    },
    getCategories(state) {
      return state.categories;
    },
    getCategoriesError(state) {
      return state.error;
    },
  },
  mutations: {
    setCategory(state, payload) {
      // eslint-disable-next-line no-param-reassign
      state.selectedCategory = payload;
    },
    setCategories(state, payload) {
      // eslint-disable-next-line no-param-reassign
      state.categories = payload;
    },
    setError(state, payload) {
      // eslint-disable-next-line no-param-reassign
      state.error = payload;
    },
  },
  actions: {
    setCategory({ commit }, category) {
      commit('setCategory', category);
    },
    async getCategories({ commit }) {
      try {
        const categories = await apiCategory.getCategories();
        commit('setError', '');
        commit('setCategories', categories);
      } catch (error) {
        commit('setCategories', []);
        commit('setError', error);
      }
    },
    async getCategoriesStockist({ commit }) {
      try {
        const categories = await apiCategory.getCategoriesStockist();
        commit('setError', '');
        commit('setCategories', categories);
      } catch (error) {
        commit('setCategories', []);
        commit('setError', error);
      }
    },
  },
};
