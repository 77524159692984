export const isSunday = (date) => {
  // Get the day of the week (0-6, where Sunday is 0 and Saturday is 6)
  const dayOfWeek = date.getDay();

  // Check if it's Sunday (0)
  return dayOfWeek === 0;
};

export const checkDateOverdue = (inputDate) => {
  const today = new Date();
  const targetDate = new Date(inputDate);

  // add one day to inputDate
  targetDate.setDate(targetDate.getDate() + 1);

  // Check if the target date is greater than or equal to today
  return today >= targetDate;
};

export const truncateString = (str, maxLength, ellipsis = '...') => {
  if (str.length <= maxLength) {
    return str;
  }
  return str.slice(0, maxLength) + ellipsis;
};

export const isAPublicHoliday = (targetDate) => {
  const publicHolidays = [
    '2024-01-01',
    '2024-01-02',
    '2024-05-01',
    '2024-07-28',
  ].map((dateStr) => new Date(dateStr));
  return publicHolidays.some(
    (date) => date.getTime() === new Date(targetDate).getTime()
  );
};

export const formatDateYYYYMMDDtoDDMMYYYY = (inputDate) => {
  const [year, month, day] = inputDate.split('-');
  return `${day}/${month}/${year}`;
};

export function getTodayDate() {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
  const yyyy = today.getFullYear();

  return `${dd}/${mm}/${yyyy}`;
}

export function formatDateToDDMMYYYY(dateString) {
  const [year, month, day] = dateString.split('-');
  return `${day}/${month}/${year}`;
}

export default {
  isSunday,
  isAPublicHoliday,
  checkDateOverdue,
  truncateString,
  getTodayDate,
  formatDateYYYYMMDDtoDDMMYYYY,
  formatDateToDDMMYYYY,
};
