var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page__content"},[_c('Breadcrumb'),_c('div',{staticClass:"max-section-width"},[(_vm.cart.length)?_c('div',{staticClass:"row"},[(
          !_vm.bottlerStorage.comboTypeQuantityRange ||
          _vm.bottlerStorage.comboTypeQuantityRange === false
        )?_c('div',{staticClass:"col col-md-10 mx-auto",attrs:{"id":"comboCart"}},[_c('ComboFamilyCart',{attrs:{"items":_vm.cart},on:{"remove":function($event){return _vm.removeComboFromCart($event)},"edit":function($event){return _vm.editComboFromCart($event)}}})],1):_vm._e(),(_vm.bottlerStorage.comboTypeQuantityRange === true)?_c('div',{staticClass:"mt-4 col col-md-10 mx-auto",attrs:{"id":"comboCart"}},[_c('ComboQuantityCart',{attrs:{"full-cart-items":_vm.cart},on:{"remove":function($event){return _vm.removeQuantityComboFromCart($event)},"edit":function($event){return _vm.editQuantityComboFromCart($event)}}})],1):_vm._e()]):_vm._e(),(_vm.cart.length)?_c('div',{staticClass:"d-flex mt-4",attrs:{"id":"cart"}},[_c('Cart',{attrs:{"cart":_vm.cart,"enable-order":_vm.enableOrder,"is-cart-item-loading":!_vm.isPriceFromApiReady},on:{"cbUpdatePrice":function($event){return _vm.definePrice()}}})],1):_vm._e(),(_vm.cart.length)?_c('div',[(_vm.cart.length)?_c('p',{staticClass:"fs-10 remove-container",on:{"click":_vm.clearCart}},[_c('ImgGeneral',{staticClass:"remove-icon",attrs:{"is-one-px-placeholder":true,"data-src":`${_vm.assetsPath}img/trash-red.svg`}}),_c('span',{staticClass:"remove-text"},[_vm._v(" "+_vm._s(_vm.$t('combo.removeAllItems')))])],1):_vm._e()]):_vm._e(),(!_vm.cart.length && _vm.loading === false)?_c('div',{staticClass:"emptyCart pt-5"},[_c('div',{staticClass:"emptyCart__item"},[_c('ImgGeneral',{attrs:{"alt":_vm.$t('cartLabel'),"url":_vm.assetsPath + 'img/icon/red-cart.svg'}})],1),_c('div',{staticClass:"emptyCart__item fs-18"},[_vm._v(" "+_vm._s(_vm.$t('cartPage.emptyCart'))+" ")]),_c('div',[_c('router-link',{staticClass:"btn product-button mx-auto btn-primary",attrs:{"to":'/category',"href":'/category'}},[_vm._v(" "+_vm._s(_vm.$t('redirectToProduct'))+" ")]),_c('div')],1)]):_vm._e(),_c('div',{staticClass:"col-md-10 mx-auto p-0"},[(
          _vm.isDeliveryAddressConfirmationActive &&
          _vm.getClientFormattedAddress() &&
          _vm.cart.length
        )?_c('div',{attrs:{"id":"address"}},[_c('Alert',{attrs:{"header":_vm.$t('cartPage.deliveryAddressValidation'),"sub-header":_vm.$t('cartPage.clientAddress', {
              address: _vm.getClientFormattedAddress(),
              contactPhone: _vm.bottlerStorage.contactPhone,
            }),"confirm-message":_vm.$t('cartPage.deliveryAddressConfirm'),"show-pending-confirm":_vm.showPendingConfirm},on:{"update":function($event){return _vm.isDeliveryAddressConfirmed($event)}}})],1):_vm._e(),(
          _vm.isFinishOrderOverLimitAuthorized &&
          _vm.isPaymentLimitActive &&
          _vm.isOrderValueOverlimit() &&
          _vm.cart.length
        )?_c('div',{staticClass:"creditLimit"},[_c('Alert',{attrs:{"header":_vm.creditFormatHeaderMessage(_vm.$t('cartPage.creditLimitHeader')),"sub-header":_vm.$t('cartPage.creditOverLimitAuthorizedMessage'),"confirm-message":_vm.$t('cartPage.confirmCreditLimit'),"show-pending-confirm":_vm.showPendingConfirm},on:{"update":function($event){return _vm.isCreditLimitAccepted($event)}}})],1):_vm._e(),(_vm.isNotAllowedOverLimit)?_c('div',[_c('Alert',{attrs:{"header":_vm.creditFormatHeaderMessage(_vm.$t('cartPage.creditLimitHeader')),"sub-header":_vm.$t('cartPage.creditLimitMessage')}})],1):_vm._e(),(
          _vm.isGlassBottlesRequired() &&
          !_vm.isReturnablePackagingsEnough() &&
          !_vm.isReturnablePackagingsQuantityOverLimit()
        )?_c('div',{staticClass:"GlassBottles"},[_c('Alert',{attrs:{"header":_vm.$t('cartPage.glassBottlesRequired'),"sub-header-items":_vm.getGlassBottlesMessage(),"confirm-message":_vm.$t('cartPage.glassBottlesConfirm'),"show-pending-confirm":_vm.showPendingConfirm,"button-type":_vm.buttonType,"button-header":_vm.$t(_vm.buttonHeader),"button-text":_vm.$t(_vm.buttonText),"button-action":_vm.getGlassBottleButtonAction,"button-style":_vm.buttonStyle},on:{"update":function($event){return _vm.isGlassBottlesAccepted($event)}}})],1):_vm._e(),(_vm.isReturnablePackagingsQuantityOverLimit())?_c('div',{staticClass:"GlassBottles"},[_c('Alert',{attrs:{"header":_vm.$t('cartPage.returnablePackagingsOverLimitHeader'),"sub-header-items":_vm.getReturnablePackagingsOverLimitDescription(),"show-pending-confirm":_vm.showPendingConfirm,"button-type":'addGlassBottles',"button-header":_vm.$t('cartPage.returnablePackagingsOverLimitButtonHeader'),"button-text":_vm.$t('cartPage.returnablePackagingsOverLimitButton'),"button-action":_vm.adjustReturnablePackagingsOnCart,"button-style":'primary'},on:{"update":function($event){return _vm.isGlassBottlesAccepted($event)}}})],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPendingConfirm && _vm.cart.length),expression:"showPendingConfirm && cart.length"}],staticClass:"pending--confirm fs-14"},[_vm._v(" "+_vm._s(_vm.$t('cartPage.pendingConfirm'))+" ")])]),(_vm.showDeliveryOptionFlag && _vm.cart.length)?_c('div',[(_vm.isNotificationEnabledLoading)?_c('div',{staticClass:"loading"},[_c('Skeleton',{staticClass:"mb-3",attrs:{"height":'120px',"type":'title-box'}})],1):_vm._e(),(_vm.showNotificationOption && !_vm.isNotificationEnabledLoading)?_c('div',{staticClass:"col-md-10 mx-auto p-0"},[_c('CheckNotification',{attrs:{"title":_vm.$t('notifications.scheduledOrderTitle'),"description":_vm.$t('notifications.scheduledOrderCheckCart', {
              bottler: _vm.bottlerName,
            }),"on-toggle":_vm.onAcceptReceiveNotification},on:{"update-optin":_vm.startNotificationLoading}})],1):_vm._e(),(!_vm.showNotificationOption && !_vm.isNotificationEnabledLoading)?_c('div',{staticClass:"scheduled col-md-10 mx-auto p-0"},[_c('DeliveryOption',{attrs:{"has-product-with-qty-limit":_vm.hasProductWithQtyLimit},on:{"startLoadScheduleOrderDates":function($event){_vm.isCheckoutButtonEnabled = false},"finishLoadScheduleOrderDates":function($event){_vm.isCheckoutButtonEnabled = true},"finishGetAvailableDays":function($event){_vm.isAvailableDatesReady = true}}})],1):_vm._e()]):_vm._e(),(_vm.showNotificationOption && !_vm.showDeliveryOptionFlag && _vm.cart.length)?_c('div',{staticClass:"scheduled col-md-10 mx-auto p-0"},[_c('CheckNotification',{attrs:{"title":_vm.$t('notifications.title'),"description":_vm.$t('notifications.checkCart', {
            bottler: _vm.bottlerName,
            value: _vm.botSourceAppName,
          })}})],1):_vm._e()]),(_vm.isMonitorActive())?_c('Monitor',{attrs:{"active":_vm.isCheckoutButtonActive,"total-api-price":_vm.totalApiPrice,"price-before-discount":_vm.priceBeforeDiscount,"discount":_vm.discount,"taxes":_vm.taxes},on:{"checkout":function($event){return _vm.checkoutOrder()}}}):_vm._e(),_c('DeliveryDateChildModal',{attrs:{"modal-id":"cartmodal","is-visible":_vm.showDeliveryDateChildModal},on:{"selectedDataObj":_vm.handleModalSelectedDate}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }