import apiBrands from '@/api/brand';

export default {
  state: {
    selectedBrand: '',
    brands: [],
    error: '',
  },
  getters: {
    getBrand(state) {
      return state.selectedBrand;
    },
    getBrands(state) {
      return state.brands;
    },
    getBrandsError(state) {
      return state.error;
    },
  },
  mutations: {
    setBrand(state, payload) {
      // eslint-disable-next-line no-param-reassign
      state.selectedBrand = payload;
    },
    setBrands(state, payload) {
      // eslint-disable-next-line no-param-reassign
      state.brands = payload;
    },
    setError(state, payload) {
      // eslint-disable-next-line no-param-reassign
      state.error = payload;
    },
  },
  actions: {
    setBrand({ commit }, brand) {
      commit('setBrand', brand);
    },
    async getBrands({ commit }) {
      try {
        const category = this.state?.category?.selectedCategory?.toLowerCase();
        const brands = await apiBrands.getBrands(category);
        commit('setError', '');
        commit('setBrands', brands);
      } catch (error) {
        commit('setBrands', []);
        commit('setError', error);
      }
    },
  },
};
