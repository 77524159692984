<template>
  <div
    id="btn-cart"
    :class="[
      'btn-cart-wrapper text-center position-relative d-flex justify-content-center w-100',
      { submitting: isSubmitting },
    ]"
  >
    <button
      class="btn-cart btn btn-primary w-100"
      type="button"
      :aria-label="$t('ariaLabel.btnAddToCart')"
      :disabled="disabled"
      @click="addToCart($event)"
    >
      <span class="label-add position-relative d-block">
        {{ $t(labelI18nKey) }}
      </span>
      <span class="label-added position-absolute">
        {{ $t('addedToCart') }}
      </span>
      <div class="loading">
        <b-spinner
          class="spinner-loading"
          label="Loading..."
        />
      </div>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    labelI18nKey: {
      type: String,
      default: 'addToCart',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    enableUserInteraction: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isSubmitting: false,
    };
  },
  watch: {
    enableUserInteraction(newVal) {
      if (newVal) {
        this.isSubmitting = true;
      } else {
        this.isSubmitting = false;
      }
    },
  },
  methods: {
    addToCart() {
      this.$emit('click-add-to-cart', {});
    },
  },
};
</script>

<style scoped>
.spinner-loading {
  width: 1rem;
  height: 1rem;
}

.label-added {
  top: 50%;
  left: 50%;
  display: none;
  transform: translate(-50%, -50%);
}

.label-add {
  overflow: hidden;
  white-space: nowrap;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  color: var(--dark-grey);
  visibility: hidden;
  opacity: 0;
  transform: translate(-50%, -50%);
}

.btn-cart-wrapper.submitting .label-add {
  animation: fadeout 1s 1 forwards;
}

.btn-cart-wrapper.submitting .btn-cart {
  background-color: transparent;
  pointer-events: none;
  animation: smallify 1s 1 forwards;
}

.btn-cart-wrapper.submitted .btn-cart {
  background-color: var(--secondary);
  pointer-events: none;
}

.btn-cart-wrapper.submitting .btn-cart:hover,
.btn-cart-wrapper.submitting .btn-cart:active,
.btn-cart-wrapper.submitting .btn-cart:focus {
  background-color: transparent !important;
}

.btn-cart-wrapper.submitting .loading {
  animation: fadein 1s 1 forwards;
}

.btn-cart-wrapper.submitted .loading {
  animation: fadeout 1s 1 forwards;
}

.btn-cart-wrapper.submitted .btn-cart:hover,
.btn-cart-wrapper.submitted .btn-cart:active,
.btn-cart-wrapper.submitted .btn-cart:focus {
  background-color: var(--secondary) !important;
}

.btn-cart-wrapper.submitted .label-add {
  visibility: hidden;
  opacity: 0;
  animation: fadeindelay 0.5s 1 forwards;
  animation-delay: 2s;
}

.btn-cart-wrapper.submitted .label-added {
  display: block;
}

@media screen and (prefers-reduced-motion: reduce) {
  .btn-cart-wrapper.submitting .label-add {
    opacity: 0;
    animation: none;
  }

  .btn-cart-wrapper.submitting .btn-cart {
    width: 40px;
    pointer-events: none;
    background-color: transparent !important;
    animation: none;
  }

  .btn-cart-wrapper.submitting .loading {
    visibility: visible;
    opacity: 1;
    animation: none;
  }

  .btn-cart-wrapper.submitted .loading {
    animation: none;
  }

  .btn-cart-wrapper.submitted .btn-cart {
    width: 9.7rem;
    pointer-events: none;
    background-color: var(--secondary) !important;
    animation: none;
  }

  .btn-cart-wrapper.submitted .label-add {
    visibility: hidden;
    opacity: 0;
    animation: none;
    animation-delay: 2s;
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}

@keyframes fadeinout {
  0% {
    opacity: 0;
  }
  50% {
    visibility: visible;
    opacity: 1;
  }
  90% {
    visibility: visible;
    opacity: 1;
  }
  99% {
    height: 100%;
  }
  100% {
    height: 0;
    visibility: hidden;
    opacity: 0;
  }
}

@keyframes fadeindelay {
  0% {
    visibility: hidden;
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}

@keyframes smallify {
  90% {
    width: 40px;
  }
  100% {
    width: 40px;
  }
}
</style>
