// use for delegate custom events across any components
// trigger: eventBus.$emit('event-name')
// listen: eventBus.$on('event-name', callbackfn)
// ref: https://vuejsdevelopers.com/2020/01/06/handling-events-vue-js/
import Vue from 'vue';

export const eventBus = new Vue();

export const events = {
  APP_IS_SHOWN: 'app-is-shown',
  PRODUCT_ADD_TO_CART: 'product-added-cart',
  CRITICAL_ERROR: 'critical-error',
};
