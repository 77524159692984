<template>
  <div
    v-if="quantity > 0 && show"
    id="monitor"
    class="max-w-1278 text-center mx-auto w-100 position-sticky bg-dark-grey"
  >
    <p
      v-if="type === 'quantity' && !enable"
      class="fs-12 text-light fw-200 mt-2 mb-2"
    >
      {{
        $t('combo.quantityToActivate', {
          quantity: minimumProductsRequired - totalItemsAddedToCombo,
        })
      }}
    </p>
    <p
      v-if="type === 'quantity' && enable"
      class="fs-12 text-light fw-200 max-w-500 mx-auto p-2 mb-0"
    >
      <span v-if="!nextRangeQuantity">
        {{ $t('combo.finalizeCombo') }}
      </span>
      <span v-if="nextRangeQuantity">
        {{ $t('combo.monitorRemainingQuantityNextWave_pt1') }}
        <u class="fs-14 text-underline text-uppercase">
          {{ getNextQuantityRange() }}
          {{ $t('combo.unityLabel') }}
        </u>
        {{ $t('combo.monitorRemainingQuantityNextWave_pt2') }}
      </span>
    </p>
    <div
      class="info-cart p-3 d-flex justify-content-center align-items-center mx-auto"
      :class="{ complete: enable }"
    >
      <div
        class="fw-200 text-white text-left mr-2 w-50"
        :class="{ invisible: quantity === 0 }"
      >
        <p class="text-break quantity fs-12 text-underline mb-2">
          <u>
            {{ quantity }}
            {{ quantity > 1 ? $t('items') : $t('item') }}
          </u>
        </p>
        <div
          id="total-price"
          class="fs-18 text-light"
        >
          <span v-if="type === 'quantity'">
            {{ formatPrice(quantityComboTotalPrice) }}
          </span>
          <span v-if="type !== 'quantity'">
            {{ formatPrice(totalPrice) }}
          </span>
        </div>
      </div>
      <div class="text-left w-50">
        <b-progress
          v-if="!enable"
          animated
          :value="totalItemsAddedToCombo"
          :max="minimumProductsRequired"
          class="mb-3"
          :variant="enable ? 'success' : 'warning'"
        />
        <b-button
          class="btn w-100"
          :class="{ 'call-to-action': enable }"
          :disabled="!enable"
          variant="primary"
          @click="addCombo()"
        >
          {{ $t('combo.confirmCombo') }}
        </b-button>
        <div v-if="enable">
          <p
            v-if="type === ''"
            class="fs-10 text-light m-1 fw-600"
          >
            {{ $t('combo.finalizeCombo') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ProductMixin from '@/components/mixins/ProductMixin';

export default {
  mixins: [ProductMixin],
  props: {
    enableCombo: {
      type: Boolean,
    },
    totalItemsAddedToCombo: {
      type: Number,
      default: 0,
    },
    minimumProductsRequired: {
      type: Number,
      default: 1,
    },
    comboCart: {
      type: Array,
      default() {
        return [];
      },
    },
    type: {
      type: String,
      default: '',
    },
    pricePromotions: {
      type: Array,
      default() {
        return [];
      },
    },
    show: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      enable: false,
      itemString: '',
      active: false,
      nextRangeQuantity: 0,
    };
  },
  computed: {
    ...mapGetters({
      products: 'getCartItems',
      quantity: 'getComboItemsQuantity',
      glassBottles: 'getProductsWithReturnablePackaging',
      totalPrice: 'getComboTotalPrice',
      quantityComboTotalPrice: 'getQuantityComboTypeTotalPrice',
    }),
  },
  watch: {
    enableCombo: {
      deep: true,
      handler() {
        this.enable = this.enableCombo;
      },
    },
    totalItemsAddedToCombo: {
      handler() {
        this.getNextQuantityRange();
        this.enableAddCombo();
      },
    },
    minimumProductsRequired: {
      handler() {
        this.enableAddCombo();
      },
    },
    pricePromotions: {
      handler() {
        this.getNextQuantityRange();
      },
    },
  },
  mounted() {
    this.enable = this.enableCombo;
    this.getNextQuantityRange();
  },
  methods: {
    addCombo() {
      this.$emit('addComboToCart');
    },
    enableAddCombo() {
      this.enable = this.totalItemsAddedToCombo >= this.minimumProductsRequired;
    },
    getNextQuantityRange() {
      this.nextRangeQuantity = this.pricePromotions.find(
        (priceRange) => priceRange.minimumQuantity > this.totalItemsAddedToCombo
      );
      return (
        this.nextRangeQuantity?.minimumQuantity - this.totalItemsAddedToCombo
      );
    },
  },
};
</script>

<style src="@/assets/styles/monitor.css" scoped></style>
