<template>
  <div>
    <CartModalPopup />
    <router-view />
  </div>
</template>

<script>
import CartModalPopup from '@/components/shared/CartModal.vue';

export default {
  components: {
    CartModalPopup,
  },
};
</script>
