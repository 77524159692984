<template>
  <div
    :class="{ 'menu-opened': menuOpened }"
    class="header sticky-top d-block w-100"
    @click.stop="closeMenu()"
  >
    <div class="header-fill">
      <div class="align-items-center d-flex w-100 header-inner">
        <button
          v-if="isHamburguerVisible"
          class="hamburger"
          :class="{ 'hamburger-dot': hasHamburgerDot && !menuOpened }"
          :aria-label="$t('ariaLabel.hamburgerMenu')"
          @click.stop="menuOpened ? closeMenu() : openMenu()"
        >
          <span class="bar bar1" />
          <span class="bar bar2" />
          <span class="bar bar3" />
        </button>
        <h1
          v-if="!bottlerLogo"
          class="align-items-center d-flex fs-10 m-0 h-100 text-light fw-200 flex-grow-1 ml-3 clientName"
        >
          <router-link
            class="logo text-light"
            :aria-label="$t('backToHome')"
            :to="'/'"
          >
            {{ clientName ? clientNameTruncated : 'Thainamthip Winnie' }}
          </router-link>
        </h1>
        <router-link
          v-if="bottlerLogo"
          class="h-100 ml-3"
          :aria-label="$t('backToHome')"
          :to="'/'"
        >
          <ImgGeneral
            :alt="storageBottler.headerTitleBottler"
            class="logo h-100"
            :data-src="bottlerLogo"
          />
        </router-link>
        <HeaderIcons
          v-if="isAuthenticated"
          v-show="!menuOpened"
          class="header-icons d-flex justify-content-end flex-grow-1"
          :menu-options="menuOptions"
          @toggle-search="toggleSearch"
        />
      </div>
      <div class="menu-wrapper">
        <Menu
          :level="'secondary'"
          class="menu-collapsible flex-wrap pt-0"
          @menuItemsCount="checkMenuEnabled"
        >
          <template v-slot:footer>
            <Footer class="mt-0" />
          </template>
        </Menu>
      </div>
    </div>
    <Search
      v-show="showSearch && !menuOpened"
      ref="search"
      @onSearchComplete="onSearchComplete()"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getStorageBottler, getSessionData } from '@/utils/config';
import Menu from '@/components/menu/Menu.vue';
import Search from '@/components/Search.vue';
import Footer from '@/components/Footer.vue';
import HeaderIcons from '@/components/header/HeaderIcons';
import ImgGeneral from '@/components/shared/ImgGeneral';
import Analytics from '@/utils/analytics';
import analyticsEvents from '@/utils/events/analytics';
import Common from '@/utils/common';

export default {
  components: {
    Search,
    Menu,
    Footer,
    ImgGeneral,
    HeaderIcons,
  },
  props: {
    menu: {
      type: Boolean,
      default: true,
    },
    clientName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      hasHamburgerDot: false,
      menuOpened: false,
      menuOptions: {},
      bottlerLogo: '',
      hasItems: false,
      showSearch: false,
      storageBottler: getStorageBottler(),
      isAuthenticated: getSessionData('isAuthenticated'),
    };
  },
  computed: {
    ...mapGetters({
      isOverdue: 'getIsOverdue',
    }),
    isHamburguerVisible() {
      return this.menu !== false && this.hasItems;
    },
    clientNameTruncated() {
      return Common.truncateString(this.clientName, 25);
    },
  },
  watch: {
    isOverdue() {
      this.hasHamburgerDot = this.isOverdue;
    },
  },
  created() {
    this.menuOptions = this.storageBottler?.menu;
    this.bottlerLogo = this.storageBottler?.logo;
  },
  methods: {
    openMenu() {
      this.menuOpened = true;
      Analytics.logEvent(analyticsEvents.EVENTS.HAMBURGUER_MENU_OPEN);
    },
    closeMenu() {
      this.menuOpened = false;
    },
    checkMenuEnabled(value) {
      this.hasItems = value;
    },
    toggleSearch() {
      this.showSearch = !this.showSearch;
      const currentSearchString = this.$store.state.search.searchString;
      this.$nextTick(() => {
        this.$refs.search.$el.querySelector('input').focus();
        this.$store.commit('setSearchString', currentSearchString);
      });
    },
    onSearchComplete() {
      this.showSearch = false;
    },
  },
};
</script>

<style scoped>
.header {
  filter: drop-shadow(0 -4px 24px rgba(0, 0, 0, 0.25));
  z-index: 1030;
}

.header-fill {
  background-color: var(--dark-grey);
}

.header-inner {
  height: 44px;
}

.clientName {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.hamburger {
  border: 0;
  flex-basis: 25px;
  min-width: 25px;
  height: 20px;
  position: relative;
  background-color: transparent;
  padding: 0;
  margin-left: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}

.hamburger-dot::after {
  content: '';
  position: absolute;
  top: -3px;
  right: -3px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: red;
}

.hamburger:focus {
  outline: 1px dotted;
}

.menu-wrapper {
  display: none;
  background-color: var(--dark);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  padding: 70px 10px 0;
  width: 80%;
  max-width: 570px;
  transform: translateX(-100%);
  height: 100%;
}

.menu-opened {
  height: 100vh;
  width: 100vw;
  position: fixed;
  background-color: rgba(
    0,
    0,
    0,
    0.8
  ); /* using rgba since need opacity in this specific color */
}

.menu-opened .hamburger .bar1 {
  transform: rotate(-45deg) translate(-3px, -3px);
}

.menu-opened .hamburger .bar2 {
  opacity: 0;
}

.menu-opened .hamburger .bar3 {
  transform: rotate(45deg) translate(-2px, 1px);
}

.menu-opened .menu-wrapper {
  transform: translateX(0);
  display: block;
}

.bar {
  background-color: var(--light);
  width: 100%;
  height: 2px;
  transition: 0.4s;
  display: block;
  border-radius: 100px;
  transform-origin: top right;
}

.menu-collapsible {
  width: 100%;
  margin: 0;
  overflow-y: visible;
}

.header-icons {
  margin-right: 10px;
}

:deep(.menu-item) {
  background-color: transparent;
  box-shadow: none;
  width: 100%;
  margin: 0;
  height: auto;
  color: var(--light);
  flex-direction: row;
  border-radius: 0;
  flex-wrap: wrap;
}

:deep(.menu-item::after) {
  content: '';
  background-color: var(--light);
  height: 1px;
  width: 100%;
  margin: 12px 0;
  opacity: 0.7;
}

:deep(.line) {
  display: none;
}

:deep(.menu-item-icon) {
  width: 30px;
  height: 30px;
  margin: 0;
}

:deep(.menu-item-name) {
  font-size: var(--fs-12);
  margin-top: 0;
}

:deep(.menu-icon-wrapper) {
  border-radius: 100px;
  padding: 5px;
  margin-right: 10px;
}

.logo {
  cursor: pointer;
  max-width: 50vw; /* TODO (Evan): when Favorites is enabled, set to 40vw  */
  flex-grow: 0; /* Prevent stretching */
  flex-shrink: 1; /* Allow shrinking if necessary */
  flex-basis: auto; /* Allow automatic sizing based on content */
  overflow: hidden; /* Hide any overflow */
  text-overflow: ellipsis; /* Display ellipsis (...) for overflowed text */
  white-space: nowrap; /* Prevent text from wrapping onto a new line */
}

@media screen and (prefers-reduced-motion: reduce) {
  .bar {
    transition: none;
  }
}
</style>
