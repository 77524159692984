<template>
  <table class="table text-left">
    <thead class="thead-dark">
      <tr>
        <th
          class="border-rounded-top-left table-col-smaller col-title"
          scope="col"
        >
          {{ $t('combo.range') }}
        </th>
        <th
          class="border-rounded-top-right col-title"
          scope="col"
        >
          {{ $t('combo.quantity') }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="priceRange in priceRangeFormattedMessages"
        :key="priceRange.id"
      >
        <th scope="row">
          {{ priceRange.id }}
        </th>
        <td>
          {{ priceRange.quantityRange }}
          {{ $t('combo.unityLabel') }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    pricePromotionsList: {
      type: Array,
      default() {
        return [];
      },
      required: true,
    },
  },
  data() {
    return {
      priceRangeFormattedMessages: [],
      pricePromotions: [],
    };
  },
  watch: {
    pricePromotionsList: {
      deep: true,
      handler() {
        this.buildPriceRangeTable();
      },
    },
  },
  mounted() {
    this.buildPriceRangeTable();
  },
  methods: {
    buildPriceRangeTable() {
      this.priceRangeFormattedMessages = [];
      this.pricePromotions = [...this.pricePromotionsList];
      this.pricePromotions.forEach((element, index) => {
        let quantityRange = `${this.$t('combo.aboveUnities')} ${
          element.minimumQuantity
        }`;
        const nextPriceRange = this.pricePromotions[index + 1];
        if (nextPriceRange) {
          quantityRange = `${element.minimumQuantity} a ${
            nextPriceRange.minimumQuantity - 1
          }`;
          if (element.minimumQuantity === nextPriceRange.minimumQuantity - 1) {
            quantityRange = element.minimumQuantity;
          }
        }
        this.priceRangeFormattedMessages.push({
          id: index + 1,
          quantityRange,
        });
      });
      this.$emit('update-price-promotions', this.pricePromotions);
    },
  },
};
</script>

<style scoped>
th,
td {
  font-weight: 200;
}
.col-title {
  font-weight: 400;
  border: 0;
}
.table-col-smaller {
  width: 30%;
}
</style>
