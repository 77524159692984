var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-3 d-flex",attrs:{"id":"product-item"}},[_c('div',{staticClass:"card-body d-flex mb-3 flex-wrap"},[_c('div',{staticClass:"align-self-start w-100"},[(_vm.isProductInCart)?_c('ProductTag',{attrs:{"label-title":_vm.$t('combo.tag'),"bg-color":'warning'}}):_vm._e()],1),_c('div',{staticClass:"w-50 text-center d-flex align-items-center"},[_c('ImgGeneral',{staticClass:"mw-100",attrs:{"alt-text":_vm.product.product.description,"url":_vm.product.product.imageUrl,"show-fallback-img":true}})],1),_c('div',{staticClass:"d-flex flex-wrap w-50"},[_c('h4',{staticClass:"fs-12 fw-300 w-100",attrs:{"translate":"no"}},[_vm._v(" "+_vm._s(_vm.product.product.brand)+" ")]),_c('div',{staticClass:"card-subprices mt-3 mb-4 w-100"},[(_vm.hasProductDiscountedPrices())?_c('ul',{staticClass:"list-unstyled fs-14 position-relative"},_vm._l((_vm.priceRangeCollection),function(priceRange,priceIndex){return _c('li',{key:priceRange.sku,staticClass:"text-primary price",class:{
              'price-active': _vm.isPriceLabelActive(
                priceRange,
                _vm.priceRangeCollection,
                priceIndex
              ),
            }},[_c('span',{staticClass:"position-relative zi-1"},[_vm._v(" "+_vm._s(_vm.formatPrice(Number(priceRange.price)))+" ")]),(_vm.getRangeFriendlyValue(priceRange.tableCode) > 0)?_c('span',{staticClass:"promotion-range-label fw-200 fs-10"},[_vm._v(" | "+_vm._s(_vm.$t('combo.range'))+" "+_vm._s(_vm.getRangeFriendlyValue(priceRange.tableCode))+" ")]):_vm._e(),_c('span',{staticClass:"mark-indicator ml-2"},[_c('img',{attrs:{"src":_vm.assetsPath + 'img/icon/hand-side-point.svg'}})])])}),0):_c('p',{staticClass:"text-primary fs-14"},[_vm._v(" "+_vm._s(_vm.formatPrice(_vm.product.product.price))+" ")])]),_c('div',{staticClass:"d-flex w-100"},[(_vm.showTag && _vm.isProductInCart)?_c('HelpButton',{staticClass:"mr-2",attrs:{"help-description":_vm.$t('combo.helpCombo'),"sku":String(_vm.product.product.sku)}}):_vm._e(),_c('p',{staticClass:"fs-12 fw-200 w-100"},[_vm._v(" "+_vm._s(_vm.product.product.description)+" ")])],1),(!_vm.isProductInCart)?_c('ProductQtyController',{attrs:{"quantity":_vm.product.quantity,"enable-order":true,"min-quantity-allowed":0},on:{"qty-updated":_vm.productQuantityUpdated}}):_vm._e(),(_vm.isProductInCart)?_c('div',[(_vm.isProductInCart.product.combo !== 0)?_c('router-link',{staticClass:"position-relative btn btn-outline d-block",attrs:{"to":`/combo-quantity/${_vm.isProductInCart.product.combo}`}},[_vm._v(" "+_vm._s(_vm.$t('accessButton'))+" ")]):_c('router-link',{staticClass:"position-relative btn btn-outline d-block",attrs:{"to":// eslint-disable-next-line max-len
          `/category/brands/products/${_vm.isProductInCart.product.category}/${_vm.isProductInCart.product.brand}`}},[_vm._v(" "+_vm._s(_vm.$t('accessButton'))+" ")])],1):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }