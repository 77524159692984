<template>
  <a
    href=""
    class="text-center"
    :class="storageBottler.useCategoryIconsRevamped ? '' : 'card-body'"
    :aria-label="$t('ariaLabel.brand')"
    @click="onClickBrand($event, brand)"
  >
    <ImgGeneral
      :alt="printAlt"
      :class="
        storageBottler.useCategoryIconsRevamped
          ? 'round-icon mw-100'
          : 'img-fluid'
      "
      :url="brand.imageUrl"
      :show-fallback-img="true"
      :show-loader-before-init="true"
    />
    <p
      v-if="storageBottler.useCategoryIconsRevamped"
      class="fs-12 fw-200 text-dark mt-2 mb-5"
    >
      {{ brand.name }}
    </p>
  </a>
</template>

<script>
import { getStorageBottler } from '@/utils/config';
import ImgGeneral from '@/components/shared/ImgGeneral';

export default {
  components: {
    ImgGeneral,
  },
  props: {
    brand: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      storageBottler: getStorageBottler(),
    };
  },
  computed: {
    printAlt() {
      return `${this.$t('brand')} ${this.brand.name.toLowerCase()}`;
    },
  },
  methods: {
    onClickBrand(evt, brand) {
      evt.preventDefault();
      this.$emit('set-brand', brand);
    },
  },
};
</script>
