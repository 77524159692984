<template>
  <div class="card-body text-mediumgrey mt-4">
    <div>
      <p class="fs-18">
        {{ $t('notifications.title') }}
      </p>
      <p class="fs-14 fw-200 pb-3">
        {{
          $t('notifications.info', {
            value: botSourceAppName,
          })
        }}
      </p>
    </div>
    <Skeleton
      v-if="loading"
      :height="'30px'"
      class="mb-3 w-25"
    />
    <RoundSwitch
      v-if="!loading"
      :state="isOptInActive"
      :text="'notifications.' + checkNotificationState()"
      @switched="registerOptInStatus"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import apiOptInRegister from '@/api/optin/optInRegister';
import RoundSwitch from '@/components/shared/RoundSwitch.vue';
import { registerService } from '@/utils/pages';
import Skeleton from '@/components/shared/Skeleton.vue';
import { SERVICES } from '@/constants/feature';
import { getBotSourceAppName } from '@/utils/config';

export default {
  components: {
    RoundSwitch,
    Skeleton,
  },
  data() {
    return {
      isOptInActive: false,
      status: 'inactive',
      loading: true,
      botSourceAppName: getBotSourceAppName(),
    };
  },
  computed: {
    ...mapGetters({
      optIn: 'getOptIn',
    }),
  },
  watch: {
    '$store.state.optIn.userOptIn': function onGetUserOptin() {
      this.isOptInActive = this.optIn;
      this.loading = false;
    },
  },
  async created() {
    await this.$store.dispatch('setOptIn');
    this.isOptInActive = this.optIn;
    this.loading = false;
  },
  methods: {
    async registerOptInStatus(userOptIn) {
      this.isOptInActive = !this.isOptInActive;
      await apiOptInRegister.register(userOptIn.toString());
      this.$store.dispatch('setOptIn');
      await registerService({
        service: SERVICES.notification,
        data: { userOptIn: this.isOptInActive },
      });
    },
    checkNotificationState() {
      if (this.isOptInActive) this.status = 'active';
      else this.status = 'inactive';
      return this.status;
    },
  },
};
</script>
