<template>
  <div
    v-if="quantity > 0"
    id="monitor"
    class="max-w-1278 text-center mx-auto w-100 position-sticky bg-dark-grey"
  >
    <div
      class="info-cart p-3 d-flex justify-content-center align-items-center mx-auto"
      :class="{ complete: enable }"
    >
      <!-- For scenarios where the product prices comes from client api (thainamtip simulate API) -->
      <div
        v-if="showApiPrice"
        class="fw-200 text-white text-left mr-2 w-50"
        :class="{ invisible: quantity === 0 }"
      >
        <b-spinner
          v-if="!displayedActive"
          class="mx-auto text-light"
          label="Loading..."
        />
        <div v-if="displayedActive">
          <p class="text-break quantity fs-12 text-underline mb-2">
            <u>
              {{ quantity }}
              {{ quantity > 1 ? $t('items') : $t('item') }}
            </u>
          </p>
          <div
            v-if="displayedPriceBeforeDiscount"
            id="price-before-discount"
            class="fs-14 text-light d-flex"
          >
            <p class="mb-0 fs-10 text-lightgrey mr-2">
              {{ $t('price') }}
            </p>
            <p class="mb-0 fs-8 text-lightgrey mr-2">
              {{ formatPrice(displayedPriceBeforeDiscount) }}
            </p>
          </div>
          <div
            v-if="displayedDiscount"
            id="discount"
            class="fs-14 text-light d-flex"
          >
            <p class="mb-0 fs-10 text-lightgrey mr-2">
              {{ $t('discount') }}
            </p>
            <p class="mb-0 fs-8 text-lightgrey mr-2">
              {{ formatPrice(displayedDiscount) }}
            </p>
          </div>
          <div
            v-if="displayedTaxes"
            id="taxes"
            class="fs-14 text-light d-flex"
          >
            <p class="mb-0 fs-10 text-lightgrey mr-2">
              {{ $t('vat') }}
            </p>
            <p class="mb-0 fs-8 text-lightgrey mr-2">
              {{ formatPrice(displayedTaxes) }}
            </p>
          </div>
          <div
            id="total-price"
            class="fs-14 text-light d-flex"
          >
            <p
              v-if="displayedTotalPrice"
              class="mb-0 fs-10 text-lightgrey mr-2"
            >
              {{ $t('finalPrice') }}
            </p>
            <p class="mb-2">
              {{ formatPrice(displayedTotalPrice) }}
              <Transition name="bounce">
                <ImgGeneral
                  v-if="isAbovePaymentLimit"
                  :is-one-px-placeholder="true"
                  :url="assetsPath + 'img/icon/yellow-warning-fill.svg'"
                  :alt="$t('finance.warningImgAlt')"
                  :title="$t('ariaLabel.warning')"
                />
              </Transition>
            </p>
            <p
              v-if="showTaxWarningOnMonitor"
              class="fs-12 mb-0"
            >
              {{ $t('finance.taxValueNotIncluded') }}
            </p>
            <p
              v-if="isAbovePaymentLimit"
              class="fs-12 mb-0"
            >
              ({{ $t('finance.paymentLimitExceeded') }})
            </p>
          </div>
        </div>
      </div>
      <div
        v-if="!showApiPrice"
        class="fw-200 text-white text-left mr-2 w-50"
        :class="{ invisible: quantity === 0 }"
      >
        <p class="text-break quantity fs-12 text-underline mb-2">
          <u>
            {{ quantity }}
            {{ quantity > 1 ? $t('items') : $t('item') }}
          </u>
        </p>
        <div
          id="total-price"
          class="fs-18 text-light"
        >
          <p class="mb-2">
            {{ formatPrice(displayedTotalPrice) }}
            <Transition name="bounce">
              <ImgGeneral
                v-if="isAbovePaymentLimit"
                :is-one-px-placeholder="true"
                :url="assetsPath + 'img/icon/yellow-warning-fill.svg'"
                :alt="$t('finance.openInvoices.warningImgAlt')"
                :title="$t('ariaLabel.warning')"
              />
            </Transition>
          </p>
          <p
            v-if="showTaxWarningOnMonitor"
            class="fs-12 mb-0"
          >
            {{ $t('finance.taxValueNotIncluded') }}
          </p>
          <p
            v-if="isAbovePaymentLimit"
            class="fs-12 mb-0"
          >
            ({{ $t('finance.paymentLimitExceeded') }})
          </p>
        </div>
      </div>
      <b-spinner
        v-if="preloading"
        class="mx-auto text-light preloader"
        label="Loading..."
      />
      <div class="text-left w-50">
        <b-button
          v-if="enable && isCartPage() && !preloading"
          :disabled="!displayedActive"
          class="mr-2 ml-2 mx-auto pl-md-5 pr-md-5 cart-page"
          :class="{ 'call-to-action': enable }"
          variant="secondary"
          @click="checkout()"
        >
          {{ $t('confirmOrder') }}
        </b-button>
        <router-link
          v-if="enable && !isCartPage()"
          :class="{ 'call-to-action': enable }"
          class="w-100 btn w-100 btn-primary call-to-action"
          :aria-label="$t('ariaLabel.cart')"
          to=""
          @click.native="checkForDeliveryDate('/cart')"
        >
          {{ $t('redirectToCart') }}
        </router-link>
        <b-progress
          v-if="!enable && displayedTotalPrice > 0 && remaining() > 0"
          animated
          :value="displayedTotalPrice"
          :max="minimumOrderValue"
          :variant="enable ? 'success' : 'warning'"
        />
        <p
          v-if="!enable && displayedTotalPrice > 0 && remaining() > 0"
          class="fs-12 text-light mt-3 mb-1 fw-200"
        >
          {{
            $t(monitorMsg, {
              value: formatPrice(remaining()),
            })
          }}
        </p>
        <div
          v-if="!isCashPayment"
          id="credit-balance"
          class="text-light d-flex mt-3 fw-200 w-50, align-items-center"
        >
          <span class="baht-icon mr-1">฿</span>
          <p class="mb-0 fs-9 text-lightgrey mr-1">
            {{ $t(creditBal) }}
          </p>
          <p class="mb-0 fs-10 text-lightgrey mr-0">
            {{ formatPrice(Number(formattedBalance)) }}
          </p>
        </div>
      </div>
    </div>
    <DeliveryDateChildModal
      modal-id="monitormodal"
      :is-visible="showDeliveryDateChildModal"
      @selectedDataObj="handleModalSelectedDate"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ImgGeneral from '@/components/shared/ImgGeneral';
import ProductMixin from '@/components/mixins/ProductMixin';
import { getStorageBottler, getAssetsPath } from '@/utils/config';
import router from '@/router';
import { openModalNotification } from '@/utils/pages';
import Common from '@/utils/common';
import DeliveryDateChildModal from '@/components/shared/DeliveryDateChildModal';

export default {
  components: {
    ...mapGetters({
      isCartCutoffAcknowledged: 'getCartCutoffAcknowledged',
    }),
    ImgGeneral,
    DeliveryDateChildModal,
  },
  mixins: [ProductMixin],
  props: {
    active: {
      type: Boolean,
      default: true,
    },
    totalApiPrice: {
      type: Number,
      default: 0,
    },
    priceBeforeDiscount: {
      type: Number,
      default: 0,
    },
    taxes: {
      type: Number,
      default: 0,
    },
    discount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      minimumOrderValue: 100,
      enable: false,
      itemString: '',
      monitorMsg: undefined,
      creditBal: undefined,
      modalData: {},
      preloading: false,
      activeModalLimitAuthorazed: false,
      assetsPath: getAssetsPath(),
      showTaxWarningOnMonitor: null,
      showApiPrice: null,
      popupShown: false,
      selectedDateObj: null,
      modalDeliveryDateSelected: '',
      showDeliveryDateChildModal: false,
      nextLink: '',
      isTrackOrderLinkClick: false,
      menuCLick: {},
    };
  },
  computed: {
    ...mapGetters({
      products: 'getCartItems',
      quantity: 'getItemsQuantity',
      glassBottles: 'getReturnablePackagingData',
      totalPrice: 'getTotalPrice',
      client: 'getClient',
      simulatedPrice: 'getSimulatedPriceState',
      getOrdersPlacedToday: 'getOrdersPlacedToday',
      getOrdersOfExpectedDeliveryDate: 'getOrdersOfExpectedDeliveryDate',
    }),
    isAbovePaymentLimit() {
      return (
        this.client.paymentLimit &&
        this.displayedTotalPrice > this.client.paymentLimit
      );
    },
    displayedTotalPrice() {
      return !this.showApiPrice
        ? this.totalPrice
        : this.totalApiPrice || this.simulatedPrice.totalPrice;
    },
    displayedDiscount() {
      return this.discount || this.simulatedPrice.discount;
    },
    displayedPriceBeforeDiscount() {
      return (
        this.priceBeforeDiscount || this.simulatedPrice.priceBeforeDiscount
      );
    },
    displayedTaxes() {
      return this.taxes || this.simulatedPrice.taxes;
    },
    displayedActive() {
      return this.isCartPage() ? this.active : !this.simulatedPrice.loading;
    },
    formattedBalance() {
      return this.$store.state.credit.credits.balance;
    },
    isCashPayment() {
      return this.client.paymentType === 'Cash';
    },
    shouldShowPopupOnCartPage() {
      return (
        !this.isCashPayment &&
        this.isCartPage &&
        this.formattedBalance - this.displayedTotalPrice < 0
      );
    },
  },
  watch: {
    products: {
      deep: true,
      handler() {
        this.enableOrder();
        // dispatch in any page, because all page has monitor component "that component contain the summary
        this.$store.dispatch('simulatePrice');
      },
    },

    displayedTotalPrice: {
      handler() {
        this.enableOrder();
        if (
          this.enable &&
          this.isCartPage() &&
          this.shouldShowPopupOnCartPage &&
          !this.popupShown
        ) {
          this.showOrderExceedPopup();
          this.popupShown = true;
        }
      },
    },
  },
  async created() {
    if (this.client.document_id || this.client.external_id) {
      this.setClientMinimumOrder();
      this.enableOrder();
    }
    const bottlerStorage = getStorageBottler();
    this.showTaxWarningOnMonitor = bottlerStorage?.showTaxWarningOnMonitor;
    this.showApiPrice = bottlerStorage?.hasRecalculatePrice;
    if (this.products.length > 0) {
      this.$store.dispatch('simulatePrice');
    }
    if (
      this.isCartPage() &&
      this.shouldShowPopupOnCartPage &&
      !this.popupShown
    ) {
      this.showOrderExceedPopup();
      this.popupShown = true;
    }
  },
  methods: {
    formatPrice(price) {
      let priceCheck = Number.isNaN(parseInt(price)) ? 0 : price;
      if (typeof priceCheck === 'number') {
        priceCheck = priceCheck.toFixed(2);
      }
      priceCheck = Number(priceCheck).toLocaleString('th-TH', {
        style: 'currency',
        currency: 'THB',
      });
      return priceCheck;
    },
    setClientMinimumOrder() {
      this.minimumOrderValue =
        this.client && this.client.minimumOrder ? this.client.minimumOrder : 0;
    },
    remaining() {
      return this.displayedTotalPrice > this.minimumOrderValue
        ? 0
        : this.minimumOrderValue - this.displayedTotalPrice;
    },
    enableOrder() {
      this.enable = !!(
        this.hasItemsInCart() &&
        this.displayedTotalPrice >= this.minimumOrderValue
      );
    },
    checkout() {
      this.preloading = true;

      setTimeout(() => {
        this.$emit('checkout');
        this.preloading = false;
      }, 2000);
    },
    changeActive(value) {
      if (value) {
        router.navigateToPage('/check');
      }
    },
    hasItemsInCart() {
      if (this.quantity <= 0) {
        this.monitorMsg = 'cartPage.emptyCart';
        this.creditBal = 'cartPage.emptyCart';
        return false;
      }
      this.monitorMsg = 'missingToOrder';
      this.creditBal = 'CreditBalance';
      return true;
    },
    isCartPage() {
      return this.$route.path === '/cart';
    },
    checkForDeliveryDate(link) {
      if (this.client.deliveryDateSelected) {
        router.navigateToPage(link);
        return true;
      }
      // Show delivery date popup instead of date selector warning
      this.showDeliveryDates(link);
      if (
        this.menuCLick.monitormodal === true &&
        router.getPath() !== '/home'
      ) {
        this.$bvModal.show('monitormodal');
      }
      this.menuCLick.monitormodal = true;
      return false;
      // Improvment for the future -> change the router link
    },

    confirmAdditionalOrder(link) {
      const h = this.$createElement;
      openModalNotification(this.$bvModal, {
        id: 'duplicateOrder',
        headerBgVariant: 'white',
        headerTextVariant: 'danger',
        hideHeaderClose: true,
        okVariant: 'secondary',
        okOnly: true,
        noCloseOnBackdrop: true,
        noCloseOnEsc: true,
        title: [
          h('b-icon', {
            props: {
              icon: 'exclamation-triangle-fill',
              scale: '1',
              variant: 'danger',
            },
          }),
          h(
            'span',
            {
              style: { color: 'red' },
            },
            ` ${this.$t('orderTracking.possibleDuplicateOrderTitle')}`
          ),
        ],
        description: [
          h('p', ` ${this.$t('orderTracking.possibleDuplicateOrderbody1')}`),
          h('p', ` ${this.$t('orderTracking.possibleDuplicateOrderbody2')}`),
          h('p', [
            h('span', [
              ` ${this.$t('orderTracking.possibleDuplicateOrderbody3')}`,
              h(
                'a',
                {
                  on: {
                    click: () => {
                      this.isTrackOrderLinkClick = true;
                      this.$bvModal.hide('duplicateOrder');
                      router.navigateToPage('/order/tracking');
                    },
                  },
                  style: {
                    cursor: 'pointer',
                    textDecoration: 'underline',
                    color: 'rgb(228, 30, 43)',
                  },
                },
                ` ${this.$t('orderTracking.possibleDuplicateOrderHistory')}`
              ),
              ` ${this.$t('orderTracking.possibleDuplicateOrderbody4')}`,
            ]),
          ]),
        ],
        okTitle: this.$t('ok'),
      }).then((value) => {
        if (value === true) {
          // selected new date will be added to the client
          this.client.minimumOrder = Number(
            this.modalDeliveryDateSelected.MinimumValue
          );
          this.client.deliveryDateSelected =
            this.modalDeliveryDateSelected.header;
          this.client.deliveryDate =
            this.modalDeliveryDateSelected.Date.split('-').join('');
          this.$store.commit('setClient', this.client);

          // if user clicks the next delivery date then continue the route to create order
          if (this.isTrackOrderLinkClick === false) {
            router.navigateToPage(link);
          }
        }
      });
    },

    async showDeliveryDates(link) {
      this.showDeliveryDateChildModal = true;
      this.nextLink = link;
    },

    handleModalSelectedDate(data) {
      if (data === null) {
        return;
      }
      this.modalDeliveryDateSelected = data;
      if (
        this.modalDeliveryDateSelected !== null &&
        typeof this.modalDeliveryDateSelected === 'object'
      ) {
        //  if user clicks the next delivery date then continue the route to create order
        if (this.nextLink === 'toggle-search') {
          this.$emit('toggle-search');
        } else {
          const ordersCreatedToday = this.getOrdersPlacedToday(
            Common.getTodayDate()
          );
          const ordersWithSameExpectedDeliveryDate =
            this.getOrdersOfExpectedDeliveryDate(
              Common.formatDateYYYYMMDDtoDDMMYYYY(
                this.modalDeliveryDateSelected.Date
              )
            );
          if (
            (ordersCreatedToday.length > 0 ||
              ordersWithSameExpectedDeliveryDate.length > 0) &&
            this.$route.path !== '/order/tracking'
          ) {
            this.confirmAdditionalOrder(this.nextLink);
          } else {
            // selected new date will be added to the client
            this.client.minimumOrder = Number(
              this.modalDeliveryDateSelected.MinimumValue
            );
            this.client.deliveryDateSelected =
              this.modalDeliveryDateSelected.header;
            this.client.deliveryDate =
              this.modalDeliveryDateSelected.Date.split('-').join('');
            this.$store.commit('setClient', this.client);

            // if user clicks the next delivery date then continue the route to create order
            if (this.nextLink === 'toggle-search') {
              this.$emit('toggle-search');
            } else if (this.isTrackOrderLinkClick === false) {
              router.navigateToPage(this.nextLink);
            }
          }
        }
      }

      // for handling when the user dismisses (cancel) the modal
      if (this.modalDeliveryDateSelected === null) {
        this.showDeliveryDateChildModal = false;
      }
    },

    showOrderExceedPopup() {
      const h = this.$createElement;
      openModalNotification(this.$bvModal, {
        headerBgVariant: 'white',
        headerTextVariant: 'danger',
        hideHeaderClose: true,
        title: [
          h('b-icon', {
            props: {
              icon: 'exclamation-triangle-fill',
              scale: '1',
              variant: 'danger',
            },
          }),
          h(
            'span',
            {
              style: { color: 'red' },
            },
            ` ${this.$t('creditLimitExceeded')}`
          ),
        ],
        description: [
          h('p', this.$t('creditLimitExceed.pleaseDoFollowing')),
          h('ul', [
            h('li', [
              h('span', this.$t('creditLimitExceed.proceedOrder1')),
              h(
                'a',
                {
                  on: {
                    mouseover: () => {
                      // Change color on mouseover
                      this.linkHoverColor = 'black';
                    },
                    mouseleave: () => {
                      // Reset color on mouseleave
                      this.linkHoverColor = 'inherit';
                    },
                  },
                  style: {
                    color: this.linkHoverColor || 'black', // Use the computed color
                    textDecoration: 'none', // Remove underline for better styling
                  },
                  attrs: {
                    href: `tel:${this.$t('creditLimitExceed.proceedOrderTel')}`,
                  },
                },
                this.$t('creditLimitExceed.proceedOrderTel')
              ),
              h('span', this.$t('creditLimitExceed.proceedOrder2')),
            ]),
            h('li', this.$t('creditLimitExceed.adjustCraft')),
          ]),
          h('p', this.$t('creditLimitExceed.note')),
        ],
        okTitle: this.$t('ok'),
      });
    },
  },
};
</script>

<style src="@/assets/styles/monitor.css" , scoped></style>

<style src="@/assets/styles/deliverydates.css" scoped></style>
