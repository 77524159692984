<template>
  <div
    id="product-item"
    class="mt-3 d-flex"
  >
    <div class="card-body d-flex flex-wrap mb-3">
      <div class="align-self-start w-100">
        <div class="text-center">
          <div
            v-if="!disableComboDiscountPercentage"
            class="product__discount-value"
          >
            -{{ combo.discountPercentage }}
          </div>
          <ImgGeneral
            class="mw-100 img-combo br-12"
            :alt-text="$t('combo.comboImageAltText', { name: combo.name })"
            :url="combo.image"
          />
          <h4
            v-if="!disableComboName"
            translate="no"
            class="fs-12 fw-600 mt-3"
          >
            {{ combo.name }}
          </h4>
        </div>
      </div>
      <div class="align-self-end w-100">
        <ButtonAddCart
          :enable-animation="false"
          :label-i18n-key="'buyNow'"
          @click-add-to-cart="redirectToComboPage(combo.id, combo.name)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getStorageBottler } from '@/utils/config';
import ButtonAddCart from '@/components/product/ButtonAddCart.vue';
import ImgGeneral from '@/components/shared/ImgGeneral';
import router from '@/router';
import { openModalNotification } from '@/utils/pages';

export default {
  components: {
    ButtonAddCart,
    ImgGeneral,
  },
  props: {
    combo: {
      type: Object,
      required: true,
    },
  },
  created() {
    const storageBottler = getStorageBottler();
    this.disableComboDiscountPercentage =
      storageBottler?.disableComboDiscountPercentage;
    this.disableComboName = storageBottler?.disableComboName;
  },
  methods: {
    initialState(comboId) {
      this.$emit('add-to-cart-c', { comboId });
    },
    redirectToComboPage(comboId, comboName) {
      const storageBottler = getStorageBottler();
      const comboNameRedirect = comboName || '';
      if (storageBottler?.comboTypeQuantityRange === true) {
        router.navigateToPage(`combo-quantity/${comboId}/${comboNameRedirect}`);
        return;
      }
      if (storageBottler?.comboTypeQuantityId === true) {
        router.navigateToPage(`combo-generic/${comboId}`);
        return;
      }
      router.navigateToPage(`combo/${comboId}`);
    },
    showPromotionDetails() {
      const h = this.$createElement;
      openModalNotification(this.$bvModal, {
        title: [
          h(
            'span',
            {
              style: { color: 'green', fontWeight: 500 },
            },
            ` ${this.$t('combo.quantityComboTitle')}`
          ),
        ],
        description: this.combo.description
          .split('\n')
          .map((row) => h('p', [row]))
          .concat([
            h('p', this.$t('combo.quantityComboTitle')),
            h('p', this.$t('combo.quantityComboDescription')),
          ]),
        okTitle: this.$t('ok'),
      });
    },
  },
};
</script>

<style scoped>
.product__discount-value {
  position: absolute;
  top: 15%;
  right: 20%;
  max-width: 60px;
  padding: 2px;
  font-size: var(--fs-10);
  font-weight: 600;
  color: var(--light);
  background-color: var(--dark);
  border-radius: 15px;
}
</style>
