import auth from './modules/auth';
import cart from './modules/cart';
import category from './modules/category';
import brand from './modules/brand';
import search from './modules/search';
import promotion from './modules/promotion';
import userFlow from './modules/userFlow';
import client from './modules/client';
import profile from './modules/profile';
import banner from './modules/banner';
import combo from './modules/combo';
import credit from './modules/credit';
import order from './modules/order';
import selectDate from './modules/selectDate';
import optIn from './modules/optIn';
import ecommerceConfig from './modules/ecommerceConfig';

const stores = {
  modules: {
    auth,
    cart,
    category,
    brand,
    search,
    promotion,
    userFlow,
    client,
    profile,
    banner,
    combo,
    order,
    credit,
    selectDate,
    optIn,
    ecommerceConfig,
  },
};

export default stores;
