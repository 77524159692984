<template>
  <div class="page__content">
    <Breadcrumb />
    <div class="container max-section-width">
      <div
        id="search"
        class="row"
      >
        <ul
          class="col list-unstyled p-0 d-flex flex-wrap justify-content-center pl-1 pr-1"
        >
          <li
            v-for="product in products"
            :key="product.product.sku"
            class="product__box"
          >
            <Product
              :product="product"
              @add-to-cart="addItemToCart"
            />
          </li>
        </ul>
      </div>
      <div class="row">
        <div class="col">
          <NoResponse v-if="!loading && !products" />
          <NotFound
            v-if="!loading && !products.length > 0"
            msg="notFound.product"
            msg01="searchAnotherTerm"
          />
          <div
            v-if="loading"
            class="loading"
          >
            <b-spinner label="Loading..." />
          </div>
        </div>
      </div>
    </div>
    <Monitor />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Product from '@/components/product/Product.vue';
import Breadcrumb from '@/components/shared/Breadcrumb.vue';
import Monitor from '@/components/Monitor.vue';
import NoResponse from '@/components/NoResponse.vue';
import NotFound from '@/components/NotFound.vue';

export default {
  components: {
    Product,
    Breadcrumb,
    Monitor,
    NoResponse,
    NotFound,
  },
  data() {
    return {
      products: [],
      category: '',
      loading: true,
      productsLoaded: false,
    };
  },
  computed: {
    ...mapGetters({
      selectedCategory: 'getCategory',
      selectedBrand: 'getBrand',
    }),
  },
  watch: {
    '$store.state.search.searchString': function onGetSearchString() {
      this.filterProducts();
    },
    '$store.state.search.products': function onGetProducts() {
      this.filterProducts();
      this.productsLoaded = true;
    },
  },
  beforeCreate() {
    this.loading = true;
  },
  created() {
    const { redirect } = this.$route.query;
    if (redirect) {
      this.$store.commit('setSearchString', redirect);
      this.filterProducts();
    }
  },
  methods: {
    ...mapActions(['addItemToCart']),
    compareStrings(query) {
      const { searchString } = this.$store.state.search;
      return String(query)
        ?.normalize('NFD')
        .replace(/\p{Diacritic}/gu, '')
        .toUpperCase()
        .includes(
          searchString
            .toUpperCase()
            .normalize('NFD')
            .replace(/\p{Diacritic}/gu, '')
        );
    },
    checkResultProduct(product) {
      if (!product) return false;

      return (
        this.compareStrings(product.brand2) ||
        this.compareStrings(product.brand) ||
        this.compareStrings(product.category) ||
        this.compareStrings(product.submenu) ||
        this.compareStrings(product.size) ||
        this.compareStrings(product.description) ||
        this.compareStrings(product.sku) ||
        this.compareStrings(`${product.brand} ${product.submenu}`) ||
        this.compareStrings(`${product.brand} ${product.size}`)
      );
    },
    filterProducts() {
      this.loading = true;
      const { searchString } = this.$store.state.search;
      if (searchString && searchString.length >= 3) {
        const { products } = this.$store.state.search;
        this.products = products.filter((productItem) => {
          const { product } = productItem;
          return this.checkResultProduct(product);
        });
      }
      if (!searchString) {
        this.products = [];
      }
      this.loading = false;
      this.productsLoaded = this.$store.state.search.products.length > 0;
    },
  },
};
</script>
