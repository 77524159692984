import { getAssetsPath } from '@/utils/config';
import { FONTS } from '@/constants/style';

export const getFontFaceStringified = (fontParams) =>
  `@font-face { font-family: '${fontParams.name}'; font-weight: ${fontParams.weight}; style: ${fontParams.style}; src: url('${fontParams.url}');}`;

export const getFontUrl = (fontFileName) =>
  `${getAssetsPath()}fonts/${fontFileName}`;

export const loadAndInjectFonts = async () => {
  const promises = [];
  const fontsStyle = document.createElement('style');

  FONTS.forEach((font) => {
    const fontUrl = getFontUrl(font.fileName);
    const fontFace = new FontFace('Unity', `url(${fontUrl})`);

    promises.push(fontFace.load());
    fontsStyle.appendChild(
      document.createTextNode(
        getFontFaceStringified({
          name: font.name,
          weight: font.fontWeight,
          url: fontUrl,
          style: font.fontStyle,
        })
      )
    );
  });

  await Promise.all(promises);
  document.head.appendChild(fontsStyle);
  document.body.classList.add('fonts-loaded');
};
