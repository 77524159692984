<template>
  <div class="container">
    <button
      v-b-toggle="'accordion-delivery-option'"
      class="text-left w-100 bg-transparent border-0 scheduled-title"
    >
      {{ $t('order.deliveryOption.title') }}
    </button>

    <b-collapse
      id="accordion-delivery-option"
      accordion="my-accordion"
    >
      <div
        v-if="!loading && availableDaysWithMaxPeriod.length"
        class="scheduled-check"
      >
        <div class="check fs-14 fw-200 m-3">
          {{ $t('order.deliveryOption.subtitle') }}
        </div>
        <div v-if="cartWithProductQtyLimit">
          <WarningBoxText
            :text="$t('order.deliveryOption.hasCartWithProductQtyLimit')"
          />
        </div>
        <CheckDeliveryOption
          v-if="showDeliveryOptionScheduledFlag"
          class="mb-3"
          :class="isDisabledScheduled"
          :description="descriptionScheduled"
          :help-message="
            $t('order.deliveryOption.helpScheduled', {
              value: botSourceAppName,
            })
          "
          :check-box-aria-label="$t('ariaLabel.orderScheduled')"
          :disabled-click="disabledClick"
          @checked="(checked) => resetSelectedDates(checked)"
        />
        <CheckDeliveryOption
          v-if="showDeliveryOptionRecurrentFlag"
          :class="isDisabledRecurrent"
          :description="descriptionRecurrent"
          :help-message="
            $t('order.deliveryOption.helpRecurrent', {
              value: botSourceAppName,
            })
          "
          :check-box-aria-label="$t('ariaLabel.orderRecurrent')"
          :disabled-click="disabledClick"
          @startLoadScheduleOrderDates="$emit('startLoadScheduleOrderDates')"
          @finishLoadScheduleOrderDates="$emit('finishLoadScheduleOrderDates')"
          @checked="(checked) => resetSelectedDates(checked)"
        />
      </div>
      <div
        v-if="!loading && !availableDaysWithMaxPeriod.length"
        class="scheduled-no-dates-description fs-14 fw-200 p-2"
      >
        <p>{{ $t('order.deliveryOption.descriptionNoDeliveryOptionDates') }}</p>
      </div>
      <Skeleton
        v-if="loading"
        :height="'30px'"
        class="w-50 mt-3"
      />
    </b-collapse>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { VBToggle, BCollapse } from 'bootstrap-vue';
import WarningBoxText from '@/components/shared/WarningBoxText.vue';
import CheckDeliveryOption from '@/components/order/deliveryOption/CheckDeliveryOption.vue';
import { getStorageBottler, getBotSourceAppName } from '@/utils/config';
import { SET_ORDER_TYPE } from '@/constants/feature';
import Skeleton from '@/components/shared/Skeleton.vue';

export default {
  components: {
    CheckDeliveryOption,
    BCollapse,
    Skeleton,
    WarningBoxText,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    hasProductWithQtyLimit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDisabledScheduled: '',
      isDisabledRecurrent: '',
      descriptionScheduled: this.$t(
        'order.deliveryOption.descriptionScheduled'
      ),
      descriptionRecurrent: this.$t(
        'order.deliveryOption.descriptionRecurrent'
      ),
      showDeliveryOptionScheduledFlag: false,
      showDeliveryOptionRecurrentFlag: false,
      loading: true,
      botSourceAppName: getBotSourceAppName(),
      cartWithProductQtyLimit: this.hasProductWithQtyLimit,
    };
  },
  computed: {
    ...mapGetters({
      availableDaysWithMaxPeriod: 'getAvailableDaysWithMaxPeriod',
    }),
  },
  async created() {
    const bottlerStorage = getStorageBottler();
    try {
      await this.$store.dispatch('setAvailableDaysWithMaxPeriod');
    } catch (err) {
      this.$store.commit('setAvailableDaysWithMaxPeriod', []);
    }
    this.showDeliveryOptionScheduledFlag =
      bottlerStorage?.showDeliveryOptionScheduled;
    this.showDeliveryOptionRecurrentFlag =
      bottlerStorage?.showDeliveryOptionRecurrent;
    this.loading = false;
    if (this.cartWithProductQtyLimit) {
      this.isDisabledRecurrent = 'is-disabled';
      this.isDisabledScheduled = 'is-disabled';
    }
    this.$emit('finishGetAvailableDays');
  },
  methods: {
    disabledClick(checked, name) {
      if (name === this.descriptionScheduled && checked === true) {
        this.isDisabledRecurrent = 'is-disabled';
        this.isDisabledScheduled = '';
        this.$store.commit('setOrderType', SET_ORDER_TYPE.scheduledOrder);
      } else if (name === this.descriptionRecurrent && checked === true) {
        this.isDisabledScheduled = 'is-disabled';
        this.isDisabledRecurrent = '';
        this.$store.commit('setOrderType', SET_ORDER_TYPE.recurrentOrder);
      } else {
        this.isDisabledRecurrent = '';
        this.isDisabledScheduled = '';
        this.$store.commit('setOrderType', '');
      }
    },
    resetSelectedDates(checked) {
      if (checked) return true;

      this.$store.commit('resetState', checked);
      return true;
    },
  },
};
</script>

<style scoped>
.is-disabled {
  pointer-events: none;
  opacity: 0.4;
}
.scheduled-title:before {
  content: var(--arrow-down-dark-icon);
  position: absolute;
  right: 20px;
  transition: 0.3s;
}
.scheduled-title.not-collapsed:before {
  transform: rotate(180deg);
}

.warning-msg {
  margin-bottom: 10px;
}
</style>
