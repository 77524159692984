import { setSessionData } from '@/utils/config';
import {
  INSTALLATION_CONFIGS,
  ENVIRONMENTS,
  SOURCE_TYPES,
} from '@/constants/config';

let sourceType;
let bottler;
let appTitle;
let language;
let country;
let appTenantPath;
let functionBaseUrl;
let functionAPIManagementUrl;
let lineChatbotUrl;

const initializeInstallationConfig = ({ envVarName, key }) => {
  const querySelectorValue = document
    .querySelector('#app')
    ?.getAttribute(`data-${key}`);
  const value = querySelectorValue || process.env[envVarName];

  setSessionData({ key, value });

  return value;
};

export const getInstallationSourceType = () => {
  if (sourceType) return sourceType;

  sourceType = initializeInstallationConfig(INSTALLATION_CONFIGS.SOURCE_TYPE);

  return sourceType;
};

const getEnvironment = () => {
  let installationSourceType = getInstallationSourceType();
  installationSourceType = installationSourceType || SOURCE_TYPES.KOBOSS;
  const env = process.env.VUE_APP_ENV.toUpperCase();
  return ENVIRONMENTS[installationSourceType.toUpperCase()][env];
};

export const getInstallationBottler = () => {
  if (bottler) return bottler;

  bottler = initializeInstallationConfig(INSTALLATION_CONFIGS.BOTTLER);

  return bottler;
};

export const getInstallationAppTitle = () => {
  if (appTitle) return appTitle;

  appTitle = initializeInstallationConfig(INSTALLATION_CONFIGS.APP_TITLE);

  return appTitle;
};

export const getInstallationLanguage = () => {
  if (language) return language;

  language = initializeInstallationConfig(INSTALLATION_CONFIGS.LANGUAGE);

  return language;
};

export const getInstallationCountry = () => {
  if (country) return country;

  country = initializeInstallationConfig(INSTALLATION_CONFIGS.COUNTRY);

  return country;
};

export const getInstallationAppTenantPath = () => {
  if (appTenantPath) return appTenantPath;

  appTenantPath = initializeInstallationConfig(
    INSTALLATION_CONFIGS.APP_TENANT_PATH
  );

  return appTenantPath;
};

export const getInstallationFunctionBaseUrl = (useAzureAPIManagement) => {
  let baseUrl;
  if (useAzureAPIManagement) {
    if (functionAPIManagementUrl) return functionAPIManagementUrl;
    if (process.env.VUE_APP_API_MANAGEMENT_URL) {
      functionAPIManagementUrl = process.env.VUE_APP_API_MANAGEMENT_URL;
      return functionAPIManagementUrl;
    }
    const environment = getEnvironment();
    functionAPIManagementUrl = environment.APIManagementUrl;
    baseUrl = functionAPIManagementUrl;
  } else {
    if (functionBaseUrl) return functionBaseUrl;
    if (process.env.VUE_APP_AZ_FUNCTION_BASEURL) {
      functionBaseUrl = process.env.VUE_APP_AZ_FUNCTION_BASEURL;
      return functionBaseUrl;
    }
    const environment = getEnvironment();
    functionBaseUrl = environment.functionBaseUrl;
    baseUrl = functionBaseUrl;
  }
  return baseUrl;
};

export const getInstallationLineChatbotUrl = () => {
  if (lineChatbotUrl) return lineChatbotUrl;

  lineChatbotUrl = process.env.VUE_APP_LINE_CHATBOT_URL;

  return lineChatbotUrl;
};
