<template>
  <div>
    <div class="alert mx-auto">
      <div class="alert__icon">
        <div class="alert__icon--area">
          <ImgGeneral
            class="alert__icon--img"
            :url="assetsPath + 'img/icon/alert.svg'"
          />
        </div>
      </div>
      <div class="alert__message">
        <div
          v-if="header"
          class="check fs-16 fw-300"
        >
          {{ header }}
        </div>
        <div
          v-if="subHeader"
          class="check fs-14 fw-200"
        >
          {{ subHeader }}
        </div>
        <div
          v-if="subHeaderItems"
          class="check fs-14 fw-200"
        >
          <ul class="glasslist mt-0 mt-md-2 ml-3">
            <li
              v-for="item in subHeaderItems.message"
              :key="item.message"
            >
              {{ item }}
            </li>
          </ul>
        </div>
        <div
          v-if="buttonType && buttonHeader && buttonText && buttonAction"
          class="check fs-14 fw-200 d-flex align-items-center"
        >
          <p>
            {{ buttonHeader }}
          </p>
          <button
            :class="getButtonStyle"
            @click="buttonAction(buttonType)"
          >
            <span>
              {{ buttonText }}
            </span>
          </button>
        </div>
        <div
          v-if="confirmMessage"
          class="check fs-14 fw-400 confirm"
          :class="{ pending: !checked && showPendingConfirm }"
        >
          <input
            :id="header"
            v-model="checked"
            class="alert-check"
            type="checkbox"
            @click="$emit('update', !checked)"
          />
          <label :for="header">
            {{ !checked && showPendingConfirm ? '* ' : '' }}
            {{ confirmMessage }}
          </label>
        </div>
      </div>
    </div>
    <div class="line" />
  </div>
</template>

<script>
import { getAssetsPath } from '@/utils/config';
import ImgGeneral from '@/components/shared/ImgGeneral.vue';

export default {
  components: {
    ImgGeneral,
  },
  props: {
    header: {
      type: String,
      default: '',
    },
    subHeader: {
      type: String,
      default: '',
    },
    subHeaderItems: {
      type: Object,
      default: null,
    },
    confirmMessage: {
      type: String,
      default: '',
    },
    showPendingConfirm: {
      type: Boolean,
      default: false,
    },
    buttonType: {
      type: String,
      default: '',
    },
    buttonHeader: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: '',
    },
    buttonAction: {
      type: Function,
      default: () => {},
    },
    buttonStyle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      checked: false,
      assetsPath: getAssetsPath(),
    };
  },
  computed: {
    getButtonStyle() {
      const defaultStyle = 'btn mx-auto mt-0 mb-1';
      if (this.buttonStyle === 'primary') {
        return `${defaultStyle} btn-primary`;
      }
      return `${defaultStyle} btn-outline`;
    },
  },
};
</script>

<style scoped>
.alert__icon--area {
  position: relative;
  width: 47px;
  height: 40px;
  background: var(--orange);
  border-radius: 7px;
}

.alert__icon--img {
  width: 20px;
  height: 20px;
  margin: 9px 9px 9px 14px;
}

.alert {
  display: flex;
  margin: 0;
  padding: 0;
  align-content: center;
}

.alert__message {
  display: grid;
  grid-template-rows: auto auto auto;
  padding-left: 8px;
}

.confirm {
  margin-left: -26px;
}

.line {
  margin-bottom: 20px;
  opacity: 0.1;
  border: 1px solid var(--dark-grey);
}

.glasslist {
  padding-inline-start: 0;
  margin-block-end: 0;
}
</style>
