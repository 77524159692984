import apiOptInGet from '@/api/optin/optInGet';

export default {
  state: {
    userOptIn: null,
  },
  getters: {
    getOptIn(state) {
      return state.userOptIn;
    },
  },
  mutations: {
    setOptIn(state, payload) {
      // eslint-disable-next-line no-param-reassign
      state.userOptIn = payload;
    },
  },
  actions: {
    async setOptIn({ commit }) {
      const optInResponse = await apiOptInGet.get();
      const payload = Boolean(optInResponse.isNotificationEnable);
      commit('setOptIn', payload);
    },
  },
};
