import apiCombo from '@/api/combo';

export default {
  state: {
    data: [],
  },
  getters: {
    getCombos(state) {
      return state.data.filter((combo) => combo.products?.length > 0);
    },
  },
  mutations: {
    setCombos(state, payload) {
      // eslint-disable-next-line no-param-reassign
      state.data = payload;
    },
  },
  actions: {
    async getCombos({ commit }) {
      const data = await apiCombo.getCombos();
      commit('setCombos', data);
    },
  },
};
