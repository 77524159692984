import apiOrderTracking from '@/api/order/orderTracking';

export default {
  state: {
    orders: {},
  },
  getters: {
    getOrders(state) {
      return state.orders;
    },
    getOrdersPlacedToday: (state) => (date) => {
      const filteredOutNulls =
        state.orders.orders.length > 0
          ? state.orders.orders.filter(
              (item) => item !== null && item !== undefined
            )
          : [];

      return filteredOutNulls.filter((order) => order.createdDate === date);
    },
    getOrdersOfExpectedDeliveryDate: (state) => (date) => {
      const filteredOutNulls =
        state.orders.orders.length > 0
          ? state.orders.orders.filter(
              (item) => item !== null && item !== undefined
            )
          : [];

      return filteredOutNulls.filter(
        (order) => order.expectedDeliveryDate === date
      );
    },
  },
  mutations: {
    setOrders(state, payload) {
      // eslint-disable-next-line no-param-reassign
      state.orders = payload;
    },
  },
  actions: {
    setOrders({ commit }, value) {
      commit('setOrders', value);
    },
    async getOrders({ commit }) {
      try {
        const orders = await apiOrderTracking.getAllOrderTracking();
        commit('setOrders', orders);
      } catch (error) {
        commit('setOrders', []);
      }
    },
  },
};
