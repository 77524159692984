<template>
  <div class="container">
    <div class="row">
      <div class="scheduled-multiselect p-2">
        <multiselect
          v-model="value"
          select-label=""
          selected-label=""
          deselect-label=""
          :options="options"
          :multiple="true"
          track-by="date"
          :custom-label="customLabel"
          :close-on-select="false"
          :placeholder="placeholder"
          @select="onSelect($event)"
          @remove="onRemove($event)"
        >
          <span
            slot="option"
            slot-scope="scope"
            class="check"
          >
            <input
              v-model="scope.option.checked"
              type="checkbox"
              @focus.prevent
            />
            {{ scope.option.date }}
          </span>
        </multiselect>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import { mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      value: [],
      options: [],
      placeholder: this.$t('order.deliveryOption.selectDate'),
      selectedDates: [],
    };
  },
  computed: {
    ...mapGetters({
      availableDaysWithMaxPeriod: 'getAvailableDaysWithMaxPeriod',
    }),
  },
  async created() {
    this.options = this.availableDaysWithMaxPeriod.map((date) => {
      const formattedDate = this.$d(
        DateTime.fromFormat(date.date, 'yyyy-MM-dd'),
        'weekdayDayMonth'
      );
      return {
        date: formattedDate,
        checked: false,
        unformattedDate: date.date,
      };
    });
  },
  methods: {
    customLabel(option) {
      return option.date;
    },
    onSelect(option) {
      const index = this.options.findIndex((item) => item.date === option.date);
      this.options[index].checked = true;
      this.selectedDates.push(
        parseInt(this.options[index].unformattedDate.replace(/[^0-9]/g, ''))
      );
      this.$store.commit('setSelectDate', this.selectedDates);
    },
    onRemove(option) {
      const index = this.options.findIndex((item) => item.date === option.date);
      this.options[index].checked = false;
      this.selectedDates.pop(this.options[index].unformattedDate);
      this.$store.commit('setSelectDate', this.selectedDates);
    },
  },
};
</script>

<style scoped>
select:focus,
:deep(.multiselect__placeholder),
:deep(.multiselect__input) {
  font-size: var(--fs-16);
}

.check input[type='checkbox'] {
  margin-right: 6px;
}
.scheduled-multiselect {
  margin-left: 30px;
  margin-bottom: 10px;
}
</style>
