<template>
  <div>
    <button
      :id="`prd-${sku}`"
      class="border-0 bg-transparent p-0"
    >
      <b-icon
        icon="question-circle-fill"
        variant="primary"
      />
    </button>
    <b-popover
      :target="`prd-${sku}`"
      placement="top"
      :content="helpDescription"
    />
  </div>
</template>

<script>
export default {
  props: {
    helpDescription: {
      type: String,
      default: '',
    },
    sku: {
      type: String,
      default: '',
    },
  },
};
</script>
