/* eslint-disable import/prefer-default-export */
export const FONTS = [
  {
    name: 'Unity',
    fileName: 'TCCC-UnityText-Light.ttf',
    fontWeight: 100,
    fontStyle: 'normal',
  },
  {
    name: 'Unity',
    fileName: 'TCCC-UnityText-Regular.ttf',
    fontWeight: 200,
    fontStyle: 'normal',
  },
  {
    name: 'Unity',
    fileName: 'TCCC-UnityText-Medium.ttf',
    fontWeight: 300,
    fontStyle: 'normal',
  },
  {
    name: 'Unity',
    fileName: 'TCCC-UnityText-Bold.ttf',
    fontWeight: 600,
    fontStyle: 'normal',
  },
  {
    name: 'Unity',
    fileName: 'TCCC-UnityText-BoldItalic.ttf',
    fontWeight: 700,
    fontStyle: 'italic',
  },
  {
    name: 'Unity',
    fileName: 'TCCC-UnityCondensed-Medium.ttf',
    fontWeight: 800,
    fontStyle: 'normal',
  },
  {
    name: 'Unity',
    fileName: 'TCCC-UnityCondensed-Bold.ttf',
    fontWeight: 900,
    fontStyle: 'normal',
  },
];
