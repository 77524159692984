<template>
  <div
    id="combos"
    class="page__content"
  >
    <Breadcrumb />
    <div class="container max-section-width">
      <div
        id="products"
        class="row"
      >
        <ul
          class="col list-unstyled d-flex flex-wrap justify-content-center pl-1 pr-1"
        >
          <li
            v-for="combo in combos"
            :key="combo.id"
            class="product__box"
          >
            <Combo :combo="combo" />
          </li>
        </ul>
      </div>
      <NoResponse v-if="!combos" />
      <NotFound
        v-if="combos && !combos.length"
        msg="notFound.promotions"
      />
    </div>
    <Monitor />
  </div>
</template>

<script>
/* eslint-disable func-names */
import { mapActions, mapGetters } from 'vuex';
import Breadcrumb from '@/components/shared/Breadcrumb.vue';
import Monitor from '@/components/Monitor.vue';
import NoResponse from '@/components/NoResponse.vue';
import NotFound from '@/components/NotFound.vue';
import Combo from '@/components/combo/Combo.vue';

export default {
  components: {
    Breadcrumb,
    Monitor,
    NoResponse,
    NotFound,
    Combo,
  },
  computed: {
    ...mapGetters({
      combos: 'getCombos',
    }),
  },
  methods: {
    ...mapActions(['addItemToCart']),
    enableOrderFlag(flag) {
      if (flag !== this.enableOrder) {
        this.enableOrder = flag;
      }
    },
  },
};
</script>
