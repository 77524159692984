import { put, get, patch } from '@/utils/httpClient';

async function updateUser(data) {
  const result = await put({
    url: 'user',
    data,
  });

  return result && result.status === 200 ? result.data : undefined;
}

async function getUserProfile() {
  const result = await get({
    url: `user/profile`,
  });

  if (result.status) {
    return result.status === 200 && result.data ? result.data : undefined;
  }

  return result.message.includes('timeout') ? undefined : {};
}

async function updateUserProfile(value) {
  const result = await patch({
    url: `user/profile`,
    data: {
      language: value,
    },
  });

  if (result.status) {
    return result.status === 200 && result.data ? result.data : undefined;
  }

  return result.message.includes('timeout') ? undefined : {};
}
export default { updateUser, getUserProfile, updateUserProfile };
