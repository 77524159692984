import { post } from '@/utils/httpClient';
import { isLatamBottler, getLatamBottlerCodeByValue } from '@/utils/config';
import { getInstallationBottler } from '@/utils/installation';

async function userAuthentication(clientId, password, bottler) {
  let actualBottler = bottler || getInstallationBottler();

  if (isLatamBottler()) {
    actualBottler = getLatamBottlerCodeByValue(actualBottler);
  }

  const result = await post({
    url: `user/authenticate/${actualBottler}`,
    data: {
      clientId,
      password,
    },
  });

  return result.status === 200 && result.data ? result.data : undefined;
}

export default { userAuthentication };
