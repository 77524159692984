<template>
  <div />
</template>
<script>
import { mapGetters } from 'vuex';
import { openModalNotification, isProductOnCart } from '@/utils/pages';

export default {
  data() {
    return {
      qty: this.product?.quantity || 0,
      price: this.product?.price || 0,
    };
  },
  computed: {
    ...mapGetters({
      cart: 'getCartItems',
    }),
    showTag() {
      return isProductOnCart({ productSku: this.sku, cart: this.cart });
    },
  },
  methods: {
    formatPrice(price) {
      let priceCheck = Number.isNaN(parseInt(price)) ? 0 : price;
      if (typeof priceCheck === 'number') {
        priceCheck = priceCheck.toFixed(2);
      }
      return `฿${priceCheck.toString()}`;
    },
    definePrice(qty) {
      this.qty = Math.round(qty);
      let { price } = this;

      if (this.hasProductPricePromotion()) {
        const promotions = this.product.product.pricePromotions;
        promotions.sort(
          (pda, pdb) => pda.minimumQuantity - pdb.minimumQuantity
        );
        promotions.forEach((promo) => {
          if (qty >= promo.minimumQuantity) {
            price = promo.price;
          }
        });
      }
      this.price = price;
    },
    hasProductPricePromotion() {
      const { pricePromotions } = this.product.product;
      if (pricePromotions && pricePromotions.length) {
        return true;
      }
      return false;
    },
    getWarningProductLimitText(product) {
      if (product.quantityLimit > 0) {
        return `${this.$t('cartPage.maxQtyAllowed', {
          quantity: product.quantityLimit,
        })}`;
      }

      return '';
    },
    getQtyLimitAllowedAddToCart(product) {
      const productInTheCart = this.cart.find(
        (p) => p.product.sku === product.sku
      );

      if (!productInTheCart) {
        return this.qty;
      }

      const qtyAllowed = product.quantityLimit - productInTheCart.quantity;
      const qtyToAddToCart = this.qty <= qtyAllowed ? this.qty : qtyAllowed;

      if (
        qtyToAddToCart !== this.qty &&
        this.$store.state.client.clientProfileInfo.type === 'Stockist'
      ) {
        this.showProductQtyLimitToast(product);
      }

      return qtyToAddToCart;
    },
    showProductQtyLimitToast(product) {
      const h = this.$createElement;
      openModalNotification(this.$bvModal, {
        title: [
          h('b-icon', {
            props: {
              icon: 'exclamation-triangle-fill',
              scale: '1',
              variant: 'danger',
            },
          }),
          h(
            'span',
            {
              style: { color: 'red' },
            },
            ` ${this.$t('exceededProductLimit.title')}`
          ),
        ],
        description: [
          h('span', product.description),
          h(
            'span',
            { style: { fontWeight: 200 } },
            this.$t('exceededProductLimit.first')
          ),
          h('span', product.quantityLimit),
          h(
            'span',
            { style: { fontWeight: 200 } },
            this.$t('exceededProductLimit.second')
          ),
          h(
            'span',
            { style: { fontWeight: 200 } },
            this.$t('exceededProductLimit.third')
          ),
          h('span', product.quantityLimit),
          h(
            'span',
            { style: { fontWeight: 200 } },
            this.$t('exceededProductLimit.fourth')
          ),
        ],
        okTitle: this.$t('ok'),
      });
    },
  },
};
</script>
