<template>
  <div>
    <router-link
      :to="'/notifications'"
      class="d-flex align-items-center"
      :aria-label="$t('ariaLabel.notifications')"
      :href="'/notifications'"
    >
      <ImgGeneral
        class="menu-item-icon mr-3"
        :data-src="`${assetsPath}img/nav/notifications-icon-secondary.svg`"
        :alt="$t('menu.notificationsIconAlt')"
      />
      <p class="text-light fs-12 mb-0 menu-item-name">
        {{ $t('notifications.title').toUpperCase() }}
      </p>
    </router-link>
  </div>
</template>

<script>
import { getAssetsPath } from '@/utils/config';
import ImgGeneral from '@/components/shared/ImgGeneral';

export default {
  components: {
    ImgGeneral,
  },
  data() {
    return {
      assetsPath: getAssetsPath(),
    };
  },
};
</script>

<style scoped>
.menu-item-name:hover .menu-item:focus {
  text-decoration: underline;
}
</style>
