import * as httpClient from '@/utils/httpClient';

async function get() {
  const result = await httpClient.get({
    url: 'optin/ecommerce',
  });

  return result.status === 200 && result.data ? result.data : undefined;
}

export default { get };
