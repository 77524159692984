<script>
import { mapGetters } from 'vuex';
import router from '@/router';
import { getStorageBottler } from '@/utils/config';
import { GLASS_BOTTLE_BUTTON_ACTION } from '@/constants/feature';
import { openModalNotification } from '@/utils/pages';

export default {
  computed: {
    ...mapGetters({
      glassBottles: 'getReturnablePackagingData',
      cartItems: 'getCartItems',
    }),
  },
  methods: {
    glassBottlesValidate() {
      if (
        (this.isGlassBottlesRequired() &&
          !this.glassBottlesAccepted &&
          !this.isReturnablePackagingsEnough()) ||
        this.isReturnablePackagingsQuantityOverLimit()
      ) {
        this.focusOnAlert();
      }
    },
    isGlassBottlesAccepted(value) {
      this.glassBottlesAccepted = value;
      this.$store.commit('setGlassBottlesTracking', value);
    },
    isGlassBottlesRequired() {
      const glassBottlesRequired =
        this.glassBottles.productsWithReturnablePackaging.length > 0;
      if (glassBottlesRequired) {
        const storageBottler = getStorageBottler();
        const glassBottleButtonAction = storageBottler?.glassBottleButtonAction;
        this.setGlassBottleButton(glassBottleButtonAction);
      }
      return glassBottlesRequired;
    },
    getBottleBoxesRequiredAmount() {
      const { productsWithReturnablePackaging } = this.glassBottles;
      const { products } = this.$store.state.search;
      const amount = {};

      const bottleBoxesAmounts = productsWithReturnablePackaging.reduce(
        (_, item) => {
          const glassBottle = products.find((p) => p.product?.sku === item.sku);

          if (!glassBottle) {
            return amount;
          }

          const glassBottleBoxId = glassBottle?.product?.boxProductId;

          amount[glassBottleBoxId] = amount[glassBottleBoxId]
            ? amount[glassBottleBoxId] + item.bottleBox
            : item.bottleBox;

          return amount;
        },
        {}
      );

      return bottleBoxesAmounts;
    },
    isEnoughAmount(requiredAmount, amountInTheCart) {
      return Object.keys(requiredAmount).every(
        (skuKey) => amountInTheCart[skuKey]?.quantity >= requiredAmount[skuKey]
      );
    },
    isReturnablePackagingsEnough() {
      const { glassBottlesAmounts, returnablePackagings, boxesPackagings } =
        this.glassBottles;

      const isReturnablePackagingsEnough = this.isEnoughAmount(
        glassBottlesAmounts,
        returnablePackagings
      );

      // if don't have enough glass bottles, don't need to validate the boxes
      if (!isReturnablePackagingsEnough) {
        return false;
      }

      const bottleBoxesAmounts = this.getBottleBoxesRequiredAmount();
      const isBoxesPackagingsEnough = this.isEnoughAmount(
        bottleBoxesAmounts,
        boxesPackagings
      );

      return isBoxesPackagingsEnough;
    },
    getGlassBottlesMessage() {
      const {
        productsWithReturnablePackaging,
        returnablePackagings,
        glassBottlesAmounts,
      } = this.glassBottles;

      let productsWithoutBottles = productsWithReturnablePackaging.filter(
        (bottle) =>
          glassBottlesAmounts[bottle.sku] >
          (returnablePackagings[bottle.sku]?.quantity || 0)
      );

      if (
        productsWithoutBottles.length === 0 &&
        !this.isReturnablePackagingsEnough()
      ) {
        productsWithoutBottles = productsWithReturnablePackaging;
      }

      return {
        message: this.buildMessageForDynamicLists({
          listText: 'cartPage.glassBottlesItemsMessage',
          listParams: productsWithoutBottles,
        }),
      };
    },
    buildMessageForDynamicLists({ listText, listParams }) {
      const listProcessed = listParams.map((params) => {
        const msg = this.getMessageFromLocale({
          message: listText,
          parameters: params,
        });
        return msg;
      });
      return listProcessed;
    },
    getMessageFromLocale({ message, parameters }) {
      return this.$t(message, parameters);
    },
    getGlassBottlesBySKU() {
      const glassBottlesbySKU = [];
      this.glassBottles.productsWithReturnablePackaging.forEach((bottle) => {
        const productSKU = this.$store.state.search.products.find(
          (p) => p.product.sku === bottle.sku
        );
        if (productSKU) {
          glassBottlesbySKU.push(productSKU);
        }
      });
      return glassBottlesbySKU;
    },
    setGlassBottleButton(glassBottleButtonAction) {
      // set type as 'redirect' or 'addGlassBottles'
      const hasButtonAction = Object.keys(GLASS_BOTTLE_BUTTON_ACTION).some(
        (key) => GLASS_BOTTLE_BUTTON_ACTION[key] === glassBottleButtonAction
      );
      if (hasButtonAction) {
        this.glassBottlesBySKU = this.getGlassBottlesBySKU();
        this.buttonType =
          this.glassBottlesBySKU.length > 0 ? glassBottleButtonAction : '';
        this.buttonHeader = `cartPage.${glassBottleButtonAction}`;
        // hide the button if there's no returnablePackaging available
        this.buttonText = this.findRequiredReturnablePackagings().length
          ? `cartPage.${glassBottleButtonAction}Button`
          : '';
        this.buttonStyle =
          glassBottleButtonAction === GLASS_BOTTLE_BUTTON_ACTION.ADD
            ? 'primary'
            : 'outline';
      }
    },
    // Retrieves the SKU from requiredReturnablePackagingAmounts and returnablePackagingInTheCart
    // if their amounts are not matching
    getReturnablePackagingAmountsNotMatching(
      requiredReturnablePackagingAmounts,
      returnablePackagingInTheCart
    ) {
      const returnablePackagingWithDifferentAmounts = Object.keys(
        returnablePackagingInTheCart
      ).filter(
        (skuKey) =>
          returnablePackagingInTheCart[skuKey].quantity !==
          requiredReturnablePackagingAmounts[skuKey]
      );

      Object.keys(requiredReturnablePackagingAmounts).forEach((skuKey) => {
        if (
          requiredReturnablePackagingAmounts[skuKey] !==
            returnablePackagingInTheCart[skuKey]?.quantity &&
          returnablePackagingWithDifferentAmounts.indexOf(skuKey) === -1
        ) {
          returnablePackagingWithDifferentAmounts.push(skuKey);
        }
      });

      return returnablePackagingWithDifferentAmounts;
    },
    // @TODO: https://jira.coke.com/jira/browse/KOBOSS-5321
    redirectToEmptyReturnablePackagingsPage() {
      const { products } = this.$store.state.search;
      let glassBottle;
      const glassBottlesWithSku =
        this.glassBottles.productsWithReturnablePackaging.filter(
          (glassB) => glassB.sku
        );
      if (glassBottlesWithSku) {
        glassBottle = products.find(
          (p) => p.product.sku === glassBottlesWithSku[0]?.sku
        );
        router.navigateToPage(
          `/category/brands/products/${glassBottle.product.category}/${glassBottle.product.brand}`
        );
      }
    },
    getReturnablePackagingsWithAmountsNotMatching({
      glassBottlesAmounts,
      returnablePackagings,
      bottleBoxesAmounts,
      boxesPackagings,
    }) {
      const glassBottlesWithDifferentAmounts =
        this.getReturnablePackagingAmountsNotMatching(
          glassBottlesAmounts,
          returnablePackagings
        );

      const bottlesBoxesWithDifferentAmounts =
        this.getReturnablePackagingAmountsNotMatching(
          bottleBoxesAmounts,
          boxesPackagings
        );

      const glassBottlesAndBoxesWithDifferentAmounts = [
        ...glassBottlesWithDifferentAmounts,
        ...bottlesBoxesWithDifferentAmounts,
      ];

      return glassBottlesAndBoxesWithDifferentAmounts;
    },
    findRequiredReturnablePackagings() {
      const { products } = this.$store.state.search;
      const { glassBottlesAmounts, returnablePackagings, boxesPackagings } =
        this.glassBottles;

      const bottleBoxesAmounts = this.getBottleBoxesRequiredAmount();

      const glassBottlesAndBoxesWithDifferentAmounts =
        this.getReturnablePackagingsWithAmountsNotMatching({
          glassBottlesAmounts,
          returnablePackagings,
          bottleBoxesAmounts,
          boxesPackagings,
        });

      const requiredReturnablePackagings = products.filter((p) =>
        glassBottlesAndBoxesWithDifferentAmounts.some(
          (glassBottle) => Number(glassBottle) === Number(p.product.sku)
        )
      );

      return requiredReturnablePackagings;
    },
    addReturnablePackagingOnCart(skuKey, qty, product) {
      const productOnCart = this.cartItems.find(
        (item) => Number(item.product.sku) === Number(skuKey)
      );

      const quantityOnCart = productOnCart?.quantity || 0;
      const quantity = qty - quantityOnCart;
      const productToAdd = {
        product,
        quantity,
      };

      this.$store.dispatch('addItemToCart', productToAdd);
    },
    adjustReturnablePackagingsOnCart() {
      const { glassBottlesAmounts, returnablePackagings, boxesPackagings } =
        this.glassBottles;

      const bottleBoxesAmounts = this.getBottleBoxesRequiredAmount();

      const glassBottlesAndBoxesWithDifferentAmounts =
        this.getReturnablePackagingsWithAmountsNotMatching({
          glassBottlesAmounts,
          returnablePackagings,
          bottleBoxesAmounts,
          boxesPackagings,
        });

      const requiredAmounts = { ...bottleBoxesAmounts, ...glassBottlesAmounts };

      const requiredReturnablePackagings =
        this.findRequiredReturnablePackagings();

      glassBottlesAndBoxesWithDifferentAmounts.forEach((skuKey) => {
        const requiredReturnablePackaging = requiredReturnablePackagings.find(
          (required) => Number(required.product.sku) === Number(skuKey)
        );

        // If we couldn't fine any "requiredReturnablePackaging" on products,
        // we couldn't neither add or remove it to cart
        // so there's no reason to try to do so
        if (requiredReturnablePackaging && !requiredAmounts[skuKey]) {
          this.$store.dispatch(
            'removeItemFromCart',
            requiredReturnablePackaging.product
          );
        }

        if (requiredReturnablePackaging && requiredAmounts[skuKey]) {
          this.addReturnablePackagingOnCart(
            skuKey,
            requiredAmounts[skuKey],
            requiredReturnablePackaging.product
          );
        }
      });

      openModalNotification(this.$bvModal, {
        title: this.$t('toast.titleGlassBottles'),
        description: this.$t('toast.bodyGlassBottles'),
        okTitle: this.$t('ok'),
      });
    },
    getGlassBottleButtonAction(glassBottleButtonAction) {
      if (glassBottleButtonAction === GLASS_BOTTLE_BUTTON_ACTION.REDIRECT) {
        this.redirectToEmptyReturnablePackagingsPage();
      }
      if (glassBottleButtonAction === GLASS_BOTTLE_BUTTON_ACTION.ADD) {
        this.adjustReturnablePackagingsOnCart();
      }
    },
    getReturnablePackagingsOverLimitDescription() {
      const { returnablePackagings, glassBottlesAmounts, boxesPackagings } =
        this.glassBottles;

      const returnablePackagingsOverLimit = Object.keys(
        returnablePackagings
      ).reduce((bottlesOverLimit, skuKey) => {
        if (
          returnablePackagings[skuKey].quantity >
          (glassBottlesAmounts[skuKey] || 0)
        ) {
          bottlesOverLimit.push(returnablePackagings[skuKey].description);
        }
        return bottlesOverLimit;
      }, []);

      const bottleBoxesAmounts = this.getBottleBoxesRequiredAmount();

      const boxesPackagingsOverLimit = Object.keys(boxesPackagings).reduce(
        (boxesOverLimit, skuKey) => {
          if (
            boxesPackagings[skuKey].quantity > (bottleBoxesAmounts[skuKey] || 0)
          ) {
            boxesOverLimit.push(boxesPackagings[skuKey].description);
          }
          return boxesOverLimit;
        },
        []
      );

      return {
        message: [
          ...returnablePackagingsOverLimit,
          ...boxesPackagingsOverLimit,
        ],
      };
    },
    isReturnablePackagingsQuantityOverLimit() {
      const returnablePackagingsOverLimitMessage =
        this.getReturnablePackagingsOverLimitDescription();
      return returnablePackagingsOverLimitMessage.message.length;
    },
  },
};
</script>
