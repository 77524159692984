export const getRoutebyQueryString = (urlParams) => {
  let redirect;

  switch (true) {
    case urlParams.has('search'):
      redirect = urlParams.get('search');
      return `/search?redirect=${redirect}`;
    case urlParams.has('page'):
      redirect = urlParams.get('page');
      return `/${redirect}`;
    default:
      return '/home';
  }
};

export default { getRoutebyQueryString };
