<template>
  <div>
    <div class="d-flex align-items-center">
      <b-icon
        class="align-self-center mt-2"
        font-scale="1"
        icon="truck"
        variant="black"
        :aria-label="$t('order.complete.imgRedTruck')"
      />
      <div class="ml-2 text-left">
        <p class="mt-2 mb-0 fw-200 fs-10">
          {{ $t('order.complete.estimatedDeliveryDate') }}
        </p>
      </div>
    </div>
    <div>
      <p
        id="dataDeliveryDate"
        class="fs-16 mt-2"
      >
        {{ deliveryDate }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    deliveryDate: {
      type: String,
      default: '',
    },
  },
};
</script>
