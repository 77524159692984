import { put } from '@/utils/httpClient';
import { getStorageBottler } from '@/utils/config';

async function getOrderEdit(data) {
  try {
    const bottlerStorage = getStorageBottler();
    const result = await put({
      url: 'order/edit',
      data,
      useAzureAPIManagement: bottlerStorage?.useAzureAPIManagement,
    });

    if (result.status === 200 && result.data) {
      return result.data;
    }
    throw result;
  } catch (error) {
    // Catching the error and returning a generic error message
    throw new Error('คำขอประมวลผลไม่สำเร็จ กรุณาลองอีกครั้ง');
  }
}

export default { getOrderEdit };
