<template>
  <div
    id="footer"
    class="footer fs-8 text-light"
  >
    <p class="m-0">
      {{ $t('rights', { year: currentYear }) }}
    </p>
    <p class="app-version m-0">
      {{ $t('version') }}
      {{ appVersion }}
    </p>
  </div>
</template>

<script>
import packageInfo from '../../package.json';

export default {
  data() {
    return {
      currentYear: new Date().getFullYear(),
      appVersion: packageInfo.version,
    };
  },
};
</script>
