import { post } from '@/utils/httpClient';
import {
  getBottlerConfigEnv,
  isValidBottler,
  isLatamBottler,
  getLatamBottlerValueByCode,
  setStorageBottler,
  setSessionData,
} from '@/utils/config';

const authenticate = async ({ token }) => {
  const bottlerStorage = getBottlerConfigEnv();
  const result = await post({
    url: 'webtoken/validate',
    data: {
      token,
    },
    useAzureAPIManagement: bottlerStorage?.useAzureAPIManagement,
  });

  try {
    if (result.status === 200) {
      const { data } = result;
      const { authError } = data.payload ? data.payload : '';
      let { bottler } = data.payload ? data.payload : '';
      const { clientId } = data.payload ? data.payload : '';

      if (bottler && isValidBottler({ bottler })) {
        if (isLatamBottler()) {
          bottler = getLatamBottlerValueByCode(bottler);
        }

        setSessionData({ key: 'authSettings', value: { bottler, clientId } });

        if (data.result) {
          return isValidBottler({ bottler }) && setStorageBottler({ bottler });
        }

        return false;
      }

      return authError ? { authError } : false;
    }

    return false;
  } catch (e) {
    return false;
  }
};

export default {
  authenticate,
};
