<template>
  <div
    class="card-body d-flex flex-column justify-content-between mb-3 p-0 position-relative"
  >
    <div class="w-100">
      <div
        class="product__img-container w-100 text-center"
        style="margin-top: 10px"
      >
        <div class="position-relative d-block">
          <div />
          <ImgGeneral
            :alt="imageAlt"
            class="mw-100"
            :data-src="imageUrl"
          />
        </div>
      </div>
      <div class="p-2 text-center">
        <div class="mb-2 fs-12 fw-600 mt-3">
          <div class="align-self-end title-card fw-500 fs-18 mt-3 mb-2">
            {{ title }}
          </div>
          <div
            v-if="description"
            class="description-card fw-200 fs-14 mt-2 mb-4"
          >
            <span>
              {{ description }}
            </span>
          </div>
        </div>
      </div>
      <div class="w-100 p-2">
        <button
          class="btn-cart btn btn-primary w-100"
          type="button"
          @click="cbClick(url)"
        >
          <span class="p-3">
            {{ btnMessage }}
          </span>
        </button>
        <div />
      </div>
    </div>
  </div>
</template>

<script>
import ImgGeneral from '@/components/shared/ImgGeneral';

export default {
  components: {
    ImgGeneral,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    btnMessage: {
      type: String,
      default: '',
    },
    imageUrl: {
      type: String,
      default: '',
      required: true,
    },
    imageAlt: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: '',
    },
    cbClick: {
      type: Function,
      default: () => {},
    },
    displayImage: {
      type: String,
      default: 'banner',
    },
  },
  computed: {
    imageClass() {
      return this.displayImage === 'sideways'
        ? 'img-card align-middle float-right p-3'
        : 'img-card w-100 br-12';
    },
  },
};
</script>

<style scoped>
.card-body:first-child {
  margin-right: 20px;
}

@media all and (min-width: 1024px) {
  .card-group:first-child {
    margin-right: 20px;
    margin-left: 20px;
  }
  .card-group:nth-child(2) {
    margin-right: 20px;
  }
}
</style>
