<template>
  <div>
    <div
      class="accordion"
      role="tablist"
    >
      <b-card
        no-body
        class="mb-1"
      >
        <b-card-header
          header-tag="header"
          role="tab"
        >
          <div>
            <div class="accordion-button">
              <b-button
                v-b-toggle="'accordion-' + index"
                block
                variant="info"
              >
                {{ family.name }}
              </b-button>
            </div>
            <div
              v-if="selectedProduct() < family.minimum"
              class="mandatory"
            >
              {{ $t('mandatory') }}
            </div>
            <div v-if="selectedProduct() >= family.minimum">
              <ImgGeneral
                id="checkedIcon"
                :url="assetsPath + 'img/icon/black-check.svg'"
                :show-fallback-img="true"
              />
            </div>
          </div>
          <div
            v-if="selectedProduct() >= 1"
            class="subtitle"
          >
            {{
              $t('selected', {
                numProduct: selectedProduct(),
              })
            }}
          </div>
          <div v-else>
            {{
              $t('combo.comboSubTitle', {
                minimum: family.minimum,
                product: family.minimum > 1 ? $t('products') : $t('product'),
              })
            }}
          </div>
        </b-card-header>

        <div
          v-for="product in family.products"
          :key="product.product.sku"
        >
          <b-collapse
            :id="'accordion-' + index"
            visible
            role="tabpanel"
          >
            <div class="product-item">
              <ImgGeneral
                class="product-img"
                :url="product.product.imageUrl"
                :show-fallback-img="true"
              />
              <div class="description">
                <h6 class="product__title mb-2">
                  {{ product.product.brand }}
                </h6>
                <h6 class="product__price">
                  {{ formatPrice(Number(product.product.price)) }}
                </h6>
                <p class="fs-10 fw-200">
                  {{ product.product.description }}
                </p>
              </div>

              <div
                class="product__quantity-section d-flex align-items-center"
                :class="initialQuantity(product)"
              >
                <div class="d-flex">
                  <b-button
                    v-show="product.quantity > 0"
                    class="minus-button"
                    :aria-label="$t('ariaLabel.decreaseAmount')"
                    variant="light"
                    :disabled="product.quantity <= 0"
                    @click="$emit('minus', product)"
                  >
                    -
                  </b-button>
                  <input
                    v-show="product.quantity > 0"
                    v-model="product.quantity"
                    :title="$t('combo.quantity')"
                    class="product__input-quantity mr-1 ml-1"
                    type="number"
                    :disabled="true"
                    @change="$emit('change', product)"
                  />
                  <b-button
                    class="plus-button"
                    :aria-label="$t('ariaLabel.increaseAmount')"
                    variant="light"
                    @click="$emit('plus', product)"
                  >
                    +
                  </b-button>
                </div>
              </div>
            </div>
          </b-collapse>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import ProductMixin from '@/components/mixins/ProductMixin';
import ImgGeneral from '@/components/shared/ImgGeneral';
import { getAssetsPath } from '@/utils/config';

export default {
  components: {
    ImgGeneral,
  },
  mixins: [ProductMixin],
  props: {
    family: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      assetsPath: getAssetsPath(),
    };
  },
  methods: {
    initialQuantity(product) {
      return product.quantity >= 1
        ? 'product__quantity-section'
        : 'product__quantity-section initial';
    },
    selectedProduct() {
      const numProducts = this.family.products.reduce((total, product) => {
        const { quantity } = product;
        return total + quantity;
      }, 0);
      return numProducts;
    },
  },
};
</script>

<style scoped>
.product-item {
  display: grid;
  grid-template-columns: 1fr 1.5fr 1.5fr;
  padding-top: 5px;
}

.product__quantity-section.initial {
  grid-template-columns: 1fr;
  text-align: right;
}

.product-img {
  max-width: 90px;
}

header .btn-info,
.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled):active:focus,
header .btn-info.not-collapsed,
header .btn-info.collapsed,
.combo-name {
  padding: 2px;
  font-size: 3vh;
  font-weight: 800;
  color: var(--dark-grey);
  text-align: left;
  background-color: var(--light);
  border: 0;
  box-shadow: none;
}

.card-header {
  padding: 2px;
  font-size: 0.8rem;
  font-weight: 800;
  color: var(--dark-grey);
}

.mandatory {
  float: right;
}

#checkedIcon {
  float: right;
  width: 18px;
  height: auto;
  margin-right: 30px;
}
</style>
