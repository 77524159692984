<template>
  <div class="page__content">
    <Breadcrumb :title="$t('pageTitles.promotions')" />
    <div class="container max-section-width">
      <div class="row">
        <div class="col fw-200 text-center max-w-700 mx-auto mt-3 mb-4">
          <p class="w-100 fs-12 fs-md-14">
            {{ $t('pageTitles.promotions-list') }}
          </p>
        </div>
      </div>
      <div
        class="col list-unstyled d-flex flex-wrap justify-content-center pl-1 pr-1"
      >
        <ServiceSmallCard
          :title="$t('pageTitles.combos')"
          :btn-message="$t('accessButton')"
          :url="'combos'"
          :image-url="assetsPath + 'img/icon/combo.png'"
          :cb-click="onClickOpenCombos"
        />
        <ServiceSmallCard
          :title="$t('pageTitles.promotions')"
          :btn-message="$t('accessButton')"
          :url="'promotions'"
          :image-url="assetsPath + 'img/nav/price-tag.svg'"
          :cb-click="onClickOpenPromotions"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/components/shared/Breadcrumb.vue';
import { MODULES } from '@/constants/feature';
import ServiceSmallCard from '@/components/ServiceSmallCard.vue';
import { getStorageBottler, getAssetsPath } from '@/utils/config';
import router from '@/router';

export default {
  components: {
    Breadcrumb,
    ServiceSmallCard,
  },
  data() {
    return {
      appModules: MODULES,
      assetsPath: getAssetsPath(),
    };
  },
  created() {
    const bottlerStorage = getStorageBottler();
    this.bottler = bottlerStorage?.bottler.toLowerCase();
  },
  methods: {
    onClickOpenCombos() {
      router.navigateToPage('/combos');
    },
    onClickOpenPromotions() {
      router.navigateToPage('/promotions');
    },
  },
};
</script>

<style scoped>
.card {
  flex-direction: row;
  background: none;
  border: 0;
}
</style>
