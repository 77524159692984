<template>
  <div class="page__content">
    <Breadcrumb />
    <Carousel />
    <div class="container max-section-width pt-3">
      <div v-if="!loading">
        <div v-if="!isBingoCardAlreadyComplete">
          <div class="row">
            <div class="col">
              <p class="bingo-text">
                {{ $t('bingo.headline') }}
              </p>
              <button
                class="bingo-btn-more-info"
                type="button"
                @click="toggleShowKnowMore()"
              >
                {{ $t('ctaKnowMoreInfo') }}
                <span
                  class="bingo-btn-arrow"
                  :class="{ 'bingo-btn-arrow-open': showKnowMore }"
                />
              </button>
              <div
                class="bingo-card-more-info"
                :class="{ 'd-none': !showKnowMore }"
              >
                <div>
                  <p class="bingo-text bingo-text-md">
                    {{ $t('bingo.whatIsQuestion') }}
                  </p>
                  <p class="bingo-text">
                    {{ $t('bingo.whatIsAnswer') }}
                  </p>
                </div>
                <div class="mt-3">
                  <p class="bingo-text bingo-text-md">
                    {{ $t('bingo.prizeQuestion') }}
                  </p>
                  <p class="bingo-text">
                    {{ $t('bingo.prizeAnswer') }}
                  </p>
                </div>
                <div class="mt-3">
                  <p class="bingo-text bingo-text-md">
                    {{ $t('bingo.pointsQuestion') }}
                  </p>
                  <p class="bingo-text">
                    {{ $t('bingo.pointsAnswer') }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col mt-3">
              <p class="bingo-text">
                {{ $t('bingo.yourCard') }}
              </p>
              <p class="bingo-text">
                {{ $t('bingo.pointsExpiration') }}
                <span class="bingo-text-md">
                  {{ expiryDays }} {{ $t('days') }}
                </span>
                ({{ expiryDate }})
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col mt-3">
            <BingoCard
              v-if="bingo.currentCard"
              :current-card="bingo.currentCard"
              :is-bingo-card-already-complete="isBingoCardAlreadyComplete"
            />
          </div>
        </div>
        <div class="row">
          <div class="col mt-3">
            <BingoHistory
              v-if="bingo.history"
              :history="bingo.history"
            />
          </div>
        </div>
      </div>
      <div
        v-if="loading"
        class="loading"
      >
        <b-spinner label="Loading..." />
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import Carousel from '@/components/Carousel.vue';
import BingoCard from '@/components/bingo/BingoCard.vue';
import BingoHistory from '@/components/bingo/BingoHistory.vue';
import apiBingo from '@/api/bingo';
import { getDateLastDayOfCurrentMonth, getDateDiffInDays } from '@/utils/pages';
import Breadcrumb from '@/components/shared/Breadcrumb.vue';

export default {
  components: {
    Carousel,
    BingoCard,
    BingoHistory,
    Breadcrumb,
  },
  data() {
    return {
      loading: true,
      bingo: undefined,
      showKnowMore: false,
      isBingoCardAlreadyComplete: false,
      expiryDays: 0,
      expiryDate: '',
    };
  },
  async created() {
    this.bingo = await apiBingo.getClientBingo();
    this.loading = false;
    this.isBingoCardAlreadyComplete = this.getIsBingoCardAlreadyComplete();
    this.setExpirationValues();
  },
  methods: {
    toggleShowKnowMore() {
      this.showKnowMore = !this.showKnowMore;
    },
    getIsBingoCardAlreadyComplete() {
      if (!this.bingo?.currentCard?.products?.length) return false;

      const { currentCard } = this.bingo;
      let response = true;

      currentCard.products.forEach((product) => {
        const isAcquired = currentCard.acquiredProducts.some(
          (p) => p.SKU === product.SKU
        );

        if (!isAcquired) response = false;
      });

      return response;
    },
    setExpirationValues() {
      const expiryDate = getDateLastDayOfCurrentMonth();
      this.expiryDays = getDateDiffInDays({
        start: DateTime.now(),
        end: expiryDate,
      });
      this.expiryDate = this.$d(expiryDate.toJSDate(), 'short');
    },
  },
};
</script>

<style scoped>
.bingo-header {
  display: flex;
  padding: 24px 0;
  vertical-align: middle;
}

.bingo-header h3 {
  margin: 0 16px 0 8px;
  font-size: var(--fs-24);
  font-weight: 800;
  color: var(--primary);
}

.bingo-text {
  font-size: var(--fs-12);
  font-weight: 100;
  color: var(--dark-grey);
}

.bingo-text-md {
  font-weight: 300;
}

.bingo-btn-more-info {
  display: flex;
  padding: 0;
  font-size: var(--fs-12);
  font-weight: 300;
  color: var(--primary);
  text-decoration: underline;
  background: none;
  border: 0;
}

.bingo-btn-arrow {
  width: 0;
  margin-top: 3px;
  margin-left: 5px;
  border: 5px solid transparent;
  border-top: 5px solid;
  border-right: 5px solid;
  transform: rotate(45deg);
}

.bingo-btn-arrow-open {
  transform: rotate(135deg);
}

.bingo-card-more-info {
  padding: 15px;
  margin-top: 15px;
  background-color: var(--light-grey);
  border-radius: 5px;
}
</style>
