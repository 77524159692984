import { post } from '@/utils/httpClient';

async function getOrderSimulate(data) {
  const result = await post({
    url: 'order/simulate',
    data,
  });

  if (result.status === 200 && result.data) {
    return result.data;
  }

  if (result === 'Error: Request failed with status code 406') {
    return 'limitOrder';
  }

  return undefined;
}

export default { getOrderSimulate };
