<template>
  <div
    v-if="client.isOrderOptionActive === true || !isOnHomePage()"
    class="carousel pb-1 pt-0 max-section-width w-100"
  >
    <div
      v-if="loading"
      class="text-center m-5 p-5 mb-md-5 mt-md-5"
    >
      <b-spinner label="Loading..." />
    </div>
    <div
      v-show="showBanner() && !loading"
      id="swiper-parent"
    >
      <div class="swiper mt-5">
        <div class="swiper-wrapper">
          <div
            v-for="banner in banners"
            :key="banner.index"
            class="swiper-slide"
          >
            <AppLink :to="banner.redirect">
              <img
                class="w-100 banner-mobile"
                :class="{ 'd-md-none': banner.srcDesktop }"
                :src="banner.src"
                :alt="banner.altText"
                @click="clickBanner(banner.index)"
              />
              <img
                class="w-100 d-none banner-desktop"
                :class="{ 'd-md-block': banner.srcDesktop }"
                :src="banner.srcDesktop"
                :alt="banner.altText"
                @click="clickBanner(banner.index)"
              />
            </AppLink>
          </div>
        </div>
        <div
          v-if="banners.length > 1"
          class="swiper-pagination-wrapper position-relative d-flex justify-content-center align-items-baseline"
        >
          <span class="playPause mr-3">
            <button
              v-show="!isCarouselPlaying"
              type="button"
              class="btn-play btn-play-pause"
              @click="play()"
            >
              <ImgGeneral
                :url="assetsPath + 'img/carousel/play.svg'"
                :alt="$t('play')"
                :is-one-px-placeholder="true"
              />
            </button>
            <button
              v-show="isCarouselPlaying"
              type="button"
              class="btn-pause btn-play-pause"
              @click="pause()"
            >
              <ImgGeneral
                :url="assetsPath + 'img/carousel/pause.svg'"
                :is-one-px-placeholder="true"
                :alt="$t('pause')"
              />
            </button>
          </span>
          <div
            class="swiper-pagination d-flex align-items-center flex-nowrap mt-3 mt-md-4"
          />
        </div>
        <div class="swiper-button-prev">
          <ImgGeneral
            :url="assetsPath + 'img/carousel/arrow-prev.svg'"
            :is-one-px-placeholder="true"
            :alt="$t('ariaLabel.prevBannerImg')"
            :title="$t('ariaLabel.prevBanner')"
          />
        </div>
        <div class="swiper-button-next">
          <ImgGeneral
            :url="assetsPath + 'img/carousel/arrow-next.svg'"
            :is-one-px-placeholder="true"
            :alt="$t('ariaLabel.nextBannerImg')"
            :title="$t('ariaLabel.nextBanner')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
import { getStorageBottler, getAssetsPath } from '@/utils/config';
import ImgGeneral from '@/components/shared/ImgGeneral';
import AppLink from '@/components/shared/AppLink';
import { eventBus, events } from '@/utils/events/eventBus';
import Analytics from '@/utils/analytics';

export default {
  components: {
    ImgGeneral,
    AppLink,
  },
  data() {
    return {
      duration: 4000,
      loading: true,
      assetsPath: getAssetsPath(),
      swiper: null,
    };
  },
  computed: {
    ...mapGetters({
      banners: 'getBanners',
      client: 'getClient',
    }),
    isCarouselPlaying() {
      return this.swiper?.autoplay?.running;
    },
  },
  watch: {
    banners() {
      this.bannersLoaded();
    },
  },
  mounted() {
    if (this.banners.length) {
      this.bannersLoaded();
      return;
    }
    this.$store.dispatch('getBanners');
  },
  methods: {
    ...mapActions(['setBanners']),
    bannersLoaded() {
      eventBus.$on(events.APP_IS_SHOWN, this.isAppShown);
      this.$nextTick(() => {
        this.initCarousel();
        this.loading = false;
      });
    },
    initCarousel() {
      this.swiper = new Swiper('.swiper', {
        loop: false,
        autoHeight: true,
        slidesPerView: 'auto',
        autoplay: {
          delay: this.duration,
        },
        pauseOnMouseEnter: 'true',
        centeredSlides: 'true',
        spaceBetween: 10,
        speed: 500,
        waitForTransition: 'false',
        modules: [Navigation, Pagination, Autoplay],
        pagination: {
          el: '.swiper-pagination-wrapper .swiper-pagination',
          clickable: 'true',
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      });
      this.swiper.autoplay.stop();
    },
    isAppShown() {
      this.play();
    },
    play() {
      this.swiper.autoplay.start();
    },

    clickBanner(bannerIndex) {
      const banner = this.banners[bannerIndex - 1];
      const redirectURI = banner.redirect || 'Redirect not set';
      const bannerSrc = window.location.href;
      Analytics.logEvent('banner_click', {
        bannerSrc,
        bannerIndex,
        redirectURI,
      });
    },
    pause() {
      this.swiper.autoplay.stop();
    },
    showBanner() {
      if (!this.banners || !this.banners.length || this.isOnHomePage())
        return false;
      return true;
    },
    isOnHomePage() {
      const bottlerStorage = getStorageBottler();
      return (
        this.$route.meta.title !== 'home' &&
        bottlerStorage?.showCarouselOnlyOnHomePage
      );
    },
  },
};
</script>

<style src="@/assets/styles/swiper-bundle.css"></style>

<style scoped>
.swiper {
  --swiper-pagination-bottom: 0;
  --swiper-pagination-bullet-width: 70px;
  --swiper-pagination-color: var(--primary);
  --swiper-navigation-size: 26px;
}
:deep(.swiper-pagination-wrapper .swiper-pagination-bullet) {
  border-radius: 10px;
  max-width: 24px;
}

.swiper-pagination-bullets.swiper-pagination-horizontal {
  position: relative;
  bottom: auto;
  top: auto;
  left: auto;
  width: auto;
}

.btn-play-pause {
  margin: 0;
  padding: 0;
  border: 0;
  background-color: transparent;
}

.swiper-slide {
  text-align: center;
  width: 80%;
  margin-bottom: 20px;
  opacity: 0.5;
  transition: opacity 0.4s ease-in-out;
}

.swiper-slide.swiper-slide-active {
  opacity: 1;
}

.swiper-slide img {
  max-width: 100%;
  border-radius: 20px;
  max-height: 100%;
}

.swiper-button-prev,
.swiper-button-next {
  transform: translateY(-50%);
}

.swiper-button-prev:after,
.swiper-button-next:after {
  content: ' ';
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  display: none;
}

@media only screen and (min-width: 850px) {
  :deep(.swiper-pagination-wrapper .swiper-pagination-bullet) {
    max-width: 50px;
  }
}
</style>
