import Common from '@/utils/common';

function getDateString(deliveryDate, locale) {
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'UTC',
  };
  const nextDate = new Date(deliveryDate);
  const formattedDate = nextDate.toLocaleString(locale, options);
  return formattedDate;
}

function getFooterString(minimumOrder) {
  const price = Number(minimumOrder).toLocaleString('th-TH', {
    style: 'currency',
    currency: 'THB',
  });
  return `${price.split('.')[0]}`;
}

export const getDeliveryDatesCommon = (
  client,
  isCartCutoffAcknowledged,
  locale,
  $store
) => {
  const currentTime = new Date();
  const endTime = new Date();
  endTime.setHours(17, 30); // 5:30 PM
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  let tomorrowString = tomorrow.toISOString().slice(0, 10);

  const deliveryDates = [];

  // while tomorrow is a sunday or public holiday
  while (tomorrow.getDay() === 0 || Common.isAPublicHoliday(tomorrowString)) {
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrowString = tomorrow.toISOString().slice(0, 10);
  }

  // if weekend or public holiday ; then increment tomorrow until it is a non weekend/public holiday
  // skip the first date from the delivery dates
  let localCartCutoffAcknowledged = isCartCutoffAcknowledged;
  if (
    (currentTime >= endTime &&
      new Date(tomorrowString) >= new Date(client?.deliveryDates[0].Date)) ||
    (currentTime.getDay === 6 &&
      new Date() >= new Date(client?.deliveryDates[0].Date))
  ) {
    // past 5:30 - show different dates
    // skip first date
    $store.commit('setCartCutoffAcknowlegement', true);
    localCartCutoffAcknowledged = $store.state.cart.cartCutoffAcknowledged;
  }

  let expressDeliveryObj = client?.deliveryDates[0];

  if (localCartCutoffAcknowledged && client?.distance === 'C') {
    expressDeliveryObj = client?.deliveryDates[1];
  }

  // test if customer is in Zone A
  const isCustomerTierA = client?.deliveryDates.every(
    (item) => item.IsInbook === true
  );

  expressDeliveryObj.header = '1';
  expressDeliveryObj.sideInformation = getDateString(
    expressDeliveryObj.Date,
    locale
  );
  expressDeliveryObj.footer = getFooterString(expressDeliveryObj.MinimumValue);

  let secondInBookElement = {};
  // get the second isInBook element
  let count = 0;
  secondInBookElement = client?.deliveryDates.find((item) => {
    if (item.IsInbook === true) {
      count++;
      return count === 2;
    }
    return true;
  });

  let weeklyDeliveryObj = {};
  let weeklyDeliveryObj2 = {};
  let weeklyDeliveryObj3 = {};
  let weeklyDeliveryObj4 = {};

  if (
    (isCustomerTierA || expressDeliveryObj.IsInbook) &&
    client?.distance !== 'D'
  ) {
    weeklyDeliveryObj = secondInBookElement;
  } else if (client?.distance === 'C') {
    weeklyDeliveryObj = client?.deliveryDates.find(
      (item) => item.IsInbook === true
    );
  } else if (client?.distance === 'D') {
    // ZONE D-Section
    const isInbookArray = client?.deliveryDates.filter(
      (d) => d.IsInbook === true && d.MinimumValue !== null
    );
    // first obj
    let expressDeliveryObj = isInbookArray[0];
    if (expressDeliveryObj !== undefined) {
      expressDeliveryObj = JSON.parse(JSON.stringify(expressDeliveryObj));
      expressDeliveryObj.sideInformation = getDateString(
        expressDeliveryObj.Date,
        locale
      );
      expressDeliveryObj.header = '1';
      expressDeliveryObj.selected = false;

      expressDeliveryObj.footer = getFooterString(
        expressDeliveryObj.MinimumValue
      );
      deliveryDates.push(expressDeliveryObj);
    }

    // second obj
    let weeklyDeliveryObj = isInbookArray[1];
    if (weeklyDeliveryObj !== undefined) {
      weeklyDeliveryObj = JSON.parse(JSON.stringify(weeklyDeliveryObj));
      weeklyDeliveryObj.sideInformation = getDateString(
        weeklyDeliveryObj.Date,
        locale
      );

      weeklyDeliveryObj.header = ' ';
      weeklyDeliveryObj.selected = false;

      weeklyDeliveryObj.footer = getFooterString(
        weeklyDeliveryObj.MinimumValue
      );
      deliveryDates.push(weeklyDeliveryObj);
    }

    // third obj
    const thirdIsInbook = isInbookArray[2];
    if (thirdIsInbook !== undefined) {
      weeklyDeliveryObj2 = JSON.parse(JSON.stringify(thirdIsInbook));

      weeklyDeliveryObj2.sideInformation = getDateString(
        weeklyDeliveryObj2.Date,
        locale
      );

      weeklyDeliveryObj2.header = '  ';
      weeklyDeliveryObj2.selected = false;

      weeklyDeliveryObj2.footer = getFooterString(
        weeklyDeliveryObj2.MinimumValue
      );
      deliveryDates.push(weeklyDeliveryObj2);
    }

    // fourth obj
    const fourthIsInbook = isInbookArray[3];
    if (fourthIsInbook !== undefined) {
      weeklyDeliveryObj3 = JSON.parse(JSON.stringify(fourthIsInbook));
      weeklyDeliveryObj3.sideInformation = getDateString(
        weeklyDeliveryObj3.Date,
        locale
      );

      weeklyDeliveryObj3.header = '   ';
      weeklyDeliveryObj3.selected = false;

      weeklyDeliveryObj3.footer = getFooterString(
        weeklyDeliveryObj3.MinimumValue
      );
      deliveryDates.push(weeklyDeliveryObj3);
    }

    if (
      currentTime >= endTime &&
      new Date(isInbookArray[0].Date) > new Date(tomorrowString)
    ) {
      // no shift
    } else if (localCartCutoffAcknowledged) {
      deliveryDates.shift();
    }
  } else {
    // find the next delivery date after the express delivery date
    weeklyDeliveryObj = client?.deliveryDates.find(
      (item) => item.Date > expressDeliveryObj.Date
    );
  }
  if (client?.distance !== 'D') {
    if (client?.distance !== 'C') {
      weeklyDeliveryObj.header = ' ';
    } else {
      weeklyDeliveryObj.header = this.$t('deliveryDateSelector.weeklyDelivery');
    }

    weeklyDeliveryObj.sideInformation = getDateString(
      weeklyDeliveryObj.Date,
      locale
    );
    weeklyDeliveryObj.footer = getFooterString(weeklyDeliveryObj.MinimumValue);

    deliveryDates.push(expressDeliveryObj);
    deliveryDates.push(weeklyDeliveryObj);
  }
  if (client?.distance !== 'C' && client?.distance !== 'D') {
    const isInbookArray = client?.deliveryDates.filter((item) => {
      return (
        new Date(new Date(item.Date).toDateString()) >
        new Date(new Date(weeklyDeliveryObj.Date).toDateString())
      );
    });

    const thirdIsInbook = isInbookArray[0];
    weeklyDeliveryObj2 = JSON.parse(JSON.stringify(thirdIsInbook));

    weeklyDeliveryObj2.sideInformation = getDateString(
      weeklyDeliveryObj2.Date,
      locale
    );

    weeklyDeliveryObj2.header = '  ';
    weeklyDeliveryObj2.selected = false;

    weeklyDeliveryObj2.footer = getFooterString(
      weeklyDeliveryObj2.MinimumValue
    );

    const fourthIsInbook = isInbookArray[1];
    weeklyDeliveryObj3 = JSON.parse(JSON.stringify(fourthIsInbook));

    weeklyDeliveryObj3.sideInformation = getDateString(
      weeklyDeliveryObj3.Date,
      locale
    );

    weeklyDeliveryObj3.header = '   ';
    weeklyDeliveryObj3.selected = false;

    weeklyDeliveryObj3.footer = getFooterString(
      weeklyDeliveryObj3.MinimumValue
    );

    deliveryDates.push(weeklyDeliveryObj2);
    deliveryDates.push(weeklyDeliveryObj3);

    if (localCartCutoffAcknowledged) {
      deliveryDates.shift();

      const nextIsInbook = isInbookArray[2];
      weeklyDeliveryObj4 = JSON.parse(JSON.stringify(nextIsInbook));

      weeklyDeliveryObj4.sideInformation = getDateString(
        weeklyDeliveryObj4.Date,
        locale
      );

      weeklyDeliveryObj4.header = '    ';
      weeklyDeliveryObj4.selected = false;

      weeklyDeliveryObj4.footer = getFooterString(
        weeklyDeliveryObj4.MinimumValue
      );
      deliveryDates.push(weeklyDeliveryObj4);
    }
  }
  const updatedClient = { ...client };

  const deliveryOptions = [
    expressDeliveryObj,
    weeklyDeliveryObj,
    weeklyDeliveryObj2,
    weeklyDeliveryObj3,
    weeklyDeliveryObj4,
  ];

  if (!client.deliveryDateSelected) {
    deliveryOptions.forEach((option) => {
      const selectedOption = option;
      selectedOption.selected = false;
    });
  } else {
    const index =
      updatedClient.deliveryDateSelected === '1'
        ? 0
        : updatedClient.deliveryDateSelected.length;
    deliveryOptions.forEach((option, i) => {
      const selectedOption = option;
      selectedOption.selected = i === index;
      if (i === index) {
        updatedClient.deliveryDate = option.Date.split('-').join('');
      }
    });
  }

  return deliveryDates;
};

export default getDeliveryDatesCommon;
