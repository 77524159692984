<template>
  <div class="page__content">
    <Breadcrumb />
    <div class="container max-section-width">
      <Notification />
    </div>
    <Monitor />
  </div>
</template>

<script>
import Breadcrumb from '@/components/shared/Breadcrumb.vue';
import Monitor from '@/components/Monitor.vue';
import Notification from '@/components/notification/Notification.vue';

export default {
  components: {
    Breadcrumb,
    Monitor,
    Notification,
  },
};
</script>
