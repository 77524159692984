/* eslint-disable import/prefer-default-export */
import { STORAGE_KEY } from '@/constants/storage';

const ENVS_BR = {
  TST: {
    functionBaseUrl:
      'https://ko-b2b-service-layer-exposed-tst.azurewebsites.net/webapi/',
    APIManagementUrl:
      'https://ko-b2b-api-management.azure-api.net/ko-b2b-service-layer-exposed-tst/',
  },
  HOM: {
    functionBaseUrl:
      'https://ko-b2b-service-layer-exposed-hom.azurewebsites.net/webapi/',
    APIManagementUrl:
      'https://ko-b2b-api-management.azure-api.net/ko-b2b-service-layer-exposed-hom/',
  },
};

export const ENVIRONMENTS = {
  ASIA: {
    TST: {
      functionBaseUrl: ENVS_BR.TST.functionBaseUrl,
      APIManagementUrl: ENVS_BR.TST.APIManagementUrl,
    },
    HOM: {
      functionBaseUrl: ENVS_BR.HOM.functionBaseUrl,
      APIManagementUrl: ENVS_BR.HOM.APIManagementUrl,
    },
    PROD: {
      functionBaseUrl:
        'https://ko-b2b-service-layer-exposed-asia-prod.azurewebsites.net/webapi/',
      APIManagementUrl:
        'https://ko-b2b-api-management.azure-api.net/ko-b2b-service-layer-exposed-asia-prod/',
    },
  },
  KOBOSS: {
    TST: {
      functionBaseUrl: ENVS_BR.TST.functionBaseUrl,
      APIManagementUrl: ENVS_BR.TST.APIManagementUrl,
    },
    HOM: {
      functionBaseUrl: ENVS_BR.HOM.functionBaseUrl,
      APIManagementUrl: ENVS_BR.HOM.APIManagementUrl,
    },
    PROD: {
      functionBaseUrl:
        'https://ko-b2b-service-layer-exposed-prod.azurewebsites.net/webapi/',
      APIManagementUrl:
        'https://ko-b2b-api-management.azure-api.net/ko-b2b-service-layer-exposed-prod/',
    },
  },
  LATAM: {
    DEV: {
      functionBaseUrl: 'https://botshoppingcartapi-dev.coca-cola.com/',
    },
    QA: {
      functionBaseUrl: 'https://botshoppingcartapi-qa.coca-cola.com/',
    },
    PROD: {
      functionBaseUrl: 'https://botshoppingcartapi.coca-cola.com/',
    },
  },
};

export const INSTALLATION_CONFIGS = {
  APP_TENANT_PATH: {
    envVarName: 'VUE_APP_TENANT_PATH',
    key: STORAGE_KEY.appTenantPath,
  },
  SOURCE_TYPE: {
    envVarName: 'VUE_APP_SOURCE_TYPE',
    key: STORAGE_KEY.sourceType,
  },
  BOTTLER: {
    envVarName: 'VUE_APP_BOTTLER',
    key: STORAGE_KEY.bottler,
  },
  APP_TITLE: {
    envVarName: 'VUE_APP_TITLE',
    key: STORAGE_KEY.appTitle,
  },
  LANGUAGE: {
    envVarName: 'VUE_APP_LANGUAGE',
    key: STORAGE_KEY.language,
  },
  COUNTRY: {
    envVarName: 'VUE_APP_COUNTRY',
    key: STORAGE_KEY.country,
  },
};

export const SOURCE_TYPES = {
  KOBOSS: 'koboss',
  LATAM: 'latam',
  ASIA: 'asia',
};

export const BOTTLERS = {
  KOBOSS: ['thainamtip'],
  LATAM: [
    'andina_cl',
    'andina_py',
    'andina_br',
    'bandeirantes',
    'kenya',
    'colima',
  ],
};

export const BOTTLER_DETAILS_LIST = {
  KOBOSS: [
    {
      value: 'thainamtip',
      name: 'Thainamtip',
    },
  ],
  LATAM: [
    {
      code: 1,
      value: 'andina_cl',
      name: 'Andina CL',
    },
    {
      code: 2,
      value: 'bandeirantes',
      name: 'Bandeirantes',
    },
    {
      code: 7,
      value: 'andina_py',
      name: 'Andina PY',
    },
    {
      code: 8,
      value: 'andina_br',
      name: 'Andina BR',
    },
    {
      code: 13,
      value: 'kenya',
      name: 'Kenya',
    },
    {
      code: 12,
      value: 'colima',
      name: 'Colima',
    },
  ],
};

export const SOURCES = {
  standalone: 'standalone',
  whatsApp: 'whatsapp',
  lineChat: 'line',
};
