import { STORAGE_KEY } from '@/constants/storage';
import {
  BOTTLERS,
  BOTTLER_DETAILS_LIST,
  SOURCE_TYPES,
  SOURCES,
} from '@/constants/config';
import {
  getInstallationSourceType,
  getInstallationBottler,
} from '@/utils/installation';
import * as CONFIG from '@/config';
import * as LOCALES from '@/locales';
import * as COUNTRIES from '@/locales/countries';

let storageBottler;

export const isLatamBottler = () =>
  getInstallationSourceType() === SOURCE_TYPES.LATAM;

export const isEnabledOrchestration = () =>
  getInstallationSourceType() === SOURCE_TYPES.ASIA || true;

export const isValidBottler = ({ bottler }) => {
  if (!bottler) return false;

  if (isLatamBottler()) {
    return BOTTLER_DETAILS_LIST.LATAM.some(
      (b) => b.code === parseInt(bottler) || b.value === bottler.toLowerCase()
    );
  }

  if (getInstallationBottler() || process.env.VUE_APP_ENV === 'prod')
    return bottler === getInstallationBottler();

  return BOTTLERS.KOBOSS.some((b) => b === bottler);
};

export const getLatamBottlerCodeByValue = (value) => {
  const response = BOTTLER_DETAILS_LIST.LATAM.find(
    (b) => b.value === value.toLowerCase()
  );

  return response?.code;
};

export const getLatamBottlerValueByCode = (code) => {
  const response = BOTTLER_DETAILS_LIST.LATAM.find(
    (b) => b.code === parseInt(code)
  );

  return response?.value;
};

export const getBottlerConfig = ({ bottler }) => {
  try {
    const { VUE_APP_ENV } = process.env;
    // Try fetching config by bottler and env
    return CONFIG[bottler.toUpperCase()][VUE_APP_ENV.toUpperCase()];
  } catch (e) {
    return undefined;
  }
};

export const getLocalStorage = ({ key, json = true }) => {
  try {
    const value = localStorage.getItem(key);
    if (!json) return value;
    return value ? JSON.parse(value) : undefined;
  } catch (e) {
    return undefined;
  }
};

export const getLocaleMessages = (language) => LOCALES[language];

export const getLocaleCountryConfig = (value) => COUNTRIES[value];

export const getI18NMessages = (language, country) => {
  const messages = getLocaleMessages(language);
  const countryConfig = getLocaleCountryConfig(`${language}_${country}`);

  const response = {
    [language]: {
      ...messages[language],
      ...(countryConfig || {}),
    },
  };

  return response;
};

export const getI18NDateTimeFormats = (language, country) => {
  let countryConfig = getLocaleCountryConfig(`${language}_${country}`);

  if (!countryConfig) {
    countryConfig = {
      dateTimeFormats: {
        short: {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        },
        weekdayDayMonth: {
          month: '2-digit',
          day: '2-digit',
          weekday: 'long',
        },
      },
    };
  }

  const response = {
    [`${language}-${country}`]: {
      ...countryConfig.dateTimeFormats,
    },
  };

  return response;
};

export const getI18NNumberFormats = (language, country) => {
  const countryConfig = getLocaleCountryConfig(`${language}_${country}`);

  return {
    [`${language}-${country}`]: {
      currency: {
        style: 'currency',
        currency: countryConfig?.currency || 'BRL',
      },
    },
  };
};

export const setLocalStorage = ({ key, value, json = true }) => {
  try {
    const data = json ? JSON.stringify(value) : value;
    localStorage.setItem(key, data);
    return true;
  } catch (e) {
    return false;
  }
};

export const setStorageBottler = ({ bottler }) => {
  const configFile = getBottlerConfig({ bottler });

  if (!configFile) return false;

  return setLocalStorage({ key: STORAGE_KEY.bottler, value: configFile });
};

export const getStorageBottler = () => {
  if (storageBottler) return storageBottler;
  storageBottler = getLocalStorage({ key: STORAGE_KEY.bottler });
  return storageBottler;
};

export const getBottlerConfigEnv = () => {
  if (storageBottler) return storageBottler;
  if (process.env.VUE_APP_BOTTLER) {
    return getBottlerConfig({ bottler: process.env.VUE_APP_BOTTLER });
  }
  return getStorageBottler();
};

export const resetStorageBottlerReference = () => {
  storageBottler = null;
};

export const getSessionData = (key) => {
  try {
    const value = sessionStorage.getItem(key);
    return value ? JSON.parse(value) : undefined;
  } catch (e) {
    return undefined;
  }
};

export const getSessionValue = (key) => {
  try {
    const value = sessionStorage.getItem(key);
    return value || undefined;
  } catch (e) {
    return undefined;
  }
};

export const setSessionData = ({ key, value }) => {
  try {
    sessionStorage.setItem(key, JSON.stringify(value));
    return true;
  } catch (e) {
    return false;
  }
};

/**
 *
 * @param {*} array to iterate to check for duplicated elements
 * @param {*} fn a callback function with the properties to be compared.
 *                eg: (a, b) => a.product.sku === b.product.sku)
 * @returns array with no duplicated items
 */
export const uniqueElements = (arr, fn) =>
  arr.reduce((acc, v) => {
    if (!acc.some((x) => fn(v, x))) acc.push(v);
    return acc;
  }, []);

export const getRelativeAppPath = () => process.env.VUE_APP_HOST_RELATIVE_PATH;

export const getFullAppPath = () => {
  const appTenantPath = getSessionData(STORAGE_KEY.appTenantPath);

  if (appTenantPath === process.env.VUE_APP_HOST_RELATIVE_PATH)
    return appTenantPath;

  return `${appTenantPath}${process.env.VUE_APP_HOST_RELATIVE_PATH.substring(
    1
  )}`;
};

export const getAssetsPath = () => process.env.VUE_APP_ASSETS_PATH;

export const getBotSourceAppName = () => {
  const storageBottler = getStorageBottler();

  return storageBottler?.botSourceAppName
    ? storageBottler.botSourceAppName
    : SOURCES.whatsApp;
};
