<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="w-100 text-center mt-5 not-found">
    <b-iconstack font-scale="6">
      <b-icon
        stacked
        icon="x-circle-fill"
        variant="danger"
      />
    </b-iconstack>
    <p
      class="m-3 fw-200"
      v-html="$t(msg)"
    />
    <p
      v-if="msg01"
      class="m-3 fw-200"
      v-html="$t(msg01)"
    />
  </div>
</template>

<script>
export default {
  props: {
    msg: {
      type: String,
      required: true,
    },
    msg01: {
      type: String,
      default: '',
    },
  },
};
</script>
