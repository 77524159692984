export const waitForTime = (targetHour, targetMinute, callback) => {
  const now = new Date();

  // Calculate the time difference in milliseconds until the target time
  const targetTime = new Date();
  targetTime.setHours(targetHour, targetMinute, 0, 0);
  let timeDifference = targetTime - now;

  // Handle the case where the target time is earlier than the current time
  if (timeDifference < 0) {
    const nextDay = new Date(now);
    nextDay.setDate(now.getDate() + 1);
    nextDay.setHours(targetHour, targetMinute, 0, 0);
    timeDifference = nextDay - now;
  }

  // Set the timeout
  setTimeout(() => {
    // This code will be executed when the timeout is reached
    // console.log('Timeout reached at', targetHour, ':', targetMinute);
    callback();
  }, timeDifference);
};

export default { waitForTime };
