import apiEcommerceConfig from '@/api/ecommerceConfig';
import {
  getStorageBottler,
  getSessionValue,
  getSessionData,
} from '@/utils/config';
import { STORAGE_KEY } from '@/constants/storage';

export default {
  state: {
    ecommerceConfig: {
      menu: {},
    },
  },
  getters: {
    getEcommerceConfig(state) {
      return state.ecommerceConfig;
    },
  },
  mutations: {
    setEcommerceConfig(state, payload) {
      // eslint-disable-next-line no-param-reassign
      state.ecommerceConfig = payload;
    },
  },
  actions: {
    setEcommerceConfig({ commit }, value) {
      commit('setEcommerceConfig', value);
    },
    async getEcommerceConfig({ commit }) {
      try {
        const storageBottler = getStorageBottler();
        if (!storageBottler?.useAPIEcommerceConfig) {
          return;
        }
        const authorization = getSessionValue(STORAGE_KEY.authorization);
        let ecommerceConfig = getSessionData(STORAGE_KEY.ecommerceConfig);
        if (!ecommerceConfig && authorization) {
          ecommerceConfig = await apiEcommerceConfig.fetchEcommerceConfig();
        }
        commit('setEcommerceConfig', ecommerceConfig);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error getting ecommerce config');
      }
    },
  },
};
