import { get } from '@/utils/httpClient';

async function getComboProducts(comboId) {
  const result = await get({
    url: `combo/products/${comboId}`,
  });

  if (result.status) {
    return result.status === 200 && result.data ? result.data : undefined;
  }

  return result.message.includes('timeout') ? undefined : [];
}

export default { getComboProducts };
