<template>
  <ul class="col col-md-10 list-unstyled mx-auto">
    <li
      v-for="item in cart"
      v-show="item.product.familyId === 0 && item.product.combo === 0"
      :key="item.product.sku + item.product.combo"
      class="card-item-no-padding mb-3"
    >
      <CartItem
        :cart-items="cart"
        :product="item"
        :is-loading="isCartItemLoading"
        @cbUpdatePrice="checkUpdatePrices()"
      />
    </li>
  </ul>
</template>
<script>
import CartItem from '@/components/cart/CartItem';

export default {
  components: {
    CartItem,
  },
  props: {
    cart: {
      type: Array,
      required: true,
    },
    enableOrder: {
      type: Boolean,
      required: true,
    },
    isCartItemLoading: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    checkUpdatePrices() {
      this.$emit('cbUpdatePrice');
    },
  },
};
</script>
