/* eslint-disable no-param-reassign */
import debounce from 'lodash.debounce';
import { getStorageBottler } from '@/utils/config';
import apiCreateSimulate from '@/api/order/orderSimulate';

const updateProductPromotionPrice = (state, type) => {
  let stateToMap = state.items;

  if (type === 'combo') stateToMap = state.comboItems;

  stateToMap.map((c) => {
    const item = c;

    if (
      item.product.pricePromotions &&
      item.product.pricePromotions.length > 0
    ) {
      const promotions = c.product.pricePromotions;
      promotions.forEach((p) => {
        if (item.quantity >= p.minimumQuantity) {
          item.product.price = p.price;
        }
      });
    }
    return item;
  });
};

const updateComboQuantityProductPromotionPrice = (
  state,
  comboTotalQuantity
) => {
  state.comboItems.map((comboItem) => {
    const item = comboItem;
    if (
      item.product.pricePromotions &&
      item.product.pricePromotions.length > 0
    ) {
      const promotions = comboItem.product.pricePromotions;
      promotions.forEach((p) => {
        if (comboTotalQuantity >= p.minimumQuantity) {
          item.product.price = p.price;
        }
      });
    }
    return item;
  });
};

// Remove the unnecessary data at final of the description
const formatProductDescription = (description) =>
  description.split('\n')[0].trim();

const getRequiredGlassBottlesAmounts = (state) => {
  const glassBottlesAmounts = {};
  state.items.forEach((item) => {
    const { glassBottleProductId, glassBottles, isGlassBottle } = item.product;

    if (Number(glassBottles) > 0 && !isGlassBottle) {
      const totalAmountOfBottlesPerItem = glassBottles * item.quantity;

      glassBottlesAmounts[glassBottleProductId] = glassBottlesAmounts[
        glassBottleProductId
      ]
        ? glassBottlesAmounts[glassBottleProductId] +
          totalAmountOfBottlesPerItem
        : totalAmountOfBottlesPerItem;
    }
  });

  return glassBottlesAmounts;
};

const getReturnablePackagings = (state) => {
  const returnablePackagings = {};
  state.items.forEach((item) => {
    const { isGlassBottle, sku } = item.product;
    const formattedDescription = formatProductDescription(
      item.product.description
    );

    if (isGlassBottle && returnablePackagings[sku]) {
      returnablePackagings[sku].quantity += item.quantity;
    }

    if (isGlassBottle && !returnablePackagings[sku]) {
      returnablePackagings[sku] = {
        boxProductId: item.product.boxProductId,
        quantity: item.quantity,
        description: formattedDescription,
      };
    }
  });

  return returnablePackagings;
};

const getBoxesPackagings = (state) => {
  const boxesPackagings = {};
  state.items.forEach((item) => {
    const { sku, isBottleBox } = item.product;
    const formattedDescription = formatProductDescription(
      item.product.description
    );

    if (isBottleBox && boxesPackagings[sku]) {
      boxesPackagings[sku].quantity += item.quantity;
    }

    if (isBottleBox && !boxesPackagings[sku]) {
      boxesPackagings[sku] = {
        quantity: item.quantity,
        description: formattedDescription,
      };
    }
  });

  return boxesPackagings;
};

const getProductsWithReturnablePackaging = (state) => {
  const productsWithReturnablePackaging = state.items.reduce((gbList, item) => {
    const { glassBottleProductId, glassBottles, isGlassBottle } = item.product;
    const totalAmountOfBottlesPerItem = glassBottles * item.quantity;
    const formattedDescription = formatProductDescription(
      item.product.description
    );

    if (Number(glassBottles) > 0 && !isGlassBottle) {
      gbList.push({
        product: formattedDescription,
        glassBottlesQuantity: totalAmountOfBottlesPerItem,
        bottleBox: item.quantity,
        sku: glassBottleProductId,
      });
    }

    return gbList;
  }, []);

  return productsWithReturnablePackaging;
};

const debouncedSimulatePrice = debounce(async ({ state, getters, commit }) => {
  try {
    const bottlerStorage = await getStorageBottler();
    if (bottlerStorage?.hasRecalculatePrice === true) {
      commit('setSimulatedPriceEnabled');
      const orderInformation = {
        simulationPrice: 'X',
        products: state.items.map((p) => ({
          ...p.product,
          quantity: p.quantity,
        })),
        DeliveryDate: getters.getClient.deliveryDate,
        orderType: '',
        userFlow: {
          glassBottlesAccepted: false,
          // unused but listed from cartpage.vue, refer there for original implementation
          // creditLimit: this.creditLimit,
        },
      };

      const simulatedPrice = await apiCreateSimulate.getOrderSimulate(
        orderInformation
      );

      commit('setSimulatedPrice', {
        totalPrice: simulatedPrice.totalValue,
        priceBeforeDiscount: simulatedPrice.priceBeforeDiscount,
        discount: simulatedPrice.discount,
        taxes: simulatedPrice.vat,
      });
    }
  } catch (error) {
    commit('setSimulatedPriceError', error.message);
  }
}, 2000);

export default {
  state: {
    items: [],
    comboItems: [],
    referenceOrderNumber: '',
    referenceOrderType: '',
    repeatOrderAcknowledged: false,
    cartAlertAcknowledged: false,
    cartCutoffAcknowledged: false,
    simulatedPriceEnabled: false,
    simulatedPriceLoading: false,
    simulatedPriceError: '',
    simulatedPrice: {
      totalValue: 0,
      priceBeforeDiscount: 0,
      discount: 0,
      taxes: 0,
    },
  },
  getters: {
    getCartItems(state) {
      if (state.items.length === 0) {
        state.referenceOrderNumber = '';
        state.referenceOrderType = '';
      }
      return state.items;
    },
    getComboCartItems(state) {
      return state.comboItems;
    },
    // returnable packaging === glass bottle
    // @TODO: https://jira.coke.com/jira/browse/KOBOSS-5321
    // this method retrieve the glass bottles quantity and its details
    getReturnablePackagingData(state) {
      const glassBottlesAmounts = getRequiredGlassBottlesAmounts(state);
      const returnablePackagings = getReturnablePackagings(state);
      const boxesPackagings = getBoxesPackagings(state);
      const productsWithReturnablePackaging =
        getProductsWithReturnablePackaging(state);

      return {
        productsWithReturnablePackaging,
        glassBottlesAmounts,
        returnablePackagings,
        boxesPackagings,
      };
    },
    getTotalPrice(state) {
      if (state.simulatedPriceEnabled) {
        return state.simulatedPrice.totalPrice;
      }
      let totalPrice = state.items.reduce(
        (t, item) => t + Number(item.product.price * item.quantity),
        0
      );
      // fix the rounding, as it may lead to infinite repetitions
      // e.g. 2,56777...
      totalPrice = Math.round(totalPrice * 100) / 100;
      return totalPrice;
    },
    getComboTotalPrice(state) {
      const totalPrice = state.comboItems.reduce(
        (t, item) => t + Number(item.product.price * item.quantity),
        0
      );
      return totalPrice;
    },
    getQuantityComboTypeTotalPrice(state) {
      const comboTotalQuantity = state.comboItems.reduce(
        (t, item) => Math.round(t + Number(item.quantity)),
        0
      );
      updateComboQuantityProductPromotionPrice(state, comboTotalQuantity);
      const totalPrice = state.comboItems.reduce(
        (t, item) => t + Number(item.product.price * item.quantity),
        0
      );
      return totalPrice;
    },
    getItemsQuantity(state) {
      updateProductPromotionPrice(state);
      const quantity = state.items.reduce(
        (t, item) => Math.round(t + Number(item.quantity)),
        0
      );
      return quantity;
    },
    getComboItemsQuantity(state) {
      updateProductPromotionPrice(state, 'combo');
      const quantity = state.comboItems.reduce(
        (t, item) => t + Number(item.quantity),
        0
      );
      return quantity;
    },
    getReferenceOrderNumber(state) {
      return state.referenceOrderNumber;
    },
    getReferenceOrderType(state) {
      return state.referenceOrderType;
    },
    isRepeatOrderAcknowledged(state) {
      return state.repeatOrderAcknowledged;
    },
    getCartAlertAcknowledged(state) {
      return state.cartAlertAcknowledged;
    },
    getCartCutoffAcknowledged(state) {
      return state.cartCutoffAcknowledged;
    },
    getSimulatedPriceState(state) {
      const {
        simulatedPriceEnabled,
        simulatedPriceLoading,
        simulatedPriceError,
        simulatedPrice,
      } = state;

      return {
        enabled: simulatedPriceEnabled,
        loading: simulatedPriceLoading,
        error: simulatedPriceError,
        ...simulatedPrice,
      };
    },
  },
  mutations: {
    addItemToCart(state, payload) {
      const item = state.items.find(
        (c) =>
          c.product.sku === payload.product.sku &&
          c.product.combo === payload.product.combo &&
          c.product.familyId === payload.product.familyId
      );
      if (item) {
        item.quantity += payload.quantity;
      } else {
        state.items.push(payload);
      }
      localStorage.setItem('cart', JSON.stringify(state.items));
    },
    addComboItemToCart(state, payload) {
      state.comboItems.push(payload);
      localStorage.setItem('comboCart', JSON.stringify(state.comboItems));
    },
    resetCart(state) {
      state.items = [];
      state.referenceOrderNumber = '';
      state.repeatOrderAcknowledged = false;
      state.referenceOrderType = '';
      localStorage.setItem('cart', JSON.stringify(state.items));
    },
    resetComboCart(state) {
      state.comboItems = [];
      state.referenceOrderNumber = '';
      state.referenceOrderType = '';
      localStorage.setItem('comboCart', JSON.stringify(state.comboItems));
    },
    setCartItems(state, payload) {
      state.items = payload;
      if (typeof state.items[0]?.product !== 'undefined') {
        localStorage.setItem('cart', JSON.stringify(state.items));
      }
    },
    setComboCartItems(state, payload) {
      state.comboItems = payload;
      localStorage.setItem('comboCart', JSON.stringify(state.comboItems));
    },
    removeComboFromCart(state, comboId) {
      state.items = state.items.filter(
        (item) => item.product.combo !== comboId
      );
      localStorage.setItem('comboCart', JSON.stringify([]));
    },
    removeItemFromCart(state, product) {
      state.items = state.items.filter(
        (item) => item.product.sku !== product.sku
      );
    },
    setRepeatOrderAcknowledged(state, isRepeat) {
      state.repeatOrderAcknowledged = isRepeat;
    },
    setReferenceOrder(state, reference) {
      state.referenceOrderNumber = reference.orderNumber;
      state.referenceOrderType = reference.orderType;
    },
    setCartAcknowlegement(state, value) {
      state.cartAlertAcknowledged = value;
    },
    setCartCutoffAcknowlegement(state, value) {
      state.cartCutoffAcknowledged = value;
    },
    setSimulatedPriceEnabled(state, value = true) {
      state.simulatedPriceEnabled = value;
    },
    setSimulatedPriceLoading(state) {
      state.simulatedPriceLoading = true;
    },
    setSimulatedPriceError(state, value) {
      state.simulatedPriceLoading = false;
      state.simulatedPriceError = value;
    },
    setSimulatedPrice(state, value) {
      state.simulatedPriceLoading = false;
      state.simulatedPriceError = '';
      state.simulatedPrice = value;
    },
  },
  actions: {
    addItemToCart({ commit, dispatch }, item) {
      commit('addItemToCart', item);
      dispatch('simulatePrice');
    },
    addComboItemToCart({ commit }, item) {
      commit('addComboItemToCart', item);
    },
    setCartItems({ commit }, items) {
      commit('setCartItems', items);
    },
    setComboCartItems({ commit }, items) {
      commit('setComboCartItems', items);
    },
    resetCart({ commit }) {
      commit('resetCart');
    },
    resetComboCart({ commit }) {
      commit('resetComboCart');
    },
    removeItemFromCart({ commit }, product) {
      commit('removeItemFromCart', product);
    },
    removeComboFromCart({ commit }, comboId) {
      commit('removeComboFromCart', comboId);
    },
    setReferenceOrder({ commit }, order) {
      commit('setReferenceOrder', order);
    },
    async initCart({ commit }, payload) {
      let cart;
      if (payload) {
        cart = payload;
      } else {
        const cartAsString = localStorage.getItem('cart');
        const cartItemsParsed = JSON.parse(cartAsString);
        cart = Array.isArray(cartItemsParsed) ? cartItemsParsed : [];
      }
      commit('setCartItems', cart);
    },
    async initComboCart({ commit }, payload) {
      let comboCart;
      if (payload) {
        comboCart = payload;
      } else {
        const comboCartAsString = localStorage.getItem('comboCart');
        const comboCartItemsParsed = JSON.parse(comboCartAsString);
        comboCart = Array.isArray(comboCartItemsParsed)
          ? comboCartItemsParsed
          : [];
      }
      commit('setComboCartItems', comboCart);
    },
    async simulatePrice({ state, getters, commit }) {
      commit('setSimulatedPriceLoading');
      debouncedSimulatePrice({ state, getters, commit });
    },
  },
};
