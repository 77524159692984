<template>
  <div class="page__content">
    <Breadcrumb
      :title="$t('pageTitles.finance')"
      :sub-title="$t('pageTitles.openInvoices')"
    />
    <div class="container max-section-width">
      <div class="row">
        <div class="col fw-200 text-center max-w-700 mx-auto mt-3 mb-4">
          <div
            v-if="!loading && invoices && invoices.hasOpenInvoices < 1"
            class="w-100 fs-12 fs-md-14"
          >
            <p>
              {{ $t('finance.openInvoices.emptyInvoices') }}
            </p>
          </div>
          <div
            v-if="!loading && invoices && invoices.hasOpenInvoices > 0"
            class="w-100 fs-12 fs-md-14"
          >
            <p>
              {{ $t('finance.openInvoices.invoiceQuantityStart') }}
              <span class="fw-300">
                {{ invoices.openInvoices.length }}
              </span>
              {{ $t('finance.openInvoices.invoiceQuantityEnd') }}
            </p>
            <p>
              {{ $t('finance.openInvoices.clickForMore') }}
            </p>
            <p class="warning-msg w-75 col-md-6">
              <span class="fw-300">
                {{ $t('finance.openInvoices.warning') }}
              </span>
              {{ $t('finance.openInvoices.warningMsg') }}
            </p>
            <div
              class="accordion"
              role="tablist"
            >
              <div
                v-for="invoice in invoices.openInvoices"
                :key="invoice.orderNumber"
                class="invoice-box mt-4"
              >
                <Invoices
                  :formatted-invoice-due-date="invoice.formattedInvoiceDueDate"
                  :invoice-debt="invoice.invoiceOrderValue"
                  :invoice-payment-expired="invoice.invoicePaymentExpired"
                  :invoice-bar-code="invoice.paymentData"
                  :order-number="invoice.orderNumber"
                  :invoice-document-number="invoice.invoiceDocumentNumber"
                  :invoice-due-date="invoice.invoiceDueDate"
                />
              </div>
            </div>
          </div>
          <div
            v-if="loading"
            class="loading"
          >
            <b-spinner label="Loading..." />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/components/shared/Breadcrumb.vue';
import Invoices from '@/components/finance/Invoices';
import { SERVICES, MODULES } from '@/constants/feature';
import { registerService } from '@/utils/pages';
import api from '@/api/finance/openInvoices';

export default {
  components: {
    Breadcrumb,
    Invoices,
  },
  data() {
    return {
      appModules: MODULES,
      loading: true,
      invoices: [],
    };
  },
  beforeCreate() {
    this.loading = true;
  },
  created() {
    registerService({ service: SERVICES.openInvoices });
    this.getOpenInvoices();
  },
  methods: {
    async getOpenInvoices() {
      this.invoices = await api.getOpenInvoices();
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.invoice-box {
  margin-right: 20px;
  margin-left: 20px;
  align-content: start;
}

.warning-msg {
  margin: auto;
  padding: 10px;
  border-color: var(--orange);
  border-width: 1px;
  border-style: solid;
}
</style>
