<template>
  <div
    v-show="showProgress"
    class="tracking"
  >
    <div class="section card-body mb-4">
      <div class="d-flex justify-content-between">
        <div class="flex-fill">
          <div class="d-flex justify-content-between mb-2">
            <div
              id="orderNumber"
              class="fw-400 fs-14 text-primary"
            >
              {{ $t('orderTracking.orderNumberLabel') }} #{{ orderNumber }}
            </div>
            <b-button
              v-if="repeatOrderEligible"
              id="repeatOrder"
              variant="secondary"
              size="sm"
              class="fs-10"
              style="white-space: nowrap; height: 100%"
              @click="onRepeatClick"
            >
              {{ $t('orderTracking.repeat') }}
            </b-button>
            <b-button
              v-if="amendOrderEligible"
              id="amendOrder"
              variant="primary"
              size="sm"
              class="fs-10"
              style="white-space: nowrap; height: 100%"
              @click="onAmendClick"
            >
              {{ $t('orderTracking.amend') }}
            </b-button>
          </div>
          <div class="d-flex justify-content-between mb-2">
            <div>
              <p class="date fs-14 fw-200 mb-2">
                {{ $t('orderTracking.orderDayLabel') }}
                <span class="fw-600">
                  {{ orderDay }}
                </span>
              </p>
              <p
                v-if="expectedDeliveryDate"
                id="expectedDeliveryDate"
                class="date fs-14 fw-200 mb-2"
              >
                {{ $t('orderTracking.expectedDeliveryLabel') }}
                <span class="fw-600">
                  {{ expectedDeliveryDate }}
                </span>
              </p>
              <p
                id="orderStatus"
                class="fs-14 fw-200 mb-0"
              >
                {{ `${$t('status')}:` }}
                <span class="fw-600">
                  {{ getStatusTh(status) }}
                </span>
              </p>
            </div>
            <div>
              <p
                v-if="orderDiscount"
                id="orderDiscount"
                class="fs-14 fw-200 mb-2"
              >
                {{ $t('discount') }}
                <span class="fw-600">
                  {{ formatPrice(orderDiscount) }}
                </span>
              </p>
              <p
                v-if="fullPrice"
                id="orderPrice"
                class="fs-14 fw-200 mb-2"
              >
                {{ $t('finalPrice') }}
                <span class="fw-600">
                  {{ formatPrice(fullPrice) }}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div class="align-self-center m-2 ml-4">
          <b-icon
            v-b-toggle="`accordion-${index}`"
            class="when-closed"
            icon="chevron-up"
          />
        </div>
      </div>
      <b-collapse
        :id="`accordion-${index}`"
        role="tabpanel"
      >
        <div
          v-if="loading"
          class="d-flex justify-content-center"
        >
          <b-spinner label="Loading..." />
        </div>
        <div v-else>
          <div
            v-if="orderProducts && fullProducts.length"
            class="mt-4"
          >
            <h2 class="fw-400 fs-14 text-primary">
              {{ $t('orderTracking.summaryTitle') }}
            </h2>
            <ul
              id="orderProducts"
              class="bg-light mt-4 mx-1 py-2 px-3"
            >
              <li
                v-for="product in updatedOrderProducts"
                :key="product.sku"
                class="list-unstyled mb-1 fs-10 text-mediumgrey card-product"
              >
                <ImgGeneral
                  :alt-text="product.description"
                  class="responsive img-fluid"
                  :url="product.imageUrl"
                  :show-fallback-img="true"
                />
                <p class="product-title mt-3 fixedHeight">
                  <!-- eslint-disable-next-line prettier/prettier -->
                {{ product.quantity }} <template v-if="product.title">x {{ product.title }}</template>
                </p>
                <p class="product-title">
                  {{ formatPrice(Number(product.price)) }}
                </p>
              </li>
            </ul>
            <p class="warning-msg max-w-500 mt-4 text-center">
              <b-icon
                icon="exclamation-circle"
                variant="primary"
                class="d-block mx-auto mb-2"
              />
              <span class="fw-300">
                {{ $t('finance.openInvoices.warning') }}
              </span>
              {{ $t('orderTracking.breakWarning') }}
            </p>
          </div>
        </div>

        <hr class="mt-5 mb-2" />
        <step-progress
          :class="`total-steps-${steps.length}`"
          :steps="getOrderStatusI18n(steps)"
          :current-step="currentStep"
          :active-color="activeColor"
          :active-thickness="activeThickness"
          :passive-thickness="passiveThickness"
          :line-thickness="lineThickness"
        />
        <p
          v-if="editedBy"
          class="fs-10 text-center"
        >
          {{ $t('orderTracking.editedCancelledBy') }} #{{ editedBy }}
        </p>
      </b-collapse>
    </div>
    <b-modal
      ref="date-modal"
      v-model="showDateModal"
      :title="
        editingOrder
          ? $t('orderTracking.editDateSelect')
          : $t('orderTracking.repeatTitle')
      "
      centered
      cancel-variant="primary"
      no-close-on-backdrop
      ok-variant="secondary"
      @hidden="deliveryDateModalCancel"
      @ok="editingOrder ? handleEditOrder() : handleRepeatOrder()"
      @show="deliveryDatesCheck"
    >
      <p v-if="editingOrder">{{
        `${$t('orderTracking.currentDeliveryDate')}: ${expectedDeliveryDate}`
      }}</p>
      <DeliveryDateModal @selected="isDeliveryDateSelected" />
      <template #modal-footer="{ ok }">
        <b-button
          size="sm"
          variant="primary"
          style="width: 94px"
          @click="deliveryDateModalCancel()"
        >
          {{ $t('orderTracking.cancel') }}
        </b-button>
        <b-button
          size="sm"
          variant="secondary"
          style="width: 94px"
          :disabled="!deliveryDateSelected"
          @click="ok()"
        >
          {{ $t('ok') }}
        </b-button>
      </template>
    </b-modal>
    <b-modal
      v-model="showAmendModal"
      :title="$t('orderTracking.amend')"
      header-close-variant="dark"
      header-text-variant="primary"
      centered
      cancel-variant="primary"
      ok-variant="secondary"
      ok-label="Ok"
      @ok="amendOrder"
      @cancel="amendCancel"
      @hidden="amendCancel"
    >
      <div
        v-if="cancelConfirmation"
        class="cancel-warning"
      >
        <b-alert
          show
          variant="warning"
        >
          <span style="color: #000">{{
            `${$t('orderTracking.cancelConfirmation')} #${orderNumber}`
          }}</span></b-alert
        >
      </div>
      <fieldset v-if="!cancelConfirmation">
        <label
          :id="'radioLabel-edit'"
          :for="'radio-edit'"
          class="row radio-label"
        >
          <div class="radio-selection">
            <input
              id="radio-edit"
              value="edit"
              type="radio"
              name="radio-input"
              class="p-2 mr-2"
              :aria-labelledby="'radioLabel-edit'"
              @change="handleAmendChange"
            />
            <span class="pt-2 text-grey headerInformation">
              {{ $t('orderTracking.edit') }}</span
            >
            <span class="fs-14 text-mediumgrey sideInformation">
              {{ `${$t('orderTracking.editOrder')} #${orderNumber}` }}
            </span>
          </div>
        </label>
        <label
          :id="'radioLabel-cancel'"
          :for="'radio-cancel'"
          class="row radio-label"
        >
          <div class="radio-selection">
            <input
              id="radio-cancel"
              value="cancel"
              type="radio"
              name="radio-input"
              class="p-2 mr-2"
              :aria-labelledby="'radioLabel-cancel'"
              @change="handleAmendChange"
            />
            <span class="pt-2 text-grey headerInformation">
              {{ $t('orderTracking.cancel') }}
            </span>
            <span class="fs-14 text-mediumgrey sideInformation">
              {{ `${$t('orderTracking.cancelOrder')} #${orderNumber}` }}
            </span>
          </div>
        </label>
      </fieldset>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          size="sm"
          variant="primary"
          style="width: 94px"
          @click="cancel()"
        >
          {{ $t('orderTracking.cancel') }}
        </b-button>
        <b-button
          size="sm"
          variant="secondary"
          style="width: 94px"
          :disabled="!amendOption"
          @click="
            () => {
              if (amendOption === 'cancel' && !cancelConfirmation) {
                cancelConfirmation = true;
                return;
              }
              cancelConfirmation = false;
              ok();
            }
          "
        >
          {{ $t('ok') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
/* eslint no-param-reassign: "warn" */
import { mapGetters, mapActions } from 'vuex';
import StepProgress from 'vue-step-progress';
import { BButton, BModal, VBToggle, BCollapse } from 'bootstrap-vue';
import Analytics from '@/utils/analytics';
import apiSendEvent from '@/api/event';
import analyticsEvents from '@/utils/events/analytics';
import DeliveryDateModal from '@/components/order/DeliveryDateModal';
import cart from '../../stores/modules/cart';
import ImgGeneral from '@/components/shared/ImgGeneral';
import ProductMixin from '@/components/mixins/ProductMixin';
import 'vue-step-progress/dist/main.css';
import router from '../../router';
import apiOrderCancel from '@/api/order/orderCancel';
import { openModalNotification } from '@/utils/pages';

export default {
  components: {
    'b-modal': BModal,
    'step-progress': StepProgress,
    'b-button': BButton,
    ImgGeneral,
    DeliveryDateModal,
    BCollapse,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  mixins: [ProductMixin],
  props: {
    index: {
      type: Number,
      default: 0,
    },
    steps: {
      type: Array,
      default: null,
    },
    status: {
      type: String,
      default: '',
    },
    orderNumber: {
      type: String,
      default: '',
    },
    orderDay: {
      type: String,
      default: null,
    },
    orderDiscount: {
      type: Number,
      default: 0,
    },
    orderSystem: {
      type: String,
      default: '',
    },
    currentStep: {
      type: Number,
      default: 0,
    },
    expectedDeliveryDate: {
      type: String,
      default: null,
    },
    fullPrice: {
      type: Number,
      default: 0,
    },
    orderProducts: {
      type: Array,
      default: null,
    },
    fullProducts: {
      type: Array,
      default: null,
    },
    editedBy: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      activeColor: 'var(--secondary)',
      passiveColor: 'var(--tertiary)',
      cancelledColor: 'var(--primary)',
      activeThickness: 10,
      passiveThickness: 2,
      lineThickness: 2,
      deliveredIndex: 4,
      activeLineHeight: '2px',
      showProgress: false,
      showDateModal: false,
      showAmendModal: false,
      deliveryDateSelected: false,
      updatedOrderProducts: [],
      repeatOrderEligible: false,
      amendOrderEligible: false,
      amendOption: '',
      cancelConfirmation: false,
      editingOrder: false,
      selectedDate: null,
      minimumOrder: null,
      deliveryDateSelectedForCancel: null,
      deliveryDate: null,
      loading: true,
    };
  },
  computed: {
    ...mapGetters({
      client: 'getClient',
    }),
  },
  watch: {
    fullProducts: {
      handler() {
        this.updateProductDetails();
      },
    },
  },
  mounted() {
    this.updatePassiveLine();
    this.updateActiveLine();
    this.updateWithCancelledStatus();
    this.updateProductDetails();
    this.isRepeatOrderEligible();
    this.isAmendOrderEligible();
    this.isDeliveryDateSelected();
  },
  methods: {
    formatPrice(price) {
      let priceCheck = Number.isNaN(parseInt(price)) ? 0 : price;
      if (typeof priceCheck === 'number') {
        priceCheck = priceCheck.toFixed(2);
      }
      priceCheck = Number(priceCheck).toLocaleString('th-TH', {
        style: 'currency',
        currency: 'THB',
      });
      return priceCheck;
    },
    ...mapActions(['setClient']),
    isRepeatOrderEligible() {
      if (
        this.orderSystem === 'BOT' &&
        (this.status === 'Completed' ||
          this.status === 'Completed with partial delivery' ||
          this.status === 'Unsuccessful delivery' ||
          this.status === 'Cancelled by customer' ||
          this.status === 'Cancelled')
      ) {
        this.repeatOrderEligible = true;
      } else {
        this.repeatOrderEligible = false;
      }
    },
    isAmendOrderEligible() {
      if (this.status === 'Submitted') {
        this.amendOrderEligible = true;
      } else {
        this.amendOrderEligible = false;
      }
    },
    getStatusTh(status) {
      return this.$t(`orderTracking.status.${status}`);
    },
    getOrderStatusI18n(steps) {
      const orderStatusI18n = steps.map((status) =>
        this.$t(`orderTracking.steps.${status}`)
      );
      return orderStatusI18n;
    },
    updatePassiveLine() {
      const passiveLine = this.$el.querySelectorAll(
        '.step-progress__wrapper-before'
      );
      passiveLine.forEach((element) => {
        // eslint-disable-next-line no-param-reassign
        element.style.backgroundColor = 'transparent';
      });
    },
    updateActiveLine() {
      const activeLine = this.$el.querySelectorAll(
        '.step-progress__wrapper-after'
      );
      activeLine.forEach((element) => {
        // eslint-disable-next-line no-param-reassign
        element.style.height = this.activeLineHeight;
        // eslint-disable-next-line no-param-reassign
        element.style.backgroundColor = 'var(--tertiary)';
      });
    },
    addTruckClass() {
      // FIX: this is hardcoded and fragile to scale. enhacement is mapped on KOBOSS-3774
      const lastStep = this.$el.querySelectorAll(
        '.tracking .step-progress__step span'
      );
      lastStep.forEach((element, index) => {
        if (index === this.deliveredIndex) {
          element.parentElement.classList.add('truck');
        }
      });
    },
    updateWithCancelledStatus() {
      if (
        this.status === 'Unsuccessful delivery' ||
        this.status === 'Cancelled by customer' ||
        this.status === 'Cancelled'
      ) {
        const parent = this.$el.querySelectorAll(
          '.step-progress__step--active'
        );
        parent.forEach((element) => {
          element.classList.add('cancelled');
        });
      }
      this.showProgress = true;
    },
    updateProductDetails() {
      const productsHash = {};
      this.fullProducts?.forEach((fullProduct) => {
        productsHash[fullProduct.product.sku] = fullProduct.product;
      });
      if (this.fullProducts.length > 0) {
        this.loading = false;
      }
      this.updatedOrderProducts = this.orderProducts?.filter((orderProduct) => {
        if (
          productsHash[orderProduct.sku] &&
          (orderProduct.reasonCode === '' || // todo: double check this criteria, this might filter incorrect items from order view
            (orderProduct.reasonCode !== '' && // don't show items with a reason code if they're from a partial delivery
              this.status !== 'Completed with partial delivery'))
        ) {
          orderProduct.description = productsHash[orderProduct.sku].description;
          orderProduct.imageUrl = productsHash[orderProduct.sku].imageUrl;
          orderProduct.title = productsHash[orderProduct.sku].description;
          return true;
        }
        return false;
      });
    },
    isDeliveryDateSelected() {
      if (this.client.deliveryDateSelected) {
        this.deliveryDateSelected = true;
        return;
      }
      this.deliveryDateSelected = false;
    },
    onRepeatClick() {
      this.showDateModal = true;
      this.isDeliveryDateSelected();
    },
    onAmendClick() {
      this.showAmendModal = true;
    },
    handleAmendChange(event) {
      this.amendOption = event.target.value;
    },
    deliveryDatesCheck() {
      // storing the client value to temp const, so that we can re-assign the client value back
      // when cancel is clicked in delivery date modal popup

      this.minimumOrder = Number(this.client.minimumOrder);
      this.deliveryDateSelectedForCancel = this.client.deliveryDateSelected;
      this.deliveryDate = this.client.deliveryDate;
    },
    deliveryDateModalCancel() {
      // re-assign the stored inital values when cancel is clicked
      this.client.minimumOrder = this.minimumOrder;
      this.client.deliveryDateSelected = this.deliveryDateSelectedForCancel;
      this.client.deliveryDate = this.deliveryDate;

      this.$store.commit('setClient', this.client);
      // override cancel click to hide or dismiss the popup
      this.$refs['date-modal'].hide();
    },
    amendCancel() {
      setTimeout(() => {
        this.amendOption = '';
        this.cancelConfirmation = false;
      }, 400);
    },
    handleRepeatOrder() {
      const productsHash = {};
      this.fullProducts?.forEach((fullProduct) => {
        productsHash[fullProduct.product.sku] = fullProduct.product;
      });
      const productsInfobipPayload = [];
      this.updatedOrderProducts.map((orderProduct) => {
        const product = productsHash[orderProduct.sku];
        productsInfobipPayload.push({
          sku: orderProduct.sku,
          quantity: Number(orderProduct.quantity),
        });
        return cart.mutations.addItemToCart(cart.state, {
          product,
          quantity: orderProduct.quantity,
        });
      });
      apiSendEvent.postEvent('AddToCartv2', {
        addtocart: true,
        products: productsInfobipPayload,
      });
      // store order number in cart state as repeat order reference and order type
      cart.mutations.setReferenceOrder(cart.state, {
        orderNumber: this.orderNumber,
        orderType: 'Reorder',
      });
      // analytics event
      Analytics.logEvent(analyticsEvents.REPEAT_ORDER, {
        description: this.orderNumber,
      });
      // redirect to cart
      router.navigateToPage('/cart');
    },
    handleEditOrder() {
      const productsHash = {};
      this.fullProducts?.forEach((fullProduct) => {
        productsHash[fullProduct.product.sku] = fullProduct.product;
      });
      const productsInfobipPayload = [];
      this.updatedOrderProducts.map((orderProduct) => {
        const product = productsHash[orderProduct.sku];
        productsInfobipPayload.push({
          sku: orderProduct.sku,
          quantity: Number(orderProduct.quantity),
        });
        return cart.mutations.addItemToCart(cart.state, {
          product,
          quantity: orderProduct.quantity,
        });
      });
      apiSendEvent.postEvent('AddToCartv2', {
        addtocart: true,
        products: productsInfobipPayload,
      });
      // store order number in cart state as edit order reference and order type
      cart.mutations.setReferenceOrder(cart.state, {
        orderNumber: this.orderNumber,
        orderType: 'Edit',
      });
      // analytics event
      Analytics.logEvent(analyticsEvents.EDIT_ORDER, {
        description: this.orderNumber,
      });
      this.editingOrder = false;
      // redirect to cart
      router.navigateToPage('/cart');
    },
    editOrder() {
      this.editingOrder = true;
      this.showDateModal = true;
      this.isDeliveryDateSelected();
    },
    async amendOrder() {
      if (this.amendOption === 'edit') {
        this.editOrder();
      } else if (this.amendOption === 'cancel') {
        try {
          await apiOrderCancel.cancelOrder(this.orderNumber);
          this.$store.dispatch('getCredits');

          // reload orders with success message
          this.$emit('refreshOrders', this.orderNumber);
        } catch (error) {
          const h = this.$createElement;
          let cancelError = this.$t('orderTracking.cancelError');
          switch (error.message) {
            case 'The order cannot be cancelled as it is no longer in a submitted state.':
              cancelError = [
                h('p', this.$t('orderTracking.cancelErrorSubmitted')),
              ];
              break;
            case 'There is no order with this order id.':
              cancelError = this.$t('orderTracking.cancelErrorOrderId');
              break;
            default:
              cancelError = [
                h('p', this.$t('orderTracking.cancelError1')),
                h('p', [
                  h('span', this.$t('orderTracking.cancelError2')),
                  h(
                    'a',
                    {
                      on: {
                        mouseover: () => {
                          // Change color on mouseover
                          this.linkHoverColor = 'black';
                        },
                        mouseleave: () => {
                          // Reset color on mouseleave
                          this.linkHoverColor = 'inherit';
                        },
                      },
                      style: {
                        color: this.linkHoverColor || 'black', // Use the computed color
                        textDecoration: 'none', // Remove underline for better styling
                      },
                      attrs: {
                        href: `tel:${this.$t('orderTracking.cancelErrorTel')}`,
                      },
                    },
                    this.$t('orderTracking.cancelErrorTel')
                  ),
                  h('span', `${this.$t('orderTracking.cancelError3')}`),
                ]),
              ];
              break;
          }
          const modalData = {
            headerBgVariant: 'white',
            headerTextVariant: 'dark',
            headerCloseVariant: 'dark',
            title: [
              h('b-icon', {
                props: {
                  icon: 'x-circle-fill',
                  scale: '1',
                  variant: 'danger',
                },
              }),
              h(
                'span',
                {
                  style: { color: 'red' },
                },
                ` ${this.$t('orderTracking.cancelOrderTitle')}`
              ),
            ],
            description: cancelError,
            okVariant: 'secondary',
            okTitle: this.$t('ok'),
          };
          openModalNotification(this.$bvModal, {
            ...modalData,
          });
        }
      }
    },
  },
};
</script>

<style scoped>
.when-closed {
  transition-duration: 400ms;
}

.collapsed.when-closed {
  transform: rotate(180deg);
}

#orderProducts {
  overflow: auto;
  white-space: nowrap;
  position: relative;
}

.card-product {
  display: inline-block;
  width: 120px;
  background-color: var(--light);
  border-radius: 8px;
  margin-right: 10px;
  text-align: center;
}

ul#orderProducts li.card-product p.fixedHeight {
  height: 100px;
}

.card-product:last-child {
  margin-right: 40px;
}

.product-title {
  white-space: break-spaces;
  text-align: left;
  padding: 4px;
}

:deep(.total-steps-2 .step-progress__bar .step-progress__step-label),
:deep(.total-steps-3 .step-progress__bar .step-progress__step-label) {
  max-width: none;
  width: 70px;
}

.sideInformation {
  font-size: 12px;
  float: left;
  margin-top: 3px;
  margin-left: 30px;
  clear: left;
}
.headerInformation {
  font-size: 14px;
  float: left;
  margin-top: 3px;
  margin-left: 30px;
  clear: left;
  font-weight: bold;
}
.radio-selection {
  box-sizing: border-box;
  width: 100%;
}
.footer {
  font-size: 12px;
  float: left;
  margin-top: 1px;
  margin-left: 50px;
  clear: left;
  margin-bottom: 2px;
}
.radio-label {
  background-color: #d6d8db;
  border: 0.5px dashed rgb(184, 178, 178);
  margin-bottom: 0;
}

input[type='radio'] {
  accent-color: var(--primary);
  float: right;
  border: 0px;
  width: 30%;
  height: 2em;
  margin: 20px;
}
</style>
