<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="image-home mx-auto text-center">
    <!-- <h2 class="text-home fs-14 fs-md-18 fw-200 mt-4 mb-3 text-primary">
      <span v-if="!description">
        {{ $t('salutation') }}
      </span>
      <span
        v-if="description"
        v-html="description"
      />
    </h2> -->
    <router-link
      v-if="buttonUrl && buttonTitle"
      class="btn btn-primary mb-5"
      :to="buttonUrl"
      :href="buttonUrl"
    >
      {{ buttonTitle }}
    </router-link>
    <ImgGeneral
      :url="image"
      class="mt-2 mb-4 w-100"
    />
  </div>
</template>

<script>
import ImgGeneral from '@/components/shared/ImgGeneral';

export default {
  components: {
    ImgGeneral,
  },
  props: {
    description: {
      type: String,
      default: '',
    },
    buttonUrl: {
      type: String,
      default: '',
    },
    buttonTitle: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      default: '',
    },
  },
};
</script>
