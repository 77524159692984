<template>
  <div class="page__content">
    <Breadcrumb />
    <Carousel />
    <div
      v-if="loading"
      class="container max-section-width"
    >
      <div class="loading">
        <b-spinner label="Loading..." />
      </div>
    </div>
    <div
      v-else
      class="container max-section-width"
    >
      <b-row
        v-if="!error"
        class="filter-container my-2 justify-content-center"
      >
        <b-form-checkbox
          v-model="showFavorites"
          class="filter-checkbox text-center fs-14"
          plain
        >
          {{ $t('favorites.filters.myFavorites') }}
          <b-icon
            variant="danger"
            class="h4 m-0"
            :icon="'heart-fill'"
          />
        </b-form-checkbox>

        <b-form-checkbox
          v-model="showRecommended"
          class="filter-checkbox text-center fs-14"
          plain
        >
          {{ $t('favorites.filters.winnieSmartPicks') }}
          <b-icon
            variant="warning"
            class="h4 m-0"
            :icon="'star-fill'"
          />
        </b-form-checkbox>
      </b-row>
      <div class="row">
        <ul class="col list-unstyled d-flex flex-wrap justify-content-center">
          <li
            v-for="product in filteredSortedProducts"
            :key="product.product.sku"
            class="product__box"
          >
            <Product
              :product="product"
              @add-to-cart="addItemToCart"
            />
          </li>
        </ul>
      </div>
      <div class="row mt-5">
        <NotFound
          v-if="error || (products.length === 0 && !loading)"
          msg="notFound.favorites"
        />
        <div class="col">
          <div
            v-if="
              !error &&
              products.length > 0 &&
              filteredSortedProducts.length === 0
            "
            class="text-center"
          >
            <div class="mb-4">
              <p>{{ $t('favorites.emptyListMessage.title') }}</p>
            </div>

            <div>
              <p class="message">
                {{ $t('favorites.emptyListMessage.firstLine') }} <br />
                {{ $t('favorites.emptyListMessage.secondLine') }}
                <b-icon
                  variant="danger"
                  class="h4 m-0"
                  :icon="'heart-fill'"
                />
                {{ $t('favorites.emptyListMessage.thirdLine') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Monitor />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { MODULES } from '@/constants/feature';
import Product from '@/components/product/Product.vue';
import Breadcrumb from '@/components/shared/Breadcrumb.vue';
import Monitor from '@/components/Monitor.vue';
import Carousel from '@/components/Carousel.vue';
import NotFound from '@/components/NotFound.vue';

export default {
  components: {
    Product,
    Breadcrumb,
    Monitor,
    Carousel,
    NotFound,
  },
  data() {
    return {
      error: this.$store.state.search.error,
      appModules: MODULES,
      showFavorites: true,
      showRecommended: true,
      loading: true,
    };
  },
  computed: {
    products() {
      return this.$store.state.search.products;
    },
    filteredSortedProducts() {
      return this.products
        .filter(
          (p) =>
            (this.showFavorites && p.product.isFavorite) ||
            (this.showRecommended && p.product.isRecommended)
        )
        .sort((a, b) => {
          // First is both recommend and favorite
          if (
            a.product.isFavorite &&
            a.product.isRecommended &&
            (!b.product.isFavorite || !b.product.isRecommended)
          ) {
            return -1;
          }

          // second is favorite without recommend
          if (
            a.product.isFavorite &&
            !a.product.isRecommended &&
            !b.product.isFavorite &&
            b.product.isRecommended
          ) {
            return -1;
          }

          // third is recommend only
          return 0;
        });
    },
  },
  watch: {
    products() {
      this.loading = false;
    },
  },
  async created() {
    if (typeof this.products !== 'undefined' && this.products.length > 0) {
      this.loading = false;
    }
  },
  methods: {
    ...mapActions(['addItemToCart']),
  },
};
</script>

<style scoped>
::v-deep .form-check-input {
  accent-color: var(--dark-grey);
  transform: scale(1.15) translate(0, 20%);
}
.form-check-label > .bi-heart-fill {
  transform: translate(0, 5%);
}
.filter-checkbox {
  margin: 0 20px;
}

.message {
  font-weight: 100;
}

@media (max-width: 550px) {
  /* mobile devices */
  .filter-container {
    justify-content: flex-start !important;
    margin: 0 0;
  }
  .filter-checkbox {
    flex: 1;
    margin: 0 0px;
    margin-left: 10px;
  }
}
</style>
