import { get } from '@/utils/httpClient';
import { getStorageBottler, getSessionData } from '@/utils/config';
import { STORAGE_KEY } from '@/constants/storage';

async function getProducts() {
  const bottlerStorage = getStorageBottler();
  const language = getSessionData(STORAGE_KEY.language);

  const result = await get({
    url: 'products',
    config: { params: { language: encodeURIComponent(language) } },
    useAzureAPIManagement: bottlerStorage?.useAzureAPIManagement,
  });

  if (result.status) {
    return result.status === 200 && result.data ? result.data : undefined;
  }

  return result.message.includes('timeout') ? undefined : [];
}

export default { getProducts };
