<template>
  <div class="w-100 text-center mt-5">
    <b-iconstack font-scale="6">
      <b-icon
        stacked
        icon="clock-fill"
        variant="danger"
      />
    </b-iconstack>
    <div class="m-3 fw-200">
      {{ $t('noResponse') }}
    </div>
    <div class="m-3 fw-200">
      {{ $t('retry') }}
    </div>
  </div>
</template>

<script>
export default {};
</script>
