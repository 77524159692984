<template>
  <div>
    <b-modal
      v-model="isAlertModalActive"
      :title="$t('cartPage.cutOffAlertTitle')"
      centered
      ok-only
      :ok-title="$t('ok')"
      header-text-variant="primary"
      header-close-variant="dark"
      ok-variant="secondary"
      no-close-on-backdrop
      @ok="setCartAlertAcknowledgedA"
    >
      <p>{{ $t('cartPage.cartCutOffAlert') }}</p></b-modal
    >
    <b-modal
      v-model="isAlertModal2Active"
      :title="$t('cartPage.cutOffAlertTitle')"
      centered
      ok-only
      header-text-variant="primary"
      header-close-variant="dark"
      :ok-title="$t('ok')"
      ok-variant="secondary"
      no-close-on-backdrop
      @ok="setCartAlertAcknowledgedA"
    >
      <p>{{ $t('cartPage.cartCutOffAlert') }}</p>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Timer from '@/utils/timer';
import { getSessionData } from '@/utils/config';

export default {
  name: 'CartModalPopup',
  components: {},
  data() {
    return {
      isAlertModalActive: false,
      isAlertModal2Active: false,
    };
  },
  computed: {
    ...mapGetters({
      client: 'getClient',
      isCartAlertAcknowledged: 'getCartAlertAcknowledged',
    }),
  },
  watch: {
    client() {
      this.isWillFireModel();
    },
  },
  created() {
    this.isWillFireModel();
  },
  methods: {
    isWillFireModel() {
      // timer for modal popup
      Timer.waitForTime(17, 0, this.showAlertModal); // 5:00 PM
      // Create a new DateTimeFormat object with the Thai time zone
      // const dateTimeFormat = new Intl.DateTimeFormat('en-US', {
      //   timeZone: 'Asia/Bangkok',
      //   year: 'numeric',
      //   month: 'numeric',
      //   day: 'numeric',
      //   hour: 'numeric',
      //   minute: 'numeric',
      //   hourCycle: 'h23',
      // });

      // reset the cart at 530pm, if the user has been on the app before 530pm
      Timer.waitForTime(
        17,
        30,
        this.client.deliveryDate ? this.resetCartDate : () => undefined
        // check if deliverydate is set, if not then dont reset date
      ); // 5:30 PM

      // Get the current time in the Thai time zone
      const currentTime = new Date();
      // get the end time
      const startTime = new Date(new Date(currentTime).setHours(17, 0)); // 5:01 PM
      const endTime = new Date(new Date(currentTime).setHours(17, 29)); // 5:29 PM
      if (currentTime >= startTime && currentTime <= endTime) {
        const filteredDates = this.client.deliveryDates.filter(
          (d) => d.IsInbook === true && d.MinimumValue !== null
        );

        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        const tomorrowString = tomorrow.toISOString().slice(0, 10);

        if (
          !this.isCartAlertAcknowledged &&
          filteredDates.length > 0 &&
          new Date(tomorrowString) >= new Date(filteredDates[0].Date) &&
          this.client.distance === 'D'
        ) {
          // calling close cut-off alert if we have the next day delivery btw 5-5:30 for zone D
          this.showAlertModal2();
        } else if (
          !this.isCartAlertAcknowledged &&
          this.client.distance !== 'D'
        ) {
          if (
            this.client.deliveryDates.length > 0 &&
            new Date(tomorrowString) >=
              new Date(this.client.deliveryDates[0].Date) &&
            this.client.distance !== 'D'
          ) {
            // calling close cut-off alert if we have the next day delivery btw 5-5:30 for all zones expect Zone D
            this.showAlertModal2();
          }
        }
      }
    },
    showAlertModal() {
      if (getSessionData('isAuthenticated') === true) {
        this.isAlertModal2Active = true;
      }
    },
    showAlertModal2() {
      if (getSessionData('isAuthenticated') === true) {
        this.isAlertModal2Active = true;
      }
    },
    setCartAlertAcknowledgedA() {
      this.$store.commit('setCartAcknowlegement', true);
    },
    resetCartDate() {
      const date = this.client.deliveryDate;
      if (date) {
        // formatting selected date to compare with tomorrow date
        const formattedSelectedDate = `${date.slice(0, 4)}-${date.slice(
          4,
          6
        )}-${date.slice(6, 8)}`;

        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        const tomorrowString = tomorrow.toISOString().slice(0, 10);

        if (formattedSelectedDate === tomorrowString) {
          // leave this.client.deliveryDateSelected as it is, required for cutoff popup
          this.client.deliveryDate = '';
          this.$store.commit('setClient', this.client);
        }
      }
    },
  },
};
</script>
