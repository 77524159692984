<template>
  <div class="py-4 card-body d-inline-block">
    <div v-show="!showEndMessage">
      <p class="fw-200 mx-auto mb-4 max-w-500">
        {{ $t('order.complete.nps.info') }}
      </p>
      <button
        v-for="(n, index) in 11"
        :id="`npsButton-${index}`"
        :key="index"
        class="ml-1 mb-1 btn btn-outline"
        :class="{ active: index === nps }"
        @click="selectScore(index)"
      >
        {{ index }}
      </button>
    </div>
    <div
      v-if="showFeedback"
      class="feedback-wrapper d-flex flex-wrap mx-auto justify-content-center"
    >
      <form @submit.prevent="submitFeedback">
        <label
          for="feedback"
          class="pt-3 mb-3 fw-200 d-block mx-auto"
        >
          {{ $t('order.complete.nps.detractorMessage') }}
        </label>
        <textarea
          id="feedback"
          v-model="userFeedback"
          class="fs-12 fw-200 p-2 w-100"
        />
        <button
          class="btn btn-primary mt-3"
          type="submit"
        >
          {{ $t('order.complete.nps.sendFeedbackBtn') }}
        </button>
      </form>
    </div>
    <div v-show="showEndMessage">
      <p
        id="acknowledgment"
        class="fs-18 mx-auto"
      >
        {{ $t('order.complete.nps.thank') }}
      </p>
      <p class="d-block fw-200 fs-16 mx-auto">
        {{ $t('order.complete.nps.endMessage') }}
      </p>
    </div>
  </div>
</template>

<script>
import apiUpdateUserSession from '@/api/userSession';
import { scrollToElement } from '@/utils/pages';
import Analytics from '@/utils/analytics';
import analyticsEvents from '@/utils/events/analytics';

export default {
  data() {
    return {
      nps: '',
      userFeedback: '',
      showFeedback: false,
      showEndMessage: false,
    };
  },
  mounted() {
    Analytics.logEvent(analyticsEvents.EVENTS.NPS, {});
  },
  methods: {
    async submitFeedback() {
      await apiUpdateUserSession.updateUserSession(
        'feedback',
        this.userFeedback
      );
      Analytics.logEvent(analyticsEvents.EVENTS.NPS_FEEDBACK, {
        feedback: this.userFeedback,
      });
      this.showEndMessage = true;
      this.showFeedback = false;
    },
    async selectScore(score) {
      this.nps = score;
      await this.submitReview(this.nps);
      Analytics.logEvent(analyticsEvents.EVENTS.NPS_EVALUATION, {
        score: this.nps,
      });
      if (score <= 6) {
        this.showFeedback = true;
        setTimeout(() => {
          scrollToElement('#feedback');
        }, 200);
        return;
      }
      this.showFeedback = false;
      this.showEndMessage = true;
    },
    async submitReview() {
      await apiUpdateUserSession.updateUserSession('nps', this.nps);
    },
  },
};
</script>

<style scoped>
.btn-outline {
  border-width: 1px;
}
.btn-outline.active {
  background-color: var(--primary);
  color: var(--light);
}
.btn-outline:hover,
.btn-outline:focus {
  filter: brightness(0.9);
}
.feedback-wrapper {
  max-width: 300px;
}
#feedback {
  resize: none;
}
</style>
