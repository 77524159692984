<template>
  <div class="page__content">
    <Breadcrumb />
    <div
      v-if="loading"
      class="spinner-border spinner-center"
      role="status"
    >
      <div>
        <span class="sr-only center">Loading...</span>
      </div>
    </div>

    <div
      v-else
      class="container max-section-width pt-0"
    >
      <div class="row">
        <div class="col fw-500 text-center max-w-700 mx-auto mt-3">
          <p class="w-100 fs-12 fs-md-14">
            {{ client.name ? client.name : 'Thainamthip Winnie' }}
          </p>
        </div>
      </div>
      <div class="section card-body mb-1">
        <div
          v-if="showOverlay"
          class="overlay"
        >
        </div>
        <div class="content">
          <div class="d-flex justify-content-between">
            <div class="flex-fill">
              <p class="fs-12 fw-100 mb-0">{{
                $t('creditPage.creditLimit')
              }}</p>

              <p class="fs-18 fw-600">{{
                formatPrice(Number(credits.limit))
              }}</p>
              <b-progress
                :value="Number(credits.used)"
                :max="Number(credits.limit)"
                class="mb-3"
                variant="danger"
              />
            </div>
          </div>
          <div class="d-flex justify-content-between mb-2">
            <p>
              <span class="fs-10 fw-100">{{ $t('creditPage.spent') }}</span>
              <br />
              <span class="fs-12 fw-600">{{
                formatPrice(Number(credits.used))
              }}</span>
            </p>
            <div class="text-right">
              <b-icon
                v-if="Number(credits.balance) < 0"
                icon="exclamation-triangle-fill"
                font-scale="1"
                variant="danger"
              >
              </b-icon>
              <span class="fs-10 fw-100">
                {{
                  Number(credits.balance) < 0
                    ? $t('creditPage.overdrawn')
                    : $t('creditPage.remaining')
                }}</span
              >
              <br />
              <span
                class="fs-12 fw-600"
                :class="{ red: Number(credits.balance) < 0 }"
                >{{ formatPrice(Number(credits.balance)) }}</span
              >
            </div>
          </div>
          <div
            v-if="Number(credits.balance) < 0"
            class="d-flex justify-content-center"
          >
            <div class="inner-box mb-2 mt-1 fs-24 fw-200 text-left text-center">
              <div class="d-flex">
                <div class="d-flex justify-content-center">
                  <b-icon
                    icon="exclamation-triangle-fill"
                    variant="danger"
                    class="d-flex mr-1 mt-1 fs-12"
                    font-scale="1"
                  />
                  <p
                    class="mb-1 fs-10 text-danger mr-1 fs-12 fw-300 text-center"
                    >{{ $t('creditPage.creditLimitExceeded') }}</p
                  >
                </div>
              </div>
              <p class="mb-1 fs-12 text-left">
                {{ $t('contactSalesTeam') }}
              </p>

              <p class="mb-1 fs-12 text-left">
                {{ $t('contactSale') }}
                <span
                  class="phone-number font-weight-bold"
                  @click="handlePhoneClick"
                  >{{ $t('phoneNumber') }}</span
                >
              </p>
            </div>
          </div>
        </div>
      </div>
      <span class="fs-10 fw-200 grey">
        <b-icon
          icon="info-circle"
          font-scale="1"
        >
        </b-icon>
        {{ $t('creditPage.creditNotice') }}
      </span>
      <p class="mt-3 mb-0 fs-10">{{ $t('creditPage.duePayments') }}</p>

      <div
        v-if="credits.due && credits.due.length !== 0"
        class="mt-3"
      >
        <div
          v-for="duePayment in credits.due"
          :key="duePayment.index"
        >
          <div class="section card-body mb-1">
            <div class="d-flex justify-content-between mb-2">
              <div class="w-100">
                <span v-if="isDateOverdue(duePayment.date)">
                  <span class="d-block"
                    ><b-badge
                      pill
                      variant="danger"
                      ><span class="fs-10 fw-200">{{
                        $t('creditPage.overdue')
                      }}</span></b-badge
                    ></span
                  >
                </span>
              </div>
            </div>
            <div class="d-flex justify-content-between mb-2">
              <p>
                <span class="fs-10 fw-100">{{ $t('creditPage.dueDate') }}</span>
                <br />
                <span class="fs-12 fw-600">{{
                  formatDate(duePayment.date)
                }}</span>
              </p>
              <div class="text-right">
                <span class="fs-10 fw-100">{{ $t('creditPage.amount') }}</span>
                <br />
                <span class="fs-12 fw-600">{{
                  formatPrice(Number(duePayment.amount))
                }}</span>
              </div>
            </div>
            <span
              v-if="isDateOverdue(duePayment.date)"
              class="fs-10 fw-200 grey"
            >
              <b-icon
                icon="info-circle"
                font-scale="1"
                variant="danger"
              ></b-icon>
              {{ $t('creditPage.overdueResolve') }}
            </span>
          </div>
        </div>
      </div>
      <div
        v-else
        class="pb-3"
      >
        <span class="fs-10 fw-200">
          {{ $t('creditPage.noDuePayment') }}
        </span>
      </div>

      <span class="fs-10 fw-200 grey">
        <b-icon
          icon="info-circle"
          font-scale="1"
        >
        </b-icon>
        {{ $t('creditPage.duePaymentInquiry') }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Breadcrumb from '@/components/shared/Breadcrumb.vue';
import Common from '@/utils/common';

export default {
  components: {
    Breadcrumb,
  },
  data() {
    return {
      credits: {
        limit: 0,
        used: 0,
        balance: 0,
        due: [],
      },
      loading: true,
    };
  },
  computed: {
    ...mapGetters({
      client: 'getClient',
      userCredits: 'getCredits',
      isOverDue: 'getIsDue',
    }),
    showOverlay() {
      return this.isOverDue && this.client.paymentType === 'Cash';
    },
    isMobile() {
      return (
        window.innerWidth <= 768 &&
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      );
    },
  },
  watch: {
    userCredits() {
      this.credits = this.userCredits;
      this.loading = false;
    },
  },
  created() {
    if (typeof this.userCredits.limit === 'number') {
      this.credits = this.userCredits;
      this.loading = false;
    }
  },
  methods: {
    isDateOverdue(date) {
      return Common.checkDateOverdue(date);
    },
    formatPrice(price) {
      let priceCheck = Number.isNaN(parseInt(price)) ? 0 : price;
      if (typeof priceCheck === 'number') {
        priceCheck = priceCheck.toFixed(2);
      }
      priceCheck = Number(priceCheck).toLocaleString('th-TH', {
        style: 'currency',
        currency: 'THB',
      });
      return priceCheck;
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date
        .toLocaleDateString({
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          timeZone: 'UTC',
        })
        .replace(/\//g, '/');
    },
    handlePhoneClick() {
      if (this.isMobile) {
        window.location.href = `tel:${this.$t('phoneNumber')}`;
      }
    },
  },
};
</script>

<style scoped>
.text-right {
  text-align: right;
}

.grey {
  color: #747474;
}

.red {
  color: #f00;
}

.inner-box {
  border: 1px solid red;
  border-radius: 10px;
  padding: 15px;
  margin: 5px; /* Added margin to the red inner box */
}

.phone-number {
  color: black;
  font-weight: bold;
  text-decoration: underline;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
  border-radius: 10px;
  pointer-events: none;
}

.section.card-body.mb-1 {
  position: relative;
}
</style>
