<template>
  <div class="w-90 ml-1 mr-1 mt-2">
    <div v-if="loadDates">
      <div class="flex-wrap fs-14">
        <div class="header-delivery text-light fs-14">
          <span>
            {{ $t('deliveryDateSelector.headerTierB') }}
          </span>
        </div>
        <div>
          <Radio
            :options="deliveryDates"
            @selected="setMinimumOrder"
          />
        </div>
      </div>
    </div>
    <div v-else>
      <div>
        <span class="sr-only center">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Radio from '@/components/shared/Radio';
import { getAssetsPath } from '@/utils/config';
import ProductMixin from '@/components/mixins/ProductMixin';
import router from '@/router';
import Common from '@/utils/common';

export default {
  components: {
    Radio,
  },
  mixins: [ProductMixin],
  data() {
    return {
      deliveryDates: [],
      deliveryDatesFiltered: [],
      expressDeliveryObj: {},
      weeklyDeliveryObj: {},
      weeklyDeliveryObj2: {},
      weeklyDeliveryObj3: {},
      weeklyDeliveryObj4: {},
      displaySelector: false,
      formattedDate: '',
      footer: '',
      header: '',
      assetsPath: getAssetsPath(),
      loadDates: false,
    };
  },
  computed: {
    ...mapGetters({
      client: 'getClient',
      isCartCutoffAcknowledged: 'getCartCutoffAcknowledged',
    }),
  },
  watch: {
    // eslint-disable-next-line func-names
    async client() {
      await this.getDeliveryDates();
      this.loadDates = true;
    },
    // eslint-disable-next-line func-names
    '$i18n.locale': async function () {
      await this.getDeliveryDates();
    },
  },
  async created() {
    if (this.client.deliveryDates?.length > 0) {
      await this.getDeliveryDates();
      this.loadDates = true;
    }
  },
  methods: {
    ...mapActions(['setClient']),
    goToProductsPage() {
      router.navigateToPage('/category');
    },
    getFooterString(minimumOrder) {
      const price = Number(minimumOrder).toLocaleString('th-TH', {
        style: 'currency',
        currency: 'THB',
      });
      return `${price.split('.')[0]}`;
    },
    setMinimumOrder(value) {
      // prevent undefined
      if (value) {
        this.client.minimumOrder = Number(value.MinimumValue);
        this.client.deliveryDateSelected = value.header;
        this.client.deliveryDate = value.Date.split('-').join('');
        this.$store.commit('setClient', this.client);
      }
    },
    getDateString(deliveryDate) {
      const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        timeZone: 'UTC',
      };
      const nextDate = new Date(deliveryDate);
      this.formattedDate = nextDate.toLocaleString(
        this.$root.$i18n.locale,
        options
      );
      return this.formattedDate;
    },
    getDeliveryDates() {
      const currentTime = new Date();
      const endTime = new Date();
      endTime.setHours(17, 30); // 5:30 PM
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      let tomorrowString = tomorrow.toISOString().slice(0, 10);

      this.deliveryDates = [];
      // filter out nulls
      this.deliveryDatesFiltered = this.client.deliveryDates.filter(
        (item) => item !== null && item !== undefined
      );

      // while tomorrow is a sunday or public holiday
      while (
        tomorrow.getDay() === 0 ||
        Common.isAPublicHoliday(tomorrowString)
      ) {
        tomorrow.setDate(tomorrow.getDate() + 1);
        tomorrowString = tomorrow.toISOString().slice(0, 10);
      }

      // if weekend or public holiday ; then increment tomorrow until it is a non weekend/public holiday
      // skip the first date from the delivery dates
      if (
        (currentTime >= endTime &&
          new Date(tomorrowString) >=
            new Date(this.deliveryDatesFiltered[0].Date)) ||
        (currentTime.getDay === 6 &&
          new Date() >= new Date(this.deliveryDatesFiltered[0].Date))
      ) {
        // past 5:30 - show different dates
        // skip first date
        this.$store.commit('setCartCutoffAcknowlegement', true);
      }

      this.displaySelector = !this.deliveryDatesFiltered[0]?.IsInbook;
      [this.expressDeliveryObj = {}] = this.deliveryDatesFiltered || [];

      if (this.isCartCutoffAcknowledged && this.client?.distance === 'C') {
        [, this.expressDeliveryObj = {}] = this.deliveryDatesFiltered || [];
      }

      // test if customer is in Zone A
      const isCustomerTierA = this.deliveryDatesFiltered.every(
        (item) => item.IsInbook === true
      );

      this.expressDeliveryObj.header = '1';
      this.expressDeliveryObj.sideInformation = this.getDateString(
        this.expressDeliveryObj.Date
      );
      this.expressDeliveryObj.footer = this.getFooterString(
        this.expressDeliveryObj.MinimumValue
      );

      let secondInBookElement = {};
      // get the second isInBook element
      let count = 0;
      secondInBookElement = this.deliveryDatesFiltered.find((item) => {
        if (item.IsInbook === true) {
          count++;
          return count === 2;
        }
        return true;
      });

      if (
        (isCustomerTierA || this.expressDeliveryObj.IsInbook) &&
        this.client?.distance !== 'D'
      ) {
        this.weeklyDeliveryObj = secondInBookElement;
      } else if (this.client?.distance === 'C') {
        this.weeklyDeliveryObj = this.deliveryDatesFiltered.find(
          (item) => item.IsInbook === true
        );
      } else if (this.client?.distance === 'D') {
        // ZONE D-Section
        const isInbookArray = this.deliveryDatesFiltered.filter(
          (d) => d.IsInbook === true && d.MinimumValue !== null
        );
        // first obj
        const expressDeliveryObj = isInbookArray[0];
        if (expressDeliveryObj !== undefined) {
          this.expressDeliveryObj = JSON.parse(
            JSON.stringify(expressDeliveryObj)
          );
          this.expressDeliveryObj.sideInformation = this.getDateString(
            this.expressDeliveryObj.Date
          );
          this.expressDeliveryObj.header = '1';
          this.expressDeliveryObj.selected = false;

          this.expressDeliveryObj.footer = this.getFooterString(
            this.expressDeliveryObj.MinimumValue
          );
          this.deliveryDates.push(this.expressDeliveryObj);
        }

        // second obj
        const weeklyDeliveryObj = isInbookArray[1];
        if (weeklyDeliveryObj !== undefined) {
          this.weeklyDeliveryObj = JSON.parse(
            JSON.stringify(weeklyDeliveryObj)
          );
          this.weeklyDeliveryObj.sideInformation = this.getDateString(
            this.weeklyDeliveryObj.Date
          );

          this.weeklyDeliveryObj.header = ' ';
          this.weeklyDeliveryObj.selected = false;

          this.weeklyDeliveryObj.footer = this.getFooterString(
            this.weeklyDeliveryObj.MinimumValue
          );
          this.deliveryDates.push(this.weeklyDeliveryObj);
        }

        // third obj
        const thirdIsInbook = isInbookArray[2];
        if (thirdIsInbook !== undefined) {
          this.weeklyDeliveryObj2 = JSON.parse(JSON.stringify(thirdIsInbook));

          this.weeklyDeliveryObj2.sideInformation = this.getDateString(
            this.weeklyDeliveryObj2.Date
          );

          this.weeklyDeliveryObj2.header = '  ';
          this.weeklyDeliveryObj2.selected = false;

          this.weeklyDeliveryObj2.footer = this.getFooterString(
            this.weeklyDeliveryObj2.MinimumValue
          );
          this.deliveryDates.push(this.weeklyDeliveryObj2);
        }

        // fourth obj
        const fourthIsInbook = isInbookArray[3];
        if (fourthIsInbook !== undefined) {
          this.weeklyDeliveryObj3 = JSON.parse(JSON.stringify(fourthIsInbook));
          this.weeklyDeliveryObj3.sideInformation = this.getDateString(
            this.weeklyDeliveryObj3.Date
          );

          this.weeklyDeliveryObj3.header = '   ';
          this.weeklyDeliveryObj3.selected = false;

          this.weeklyDeliveryObj3.footer = this.getFooterString(
            this.weeklyDeliveryObj3.MinimumValue
          );
          this.deliveryDates.push(this.weeklyDeliveryObj3);
        }

        if (
          currentTime >= endTime &&
          new Date(isInbookArray[0].Date) > new Date(tomorrowString)
        ) {
          // no shift
        } else if (this.isCartCutoffAcknowledged) {
          this.deliveryDates.shift();
        }
      } else {
        // find the next delivery date after the express delivery date
        this.weeklyDeliveryObj = this.deliveryDatesFiltered.find(
          (item) => item.Date > this.expressDeliveryObj.Date
        );
      }
      if (this.client?.distance !== 'D') {
        if (this.client?.distance !== 'C') {
          this.weeklyDeliveryObj.header = ' ';
        } else {
          this.weeklyDeliveryObj.header = this.$t(
            'deliveryDateSelector.weeklyDelivery'
          );
        }

        this.weeklyDeliveryObj.sideInformation = this.getDateString(
          this.weeklyDeliveryObj.Date
        );
        this.weeklyDeliveryObj.footer = this.getFooterString(
          this.weeklyDeliveryObj.MinimumValue
        );

        this.deliveryDates.push(this.expressDeliveryObj);
        this.deliveryDates.push(this.weeklyDeliveryObj);
      }
      if (this.client?.distance !== 'C' && this.client?.distance !== 'D') {
        const isInbookArray = this.deliveryDatesFiltered.filter((item) => {
          return (
            new Date(new Date(item.Date).toDateString()) >
            new Date(new Date(this.weeklyDeliveryObj.Date).toDateString())
          );
        });

        const thirdIsInbook = isInbookArray[0];
        this.weeklyDeliveryObj2 = JSON.parse(JSON.stringify(thirdIsInbook));

        this.weeklyDeliveryObj2.sideInformation = this.getDateString(
          this.weeklyDeliveryObj2.Date
        );

        this.weeklyDeliveryObj2.header = '  ';
        this.weeklyDeliveryObj2.selected = false;

        this.weeklyDeliveryObj2.footer = this.getFooterString(
          this.weeklyDeliveryObj2.MinimumValue
        );

        const fourthIsInbook = isInbookArray[1];
        this.weeklyDeliveryObj3 = JSON.parse(JSON.stringify(fourthIsInbook));

        this.weeklyDeliveryObj3.sideInformation = this.getDateString(
          this.weeklyDeliveryObj3.Date
        );

        this.weeklyDeliveryObj3.header = '   ';
        this.weeklyDeliveryObj3.selected = false;

        this.weeklyDeliveryObj3.footer = this.getFooterString(
          this.weeklyDeliveryObj3.MinimumValue
        );

        this.deliveryDates.push(this.weeklyDeliveryObj2);
        this.deliveryDates.push(this.weeklyDeliveryObj3);

        if (this.isCartCutoffAcknowledged) {
          this.deliveryDates.shift();

          const nextIsInbook = isInbookArray[2];
          this.weeklyDeliveryObj4 = JSON.parse(JSON.stringify(nextIsInbook));

          this.weeklyDeliveryObj4.sideInformation = this.getDateString(
            this.weeklyDeliveryObj4.Date
          );

          this.weeklyDeliveryObj4.header = '    ';
          this.weeklyDeliveryObj4.selected = false;

          this.weeklyDeliveryObj4.footer = this.getFooterString(
            this.weeklyDeliveryObj4.MinimumValue
          );
          this.deliveryDates.push(this.weeklyDeliveryObj4);
        }
      }
      if (!this.client.deliveryDateSelected) {
        this.expressDeliveryObj.selected = false;
        this.weeklyDeliveryObj.selected = false;
        this.weeklyDeliveryObj2.selected = false;
        this.weeklyDeliveryObj3.selected = false;
        this.weeklyDeliveryObj4.selected = false;
      } else if (this.client.deliveryDateSelected === '1') {
        this.expressDeliveryObj.selected = true;
        this.weeklyDeliveryObj.selected = false;
        this.weeklyDeliveryObj2.selected = false;
        this.weeklyDeliveryObj3.selected = false;
        this.weeklyDeliveryObj4.selected = false;
        this.client.deliveryDate =
          this.expressDeliveryObj.Date.split('-').join('');
      } else if (this.client.deliveryDateSelected === ' ') {
        this.expressDeliveryObj.selected = false;
        this.weeklyDeliveryObj.selected = true;
        this.weeklyDeliveryObj2.selected = false;
        this.weeklyDeliveryObj3.selected = false;
        this.weeklyDeliveryObj4.selected = false;
        this.client.deliveryDate =
          this.weeklyDeliveryObj.Date.split('-').join('');
      } else if (this.client.deliveryDateSelected === '  ') {
        this.expressDeliveryObj.selected = false;
        this.weeklyDeliveryObj.selected = false;
        this.weeklyDeliveryObj2.selected = true;
        this.weeklyDeliveryObj3.selected = false;
        this.weeklyDeliveryObj4.selected = false;
        this.client.deliveryDate =
          this.weeklyDeliveryObj2.Date.split('-').join('');
      } else if (this.client.deliveryDateSelected === '   ') {
        this.expressDeliveryObj.selected = false;
        this.weeklyDeliveryObj.selected = false;
        this.weeklyDeliveryObj2.selected = false;
        this.weeklyDeliveryObj3.selected = true;
        this.weeklyDeliveryObj4.selected = false;
        this.client.deliveryDate =
          this.weeklyDeliveryObj3.Date.split('-').join('');
      } else if (this.client.deliveryDateSelected === '    ') {
        this.expressDeliveryObj.selected = false;
        this.weeklyDeliveryObj.selected = false;
        this.weeklyDeliveryObj2.selected = false;
        this.weeklyDeliveryObj3.selected = false;
        this.weeklyDeliveryObj4.selected = true;
        this.client.deliveryDate =
          this.weeklyDeliveryObj3.Date.split('-').join('');
      }
      // else {
      //   this.expressDeliveryObj.selected = false;
      //   this.weeklyDeliveryObj.selected = false;
      //   this.weeklyDeliveryObj2.selected = false;
      //   this.weeklyDeliveryObj3.selected = false;
      //   this.weeklyDeliveryObj4.selected = false;
      //   this.client.deliveryDate =
      //     this.expressDeliveryObj.Date.split('-').join('');
      // }
    },
  },
};
</script>

<style scoped>
@media all and (min-width: 1024px) {
  .card-body {
    max-width: 60%;
    margin: 0 auto;
    border-radius: 0;
  }
}

.header-delivery {
  border: 0;
  background-color: var(--primary);
  width: 100% !important;
  text-align: center;
  height: 40px;
  padding-top: 10px;
}

.selector-container {
  display: flex;
  flex-wrap: wrap;
}

.headerInformation {
  font-size: 14px;
  float: left;
  margin-top: 3px;
  margin-left: 30px;
  clear: left;
  font-weight: bold;
}

.footer {
  font-size: 12px;
  float: left;
  margin-top: 1px;
  margin-left: 50px;
  clear: left;
  margin-bottom: 2px;
}

.tier-selection {
  box-sizing: border-box;
  width: 100%;
  background-color: #d6d8db;
  border: 0.5px dashed rgb(184, 178, 178);
  margin-bottom: 0;
}

.sideInformation {
  font-size: 12px;
  float: left;
  margin-top: 3px;
  margin-left: 30px;
  clear: left;
}
</style>
