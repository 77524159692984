import { get } from '@/utils/httpClient';

async function getBanners() {
  const result = await get({
    url: 'ecommerce/banner',
  });

  return result && result.status === 200 ? result.data : undefined;
}

export default {
  getBanners,
};
