import { put } from '@/utils/httpClient';

async function registerServiceTracking({ origin, service, data }) {
  const result = await put({
    url: 'service/tracking',
    data: {
      origin,
      service,
      data,
    },
  });

  return result && result.status === 200 ? result.data : undefined;
}

export default { registerServiceTracking };
