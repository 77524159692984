<template>
  <div
    class="message-error d-flex justify-content-center align-items-center m-3"
  >
    <span
      class="form-validation-error"
      aria-hidden="true"
    />
    <div class="pl-3 fs-12 text-primary">
      <span class="d-block">{{ $t('login.errorLoginMsg') }}</span>
      <span>{{ $t('login.tryAgainMsg') }}</span>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
};
</script>

<style scoped>
.message-error {
  border: 1px solid var(--primary);
  padding: 12px;
}

.form-validation-error {
  width: 23px;
  height: 23px;
  background: var(--error-icon) no-repeat;
}
</style>
