import { post } from '@/utils/httpClient';

async function register(userOptIn) {
  const result = await post({
    url: 'optin/register/ecommerce',
    data: {
      userOptIn,
    },
  });

  return result.status === 200 && result.data ? result.data : undefined;
}

export default { register };
