<template>
  <div class="bingo-history">
    <h4>{{ $t('history') }}</h4>
    <table
      v-if="historyList.length > 0"
      :aria-label="$t('bingo.historyTable')"
      class="bingo-history-table"
    >
      <thead>
        <tr class="bingo-history-table-header">
          <th>
            {{ $t('period') }}
          </th>
          <th>
            {{ $t('prize') }}
          </th>
          <th>
            {{ $t('status') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="h in historyList"
          :key="h.period"
          class="bingo-history-table-content"
        >
          <td>
            {{ h.period }}
          </td>
          <td>
            {{ getBingoPrizeProductsText({ prizeProducts: h.prizeProducts }) }}
          </td>
          <td>
            {{ h.status }}
          </td>
        </tr>
      </tbody>
    </table>
    <div v-else>
      <p class="bingo-text">
        {{ $t('bingo.emptyHistory') }}
      </p>
    </div>
  </div>
</template>

<script>
import { getBingoPrizeProductsText } from '@/utils/pages';
import Analytics from '@/utils/analytics';
import analyticsEvents from '@/utils/events/analytics';

export default {
  props: {
    history: {
      type: [Object, Array],
      required: true,
    },
  },
  data() {
    return {
      historyList: [],
    };
  },
  created() {
    this.historyList = this.getHistoryList();
  },
  methods: {
    // Normalize history to an array
    getHistoryList() {
      // TODO: Move this tracking to GTM triggers and remove from code
      Analytics.logEvent(analyticsEvents.EVENTS.BINGO_HISTORY, {
        description: JSON.stringify(this.history),
      });

      if (!this.history) return [];

      if (!Array.isArray(this.history)) return [this.history];

      return this.history;
    },
    getBingoPrizeProductsText,
  },
};
</script>

<style scoped>
.bingo-text {
  font-size: var(--fs-12);
  font-weight: 100;
  color: var(--dark-grey);
}

.bingo-history {
  padding: 15px 0;
  margin-top: 15px;
}

.bingo-history h4 {
  font-size: var(--fs-18);
  font-weight: 300;
  color: var(--primary);
}

.bingo-history-table {
  width: 100%;
}

.bingo-history-table thead {
  font-size: var(--fs-12);
  font-weight: 300;
}

.bingo-history-table-header th {
  padding: 5px 10px;
  font-weight: 300;
  color: var(--light);
  background-color: var(--dark-grey);
  border: 0;
}

.bingo-history-table-header th:first-child {
  border-top-left-radius: 5px;
}

.bingo-history-table-header th:last-child {
  border-top-right-radius: 5px;
}

.bingo-history-table tbody {
  font-size: var(--fs-12);
  font-weight: 100;
}

.bingo-history-table-content td {
  padding: 5px;
  color: var(--dark-grey);
  background-color: var(--light-grey);
  border: 0;
}

.bingo-history-table-content td:first-child {
  border-bottom-left-radius: 5px;
}

.bingo-history-table-content td:last-child {
  border-bottom-right-radius: 5px;
}
</style>
